import { and, Timestamp } from "@firebase/firestore";
import { notifications } from "@mantine/notifications";
import {
    addDoc,
    collection,
    collectionGroup,
    deleteDoc,
    doc,
    getDocsFromCache,
    getDocsFromServer,
    query,
    Query,
    QuerySnapshot,
    setDoc,
    where,
} from "firebase/firestore";
import { DateTime } from "luxon";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context";
import { useContext, useEffect, useMemo, useState } from "react";
import { Suggestion } from "use-places-autocomplete";
import { IconURLs, URLGroup, URLs } from '../components/PlaceSVGs';
import { useToday } from "../components/TripActivityScreen/dates";
import { AllTripActivitiesContext, HomeContext } from "./context";
import {
    FirebaseRefs,
    useFirebaseRefs,
    useMemoizedQuery,
} from "./firebase";
import {
    canNativelyGeocode,
    forwardGeocode,
    ForwardGeocodeResponse,
    forwardGeocodeViaNativeGeocoder,
} from "./geocoder";
import { getDetailsForPlaceId, getLocalityForPlaceId } from "./google";
import { saveConfirmationHaptic } from "./haptics";
import {
    MappableRecord,
    Place,
    PlaceClassType,
    placeConverter,
    PlaceRecord,
    PlaceType,
    SavedType,
    SearchEngineMappableRecord,
    SearchEngineResult,
} from "./interfaces";
import { getLogger } from "./logger";
import { Position, positionFromLatLng, PositionKey, positionToKey } from "./position";
import { isIdea, TSDate } from "./time";
import { UserData } from "./userdata";
import { MultiMap } from "../../shared/collections";

const logger = getLogger('placeFunctions');

export type AllTripActivities = {
    ideas: Place[] | undefined,
    past: Place[] | undefined,
    future: Place[] | undefined,
    today: Place[] | undefined,
    activityIdsByPositionKey: MultiMap<PositionKey, string>,
    activityIdsByAddressKey: MultiMap<string, string>,
    allActivities: Map<string, Place>,
};

export const placeReverseSorter = (a: Place, b: Place) => (b.startdatetime?.daysSinceEpoch ?? 0) - (a.startdatetime?.daysSinceEpoch ?? 0);
export const placeForwardSorter = (a: Place, b: Place) => (a.startdatetime?.daysSinceEpoch ?? 0) - (b.startdatetime?.daysSinceEpoch ?? 0);

function classifyPlace(place: Place | undefined, beginningOfToday: TSDate) {
    if (!place) {
        return undefined;
    } else if (isIdea(place.startdatetime)) {
        return 'idea';
    } else if (place.startdatetime.isBefore(beginningOfToday)) {
        return 'past';
    } else if (place?.startdatetime.isAfter(beginningOfToday)) {
        return 'future';
    } else {
        return 'today';
    }
}

async function getRecentActivitiesFromCache(firebaseRefs: FirebaseRefs, firebaseUserId: string): Promise<Place[] | undefined> {
    const initialLookbackTimestamp = DateTime.now().minus({ days: 30 });
    const recentActivitiesQuery = query(
        collectionGroup(firebaseRefs.firestore, "activities"),
        and(
            where('acl.readWriteIds', 'array-contains', firebaseUserId),
            where('startdatetime', '>=', Timestamp.fromMillis(initialLookbackTimestamp.toMillis())),
        )
    )
        .withConverter(placeConverter);

    const querySnapshot = await getDocsFromCache(recentActivitiesQuery);
    return querySnapshot.docs
        .map(r => r.data())
        .filter(item => !!item);
}

function buildMapFromActivityList(candidates: Place[]) {
    const newMap = new Map<string, Place>();
    for (const record of candidates) {
        if (record.docid) {
            newMap.set(record.docid, record);
        }
    }
    return newMap;
}

export function useAllTripActivitiesContextState(userData: UserData): AllTripActivities {
    const firebaseRefs = useFirebaseRefs();
    const today = useToday();

    const [ allActivities, setAllActivities ] = useState<Map<string, Place> | undefined>();

    const memoizedQuery = useMemo(
        () => userData.firebaseUserId
            ? query(
                collectionGroup(firebaseRefs.firestore, "activities"),
                where('acl.readWriteIds', 'array-contains', userData.firebaseUserId)
            ).withConverter(placeConverter)
            : undefined,
        [ firebaseRefs.firestore, userData.firebaseUserId ]
    );
    const activitiesFromFullDatabaseQuery = useMemoizedQuery(
        memoizedQuery,
        'useAllTripActivities',
    );

    useEffect(() => {
        if (activitiesFromFullDatabaseQuery) {
            setAllActivities(buildMapFromActivityList(activitiesFromFullDatabaseQuery ?? []));
        }
    }, [ activitiesFromFullDatabaseQuery ]);

    useEffect(() => {
        if (userData.firebaseUserId) {
            getRecentActivitiesFromCache(firebaseRefs, userData.firebaseUserId)
                .then(activities => {
                    if (activities && activities.length > 0) {
                        // Only bother with cached data if there's data to be had
                        setAllActivities(current => {
                            if (current) {
                                return current;
                            } else {
                                // only assign these cache values if we haven't already loaded something from the proper query
                                return buildMapFromActivityList(activities);
                            }
                        });
                    }
                });
        }
    }, [ firebaseRefs, userData.firebaseUserId ]);

    const separated = useMemo(
        () => allActivities
            ? separateActivitiesByTimePeriod(today, [ ...allActivities.values() ])
            : { ideas: undefined, past: undefined, future: undefined, today: undefined },
        [ allActivities, today ]
    );

    const [ activityIdsByPositionKey, activityIdsByAddressKey ] = useMemo(
        () => {
            const byPositionKey = new MultiMap<PositionKey, string>();
            const byAddress = new MultiMap<string, string>();
            if (allActivities) {
                for (const activity of allActivities.values()) {
                    if (activity.docid) {
                        const position = positionFromLatLng(activity);
                        if (position) {
                            byPositionKey.set(positionToKey(position), activity.docid);
                        }
                        const addressKey = addressToAddressKey(activity.address ?? undefined);
                        if (addressKey) {
                            byAddress.set(addressKey, activity.docid);
                        }
                    }
                }
            }

            return [ byPositionKey, byAddress ];
        },
        [ allActivities ]
    );

    return useMemo(() => ({
        ideas: mapToList(separated.ideas, (a, b) => (a.dayorder ?? 0) - (b.dayorder ?? 0)),
        past: mapToList(separated.past, placeReverseSorter),
        future: mapToList(separated.future, placeForwardSorter),
        today: mapToList(separated.today, placeForwardSorter),
        activityIdsByPositionKey,
        activityIdsByAddressKey,
        allActivities: allActivities ?? new Map(),
    }), [ separated, activityIdsByPositionKey, activityIdsByAddressKey, allActivities ]);
}

function mapToList<T>(map: Map<string, T> | undefined, sorter: (a: T, b: T) => number) {
    return map === undefined ? undefined : [ ...map.values() ].sort(sorter);
}

// Creates a canonicalized representation of the place's address for use in
// by-address lookups of places.
export function addressToAddressKey(address: string | undefined) {
    const key = address?.replaceAll(/ \n,/g, '');
    if (!key || key === '') {
        return undefined;
    } else {
        return key;
    }
}

function separateActivitiesByTimePeriod(today: TSDate, allActivities: Place[] | undefined) {
    if (!allActivities) {
        return { ideas: undefined, past: undefined, future: undefined, today: undefined };
    } else {
        const ideas: Map<string, Place> = new Map();
        const past: Map<string, Place> = new Map();
        const futures: Map<string, Place> = new Map();
        const todaysActivities: Map<string, Place> = new Map();
        allActivities?.forEach(place => {
            if (place.docid) {
                switch (classifyPlace(place, today)) {
                    case 'idea':
                        ideas.set(place.docid, place);
                        break;
                    case 'past':
                        past.set(place.docid, place);
                        break;
                    case 'future':
                        futures.set(place.docid, place);
                        break;
                    case 'today':
                        todaysActivities.set(place.docid, place);
                        break;
                }
            }
        });
        return {
            ideas,
            past,
            future: futures,
            today: todaysActivities,
        };
    }
}

export type BatchQueryMetadata = { queryTarget: 'server' | 'cache' };
export function executeQueriesAgainstCacheThenServer<T, U>(
    queries: Query<T>[],
    queryMapper: (q: QuerySnapshot<T> | undefined) => U[],
    queryReducer: (results: U[][], metadata: BatchQueryMetadata) => unknown
) {
    let hasClientError = false;
    const cacheResults = queries.map(async q => {
        try {
            return queryMapper(await getDocsFromCache(q));
        } catch (error) {
            hasClientError = true;
            // TODO should we filter to code=permission-denied and re-throw otherwise?
            logger.warn(`Trapped a Firestore error when querying against cache in executeQueriesAgainstCacheThenServer(): ${error}`);
            return undefined;
        }
    });

    Promise.all(cacheResults as Promise<U[]>[])
        .then(async awaited => {
            if (!hasClientError) {
                queryReducer(awaited, { queryTarget: 'cache' });

                // It is important that this only happens after we have received the full cached results.
                // Firebase has a weird behavior in which concurrent issuance of the same query results in
                // a cancellation of the first query processing step, but not until after the first chunk
                // (roughly 100 elements) is enlisted in cache. The first set of results are apparently
                // never passed to the handler function, but then the second query's handler function is
                // invoked with just that first chunk of data, and the full results are never fetched. The
                // second query's metadata indicates that the data is served from cache, but since the
                // cache wasn't fully populated, this is not the complete data set.
                let hasServerError = false;
                const serverResults = queries.map(async q => {
                    try {
                        return queryMapper(await getDocsFromServer(q));
                    } catch (error) {
                        hasServerError = true;
                        logger.warn(`Trapped a Firestore error when querying against server in executeQueriesAgainstCacheThenServer(): name=${error}`);
                        return undefined;
                    }
                });
                const awaitedServerResults = await Promise.all(serverResults);
                if (!hasServerError) {
                    const filteredResults = awaitedServerResults.filter(result => !!result) as U[][];
                    queryReducer(filteredResults, { queryTarget: 'server' });
                }
            }
        });
}

/**
 * Return the title if set; otherwise the 'location'.
 */
export function headlineForPlace(place: Place): string {
    return place.title ?? place.location ?? '<unknown>';
}

/**
 * Returns the address for the given place, excluding information presented via
 * {@link headlineForPlace} but including as much non-duplicate info as possible.
 */
export function addressForPlace(place: Place): string {
    if (place.title) {
        if (place.title === place.location) {
            return place.address ?? '';
        } else {
            return place.location && place.address
                ? `${place.location}, ${place.address}`
                : (place.address ?? place.location ?? '');
        }
    } else {
        // If there's no title, we used the location in the headline; don't repeat it here
        return place.address ?? '';
    }
}

export type PositionedPlace = Place & Position;

export function hasPosition(place: Place): place is PositionedPlace {
    return place.lat !== null && place.lat !== undefined
        && place.lng !== null && place.lng !== undefined;
}

export function removeUnpositioned(places: Place[]): PositionedPlace[] {
    return places.filter(place => hasPosition(place)) as PositionedPlace[];
}

export function placeToMappableRecord(place: PositionedPlace): PlaceRecord & MappableRecord {
    if (!place.docid) {
        throw Error("'place.docid' must be non-null!");
    }

    return {
        type: 'place',
        id: place.docid,
        place,
        title: place.title ?? undefined,
        location: place.location ?? undefined,
        address: async () => place.address ?? undefined,
        position: { lat: place.lat, lng: place.lng },
        locality: async () => place.locality ?? undefined,
    };
}

export async function searchEngineResultToMappableRecord(result: SearchEngineResult): Promise<SearchEngineMappableRecord | undefined> {
    const position = await result.position();
    if (position) {
        return {
            type: 'search-engine-result',
            searchEngineResult: result,
            // Apple results don't have IDs, but always(?) return addresses
            id: result.type === 'google-search' ? result.value.place_id : (await result.full_address())!,
            title: result.title,
            location: result.title,
            address: result.full_address,
            position,
            locality: result.locality,
        };
    } else {
        return undefined;
    }
}

export function navigateToPlace(router: AppRouterInstance, place: Place) {
    switch (place.placeclass) {
        case PlaceClassType.Activity:
            router.push(`/trip/${place.tripdocid}/${place.docid}`);
            break;
        case PlaceClassType.Saved:
            router.push(`/traveler/${place.creatorId}/saved/${place.docid}`);
            break;
        default:
            console.warn(`Attempted to load a non-activity / non-saved-place record! `
                + `tripdocid: ${place.tripdocid}; docid: ${place.docid}; class: ${place.placeclass}`);
            break;
    }
}

export function extractPlaceTypeFromGoogleSuggestion(suggestion: Suggestion | undefined): PlaceType | undefined {
    if (!suggestion) {
        return undefined;
    } else if (suggestion.types.includes('restaurant')) {
        return PlaceType.Meal;
    } else {
        return undefined;
    }
}

export function assertNonEmpty(str: string | undefined | null, name: string) {
    // TODO should we do any other validation, like ensuring the field isn't a relative path? What sorts of
    //  path injection attacks are possible?
    if (!str || str.length === 0) {
        throw Error(`Field ${name} must not be empty (or null)!`);
    } else {
        return str;
    }
}

/**
 * Updates a trip activity with the partial values provided, merging with whatever's in the database.
 * The partial fields must not include 'placetype', as that is a saved-place-only field.
 */
export async function updateTripActivity(
    firebaseRefs: FirebaseRefs,
    tripId: string,
    placeDocId: string,
    placeFields: Omit<Partial<Place>, 'placetype'>
) {
    assertNonEmpty(tripId, 'tripId');
    assertNonEmpty(placeDocId, 'placeDocId');

    const ref = doc(firebaseRefs.firestore, 'trips', tripId, 'activities', placeDocId)
        .withConverter(placeConverter);
    await setDoc(ref, placeFields, { merge: true });
}

/**
 * Inserts a trip activity with the partial values provided.
 * The partial fields must not include 'placetype', as that is a saved-place-only field.
 */
export async function insertTripActivity(
    firebaseRefs: FirebaseRefs,
    tripId: string,
    userData: UserData,
    placeFields: Omit<Partial<Place>, 'placetype'>,
    additionalAction: (insertedDocId: string) => Promise<unknown>
) {
    assertNonEmpty(tripId, 'tripId');

    const place: Partial<Place> = {
        ...placeFields,
        tripdocid: tripId,
        creatorId: userData.firebaseUserId,
        schemaVersion: 1,
        activityType: placeFields.activityType ?? (placeFields as Place).placetype,
    };
    deleteField(place, 'placetype');

    const ref = doc(collection(firebaseRefs.firestore, 'trips', tripId, 'activities')
        .withConverter(placeConverter));
    return Promise.all([
        setDoc(ref, place),
        additionalAction(ref.id),
    ]);
}

function deleteField<T>(ob: T, field: keyof (T)) {
    if (Object.prototype.hasOwnProperty.call(ob, field)) {
        delete ob[field];
    }
}

// delete Activity in firestore
export function deletePlace(firebaseRefs: FirebaseRefs, place: Place) {
    if (place.placeclass === PlaceClassType.Activity) {
        if (place.tripdocid && place.docid) {
            saveConfirmationHaptic();
            const fref = doc(firebaseRefs.firestore, 'trips', place.tripdocid, 'activities', place.docid);
            deleteDoc(fref);

            notifications.show({
                title: "Activity Deleted",
                message: "Your activity is deleted",
                autoClose: true,
            });
        } else {
            notifications.show({
                title: "Failed to delete",
                message: "Your activity was not deleted",
                autoClose: false,
            });
            logger.warn(`Failed to delete activity! docid='${place.docid}', tripdocid='${place.tripdocid}'`);
        }
    } else {
        const userId = place.creatorId ?? place.userid;
        if (userId && place.docid) {
            saveConfirmationHaptic();
            const fref = doc(firebaseRefs.firestore, 'users', userId, 'places', place.docid);
            deleteDoc(fref);

            notifications.show({
                title: "Saved Place Deleted",
                message: "Your saved place is deleted",
                autoClose: true,
            });
        } else {
            notifications.show({
                title: "Failed to delete",
                message: "Your saved place was not deleted",
                autoClose: false,
            });
            logger.warn(`Failed to delete saved place! docid='${place.docid}', creatorId='${place.creatorId}', userid='${place.userid}'`);
        }
    }
}

/**
 * Inserts a saved place with the partial values provided.
 */
export async function insertSavedPlace(
    firebaseRefs: FirebaseRefs,
    userData: UserData,
    placeFields: Partial<Place>,
    category?: SavedType,
) {
    const userId = assertNonEmpty(userData.firebaseUserId, 'userData.firebaseUserId');

    const ref = collection(firebaseRefs.firestore, 'users', userId, 'places')
        .withConverter(placeConverter);
    const categories: SavedType[] = [];
    if (category) {
        categories.push(category);
    }
    categories.push(...(placeFields.categories ?? []).filter(c => c !== category));

    const place = {
        ...placeFields,
        userid: userId,
        schemaVersion: 1,
        categories,
        placetype: placeFields.activityType ?? placeFields.placetype,
    } as Partial<Place>;

    // Remove some fields that don't belong in a saved place, or shouldn't be copied
    deleteField(place, 'tripdocid');
    deleteField(place, 'docid');
    deleteField(place, 'startdatetime');
    deleteField(place, 'enddatetime');
    deleteField(place, 'created');
    deleteField(place, 'updatedAt');
    deleteField(place, 'dayorder');
    deleteField(place, 'isfavorite');
    deleteField(place, 'order');
    deleteField(place, 'totalvotes');
    deleteField(place, 'activityType');
    deleteField(place, 'creatorId');

    if (!place.location) {
        place.location = place.title;
    }

    await addDoc(ref, place);
}

/**
 * Updates a saved place with the partial values provided, merging with whatever's in the database.
 * The partial fields must not include 'activityType', as that is a trip-activity-only field.
 */
export async function updateSavedPlace(
    firebaseRefs: FirebaseRefs,
    userData: UserData,
    placeDocId: string,
    placeFields: Omit<Partial<Place>, 'activityType'>) {
    const userId = assertNonEmpty(userData.firebaseUserId, 'userData.firebaseUserId');
    assertNonEmpty(placeDocId, 'placeDocId');

    deleteField(placeFields as Place, 'activityType');

    const ref = doc(firebaseRefs.firestore, 'users', userId, 'places', placeDocId)
        .withConverter(placeConverter);
    await setDoc(ref, placeFields, { merge: true });
}

export function mapIconUrlForPlace(place: Place, selfSavedCategories: SavedType[], activeTripId: string | undefined) {
    let urlGroup: URLGroup;
    if (place.placeclass === PlaceClassType.Saved) {
        if (place.categories?.includes('Favorite')) {
            urlGroup = URLs.SavedFavorite;
        } else {
            urlGroup = URLs.SavedWantToGo;
        }
    } else {
        const urlGroupGroup = activeTripId === place.tripdocid ? URLs.ActiveTrip : URLs.OtherTrip;
        if (place.categories?.includes('Favorite') || selfSavedCategories.includes('Favorite')) {
            urlGroup = urlGroupGroup.TripAndFavorite;
        } else if (place.categories?.includes('Want to go') || selfSavedCategories.includes('Want to go')) {
            urlGroup = urlGroupGroup.TripAndWantToGo;
        } else {
            urlGroup = urlGroupGroup.TripActivity;
        }
    }

    const placeType = placeTypeFromPlace(place);
    let urls: IconURLs;
    switch (placeType) {
        case PlaceType.Hotel:
        case PlaceType.Lodging:
            urls = urlGroup.Lodging;
            break;
        case PlaceType.Meal:
            urls = urlGroup.Food;
            break;
        case PlaceType.Flight:
            urls = urlGroup.Flight;
            break;
        default:
            urls = urlGroup.BlueDot;
    }

    return urls.map;
}

export function isLodging(place: Pick<Place, 'activityType'> | null): boolean {
    return place?.activityType === PlaceType.Lodging || place?.activityType === PlaceType.Hotel;
}

export function dateRange(place: Pick<Place, 'startdatetime' | 'enddatetime'>) {
    if (!place.startdatetime || isIdea(place.startdatetime)) {
        return [];
    } else if (!place.enddatetime || !place.startdatetime.isBefore(place.enddatetime)) {
        return [ place.startdatetime ];
    } else {
        const range: TSDate[] = [];
        for (let i = 0; !place.startdatetime.plus({ days: i })
            .isAfter(place.enddatetime); i++) {
            range.push(place.startdatetime.plus({ days: i }));
        }
        return range;
    }
}

export function placeTypeFromPlace(place: Partial<Place>): PlaceType | undefined {
    let placeType: PlaceType | undefined;
    if (place.placeclass === PlaceClassType.Saved) {
        placeType = place.placetype ?? place.activityType ?? PlaceType.Activity;
    } else if (place.placeclass === PlaceClassType.Activity) {
        placeType = place.activityType ?? place.placetype ?? PlaceType.Activity;
    } else {
        console.warn(`Unexpected placeclass: ${place.placeclass}. Will fall back to 'Activity'`);
        placeType = PlaceType.Activity;
    }

    if (placeType === PlaceType.Hotel) {
        return PlaceType.Lodging;
    } else {
        return placeType;
    }
}

// Scan the activities for places that have a known address but no known
// coordinates, and geocode them using on-device capabilities
export function repairTripActivities(firebaseRefs: FirebaseRefs, activities: Place[]): () => unknown {
    const problemActivities = activities.filter(activity => {
        return (activity.lat === null || activity.lng === null)
            && (activity.address ?? '').trim() !== '';
    });

    logger.log(`Found ${problemActivities.length} activities in need of repair`);

    if (!canNativelyGeocode()) {
        return () => {};
    }

    let isRunning = true;
    setTimeout(async () => {
        for (const activity of problemActivities) {
            if (!isRunning) {
                break;
            }
            if (activity.tripdocid && activity.docid && activity.address) {
                // We explicitly use the native geocoder here, so that we never incur costs for this repair
                // eslint-disable-next-line no-await-in-loop
                const result = await forwardGeocodeViaNativeGeocoder(activity.address);
                if (result) {
                    const patch = {
                        lat: result.position?.lat ?? null,
                        lng: result.position?.lng ?? null,
                    };
                    updateTripActivity(firebaseRefs, activity.tripdocid, activity.docid, patch);
                }
            }
        }
    }, 0);

    return () => { isRunning = false; };
}

export async function geocodeAndUpdatePlace(
    originalFields: Partial<Place> | undefined,
    newFields: Partial<Place>) {
    return testableGeocodePlaceHelper(
        originalFields,
        newFields,
        forwardGeocode,
        getDetailsForPlaceId,
        getLocalityForPlaceId
    );
}

export async function testableGeocodePlaceHelper(
    originalFields: Partial<Place> | undefined,
    newFields: Partial<Place>,
    forwardGeocodeFn: (address: string) => Promise<ForwardGeocodeResponse | undefined>,
    getDetailsFn: (placeId: string) => Promise<{ position: Position | undefined, locality: string | undefined } | undefined>,
    getLocalityFn: (placeId: string) => Promise<string | undefined>
) {
    const hasNewAddress = originalFields?.address !== newFields.address;
    const hasNewLocality = originalFields?.locality !== newFields.locality;
    const hasNewPosition = originalFields?.lat !== newFields.lat || originalFields?.lng !== newFields.lng;
    if (hasNewAddress && hasNewLocality && hasNewPosition) {
        // if everything is new, assume the values were all properly set
        return;
    }

    const needsPosition = newFields.lat === null || newFields.lng === null;
    const needsLocality = !newFields.locality;
    if (hasNewAddress || (newFields.address && (needsPosition || needsLocality))) {
        // if we have a new address, geocode it and update the position and locality accordingly
        const newValue = newFields.address;
        if (newValue && newValue.trim() !== '') {
            try {
                const geocoded = await forwardGeocodeFn(newValue);
                if (needsPosition && geocoded?.position) {
                    newFields.lat = geocoded?.position?.lat ?? null;
                    newFields.lng = geocoded?.position?.lng ?? null;
                }
                if (needsLocality && geocoded?.locality) {
                    newFields.locality = geocoded?.locality;
                }
            } catch (err) {
                logger.warn(`Failed to geocode address! ${err}`);
                console.warn("Failed to geocode address!", err);
            }
        }
    }

    const stillNeedsPosition = newFields.lat === null || newFields.lng === null;
    const stillNeedsLocality = !newFields.locality;
    if (stillNeedsPosition || stillNeedsLocality) {
        try {
            if (newFields.placeid) {
                // do a lookup by place id. This is the most expensive option, so we do it last.
                if (stillNeedsPosition) {
                    const details = await getDetailsFn(newFields.placeid);
                    if (stillNeedsPosition && details?.position) {
                        newFields.lat = details?.position?.lat;
                        newFields.lng = details?.position?.lng;
                    }
                    if (stillNeedsLocality && details?.locality) {
                        newFields.locality = details?.locality;
                    }
                } else if (stillNeedsLocality) {
                    newFields.locality = await getLocalityFn(newFields.placeid);
                }
            }
        } catch (err) {
            logger.warn(`Failed in Google placeId-based address geocoding: ${err}`);
            console.warn('Failed in Google placeId-based address geocoding!', err);
        }
    }
}

type PlaceIdentifiers = {
    position: Position | undefined,
    address: string | undefined,
    docid: string | undefined,
};
export function useCoincidentActivities(place: Place | undefined) {
    const placeIdentifiers = usePlaceIdentifiers(place);
    const {
        activityIdsByAddressKey,
        activityIdsByPositionKey,
        allActivities,
    } = useContext(AllTripActivitiesContext);

    const coincidentPlaceIds = useMemo(
        () => {
            const ids = new Set<string>();
            const addressKey = addressToAddressKey(placeIdentifiers.address);
            if (addressKey) {
                activityIdsByAddressKey.get(addressKey)?.forEach(docid => ids.add(docid));
            }
            const position = positionFromLatLng(placeIdentifiers.position);
            if (position) {
                activityIdsByPositionKey.get(positionToKey(position))?.forEach(docid => ids.add(docid));
            }
            if (placeIdentifiers.docid) {
                ids.delete(placeIdentifiers.docid);
            }
            return ids;
        },
        [ placeIdentifiers, activityIdsByPositionKey, activityIdsByAddressKey ]
    );

    return useMemo(
        () => {
            return ([ ...coincidentPlaceIds ]
                .map(docid => allActivities.get(docid))
                .filter(p => !!p) as Place[])
                .sort(placeReverseSorter);
        },
        [ coincidentPlaceIds, allActivities ]
    );
}

function keysForPlaceIdentifiers(placeIdentifiers: PlaceIdentifiers) {
    const addressKey = addressToAddressKey(placeIdentifiers.address);
    const position = positionFromLatLng(placeIdentifiers.position);
    const positionKey = position ? positionToKey(position) : undefined;
    return { addressKey, positionKey };
}

export function usePlaceIdentifiers(place: Place | undefined) {
    return useMemo(
        () => ({
            position: positionFromLatLng({ lat: place?.lat, lng: place?.lng }),
            address: place?.address ?? undefined,
            docid: place?.docid ?? undefined,
        }),
        [ place?.address, place?.docid, place?.lat, place?.lng ]
    );
}

export function useCoincidentSavedPlaces(place: Place | undefined) {
    const placeIdentifiers = usePlaceIdentifiers(place);
    const { uidPlaces } = useContext(HomeContext);
    return useMemo(
        () => {
            const targetKeys = keysForPlaceIdentifiers(placeIdentifiers);
            return uidPlaces.filter(savedPlace => {
                const savedKeys = keysForPlaceIdentifiers({
                    address: savedPlace.address ?? undefined,
                    position: positionFromLatLng(savedPlace),
                    docid: savedPlace.docid ?? undefined,
                });
                return ((!!targetKeys.positionKey && savedKeys.positionKey === targetKeys.positionKey)
                    || (!!targetKeys.addressKey && savedKeys.addressKey === targetKeys.addressKey))
                    && savedPlace.docid !== placeIdentifiers.docid;
            });
        },
        [ placeIdentifiers, uidPlaces ]
    );
}
