import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "@firebase/functions";
import { useDebouncedValue } from "@mantine/hooks";
import { DateTime } from "luxon";
import Sheet from "react-modal-sheet";
import { compareUsers, Trip, tripConverter, UserClass } from "../../lib/interfaces";
import { HomeContext, UserContext } from "../../lib/context";
import { useUsersById } from "../../lib/hooks";
import { useFirebaseRefs } from "../../lib/firebase";
import { EditableEntry } from "../TSEditableList/EditableEntry";
import { SearchList } from "../TSEditableList/SearchList";
import { TSSheet } from "../TSSheet/TSSheet";
import { Delayed } from "../Delayed";
import { TSLoader } from "../TSLoader";

export function TravelersSheet(props: { trip: Trip, onClose: () => unknown }) {
    const firebaseRefs = useFirebaseRefs();
    const { firebaseUserId: currentUserId } = useContext(UserContext);
    const [ canEditRoster, setCanEditRoster ] = useState(false);

    useEffect(() => {
        const found = currentUserId ? props.trip.users?.includes(currentUserId) ?? false : false;
        setCanEditRoster(found);
    }, [ currentUserId, props.trip ]);

    const addUser = async (toAdd: TravelerResult) => {
        const tripRef = doc(firebaseRefs.firestore, 'trips', props.trip.docid!)
            .withConverter(tripConverter);
        const latestTrip = (await getDoc(tripRef)).data();
        const newTrip: Partial<Trip> = { users: [ ...(latestTrip?.users ?? []), toAdd.uid ] };
        await setDoc(tripRef, newTrip, { merge: true });
    };

    const deleteUser = async (toDelete: UserClass) => {
        const tripRef = doc(firebaseRefs.firestore, 'trips', props.trip.docid!)
            .withConverter(tripConverter);
        const latestTrip = (await getDoc(tripRef)).data();
        const newTrip: Partial<Trip> = { users: (latestTrip?.users ?? []).filter(candidateId => candidateId !== toDelete.uid) };
        await setDoc(tripRef, newTrip, { merge: true });
    };

    return <TSSheet title='Fellow Travelers' isOpen onClose={props.onClose}>
        <div
            className='safeAreaPaddingBottom'
            style={{
                paddingLeft: "1rem",
                paddingRight: "1rem",
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Sheet.Scroller>
                <TravelerEditor
                    canEditRoster={canEditRoster}
                    userIds={props.trip.users ?? []}
                    addUser={addUser}
                    deleteUser={deleteUser}
                />
            </Sheet.Scroller>
        </div>
    </TSSheet>;
}

export function TravelerEditor(props: {
    userIds: string[],
    canEditRoster: boolean,
    addUser: (user: TravelerResult) => unknown,
    deleteUser: (user: UserClass) => unknown,
}) {
    const users = useUsersById(props.userIds);
    const [ sortedUsers, setSortedUsers ] = useState<UserClass[]>([]);

    useEffect(() => {
        if (users) {
            setSortedUsers([ ...users ].sort(compareUsers));
        }
    }, [ users ]);

    if (!users) {
        return <Delayed delay={250}>
            <TSLoader id='TravelerEditor-loading' layout='fill-content-area' />
        </Delayed>;
    } else {
        return <div style={{ overflowY: 'auto' }}>
            {sortedUsers.map(user =>
                <TravelerEntry key={user.uid} user={user} canEditRoster={props.canEditRoster} onDelete={props.deleteUser} />)}
            {props.canEditRoster && <AddTravelerComponent currentTravelers={sortedUsers} addUser={props.addUser} />}
        </div>;
    }
}

function TravelerEntry(props: { user: UserClass, canEditRoster: boolean, onDelete: (user: UserClass) => unknown }) {
    return <EditableEntry
        canEdit={props.canEditRoster}
        onDelete={() => props.onDelete(props.user)}
        headline={props.user.displayName ?? undefined}
        secondaryLine={props.user.email ?? undefined}
    />;
}

type TravelerResult = { uid: string, displayName: string | undefined, email: string | undefined, username?: string };
let queryIdGenerator = 0; // Maintain an ID for each query so that we don't use slow out-of-date responses
function AddTravelerComponent(props: { currentTravelers: UserClass[], addUser: (user: TravelerResult) => unknown }) {
    const firebaseRefs = useFirebaseRefs();
    const { tripAdjacentUsersById } = useContext(HomeContext);
    const [ travelerQuery, setTravelerQuery ] = useState<string>('');
    const [ debouncedQuery ] = useDebouncedValue(travelerQuery, 200);
    const [ queryInProgress, setQueryInProgress ] = useState(false);
    const [ showAddUserComponent, setShowAddUserComponent ] = useState(false);
    const lastWarmup = useRef<DateTime>();

    const [ serverTravelerSearchResults, setServerTravelerSearchResults ] = useState<{ id: number, travelers: TravelerResult[] }>(
        { id: 0, travelers: [] });
    const [ queryFailure, setQueryFailure ] = useState<string | undefined>();

    useEffect(() => {
        // Perform a query for '' immediately upon showing the add-user widget, so that we can warm up the "serverless" function
        // The server will short-circuit empty search queries, but as a side effect, will load the instance and all the data
        // needed for a proper search.
        if (showAddUserComponent && (!lastWarmup.current || lastWarmup.current.diffNow('minutes').minutes >= 10)) {
            const functions = getFunctions(firebaseRefs.firestore.app);
            lastWarmup.current = DateTime.now();
            httpsCallable(functions, 'userSearch')({ searchQuery: '' });
        }
    }, [ showAddUserComponent, firebaseRefs ]);

    useEffect(() => {
        setTravelerQuery('');
    }, [ showAddUserComponent ]);

    useEffect(() => {
        const queryId = queryIdGenerator++;
        if (debouncedQuery === '' || debouncedQuery.length < 3) {
            setServerTravelerSearchResults({ id: queryId, travelers: [] });
        } else {
            // TODO cancellation
            const functions = getFunctions(firebaseRefs.firestore.app);
            setQueryInProgress(true);
            httpsCallable(functions, 'userSearch')({ searchQuery: debouncedQuery })
                .then(response => {
                    if (serverTravelerSearchResults.id < queryId) {
                        const { results: hits } = response.data as { results: TravelerResult[] };
                        setServerTravelerSearchResults({
                            id: queryId,
                            travelers: hits.filter(hit => !props.currentTravelers.find(t => t.uid === hit.uid)),
                        });
                        setQueryFailure(undefined);
                    }
                    setQueryInProgress(false);
                })
                .catch(err => {
                    if (serverTravelerSearchResults.id < queryId) {
                        setServerTravelerSearchResults({ id: queryId, travelers: [] });
                        setQueryFailure("User search failed!");
                        console.warn("Trapped an error when searching for users", err);
                    }
                    setQueryInProgress(false);
                });
        }
    }, [ debouncedQuery, firebaseRefs ]);

    const clientResults: TravelerResult[] = useMemo(
        () => {
            const loweredQuery = debouncedQuery.toLocaleLowerCase();
            return [ ...tripAdjacentUsersById.values() ]
                .filter(user => {
                    return !!user.uid && (
                        user.email?.toLocaleLowerCase().includes(loweredQuery)
                        || user.displayName?.toLocaleLowerCase().includes(loweredQuery));
                })
                .map(user => ({
                    uid: user.uid!, // we filter for records that contain uids above
                    email: user.email ?? undefined,
                    displayName: user.displayName ?? undefined,
                }));
        },
        [ tripAdjacentUsersById, debouncedQuery ]
    );

    const filteredServerResults = useMemo(
        () => {
            const clientUids = new Set(clientResults.map(r => r.uid));
            return serverTravelerSearchResults.travelers.filter(r => !clientUids.has(r.uid));
        },
        [ clientResults, serverTravelerSearchResults ]
    );

    const travelerToResult = (record: TravelerResult) => ({
        headline: `${record.displayName}${record.username ? ` (${record.username})` : ''}`,
        secondaryLine: record.email,
        record,
    });
    return <SearchList<TravelerResult>
        addText='Add a Traveler'
        addRecord={props.addUser}
        query={travelerQuery}
        setQuery={setTravelerQuery}
        queryFailure={queryFailure}
        queryInProgress={queryInProgress}
        showAddComponent={showAddUserComponent}
        setShowAddComponent={setShowAddUserComponent}
        results={[
            {
                groupName: 'Your Past Travelers',
                results: clientResults.map(travelerToResult),
            },
            {
                groupName: '',
                results: filteredServerResults.map(travelerToResult),
            },
        ]}
    />;
}
