import { MantineColorScheme } from "@mantine/core";

import classes from "./TSAppShell/ShellStyles.module.css";

export function tabsProps(colorScheme: MantineColorScheme) {
    return {
        classNames: {
            tab: classes.tab,
            panel: `${classes.tabPanel} tscroll-appshell-main`,
        },
        variant: "filled",
        keepMounted: true,
        styles: {
            tab: {
                tabsList: {
                    backgroundColor: colorScheme === 'dark' ? 'var(--mantine-color-dark-7)' : 'var(--mantine-color-gray-4)',
                },
            },
        },
    };
}
