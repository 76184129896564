import React from 'react';
import {
    Helm,
    PhotoFillOnRectangleFill,
    PhotoOnRectangle,
    Placemark,
    PlacemarkFill,
    Plus,
} from "framework7-icons-plus/react";

import { ActionIcon, AppShell, Center, Group, Space, Tabs, Title } from '@mantine/core';
import { extraFooterClasses } from "../../lib/appShellUtils";

import shellClasses from '../TSAppShell/ShellStyles.module.css';

export function TripAppShellFooter({ tab, floatingActionButtonLabel, onClickFloatingActionButton }: {
    tab?: string,
    floatingActionButtonLabel?: string,
    onClickFloatingActionButton: () => unknown,
}) {
    return <AppShell.Footer className={`${shellClasses.footer} ${extraFooterClasses(tab)}`}>
        <Group justify='space-between' gap="xs">
            <Group justify='space-between' grow gap="xs" style={{ flex: 7 }}>
                <Tabs.List>
                    <Tabs.Tab style={{ flex: 1 }} value="Plans" w={30}>
                        <Center>
                            {tab === 'Plans' ?
                                (<Helm height={18} width={18} />) :
                                (<Helm height={18} width={18} />)}
                        </Center>
                        <Space h='xs' />
                        <Title style={{ textAlign: 'center' }} order={5}>
                            Plans
                        </Title>
                    </Tabs.Tab>
                    <Tabs.Tab style={{ flex: 1 }} value="Gallery" w={30}>
                        <Center>
                            {tab === 'Gallery' ?
                                (<PhotoFillOnRectangleFill height={18} width={18} />) :
                                (<PhotoOnRectangle height={18} width={18} />)}
                        </Center>
                        <Space h='xs' />
                        <Title style={{ textAlign: 'center' }} order={5}>
                            Gallery
                        </Title>
                    </Tabs.Tab>
                    <Tabs.Tab style={{ flex: 1 }} value="Map" w={30}>
                        <Center>
                            {tab === 'Map' ?
                                (<PlacemarkFill height={18} width={18} />) :
                                (<Placemark height={18} width={18} />)}
                        </Center>
                        <Space h='xs' />
                        <Title style={{ textAlign: 'center' }} order={5}>
                            Map
                        </Title>
                    </Tabs.Tab>
                </Tabs.List>
            </Group>
            <Center style={{ flex: 1 }} pr='xs'>
                <ActionIcon
                    className='tscroll-floating-action-button'
                    variant="filled"
                    size="xl"
                    radius='xl'
                    aria-label={floatingActionButtonLabel}
                    onClick={onClickFloatingActionButton}
                >
                    <Plus height={18} width={18} />
                </ActionIcon>
            </Center>
        </Group>
    </AppShell.Footer>;
}
