import { useEffect, useState } from "react";
import { ActionIcon, Text } from "@mantine/core";
import { IconSquareRoundedChevronUp, IconSquareRoundedMinus, IconTrash } from "@tabler/icons-react";

import placeClasses from "../SavedPlacesList/SavedPlacesList.module.css";

export function EditableEntry(props: {
    headline?: string,
    secondaryLine?: string,
    canEdit: boolean,
    onDelete: () => unknown
}) {
    const [ removalPhase, setRemovalPhase ] = useState<'none' | 'started' | 'confirmed' | 'done'>('none');

    useEffect(() => {
        if (removalPhase === 'confirmed') {
            props.onDelete();
            setRemovalPhase('done');
        }
    }, [ removalPhase, props.onDelete ]);

    const indicateRemoved = removalPhase === 'confirmed' || removalPhase === 'done';
    return <div
        key={props.headline}
        className={placeClasses.item}
        style={{ display: 'flex' }}
    >
        <div style={{ flex: 1 }}>
            <Text color={indicateRemoved ? 'dimmed' : undefined}
                  style={{ fontStyle: indicateRemoved ? "italic" : undefined }}
            >
                {props.headline}
            </Text>
            { props.secondaryLine && <Text size='sm' color='dimmed'>{props.secondaryLine}</Text> }
        </div>
        {props.canEdit && removalPhase === 'none'
            && <ActionIcon onClick={() => setRemovalPhase('started')}><IconSquareRoundedMinus /></ActionIcon>}
        {props.canEdit && removalPhase === 'started'
            && <>
                <ActionIcon
                    radius='xl'
                    size='lg'
                    style={{ color: 'red' }}
                    variant="filled"
                    onClick={() => setRemovalPhase('confirmed')}
                >
                    <IconTrash />
                </ActionIcon>
                <ActionIcon onClick={() => setRemovalPhase('none')}><IconSquareRoundedChevronUp /></ActionIcon>
            </>}
    </div>;
}
