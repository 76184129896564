import React, { PropsWithChildren } from "react";

/**
 * Wraps the children in a pair of divs that enables vertical scrolling of the children within the parent
 * context. This relies on support from the parent context's hierarchy.
 */
export function TSScrollable(props: { keyboardHeightAware?: boolean } & PropsWithChildren) {
    const className = props.keyboardHeightAware ? 'keyboard-height-padded' : '';
    return <div style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    }}>
        <div style={{ overflowY: 'auto', height: '100%' }} className={className}>
            {props.children}
        </div>
    </div>;
}
