import getDistance from 'geolib/es/getDistance';
import { Place, PlaceClassType, Trip } from "./interfaces";
import { positionFromLatLng } from "./position";

export type Distance = {
    fromMe: number | undefined,
    fromTrip: number | undefined,
    fromLodging: number | undefined,
};
export function computeDistance(item: Place, trip: Trip | null,
    deviceLocation: { lat: number, lng: number } | null, lodging: Place | null): Distance {
    const placePosition = positionFromLatLng(item);
    const tripPosition = positionFromLatLng(trip);
    const lodgingPosition = positionFromLatLng(lodging);
    return {
        fromTrip: trip === null || !tripPosition || !placePosition
            ? undefined
            : getDistance(
                { latitude: item.lat!, longitude: item.lng! },
                { latitude: trip?.lat!, longitude: trip?.lng! }
        ),
        fromLodging: lodging === null || !lodgingPosition || !placePosition
            ? undefined
            : getDistance(
                { latitude: item.lat!, longitude: item.lng! },
                { latitude: lodging?.lat!, longitude: lodging?.lng! }
        ),
        fromMe: deviceLocation === null || !placePosition
            ? undefined : getDistance(
                { latitude: item.lat!, longitude: item.lng! },
                { latitude: deviceLocation.lat, longitude: deviceLocation.lng }
        ),
    };
}

export function distanceTextForCoords(
    item: Place, trip: Trip | null,
    hasHotel: boolean,
    pType: PlaceClassType,
    coords: { lat: number, lng: number } | null,
    lodging: Place | null,
) {
    return distanceTextForDistance(trip, hasHotel, pType, computeDistance(item, trip, coords, lodging), lodging);
}

export function metersToMetricMessage(distanceInMeters: number) {
    if (distanceInMeters < 1000) {
        return `${Math.round(distanceInMeters * 1000) / 1000} meters` as string;
    }
    let distanceInKm;
    if (distanceInMeters < 10000) {
        distanceInKm = Math.round(distanceInMeters / 100) / 10;
    } else {
        distanceInKm = Math.round(distanceInMeters / 1000); // TODO round without using toStringAsPrecision()
    }
    return `${distanceInKm} km` as string;
}

export function distanceTextForDistance(
    trip: Trip | null,
    hasHotel: boolean,
    pType: PlaceClassType,
    distance: Distance,
    lodging: Place | null,
) {
    if (!hasHotel && pType === PlaceClassType.Activity) {
        if (distance.fromTrip === undefined) {
            return 'somewhere';
        } else {
            return `${metersToMetricMessage(distance.fromTrip)} from ${trip?.location}`;
        }
    }

    if (hasHotel && pType === PlaceClassType.Activity) {
        if (distance.fromLodging === undefined) {
            return 'somewhere';
        } else {
            return `${metersToMetricMessage(distance.fromLodging)} from ${lodging?.location}`;
        }
    }

    if (!hasHotel && pType === PlaceClassType.Saved) {
        if (distance.fromMe !== undefined) {
            return `${metersToMetricMessage(distance.fromMe)} from here`;
        }
    }

    return '';
}
