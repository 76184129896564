import { useRouter } from "next/navigation";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import Sheet, { SheetRef } from "react-modal-sheet";
import { AllTripActivitiesContext, HomeContext } from "../../lib/context";
import { currentLocation } from "../../lib/geolocation";
import { useSafeArea, useWindowHeight } from "../../lib/hooks";
import {
    AppShellType,
    MappableRecord,
    Place,
    PlaceClassType,
    PlaceComment,
    PlaceRecord,
    SearchEngineMappableRecord,
    SearchEngineResult,
} from "../../lib/interfaces";
import { getLogger } from "../../lib/logger";
import {
    hasPosition,
    navigateToPlace,
    placeToMappableRecord,
    removeUnpositioned,
    searchEngineResultToMappableRecord,
} from "../../lib/placeFunctions";
import { areBoundsEqual, Bounds, Position, positionFromLatitudeLongitude } from "../../lib/position";
import {
    ChipConfig,
    EmptyQueryBehavior,
    newSearchQuery,
    SearchBias,
    SearchQuery,
    SearchResults,
} from '../../lib/search';
import { useQueryParams } from '../../lib/urls';
import { DetailMode, SearchUI } from "../SearchUI/SearchUI";
import { SheetsContext, sheetsMountPointId } from "../Sheets";
import { showModalAsync } from "../TSModal";
import { TSSheetHeader } from "../TSSheet/TSSheet";

import sheetClasses from "../TSSheet/TSSheet.module.css";
import { GoogleMapPlaces } from "./GoogleMapPlaces";

const logger = getLogger('SearchableMap');

export function SearchableMap(props: {
    placesToSearch: Place[],
    commentsToSearch?: PlaceComment[],
    currentUserId: string,
    isMapVisible: boolean,
    appShellType: AppShellType,
    selected: string | null,
    setSelected: (id: string | undefined) => unknown,
    refreshRoute: string,
    chipConfig: ChipConfig
    emptyQueryBehavior: EmptyQueryBehavior
    toggleTravelScrollDrawer: () => unknown
    fallbackLocation?: Position
    activeTripId?: string
}) {
    const { userTrips } = useContext(HomeContext);
    const { allActivities } = useContext(AllTripActivitiesContext);
    const sheets = useContext(SheetsContext);
    const router = useRouter();
    const queryParams = useQueryParams();

    const [ sessionId, setSessionId ] = useState<string | undefined>();
    const [ searchHits, setSearchHits ] = useState<SearchResults | undefined>();
    const [ searchQuery, setSearchQuery ] = useState<SearchQuery>();

    const [ currentViewport, setCurrentViewport ] = useState<Bounds | undefined>();
    const [ zoomLevel, setZoomLevel ] = useState<number | undefined>(undefined);

    const sheetRef = useRef<SheetRef>();

    const safeArea = useSafeArea();

    const fullyClosedSnapPointIndex = 3;
    const mostlyClosedSnapPointIndex = 2;
    const halfOpenedSnapPointIndex = 1;
    const mostlyOpenedSnapPointIndex = 0;

    const [ currentSnapPoint, setCurrentSnapPoint ] = useState<number>(mostlyClosedSnapPointIndex);
    const currentSnapPointRef = useRef<number>(mostlyClosedSnapPointIndex);

    const initialDocumentHeight = useMemo(
        () => document.documentElement.scrollHeight,
        [] // TODO recompute on rotation. We need to ensure we do *not* recompute on keyboard-induced resize on Android
    );
    const { windowHeight } = useWindowHeight();

    useEffect(() => {
        if (props.isMapVisible) {
            // Re-snap the sheet when the document height changes. On Android, the document height
            // changes whenever the keyboard is revealed / dismissed.
            sheetRef.current?.snapTo(currentSnapPointRef.current);
        }

        // We depend on isMapVisible so that we don't attempt snapping when heights are zero.
        // We depend on windowHeight solely as a change trigger.
    }, [ props.isMapVisible, windowHeight ]);

    const snapPoints = [
        Math.floor(0.9 * initialDocumentHeight), // almost all the way open
        0.4 * initialDocumentHeight, // around midway
        145 + safeArea.safeAreaInsetBottom, // Mostly closed -- 145 is enough for the search box to peek up. TODO compute this value
        0,
    ];
    snapPoints[2] = Math.min(snapPoints[1] - 1, snapPoints[2]); // Snap points must be in decreasing size

    // We maintain this state because our list of records to show is periodically rebuilt, and so this
    // allows us to re-associate any previously-selected search engine results.
    const [ selectedSearchEngineResult, setSelectedSearchEngineResult ] = useState<SearchEngineMappableRecord>();

    const [ geolocatedSearchEngineResults, setGeolocatedSearchEngineResults ] = useState<SearchEngineMappableRecord[]>();

    useEffect(() => {
        if (searchHits?.searchEngineResults) {
            // We filter the list to those that we can geolocate. This runs the geolocations
            // in parallel, and then awaits all of them before assigning to the internal state.
            const results = searchHits.searchEngineResults;
            const filtered: SearchEngineMappableRecord[] = [];
            let active = true;
            const promises = results
                .map(async result => {
                    if (active) {
                        // Google search results are expensive to geocode; only do that if the user has selected a result.
                        if (result.type !== 'google-search' || selectedSearchEngineResult?.id === result.value.place_id) {
                            const converted = await searchEngineResultToMappableRecord(result);
                            if (converted) {
                                filtered.push(converted);
                            }
                        }
                    }
                });
            Promise.all(promises).then(() => {
                if (active) {
                    setGeolocatedSearchEngineResults(filtered);
                }
            });
            return () => { active = false; };
        } else {
            setGeolocatedSearchEngineResults(undefined);
            return () => { };
        }
    }, [ searchHits?.searchEngineResults, selectedSearchEngineResult ]);

    // TODO we should look through the saved places too; the user might have selected one of them
    const selectedActivity = useMemo(
        () => {
            if (props.selected) {
                const activity = allActivities.get(props.selected);
                if (activity) {
                    return activity;
                } else {
                    return searchHits?.activities.find(a => a.docid === props.selected);
                }
            } else {
                return undefined;
            }
        },
        [ allActivities, searchHits?.activities, props.selected ]
    );

    const placesToShow = useMemo(() => {
        const toShow: MappableRecord[] = [];
        if (selectedActivity) {
            if (hasPosition(selectedActivity)) {
                toShow.push(placeToMappableRecord(selectedActivity));
            } else {
                logger.warn(`Cannot position activity ${selectedActivity.docid}! Will not display anything.`);
            }
        }

        if (searchHits) {
            const sources = searchHits.query?.sources;

            const activities = removeUnpositioned(searchHits.activities ?? []);
            const thisTripActivities = activities
                .filter(activity => props.activeTripId && activity.tripdocid === props.activeTripId && activity.docid !== props.selected);
            const otherTripActivities = activities
                .filter(activity => (!props.activeTripId || activity.tripdocid !== props.activeTripId) && activity.docid !== props.selected);

            toShow.push(...thisTripActivities.map(placeToMappableRecord));

            toShow.push(...removeUnpositioned(
                searchHits.savedPlaces.map(r => r.results[0]) ?? [])
                .map(placeToMappableRecord));

            // TODO until we resolve the remaining issues in the commit message, do not include the
            //  other-trip results in the placesToShow list
            //toShow.push(...otherTripActivities.map(placeToMappableRecord));

            toShow.push(...(geolocatedSearchEngineResults ?? []));
        }

        if (toShow.length > 0) {
            return toShow;
        } else {
            return undefined;
        }
    }, [ props.currentUserId, searchHits, props.appShellType, geolocatedSearchEngineResults, selectedActivity ]);

    useEffect(() => {
        const emptyQueryBehavior = props.emptyQueryBehavior ?? 'match-none';
        const initialQuery = newSearchQuery(
            {
                searchTerm: '',
                chipConfig: props.chipConfig,
                emptyQueryBehavior,
                bias: biasForViewport(currentViewport, props.fallbackLocation),
            },
            setSearchQuery
        );
        initialQuery.loadFromParams(queryParams);
        setSearchQuery(initialQuery);
    }, []);

    useEffect(() => {
        // We do not include queryParams in our dependency list, since we don't want to do an update cycle
        searchQuery?.updateHistory(queryParams);
    }, [ searchQuery ]);

    const updateSearchQuery = useCallback(
        (newQuery: SearchQuery) => {
            setSearchQuery(newQuery.updateLocationBias(biasForViewport(currentViewport, props.fallbackLocation)));
        },
        [ currentViewport, props.fallbackLocation ]
    );

    const fullyCloseSearchSheet = () => {
        sheetRef.current?.snapTo(fullyClosedSnapPointIndex);
    };
    const collapseSearchSheet = () => {
        sheetRef.current?.snapTo(mostlyClosedSnapPointIndex);
    };
    const mostlyOpenSearchSheet = () => {
        sheetRef.current?.snapTo(mostlyOpenedSnapPointIndex);
    };

    const selectedRecord = useMemo(() => {
        // We need to update the selected record when the placesToShow list changes, since the
        // selected instance might be a different object now, even if it's the same ID
        if (placesToShow) {
            if (props.selected) {
                return placesToShow.find(p => p.id === props.selected);
            } else if (selectedSearchEngineResult) {
                // if we already have selected a search result, keep it, but re-query placesToShow to get the latest object reference
                const toShow = placesToShow.find(p => p.id === selectedSearchEngineResult.id);
                return toShow;
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }, [ placesToShow, props.selected, selectedSearchEngineResult ]);

    const selectRecord = (record: SearchEngineMappableRecord | PlaceRecord | undefined) => {
        if (record?.type === 'place') {
            setSelectedSearchEngineResult(undefined);
            props.setSelected(record.place.docid ?? undefined);
        } else if (record) {
            // TODO should we figure out some way to put search selections into the URL?
            setSelectedSearchEngineResult(record);
            props.setSelected(undefined);
        } else {
            // explicitly clear the selection, to handle the deselection-of-google-maps case
            setSelectedSearchEngineResult(undefined);
            props.setSelected(undefined);
        }
    };

    useEffect(() => {
        if (searchHits?.query?.searchTerm && currentSnapPointRef.current !== mostlyOpenedSnapPointIndex) {
            // If the map is visible, create a new session every time the query changes -- this allows live re-fitting
            setSessionId(`search-${Buffer.from(searchHits.query.searchTerm).toString('base64')}`);
        } else if (!searchHits?.query?.searchTerm) {
            setSessionId(undefined);
        } // TODO in what other cases should we change the session ID?
    }, [ searchHits?.query?.searchTerm ]);

    const updateSearchSheetHeightFromDetailMode = (mode: DetailMode) => {
        if (selectedRecord) {
            fullyCloseSearchSheet();
        } else {
            switch (mode) {
                case 'collapsed':
                    collapseSearchSheet();
                    break;
                case 'filter':
                    sheetRef.current?.snapTo(halfOpenedSnapPointIndex);
                    break;
                case 'results':
                    mostlyOpenSearchSheet();
                    break;
            }
        }
    };

    useEffect(() => {
        if (selectedRecord) {
            fullyCloseSearchSheet();
        } else if (searchHits?.query?.hasQueryOrFilter) { // This is intentionally not a dependency
            mostlyOpenSearchSheet();
        } else if (currentSnapPointRef.current === fullyClosedSnapPointIndex) {
            collapseSearchSheet();
        }
    }, [ selectedRecord ]);

    if (!props.currentUserId) {
        return null;
    }

    const onResultSelect = async (record: PlaceRecord | SearchEngineResult) => {
        if (record.type === 'place') {
            if (hasPosition(record.place)) {
                selectRecord(record);
            } else {
                const gotoChoice = { title: 'Take me there' };
                const response = await showModalAsync(
                    sheets,
                    'Go to activity detail?',
                    [ { title: 'Cancel' }, gotoChoice ],
                    "This activity doesn't have a position, so it cannot be placed on the map."
                );
                if (response.type === 'choice' && response.choice === gotoChoice) {
                    navigateToPlace(router, record.place);
                }
            }
        } else {
            // TODO check for undefined mappable record, and alert user
            selectRecord(await searchEngineResultToMappableRecord(record));
        }
    };

    const searchUIHeight = (() => {
        if (selectedRecord) {
            return 'collapsed';
        } else {
            switch (currentSnapPointRef.current) {
                case mostlyOpenedSnapPointIndex:
                    return 'full';
                case halfOpenedSnapPointIndex:
                    return 'half';
                case mostlyClosedSnapPointIndex:
                case fullyClosedSnapPointIndex:
                default:
                    return 'collapsed';
            }
        }
    })();

    return <>
        { searchQuery && <Sheet
            style={{ zIndex: 100 }}
            ref={sheetRef}
            mountPoint={document.getElementById(sheetsMountPointId) ?? undefined}
            snapPoints={snapPoints}
            initialSnap={selectedRecord ? fullyClosedSnapPointIndex : mostlyClosedSnapPointIndex}
            isOpen
            onSnap={newSnap => {
                setCurrentSnapPoint(newSnap);
                currentSnapPointRef.current = newSnap;
            }}
            onClose={() => undefined}
        >
            <Sheet.Container style={{ display: props.isMapVisible ? 'initial' : 'none' }}>
                <TSSheetHeader showCloseIcon={false} />
                <Sheet.Content
                    className={`${sheetClasses.sheetContent} tscroll-footer`}
                    style={{
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                        height: '100%',
                    }}>
                    <SearchUI
                        places={props.placesToSearch}
                        comments={props.commentsToSearch}
                        userTrips={userTrips ?? []}
                        currentUserId={props.currentUserId}
                        height={searchUIHeight}
                        setDetailMode={updateSearchSheetHeightFromDetailMode}
                        onResultSelect={onResultSelect}
                        notifySearchHits={setSearchHits}
                        searchQuery={searchQuery}
                        setSearchQuery={updateSearchQuery}
                        bounds={currentViewport}
                        recentActivitiesEmptyQueryBehavior={(zoomLevel ?? 0) >= 13 ? 'match-all' : 'match-none'}
                    />
                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop style={{ backgroundColor: 'transparent' }}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        pointerEvents: currentSnapPoint === mostlyOpenedSnapPointIndex ? 'all' : 'none',
                    }}
                    onClick={() => collapseSearchSheet()}
                />
            </Sheet.Backdrop>
        </Sheet> }
        <GoogleMapPlaces
            recordsToMap={placesToShow ?? []}
            isMapVisible={props.isMapVisible}
            selected={selectedRecord}
            onSelectionChanged={selectRecord}
            appShellType={props.appShellType}
            refreshRoute={props.refreshRoute}
            sessionId={sessionId}
            setCurrentViewport={(viewport, zoom) => {
                setCurrentViewport(current => {
                    if (areBoundsEqual(viewport, current)) {
                        return current;
                    } else {
                        return viewport;
                    }
                });

                if (zoom !== undefined) {
                    setZoomLevel(zoom);
                }
            }}
            toggleTravelScrollDrawer={props.toggleTravelScrollDrawer}
            currentUserId={props.currentUserId}
            activeTripId={props.activeTripId}
        />
    </>;
}

function roundToPrecision(x: number, factor: number) {
    return Math.max(factor, Math.round(x / factor) * factor);
}

function biasForViewport(viewport: Bounds | undefined, fallback: Position | undefined): SearchBias {
    const tenKm = 10000;
    const fallbackLocation: Position | undefined = fallback ?? positionFromLatitudeLongitude(currentLocation()?.coords);
    const fallbackBias = fallbackLocation ? { center: fallbackLocation, radius: tenKm } : 'IP_BIAS';
    if (viewport) {
        const width = viewport.northEast.lng - viewport.southWest.lng;
        const height = viewport.northEast.lat - viewport.southWest.lat;
        if (width > 10 || height > 10) {
            return fallbackBias;
        } else {
            // We use the full height as the radius instead of half the height to include surroundings.
            // (We use height instead of width since degrees latitude are always the same length,
            // whereas degrees longitude vary with latitude.)
            const bias = {
                center: {
                    lat: roundToPrecision(viewport.center.lat, 0.05), // round to the nearest 5-hundredths of a degree -- about 5km
                    lng: roundToPrecision(viewport.center.lng, 0.05), // this is a bit wonky since lng isn't constant-sized
                },
                radius: roundToPrecision(height * 111139, tenKm), // 111,139 meters / degree, rounded to the nearest 10km
            };
            return bias;
        }
    } else {
        return fallbackBias;
    }
}
