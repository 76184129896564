import { Capacitor } from "@capacitor/core";
import { useJsApiLoader } from "@react-google-maps/api";
import { CapacitorNativeSupport, NativeMapsSearchResult } from "@travelscroll/capacitor-native-support";
import getDistance from "geolib/es/getDistance";
import { useContext, useEffect, useMemo, useState } from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import { setUnion } from '../../shared/collections';
import { googleMapsLoaderOptions } from "./config";
import { UserContext } from "./context";
import { usePositionSnapshot } from "./geolocation";
import { getDetailsForSuggestion, getPhotosForSuggestion, ParsedDetails } from "./google";
import { Place, PlaceClassType, PlaceComment, PlaceType, SearchEngineResult } from "./interfaces";

import { extractPlaceTypeFromGoogleSuggestion } from "./placeFunctions";
import { Bounds, Position, positionFromLatitudeLongitude, positionFromLatLng } from "./position";
import {
    SavedPlaceResult,
    useFilteredPlaces,
    useRecentActivityResultsFromTrips,
    useSavedPlaceSearchResults,
} from "./searchHooks";
import { Appendables, QueryParams, QueryParamsAndMutators } from './urls';

export type TripChipOption = 'all' | 'today' | 'upcoming' | 'ideas';
export type SearchFilters = {
    meals: boolean;
    lodging: boolean;
    flights: boolean;
    activities: boolean;
    tripChip: TripChipOption
};
export type SearchSources = {
    yourSaved: boolean
    otherSaved: boolean
    recentTrips: boolean
    searchEngine: boolean
};
export type SearchSorts = {
    distance: boolean
    rating: boolean
};
export type SearchConfig = {
    filters: SearchFilters
    sources: SearchSources
    sorts: SearchSorts
};
export type EmptyQueryBehavior = 'match-all' | 'match-none';
export type SearchQuery = {
    loadFromParams(queryParams: QueryParamsAndMutators): SearchQuery;
    emptyQueryBehavior: EmptyQueryBehavior;
    searchTerm: string;
    chipConfig: ChipConfig;
    filters: SearchFilters;
    sources: SearchSources;
    bias: SearchBias;
    sorts: SearchSorts;

    // see https://developers.google.com/maps/documentation/javascript/supported_types#table3
    searchType: 'region' | undefined;

    // Changes whenever the query is modified in any manner
    hash: string;

    // Changes whenever the query is modified in a way that should result in a browser URL change of some sort.
    // When this changes, it's appropriate to call `updateHistory()`
    historyHash: string;

    hasQuery: boolean;
    hasQueryOrFilter: boolean;

    setTerm(searchTerm: string): SearchQuery;
    setTripChipOption(option: TripChipOption): SearchQuery;
    updateLocationBias(bias: SearchBias): SearchQuery;

    toggleYourSaved(): SearchQuery;
    toggleOtherSaved(): SearchQuery;
    toggleRecentTrips(): SearchQuery;
    toggleSearchEngine(): SearchQuery;

    toggleSortByDistance(): SearchQuery;
    toggleSortByRating(): SearchQuery;
    includeActivityType(type: Omit<PlaceType, PlaceType.Hotel>, checked: boolean): void;

    // Updates the browser history, using 'replace' or 'push' as appropriate
    updateHistory(router: QueryParamsAndMutators): void;
};

export function newSearchQuery(
    template: Partial<SearchQuery> & Pick<SearchQuery, 'searchTerm' | 'chipConfig' | 'emptyQueryBehavior'>,
    replacer: (newQuery: SearchQuery) => unknown,
    defaults?: { filters: SearchFilters, sources: SearchSources, sorts: SearchSorts }
): SearchQuery {
    const templateCopy = { ...template };
    if (templateCopy.filters === undefined) {
        templateCopy.filters = {
            tripChip: 'all',
            flights: true,
            meals: true,
            activities: true,
            lodging: true,
        };
    } else {
        templateCopy.filters = { ...templateCopy.filters };
    }
    if (templateCopy.sources === undefined) {
        templateCopy.sources = {
            yourSaved: templateCopy.chipConfig.savedPlaces,
            otherSaved: false,
            recentTrips: templateCopy.chipConfig.recents,
            searchEngine: templateCopy.chipConfig.searchEngine,
        };
    } else {
        templateCopy.sources = { ...templateCopy.sources };
    }
    if (templateCopy.sorts === undefined) {
        templateCopy.sorts = {
            distance: false,
            rating: false,
        };
    } else {
        templateCopy.sorts = { ...templateCopy.sorts };
    }

    const defaultValues: SearchConfig =
        defaults ?? {
            filters: { ...templateCopy.filters },
            sources: { ...templateCopy.sources },
            sorts: { ...templateCopy.sorts },
        };

    const hash = hashQueryParts(templateCopy.searchTerm, templateCopy.filters, templateCopy.sources, templateCopy.sorts, templateCopy.bias);
    const historyHash = hashQueryParts(templateCopy.searchTerm, templateCopy.filters, templateCopy.sources, templateCopy.sorts);
    const hasQuery = templateCopy.searchTerm.trim() !== '';
    const hasQueryOrFilter = hasQuery || (templateCopy.chipConfig.tripChips && templateCopy.filters.tripChip !== 'all');
    let query: SearchQuery;
    const copy = (deepFilters: boolean, deepSources: boolean, deepSorts: boolean) => {
        const newValue = newSearchQuery({
                searchTerm: query.searchTerm,
                chipConfig: query.chipConfig,
                emptyQueryBehavior: query.emptyQueryBehavior,

                // optionally deep-copy in case effects depend on the object
                filters: deepFilters ? { ...query.filters } : query.filters,
                sources: deepSources ? { ...query.sources } : query.sources,
                sorts: deepSorts ? { ...query.sorts } : query.sorts,

                bias: query.bias,
                searchType: query.searchType,
            },
            replacer,
            defaultValues);
        replacer(newValue);
        return newValue;
    };
    query = {
        searchTerm: templateCopy.searchTerm,
        chipConfig: templateCopy.chipConfig,
        emptyQueryBehavior: templateCopy.emptyQueryBehavior,
        filters: templateCopy.filters,
        sources: templateCopy.sources,
        sorts: templateCopy.sorts,
        bias: templateCopy.bias ?? 'IP_BIAS',
        searchType: templateCopy.searchType,
        hash,
        historyHash,
        hasQuery,
        hasQueryOrFilter,
        setTerm: (newTerm: string) => {
            query.searchTerm = newTerm;
            return copy(false, false, false);
        },
        setTripChipOption: (option: TripChipOption) => {
            query.filters.tripChip = option;
            return copy(true, false, false);
        },
        toggleYourSaved: () => {
            query.sources.yourSaved = !query.sources.yourSaved;
            return copy(false, true, false);
        },
        toggleOtherSaved: () => {
            query.sources.otherSaved = !query.sources.otherSaved;
            return copy(false, true, false);
        },
        toggleRecentTrips: () => {
            query.sources.recentTrips = !query.sources.recentTrips;
            return copy(false, true, false);
        },
        toggleSearchEngine: () => {
            query.sources.searchEngine = !query.sources.searchEngine;
            return copy(false, true, false);
        },
        updateLocationBias(newBias: SearchBias): SearchQuery {
            query.bias = newBias;
            return copy(false, false, false);
        },
        toggleSortByDistance(): SearchQuery {
            query.sorts.distance = !query.sorts.distance;
            return copy(false, false, true);
        },
        toggleSortByRating(): SearchQuery {
            query.sorts.rating = !query.sorts.rating;
            return copy(false, false, true);
        },
        includeActivityType(type: Omit<PlaceType, PlaceType.Hotel>, checked: boolean) {
            switch (type) {
                case PlaceType.Activity:
                    query.filters.activities = checked;
                    break;
                case PlaceType.Flight:
                    query.filters.flights = checked;
                    break;
                case PlaceType.Meal:
                    query.filters.meals = checked;
                    break;
                case PlaceType.Lodging:
                    query.filters.lodging = checked;
                    break;
            }
            return copy(true, false, false);
        },
        updateHistory(queryParams: QueryParamsAndMutators) {
            updateRouterHistory(
                queryParams,
                this.searchTerm,
                { filters: this.filters, sources: this.sources, sorts: this.sorts },
                defaultValues
            );
        },
        loadFromParams(queryParams: QueryParamsAndMutators): SearchQuery {
            query.searchTerm = queryParams.first('q') ?? '';
            query.filters = parseFilters(queryParams, defaultValues.filters);
            query.sources = parseSources(queryParams, defaultValues.sources);
            query.sorts = parseSorts(queryParams, defaultValues.sorts);
            return copy(true, true, true);
        },
    };
    return query;
}

function hashQueryParts(
    searchTerm: string,
    filters: SearchFilters,
    sources: SearchSources,
    sorts: SearchSorts,
    bias?: SearchBias,
) {
    // TODO this isn't actually hashed at all just yet, but we use it as though it is
    const hash = `${filters.tripChip}:${filters.activities}:${filters.meals}:${filters.flights}:${filters.lodging}`
        + `:${sources.yourSaved}:${sources.otherSaved}:${sources.recentTrips}:${sources.searchEngine}`
        + `:${searchTerm}`
        + `:${sorts.distance}:${sorts.rating}`;
    if (bias) {
        return `${hash}:${biasToString(bias)}`;
    } else {
        return hash;
    }
}

type NoInfer<T> = [T][T extends unknown ? 0 : never];
function parseBoolean<T = void>(
    queryParams: QueryParams,
    key: Extract<keyof NoInfer<T>, string>,
    defaultValue: boolean
): boolean {
    if (queryParams.has(key)) {
        return queryParams.first(key) === 'true';
    } else {
        return defaultValue;
    }
}

// TODO write a test that validates that SearchFilters, SearchSources and SearchSorts do not have overlapping keys

function parseFilters(queryParams: QueryParams, defaults: SearchFilters): SearchFilters {
    return {
        meals: parseBoolean<SearchFilters>(queryParams, 'meals', defaults.meals),
        lodging: parseBoolean<SearchFilters>(queryParams, 'lodging', defaults.lodging),
        flights: parseBoolean<SearchFilters>(queryParams, 'flights', defaults.flights),
        activities: parseBoolean<SearchFilters>(queryParams, 'activities', defaults.activities),
        tripChip: queryParams.first('tripChip') as TripChipOption ?? defaults.tripChip,
    };
}

function parseSources(queryParams: QueryParams, defaults: SearchSources): SearchSources {
    return {
        yourSaved: parseBoolean<SearchSources>(queryParams, 'yourSaved', defaults.yourSaved),
        otherSaved: parseBoolean<SearchSources>(queryParams, 'otherSaved', defaults.otherSaved),
        recentTrips: parseBoolean<SearchSources>(queryParams, 'recentTrips', defaults.recentTrips),
        searchEngine: parseBoolean<SearchSources>(queryParams, 'searchEngine', defaults.searchEngine),
    };
}

function parseSorts(queryParams: QueryParams, defaults: SearchSorts): SearchSorts {
    return {
        distance: parseBoolean<SearchSorts>(queryParams, 'distance', defaults.distance),
        rating: parseBoolean<SearchSorts>(queryParams, 'rating', defaults.rating),
    };
}

function toUrlFormat(o: { [key: string]: boolean | string }): { [key: string]: string } {
    const formatted: { [key: string]: string } = {};
    Object.keys(o).forEach(k => {
        if (o[k] === true) {
            formatted[k] = "true";
        } else if (o[k] === false) {
            formatted[k] = "false";
        } else if (typeof o[k] === 'string') {
            formatted[k] = o[k] as string;
        }
    });
    return formatted;
}

export type SearchBias = { center: Position, radius: number } | 'IP_BIAS';

export type ChipConfig = {
    tripChips: boolean
    recents: boolean
    savedPlaces: boolean
    searchEngine: boolean
};

export type SearchResults = {
    query: SearchQuery,
    activities: Place[],
    savedPlaces: SavedPlaceResult[],
    searchEngineResults: SearchEngineResult[],
};

function biasToString(bias: SearchBias) {
    if (typeof bias === 'string') {
        return bias;
    } else {
        return `${bias.center.lat},${bias.center.lng},${bias.radius}`;
    }
}

function isAnythingFiltered(filters: SearchFilters) {
    return filters.tripChip !== 'all' || !filters.lodging || !filters.meals || !filters.flights || !filters.activities;
}

const availableSearchProviders = Capacitor.getPlatform() === 'ios'
    ? { apple: useAppleResults, google: useGoogleResults, mock: useMockResults }
    : { google: useGoogleResults, mock: useMockResults };

function useSearchEngineResults(searchQuery: SearchQuery) {
    const userData = useContext(UserContext);

    const preferredProviders = userData.searchProviders?.filter(p => p in availableSearchProviders);
    let searchProvider: 'apple' | 'google' | 'mock' = 'google';
    if (preferredProviders && preferredProviders.length > 0) {
        [ searchProvider ] = preferredProviders;
    }

    // We use all the appropriate search engines at the same time so that we don't break the
    // React hook rules.
    const googleResults = useGoogleResults(searchProvider === 'google' ? searchQuery : undefined);
    const appleResults = useAppleResults(searchProvider === 'apple' ? searchQuery : undefined);
    const mockResults = useMockResults(searchProvider === 'mock' ? searchQuery : undefined);

    switch (searchProvider) {
        case "google":
            return googleResults;
        case "apple":
            return appleResults;
        case "mock":
            return mockResults;
        default:
            return [];
    }
}

function useAppleResults(searchQuery: SearchQuery | undefined) {
    const currentPosition = usePositionSnapshot();
    const [ searchEngineResults, setSearchEngineResults ] = useState<SearchEngineResult[]>([]);

    useEffect(() => {
        if (searchQuery) {
            CapacitorNativeSupport.mapSearch({ term: searchQuery.searchTerm })
                .then(async autocompleteResults => {
                    const searchResults = autocompleteResults.results
                        .map(result => {
                            const position = positionFromLatitudeLongitude(result.position);
                            const appleResult: SearchEngineResult = {
                                type: 'apple-search',
                                title: result.title,
                                placeType: extractPlaceTypeFromAppleResult(result),
                                cheap_address: result.address,
                                photos: async () => [],
                                position: async () => position,
                                locality: async () => result.locality,
                                full_address: async () => result.address,
                                distance_meters: async () => calculateDistance(
                                    positionFromLatitudeLongitude(currentPosition?.coords),
                                    async () => ({ position })
                                ),
                            };
                            return appleResult;
                        });
                    setSearchEngineResults(searchResults);
                });
        } else {
            setSearchEngineResults([]);
        }
    }, [ searchQuery ]);

    return searchEngineResults;
}

function extractPlaceTypeFromAppleResult(result: NativeMapsSearchResult) {
    switch (result.category) {
        case "airport":
            return PlaceType.Flight;
        case "bakery":
        case "brewery":
        case "cafe":
        case "restaurant":
            return PlaceType.Meal;

        case "campground":
        case "hotel":
            return PlaceType.Lodging;

        case "amusementPark":
        case "aquarium":
        case "atm":
        case "bank":
        case "beach":
        case "carRental":
        case "evCharger":
        case "fireStation":
        case "fitnessCenter":
        case "foodMarket":
        case "gasStation":
        case "hospital":
        case "laundry":
        case "library":
        case "marina":
        case "movieTheater":
        case "museum":
        case "nationalPark":
        case "nightlife":
        case "park":
        case "parking":
        case "pharmacy":
        case "police":
        case "postOffice":
        case "publicTransport":
        case "restroom":
        case "school":
        case "stadium":
        case "store":
        case "theater":
        case "university":
        case "winery":
        case "zoo":
            return PlaceType.Activity;

        case undefined:
        default:
            return undefined;
    }
}

function useGoogleResults(searchQuery: SearchQuery | undefined) {
    const currentPosition = usePositionSnapshot();
    const searchCacheKey = `search-5m-${searchQuery ? biasToString(searchQuery.bias) : ''}-${searchQuery?.searchType}`;
    const googleMapsStatus = useJsApiLoader(googleMapsLoaderOptions);
    const google = usePlacesAutocomplete({ // TODO use an autocomplete session id here and when fetching details
        initOnMount: false,
        googleMaps: (window as any).google?.maps,
        debounce: 300,
        cacheKey: searchCacheKey,
        cache: 5 * 60, // 5 minutes -- we use distance_meters, and so need to re-query periodically. TODO include rounded origin in search key and extend this duration
        requestOptions: {
            locationBias: searchQuery?.bias,
            types: searchQuery?.searchType === 'region' ? [ '(regions)' ] : undefined,
            origin: currentPosition ? positionFromLatitudeLongitude(currentPosition.coords) : undefined,
        },
    });

    const [ searchResults, setSearchResults ] = useState<SearchEngineResult[]>([]);

    useEffect(() => {
        // Issue the Google search, if the chip dictates we're using it
        if (searchQuery?.sources.searchEngine && google?.ready) {
            google.setValue(searchQuery.searchTerm);
        }
    }, [ searchQuery?.sources.searchEngine, searchQuery?.searchTerm, google.ready ]);

    useEffect(() => {
        if (searchQuery?.chipConfig.searchEngine && googleMapsStatus.isLoaded && (window as any).google?.maps) {
            google.init();
        }
    }, [ searchQuery?.chipConfig.searchEngine, googleMapsStatus.isLoaded, google.init, (window as any).google?.maps ]);

    useEffect(() => {
        if (!google.suggestions.loading) {
            setSearchResults(
                google.suggestions.data
                    .map(suggestion => {
                        if (!suggestion) {
                            return undefined;
                        } else {
                            // This logic ensures that we only attempt to fetch details once,
                            // even if we access both 'position' and 'locality'
                            let detailsPromise: Promise<ParsedDetails> | undefined;
                            const detailsProvider: () => Promise<ParsedDetails> = () => {
                                if (!detailsPromise) {
                                    detailsPromise = getDetailsForSuggestion(suggestion);
                                }
                                return detailsPromise;
                            };

                            const result: SearchEngineResult = {
                                type: 'google-search',
                                value: suggestion,
                                title: suggestion.structured_formatting.main_text,
                                placeType: extractPlaceTypeFromGoogleSuggestion(suggestion),
                                cheap_address: suggestion.structured_formatting.secondary_text,
                                photos: async () => {
                                    const pics = await getPhotosForSuggestion(suggestion);
                                    return pics ?? [];
                                },
                                position: async () => (await detailsProvider()).position,
                                locality: async () => (await detailsProvider()).locality,
                                distance_meters: async () => suggestion.distance_meters,

                                // We need to use the 'getDetails' suggestion response here, since the original autocomplete
                                // value from Google does not include a full address.
                                full_address: async () => (await detailsProvider()).suggestion.structured_formatting.secondary_text,
                            };
                            return result;
                        }
                    })
                    .filter(r => !!r) as SearchEngineResult[]
            );
        }
    }, [ google.suggestions, currentPosition ]);

    return searchResults;
}

async function calculateDistance(currentPosition: Position | undefined, hitPositionProvider: () => Promise<{ position: Position | undefined }>) {
    if (currentPosition) {
        const { position } = await hitPositionProvider();
        if (position) {
            return getDistance(position, currentPosition);
        } else {
            return undefined;
        }
    } else {
        return undefined;
    }
}

export function useSearchResults(
    searchQuery: SearchQuery,
    places: Place[],
    comments: PlaceComment[] | undefined,
    relativeLocation: Position | undefined,
    bounds: Bounds | undefined,
    recentActivitiesEmptyQueryBehavior: EmptyQueryBehavior,
): SearchResults | undefined {
    const searchEngineResults = useSearchEngineResults(searchQuery);

    // TODO debounce the internal searches as well as the Google search
    const emptyQueryBehavior = isAnythingFiltered(searchQuery.filters)
        ? 'match-all' // if we're filtering, treat '' like '*'
        : searchQuery.emptyQueryBehavior;

    const recentActivitiesHits = searchQuery.chipConfig.recents
        ? useRecentActivityResultsFromTrips(searchQuery, bounds, recentActivitiesEmptyQueryBehavior)
        : undefined;

    const savedPlaceResults = searchQuery.chipConfig.savedPlaces
        ? useSavedPlaceSearchResults(searchQuery, searchQuery.sources.yourSaved, searchQuery.sources.otherSaved)
        : undefined;

    const unsortedProvidedPlaceHits = useFilteredPlaces(
        places,
        comments,
        emptyQueryBehavior,
        searchQuery
    );

    const sortLocation = useMemo(
        () => {
            // We only assign the sortLocation if we're sorting by distance and we have a relative location.
            // This indirection prevents us from re-doing searches when the location changes but we're not
            // sorting by distance.
            if (searchQuery.sorts.distance && relativeLocation) {
                return relativeLocation;
            } else {
                return undefined;
            }
        },
        [ searchQuery.sorts.distance, relativeLocation ]
    );

    const providedPlaceHits = useMemo(() => {
        const copy = [ ...unsortedProvidedPlaceHits ];
        if (searchQuery.sorts.rating || searchQuery.sorts.distance) {
            // We sort by rating first and then distance, if both are set.
            // This prioritizes distance.
            if (searchQuery.sorts.rating) {
                copy.sort((a, b) => {
                    if (a.placeclass === PlaceClassType.Activity) {
                        return (a.totalvotes ?? 0) - (b.totalvotes ?? 0);
                    } else if (a.placeclass === PlaceClassType.Saved) {
                        return (a.rating ?? -1) - (b.rating ?? -1);
                    } else {
                        return 0;
                    }
                });
            }
            if (searchQuery.sorts.distance && sortLocation) {
                copy.sort((a, b) => {
                    const aPos = positionFromLatLng(a);
                    const bPos = positionFromLatLng(b);
                    if (!aPos && !bPos) {
                        return 0;
                    } else if (!aPos) {
                        return 1;
                    } else if (!bPos) {
                        return -1;
                    } else {
                        const aDistance = getDistance(aPos, sortLocation);
                        const bDistance = getDistance(bPos, sortLocation);
                        return aDistance - bDistance;
                    }
                });
            }
        }
        return copy;
    }, [ unsortedProvidedPlaceHits, searchQuery.sorts, searchQuery.filters, sortLocation ]);

    return useMemo(() => {
        if (searchQuery) {
            const providedActivities = providedPlaceHits.filter(p => p.placeclass === PlaceClassType.Activity);
            const providedSavedPlaces = providedPlaceHits.filter(p => p.placeclass === PlaceClassType.Saved);
            return {
                query: searchQuery,
                activities: [
                    ...(providedActivities),
                    ...(searchQuery.sources.recentTrips ? (recentActivitiesHits ?? []).slice(0, 50) : []), // limit how many recents can be drawn on the map, to keep performance reasonable
                ],
                savedPlaces: mergeSavedPlaceResults(providedSavedPlaces, savedPlaceResults),
                searchEngineResults,
            };
        } else {
            return undefined;
        }
    }, [ searchEngineResults, savedPlaceResults, providedPlaceHits, recentActivitiesHits, searchQuery ]);
}

function mergeSavedPlaceResults(places: Place[], savedPlaceResults: SavedPlaceResult[] | undefined) {
    // TODO we can make this smarter, but right now it doesn't matter, since we tear apart the savedPlaceResults
    //  when we map them.
    return [ ...places.map(p => ({ results: [ p ] } as SavedPlaceResult)), ...(savedPlaceResults ?? []) ];
}

function updateRouterHistory(
    queryParams: QueryParamsAndMutators,
    searchTerm: string,
    newConfig: SearchConfig,
    defaults: SearchConfig
) {
    const { toAppend, toOmit } = buildRouterInfo(queryParams, searchTerm, newConfig, defaults);
    queryParams.route(toAppend, toOmit, 'browser-replace');
}

export function buildRouterInfo(
    queryParams: QueryParams,
    searchTerm: string,
    newConfig: SearchConfig,
    defaults: SearchConfig) {
    const currentQ = queryParams.first('q');
    const currentFilters = parseFilters(queryParams, defaults.filters);
    const currentSources = parseSources(queryParams, defaults.sources);
    const currentSorts = parseSorts(queryParams, defaults.sorts);

    const toAppend: Appendables = {
        ...toUrlFormat(newConfig.filters),
        ...toUrlFormat(newConfig.sources),
        ...toUrlFormat(newConfig.sorts),
    };

    // remove keys that are set to their defaults
    const defaultAppendables: Appendables = {
        ...toUrlFormat(defaults.filters),
        ...toUrlFormat(defaults.sources),
        ...toUrlFormat(defaults.sorts),
    };
    Object.keys(toAppend).forEach(key => {
        if (toAppend[key] === defaultAppendables[key]) {
            delete toAppend[key];
        }
    });

    const toOmit = [];

    if (currentQ !== searchTerm) {
        toOmit.push('q');
        if (searchTerm) {
            toAppend.q = searchTerm;
        }
    }

    // Put all the current-value keys into the toOmit set, so that the current
    // values of all of them are excluded from the URL
    setUnion(
        new Set(Object.keys(currentFilters)),
        new Set(Object.keys(currentSources)),
        new Set(Object.keys(currentSorts)),
    ).forEach(toRemove => {
        if (queryParams.has(toRemove)) {
            toOmit.push(toRemove);
        }
    });

    return { toAppend, toOmit };
}

const mockSearchCandidates: SearchEngineResult[] = [
    {
        type: 'mock-search',
        title: 'Nova Scotia',
        cheap_address: 'i-am-nova-scotias-cheap-address',
        placeType: undefined,
        photos: async () => ([]),
        position: async () => ({ lat: 45.0778, lng: -63.5467 }),
        locality: async () => undefined,
        full_address: async () => 'i-am-nova-scotias-full-address',
        distance_meters: async () => 42,
    },
    {
        type: 'mock-search',
        title: 'swimming',
        cheap_address: 'i-am-swimming-cheap-address',
        placeType: PlaceType.Activity,
        photos: async () => ([]),
        position: async () => ({ lat: 45.0778, lng: -63.5467 }),
        locality: async () => 'i-am-swimming-locality',
        full_address: async () => 'i-am-swimming-full-address',
        distance_meters: async () => 42,
    },
    {
        type: 'mock-search',
        title: 'hiking',
        cheap_address: 'i-am-hiking-cheap-address',
        placeType: PlaceType.Activity,
        photos: async () => ([]),
        position: async () => ({ lat: 45.0778, lng: -63.5467 }),
        locality: async () => 'i-am-hiking-locality',
        full_address: async () => 'i-am-hiking-full-address',
        distance_meters: async () => 42,
    },
    {
        type: 'mock-search',
        title: 'Empire State Building',
        cheap_address: 'i-am-empire-state-building-cheap-address',
        placeType: PlaceType.Activity,
        photos: async () => ([]),
        position: async () => ({ lat: 40.7484, lng: -73.9857 }),
        locality: async () => 'i-am-empire-state-building-locality',
        full_address: async () => 'i-am-empire-state-building-full-address',
        distance_meters: async () => 42,
    },
];
function useMockResults(searchQuery: SearchQuery | undefined) {
    return useMemo(
        () => {
            if (!searchQuery || searchQuery.searchTerm.length < 3) {
                return [];
            } else {
                return mockSearchCandidates.filter(candidate =>
                    candidate.title.toLocaleLowerCase().includes(searchQuery.searchTerm.toLocaleLowerCase()));
            }
        },
        [ searchQuery?.searchTerm ]
    );
}
