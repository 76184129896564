import { useContext } from 'react';
import { useSearchParams } from 'next/navigation';
import { HomeAppShell } from '../components/HomeAppShell/HomeAppShell';
import { RequiresAuthnAndData } from "../components/RequiresAuthnAndData";
import { HomeContext } from '../lib/context';

'use client';

export default function HomePage() {
    const { uidPlaces, userTrips } = useContext(HomeContext);
    const searchParams = useSearchParams();
    const tab = searchParams.get('tab');
    const place = searchParams.get('place');

    return <RequiresAuthnAndData
        isLoading={false}
        supportsCachedAuth
        childProvider={userData =>
            <HomeAppShell
                userid={userData.firebaseUserId}
                tab={tab ?? 'Overview'}
                selected={place}
                userTrips={userTrips}
                savedUidPlaces={uidPlaces}
            />
        }
    />;
}

HomePage.debugId = 'Index';
