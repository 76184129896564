import { Text, Avatar, ActionIcon } from '@mantine/core';
import { useContext } from 'react';
import { IconTrash } from "@tabler/icons-react";
import { useFirebaseRefs } from "../../lib/firebase";
import { UserClass, PlaceComment } from '../../lib/interfaces';
import { showModalAsync } from "../TSModal";
import { SheetsContext } from "../Sheets";
import { deleteComment } from "../../lib/comments";

import classes from "./Comment.module.css";
import {getInitialsShortname} from "../../lib/userdata";

interface CommentSimpleProps {
    comment: PlaceComment
    commentUser: UserClass | undefined
    authorimage: string | undefined
    timestamp: string | undefined
}

export function CommentSimple({ comment, commentUser, authorimage, timestamp }: CommentSimpleProps) {
    const firebaseRefs = useFirebaseRefs();
    const sheets = useContext(SheetsContext);
    const { initials, shortname } = getInitialsShortname(commentUser?.displayName ?? '');

    return (
        <div aria-label='Comment' className={classes.item} style={{ width: '100%', display: 'flex' }}>
            {authorimage && <Avatar src={authorimage} alt={commentUser?.displayName ?? ''} radius="xl" size='md' />}
            {!authorimage && <Avatar variant="filled" radius="xl" className={classes.itemIcon} size='md'>{initials}</Avatar>}
            <div style={{ flex: '1', display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div style={{ display: 'flex' }}>
                    <Text fw={700} size="md" color='dimmed'>
                        {shortname}
                    </Text>
                    { timestamp &&
                        <Text
                            size='sm'
                            color='dimmed'
                            style={{
                                paddingLeft: '1rem',
                                alignSelf: 'center',
                            }}>
                            {timestamp}
                        </Text> }
                    <ActionIcon
                        variant="transparent"
                        size="md"
                        radius='xl'
                        style={{ marginLeft: 'auto' }}
                        onClick={async () => {
                            const response = await showModalAsync(
                                sheets,
                                'Delete comment?',
                                [
                                    { title: 'Cancel', id: 'cancel' },
                                    { title: 'Delete', id: 'delete', type: 'destructive' },
                                ]);
                            if (response.type === 'choice' && response.choice.id === 'delete') {
                                deleteComment(firebaseRefs, comment);
                            }
                        }}
                    >
                        <IconTrash size={24} />
                    </ActionIcon>
                </div>
                <Text className={classes.comment}>
                    {comment.comment ?? ''}
                </Text>
            </div>
        </div>
    );
}
