import { Button, Card, Group, List, Text } from '@mantine/core';
import { useRouter } from 'next/navigation';
import { useContext, useMemo } from "react";
import { Trip } from '../../lib/interfaces';
import { TSCachedImg } from "../TSImageCache";
import { useGoogleImages, revokeCachedGooglePlaceImages } from '../../lib/google';
import { showAddEditTrip } from "../AddEditTrip/AddEditTrip";
import { SheetsContext } from "../Sheets";
import {DateTime} from "luxon";

export function TripsList({ userTrips }: { userTrips: Trip[] }) {
    const router = useRouter();
    const sheets = useContext(SheetsContext);
    const trips = userTrips.sort((a, b) => b.startdate!.compareTo(a.startdate!));

    const items = trips.map((item, index) =>
        <Card
            aria-label='Trip Card'
            key={index}
            p={0}
            m={0}
            onClick={() => router.push(`/trip/${item.docid}`)}
        >
            <TripImage trip={item} />
            <Group gap={0} wrap='nowrap' py={5} justify="space-between" grow>
                <Text truncate="end">
                    {item.title}
                </Text>
                <Text style={{ textAlign: 'right' }} truncate='end'>
                    {item.startdate!.toLocalDateTime().toLocaleString(DateTime.DATE_MED)}
                </Text>
            </Group>
        </Card>);

    if (items.length === 0) {
        return <div className='trip-list' style={{ padding: '1rem' }}>
            <div style={{ paddingBottom: '1rem' }}>You don&apos;t have any trips yet!</div>
            <Button onClick={() => showAddEditTrip(sheets, {})}>Plan your first trip</Button>
        </div>;
    } else {
        return <List px='xs' className='trip-list'>{items}</List>;
    }
}

function useAppropriateImageUrl(trip: Trip) {
    // If there is no custom image url but there is a place URL, look up the place and
    // use the first image for the place.
    const { googlePics } = useGoogleImages(trip.img ? null : trip.placeid);

    return useMemo(
        () => {
            if (!trip.img && trip.placeid) {
                if (googlePics && googlePics.length > 0) {
                    return googlePics[0].url400h ?? googlePics[0].url;
                } else {
                    return undefined;
                }
            } else {
                return trip.img400h ?? trip.img;
            }
        },
        [ trip.img, trip.placeid, trip.img400h, googlePics ]
    );
}

export function TripImage(props: { trip: Trip }) {
    const url = useAppropriateImageUrl(props.trip);

    return <TSCachedImg
        style={{ minHeight: '200px', height: '200px' }}
        src={url}
        alt={props.trip.title ?? 'Unknown Trip'}
        lazyLoading
        onFetchFailure={() => {
            // TODO trigger a re-render of this object, which will re-fetch from cache now that we've revoked
            props.trip.placeid && revokeCachedGooglePlaceImages(props.trip.placeid);
        }}
    />;
}
