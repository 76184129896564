import React, { PropsWithChildren, useContext, useState } from "react";
import { AppShell, Group, Text, useMantineTheme } from "@mantine/core";
import { useColorScheme } from "@mantine/hooks";
import { UserContext } from "../../lib/context";
import { TravelScrollBurger, TravelScrollDrawer } from "../TravelScrollMenu";
import { SheetsMountPoint } from "../Sheets";
import { Trip } from "../../lib/interfaces";

import shellClasses from "./ShellStyles.module.css";
import { UnauthenticatedRightSide } from "./UnauthenticatedRightSide";

export function TSAppShell(props: {
    header?: {
        canOpenMenu?: boolean,
        title?: string,
        rightSide?: JSX.Element | false,
        bottom?: JSX.Element | false,
    },
    activeTrip?: Trip,
    drawerState?: {
        isDrawerOpen: boolean,
        setDrawerOpen: (isOpen: boolean) => unknown,
    },
    ariaLabel: string,
} & PropsWithChildren) {
    const theme = useMantineTheme();
    const colorScheme = useColorScheme();
    const userData = useContext(UserContext);

    const [ internalIsDrawerOpen, internalSetDrawerOpen ] = useState(false);

    const effectiveDrawerState = props.drawerState
        ?? { isDrawerOpen: internalIsDrawerOpen, setDrawerOpen: internalSetDrawerOpen };

    const canOpenMenu = props.header?.canOpenMenu ?? true;
    return <AppShell
        className={shellClasses.appshell}
        aria-label={props.ariaLabel}
        padding={0}
        styles={{
            main: {
                backgroundColor: colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
            },
        }}
    >
        { props.header && <TSAppShellHeader
            canOpenMenu={canOpenMenu}
            title={props.header.title}
            isDrawerOpen={effectiveDrawerState.isDrawerOpen}
            setDrawerOpen={effectiveDrawerState.setDrawerOpen}
            rightSide={props.header.rightSide
                ? props.header.rightSide
                : userData.status === 'logged-out' ? <UnauthenticatedRightSide /> : undefined}
        >
            {props.header.bottom && props.header.bottom}
        </TSAppShellHeader> }
        <SheetsMountPoint />
        {props.children}
        {canOpenMenu && <TravelScrollDrawer
            activeTrip={props.activeTrip}
            isOpen={effectiveDrawerState.isDrawerOpen}
            setOpen={effectiveDrawerState.setDrawerOpen}
        />}
    </AppShell>;
}

function TSAppShellHeader(props: {
    canOpenMenu: boolean,
    title?: string,
    rightSide?: JSX.Element,
    isDrawerOpen: boolean,
    setDrawerOpen: (isOpen: boolean) => unknown,
} & PropsWithChildren) {
    return <AppShell.Header
        className={shellClasses.header}
        styles={{
            header: {
                backgroundColor: 'var(--mantine-color-blue-5)',
            },
        }}
    >
        <Group wrap='nowrap' style={{ height: '100%' }} justify='space-between' gap='xs' ml='xs' mr='sm'>
            <Group wrap='nowrap' style={{ height: '100%', overflow: 'hidden', flex: 1 }} justify="left" gap="xs">
                <TravelScrollBurger
                    canOpenMenu={props.canOpenMenu}
                    isOpen={props.canOpenMenu ? props.isDrawerOpen : false}
                    setOpen={props.canOpenMenu ? props.setDrawerOpen : () => { }}
                />
                { props.title && <Text fw={600} lineClamp={1} style={{ textOverflow: 'ellipsis', overflow: 'hidden', flex: 1, textAlign: 'left' }} color='white'>
                    {props.title}
                </Text> }
            </Group>
            { props.rightSide && <Group wrap='nowrap' justify='right'>
                {props.rightSide}
            </Group> }
        </Group>
        {props.children}
    </AppShell.Header>;
}
