import { Timestamp } from "@firebase/firestore";
import { DateTime } from "luxon";
import * as dayjs from "dayjs";

const localizedFormat = require('dayjs/plugin/localizedFormat');

dayjs.extend(localizedFormat); // This adds support for advanced formats like 'll'

const epoch = DateTime.fromISO("1970-01-01T00:00:00Z", { zone: 'utc' });

export class TSDate {
    readonly daysSinceEpoch: number;

    private constructor(days: number) {
        this.daysSinceEpoch = days;
    }

    toString() {
        return this.toLocalDateTime().toISODate();
    }

    toLocalDateTime() {
        return epoch                                      // start from the epoch-reference date in UTC
            .setZone("local", { keepLocalTime: true })    // switch it to the local zone, keeping the time intact
            .plus({ days: this.daysSinceEpoch });         // add our day count to the result
    }

    toLocalJSDate() {
        return this.toLocalDateTime().toJSDate();
    }

    // Note that this is a bit different semantically than the DateTime and JS Date variants -- we persist
    // this value, whereas we display those two. Thus, the other two will have different millisecond values
    // than the return from this method.
    toTimestamp() {
        return Timestamp.fromMillis(epoch.plus({ days: this.daysSinceEpoch }).toMillis());
    }

    equals(other: TSDate) {
        return this.daysSinceEpoch === other.daysSinceEpoch;
    }

    isAfter(other: TSDate) {
        return this.daysSinceEpoch > other.daysSinceEpoch;
    }

    isBefore(other: TSDate) {
        return this.daysSinceEpoch < other.daysSinceEpoch;
    }

    isEqualOrAfter(other: TSDate) {
        return this.daysSinceEpoch > other.daysSinceEpoch || this.equals(other);
    }

    isEqualOrBefore(other: TSDate) {
        return this.daysSinceEpoch < other.daysSinceEpoch || this.equals(other);
    }

    compareTo(other: TSDate) {
        return this.daysSinceEpoch - other.daysSinceEpoch;
    }

    plus({ days }: { days: number }) {
        return new TSDate(this.daysSinceEpoch + days);
    }

    minus({ days }: { days: number }) {
        return new TSDate(this.daysSinceEpoch - days);
    }

    static fromDateTime(dateTime: DateTime): TSDate {
        const converted = dateTime.setZone('utc', { keepLocalTime: true }).startOf('day');
        return new TSDate(converted.diff(epoch, 'days').get('days'));
    }

    static fromDate(date: Date | null | undefined): TSDate | undefined {
        return date ? this.fromDateTime(DateTime.fromJSDate(date)) : undefined;
    }

    static fromMillis(millis: number): TSDate {
        return this.fromTimestamp(Timestamp.fromMillis(millis))!;
    }

    // As with toTimestamp(), this method differs from fromDateTime(), in that it's designed
    // to work with the persistent representation, not display formats.
    static fromTimestamp(timestamp: Timestamp | null | undefined): TSDate | undefined {
        if (timestamp) {
            const parsed = DateTime.fromMillis(timestamp.toMillis(), { zone: 'utc' });
            return new TSDate(parsed.startOf('day').diff(epoch, 'days').get('days'));
        } else {
            return undefined;
        }
    }

    static fromString(str: string): TSDate {
        const dateTime = DateTime.fromFormat(str, "yyyy-MM-dd");
        if (!dateTime.isValid) {
            throw Error(`Failed to parse date '${str}'!`);
        }

        return TSDate.fromDateTime(dateTime);
    }

    static today() {
        return TSDate.fromDateTime(DateTime.local());
    }

    toRelative() {
        const dayDelta = Math.abs(TSDate.today().compareTo(this));
        if (dayDelta <= 10) {
            return this.toLocalDateTime()?.toRelativeCalendar({ unit: 'days' });
        } else if (dayDelta <= 21) {
            return this.toLocalDateTime()?.toRelativeCalendar({ unit: 'weeks' });
        } else {
            return this.toLocalDateTime()?.toRelativeCalendar();
        }
    }
}

export const IDEA_TSDATE = TSDate.fromDateTime(DateTime.utc(1900, 1, 1));
const IDEAS_CUTOFF = TSDate.fromDateTime(DateTime.utc(1990, 1, 1));

export function isIdea(date: TSDate | null | undefined): date is null | undefined {
    return !date || date.isBefore(IDEAS_CUTOFF);
}

export function today(): DateTime {
    return DateTime.local();
}

export function timestampToDateTime(timestamp: Timestamp | null | undefined): DateTime | undefined {
    if (timestamp) {
        return DateTime.fromMillis(timestamp.toMillis());
    } else {
        return undefined;
    }
}
