import { Chip, Group, List, SegmentedControl, Text } from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ChevronRight } from 'framework7-icons-plus/react';
import { useContext, useEffect, useMemo } from 'react';
import { useRouter } from 'next/navigation';
import { Place, PlaceClassType } from '../../lib/interfaces';
import { HomeContext, UserContext } from '../../lib/context';
import { computeDistance, Distance, distanceTextForDistance } from '../../lib/distance';
import { positionFromLatitudeLongitude } from "../../lib/position";
import { newSearchQuery } from "../../lib/search";
import { useFilteredPlaces } from "../../lib/searchHooks";
import { useGeolocationData, usePositionSnapshot } from '../../lib/geolocation';
import { useQueryParams } from '../../lib/urls';
import { PlaceDetailIcons } from "../PlaceDetailIcons";

import classes from "./SavedPlacesList.module.css";
import {useTripAdjacentUserFavorites} from "../../lib/hooks";

export type SavedPlaceSortOpts = 'created' | 'distance' | 'rating' | 'order';
export function SavedPlacesChips(props: {
    sortBy: SavedPlaceSortOpts,
}) {
    const router = useRouter();
    const searchParams = useQueryParams();

    const setSortBy = (newSort: SavedPlaceSortOpts) => {
        searchParams.replace({ sortBy: newSort }, []);
    };

    return <div style={{ display: 'flex', gap: '0.5rem' }}>
        <Chip.Group
            multiple={false}
            value={props.sortBy}
            onChange={value => setSortBy(value as SavedPlaceSortOpts)}
        >
            <Chip value="created" size="xs">Created</Chip>
            <Chip value="distance" size="xs">Distance From Me</Chip>
            <Chip value="rating" size="xs">Rating</Chip>
        </Chip.Group>
    </div>;
}

export function SavedPlacesSegmentedControl({ places, uid, searchQuery }: {
    places: Place[],
    uid: string,
    searchQuery: string | undefined,
}): JSX.Element {
    const router = useRouter();
    const searchParams = useQueryParams();
    const user = useContext(UserContext);
    const { tripAdjacentUsersById } = useContext(HomeContext);

    const query = useMemo(
        () => newSearchQuery(
            {
                searchTerm: searchQuery ?? '',
                chipConfig: { tripChips: false, savedPlaces: true, recents: false, searchEngine: false },
                emptyQueryBehavior: "match-all",
            },
            () => { }),
        [ searchQuery ]
    );

    const filteredPlaces = useFilteredPlaces(
        places,
        undefined,
        "match-all",
        query
    );
    const { getPosition } = useGeolocationData(false);
    const tripAdjacentUserFavorites = useTripAdjacentUserFavorites(tripAdjacentUsersById);
    const sortBy = (searchParams.first('sortBy') as SavedPlaceSortOpts) ?? 'created';

    useEffect(() => {
        if (sortBy === 'distance') {
            // if we're sorting by position, trigger a location check
            getPosition();
        }
    }, [ sortBy ]);

    const friendsWithFavorites = useMemo(
        () => Array.from(tripAdjacentUsersById.values())
            .filter(tripUser => tripAdjacentUserFavorites?.some(favorite => favorite.userid === tripUser.uid)),
        [ tripAdjacentUsersById, tripAdjacentUserFavorites ]
    );

    const userItems = friendsWithFavorites?.map((item, index) =>
            <List.Item key={index} onClick={() => router.push(`/traveler/${item.uid}?tab=Saved`)} pb="md">
                <Group align='center' gap={2}>{item.displayName!}<ChevronRight /></Group>
            </List.Item>);

    const hitCountText = filteredPlaces.length === places.length
        ? places.length.toString()
        : `${filteredPlaces.length} of ${places.length}`;
    const segment = searchParams.first('seg') ?? 'MySaveds';
    return (
        <>
            {user.isLoggedIn && uid === user.firebaseUserId && <Group justify="space-between" p="xs">
                <SegmentedControl
                    value={segment}
                    onChange={(value) => {
                        searchParams.replace({ seg: value ?? 'MySaveds' }, []);
                    }}
                    data={[
                        { label: <Text>My Saved Places ({hitCountText})</Text>, value: 'MySaveds' },
                        { label: <Text>Friends&apos; Favorites</Text>, value: 'Friends' },
                    ]}
                />
                { segment === 'MySaveds' && <SavedPlacesChips sortBy={sortBy} /> }
            </Group>}
            {segment === 'MySaveds' && <SavedPlacesList
                places={filteredPlaces}
                uid={uid}
                sortBy={sortBy}
            />}
            {segment === 'Friends' && <List p="md">
                {userItems}
            </List>}
        </>
    );
}

export function SavedPlacesList({ places, uid, sortBy }: {
    places: Place[],
    uid: string,
    sortBy: SavedPlaceSortOpts,
}) {
    type PotentiallyLocatedPlace = { place: Place, distance: Distance };
    const [ placesToShow, placesToShowHandlers ] = useListState<PotentiallyLocatedPlace>([]);
    const positionSnapshot = usePositionSnapshot();

    const router = useRouter();

    useEffect(() => {
        const myCoords = positionFromLatitudeLongitude(positionSnapshot?.coords) ?? null;
        const withDistance = places.map(place => ({ place, distance: computeDistance(place, null, myCoords, null) }));
        if (sortBy === 'distance' && myCoords) {
            withDistance.sort((a, b) => (a.distance.fromMe ?? 0) - (b.distance.fromMe ?? 0));
        } else if (sortBy === 'created') {
            withDistance.sort((a, b) => (a.place.created?.toMillis() ?? 0) - (b.place.created?.toMillis() ?? 0));
            withDistance.reverse();
        } else if (sortBy === 'rating') {
            withDistance.sort((a, b) => (a.place.rating ?? -1) - (b.place.rating ?? -1));
            withDistance.reverse();
        } else {
            withDistance.sort((a, b) => (a.place.order ?? 0) - (b.place.order ?? 0));
        }
        placesToShowHandlers.setState(withDistance);
    }, [ places, sortBy, positionSnapshot ]);

    const SavedItems = placesToShow.map((potentiallyLocatedPlace, index) => {
        const item = potentiallyLocatedPlace.place;

        return <Draggable
            key={item.docid}
            index={index}
            draggableId={item.docid!}
            isDragDisabled
        >
            {/* allow user to drag their own - fromHere || user.firebaseUserId !== uid */}
            {(provided, snapshot) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                    className={`${classes.item} ${snapshot.isDragging ? classes.itemDragging : ''}`}
                    ref={provided.innerRef}
                    key={item.docid!}
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}
                    onClick={() => {
                        if (item.placeclass === PlaceClassType.Saved) {
                            router.push(`/traveler/${uid}/saved/${item.docid}`);
                        }
                    }}>
                    <Group wrap='nowrap' justify='space-between' gap='sm' py="xs">
                        <div style={{ flex: '1' }}>
                            <Text lineClamp={1} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{item.title ?? item.location}</Text>
                            <Text lineClamp={1} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} color='dimmed' size="xs">{item.address}</Text>
                            {sortBy === 'distance' && <Text lineClamp={1} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} color='dimmed' size="xs">
                                {distanceTextForDistance(null, false, PlaceClassType.Saved, potentiallyLocatedPlace.distance, null)}
                            </Text>}
                        </div>
                        <PlaceDetailIcons place={item} />
                    </Group>
                </div>
            )}
        </Draggable>;
    }) as JSX.Element[];

    return <div style={{ flex: 1, overflowX: "hidden", overflowY: "auto" }}>
        <DragDropContext
            onDragEnd={({ destination, source }) => { placesToShowHandlers.reorder({ from: source.index, to: destination?.index || 0 }); }}
        >
            <Droppable droppableId="dnd-list" direction="vertical">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {SavedItems}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    </div>;
}
