import { createContext } from 'react';
import { MultiMap } from "../../shared/collections";
import { FirebaseRefs } from "./firebase";
import { PositionKey } from "./position";
import { UserData } from "./userdata";
import { HomeData, TripData } from "./interfaces";
import { AllTripActivities } from "./placeFunctions";
import { BackgroundJob, BackgroundJobs } from './background-jobs';

export const UserContext = createContext<UserData>({
    isLoggedIn: false,
    status: 'unknown',
    countryTracking: undefined,
    searchProviders: undefined,
    privateDataLoaded: false,
});
export const HomeContext = createContext<HomeData>({
    uidPlaces: [],
    tripAdjacentUsersById: new Map(),
    userTrips: undefined,
    userTripsById: undefined,
});
export const AllTripActivitiesContext = createContext<AllTripActivities>({
    ideas: undefined,
    past: undefined,
    future: undefined,
    today: undefined,
    allActivities: new Map(),
    activityIdsByAddressKey: new MultiMap<string, string>(),
    activityIdsByPositionKey: new MultiMap<PositionKey, string>(),
});
export const TripContext = createContext<TripData>({ currentTrip: undefined });
export const BackgroundJobContext = createContext<BackgroundJobs>({ jobs: [], registerJob: () => (<BackgroundJob>{}) });
export const FirebaseContext = createContext<FirebaseRefs | undefined>(undefined);
