import { addDoc, collection, doc, setDoc, deleteDoc } from "firebase/firestore";
import { notifications } from "@mantine/notifications";
import { FirebaseRefs } from "./firebase";
import { Place, Trip, tripConverter } from "./interfaces";
import { isIdea, TSDate } from "./time";
import { UserData } from "./userdata";
import { assertNonEmpty } from "./placeFunctions";

/**
 * Updates a trip with the partial values provided,
 * merging with whatever's in the database.
 */
export async function updateTrip(
    firebaseRefs: FirebaseRefs,
    tripId: string,
    tripFields: Partial<Trip>) {
    assertNonEmpty(tripId, 'tripId');

    const ref = doc(firebaseRefs.firestore, 'trips', tripId)
        .withConverter(tripConverter);
    await setDoc(ref, tripFields, { merge: true });
}

/**
 * Inserts a trip with the partial values provided.
 */
export async function insertTrip(
    firebaseRefs: FirebaseRefs,
    userData: UserData,
    tripFields: Partial<Trip>) {
    const trip: Partial<Trip> = {
        ...tripFields,
        creatorId: userData.firebaseUserId,
        schemaVersion: 1,
    };

    if (userData.firebaseUserId && (!trip.users || trip.users.length === 0)) {
        // If we're inserting a new record, make sure there are *some* users in the trip;
        // otherwise, this will never be visible to anyone
        trip.users = [ userData.firebaseUserId ];
    }

    const ref = collection(firebaseRefs.firestore, 'trips')
        .withConverter(tripConverter);
    await addDoc(ref, trip);
}

// delete Activity in firestore
export const deleteTrip = (firebaseRefs: FirebaseRefs, trip: Partial<Trip>) => {
    if (trip.docid) {
        const tRef = doc(firebaseRefs.firestore, 'trips', trip.docid);
        deleteDoc(tRef);

        notifications.show({
            title: "Trip Deleted",
            message: "Your trip is deleted",
            autoClose: true,
        });
    }
};

type DateRangedTrip = { tripId: string, startDate: TSDate | undefined, endDate: TSDate | undefined };
export function buildDateRangedTrips(activities: Place[]) {
    const dateRangedTrips = new Map<string, DateRangedTrip>();
    for (const activity of activities) {
        if (activity.tripdocid && !isIdea(activity.startdatetime)) {
            let tripInfo = dateRangedTrips.get(activity.tripdocid);
            if (!tripInfo) {
                tripInfo = {
                    tripId: activity.tripdocid,
                    startDate: undefined,
                    endDate: undefined,
                };
                dateRangedTrips.set(tripInfo.tripId, tripInfo);
            }

            if (!tripInfo.startDate || activity.startdatetime.isBefore(tripInfo.startDate)) {
                tripInfo.startDate = activity.startdatetime;
            }

            const activityEnd = activity.enddatetime ?? activity.startdatetime;
            if (!tripInfo.endDate || activityEnd.isAfter(tripInfo.endDate)) {
                tripInfo.endDate = activityEnd;
            }
        }
    }
    return [ ...dateRangedTrips.values() ];
}

export function findTripsDuringDates(dateRangedTrips: DateRangedTrip[], dates: TSDate[]) {
    return dateRangedTrips
        .filter(trip => {
            const { startDate, endDate } = trip;
            if (!startDate || !endDate) {
                return false;
            } else {
                const dateWithinRange = dates.find(date => date.isEqualOrAfter(startDate) && date.isEqualOrBefore(endDate));
                return !!dateWithinRange;
            }
        })
        .sort((a, b) => a.startDate!.compareTo(b.startDate!));
}

export function tripDescription(trip: Trip | undefined) {
    const titleOrLocation = trip ? trip.title ?? trip.location : 'Trip Activity';
    if (trip?.startdate?.isBefore(TSDate.today().minus({ days: 45 }))) {
        const monthAndYear = trip.startdate.toLocalDateTime()
            .toLocaleString({ year: 'numeric', month: 'short' });
        return `${titleOrLocation} ${monthAndYear}`;
    } else {
        return titleOrLocation ?? undefined;
    }
}
