import {UseLoadScriptOptions} from "@react-google-maps/api/dist/useLoadScript";

export const googleMapsLoaderOptions: UseLoadScriptOptions = {
    googleMapsApiKey: 'AIzaSyDt62TlQDwtL1V1qcU4DxGfVMCttImjclk',
    libraries: [ 'places' ],
};

export const firebaseConfig = {
    apiKey: 'AIzaSyC3GmmDFALfQwbSrduZmCbNo5ZtkwjZAWg',
    authDomain: 'firetodo2.firebaseapp.com',
    databaseURL: 'https://firetodo2.firebaseio.com',
    projectId: 'firetodo2',
    storageBucket: 'firetodo2.appspot.com',
    messagingSenderId: '719666053613',
    appId: '1:719666053613:web:d1cf826e0d48cb6b6ea7e4',
    measurementId: 'G-QP41YFBEML',
};

export type TestConfig = {
    testUsers: { username: string, password: string }[],
    isTestEnv: boolean | undefined,
};

export const testConfig: TestConfig = {
    testUsers: [],
    isTestEnv: undefined,
};

function loadTestUsers() {
    if (process.env.NEXT_PUBLIC_TEST_USERNAME_0) {
        if (!process.env.NEXT_PUBLIC_TEST_PASSWORD_0) {
            throw Error("NEXT_PUBLIC_TEST_USERNAME_0 specified but NEXT_PUBLIC_TEST_PASSWORD_0 was not!");
        }
        testConfig.testUsers.push({ username: process.env.NEXT_PUBLIC_TEST_USERNAME_0, password: process.env.NEXT_PUBLIC_TEST_PASSWORD_0 });
    }
    if (testConfig.testUsers.length > 0) {
        testConfig.isTestEnv = true;
    } else {
        testConfig.isTestEnv = false;
    }
}
loadTestUsers();
