import { useEffect } from 'react';
import { PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications';
import { Timestamp, doc, setDoc, DocumentData } from 'firebase/firestore';
import { notifications } from "@mantine/notifications";
import { Capacitor } from "@capacitor/core";
import { getLogger } from './logger';
import { FirebaseRefs, useFirebaseRefs } from './firebase';

const logger = getLogger('push-notifications');

export function usePushNotifications(userId: string | undefined) {
    const firebaseRefs = useFirebaseRefs();

    useEffect(() => {
        if (userId && Capacitor.isNativePlatform()) {
            const deregistrationPromise = new Promise<() => unknown>(resolve => {
                PushNotifications.checkPermissions().then(async (res) => {
                    if (res.receive !== 'granted') {
                        const result = await PushNotifications.requestPermissions();
                        if (result.receive === 'denied') {
                            logger.log('Push Notification permission denied');
                            resolve(() => undefined);
                        } else {
                            logger.log('Push Notification permission granted');
                            resolve(await register(firebaseRefs, userId));
                        }
                    } else {
                        resolve(await register(firebaseRefs, userId));
                    }
                });
            });

            return () => {
                deregistrationPromise.then(deregister => deregister());
            };
        } else {
            return () => undefined;
        }
    }, [ userId ]); // we don't depend on firebaseRefs because we only want to do this registration once per launch
}

async function register(firebaseRefs: FirebaseRefs, userId: string) {
    // Register with Apple / Google to receive APNS / FCM notifications
    await PushNotifications.register();

    // On success, we should be able to receive notifications
    const registrationListener = PushNotifications.addListener('registration',
        async (token: Token) => {
            logger.log('Push registration success');
            const ref = doc(firebaseRefs.firestore, 'private-user-data', userId, 'devicetokens', token.value);
            const data: DocumentData = {
                token: token.value,
                platform: "ios",
                createdAt: Timestamp.now(),
            };
            await setDoc(ref, data);
        }
    );

    // Some issue with our setup and push will not work
    const errorListener = PushNotifications.addListener('registrationError', (error) => {
        logger.warn(`Error on registration: ${error.error}`);
    });

    // Show the notification payload if the app is in the foreground
    const receivedListener = PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
            notifications.show({ message: `${notification.title} ${notification.body}` });
        }
    );

    return () => Promise.all([
        registrationListener.remove(),
        errorListener.remove(),
        receivedListener.remove(),
    ]);
}
