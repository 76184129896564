import { ActionIcon, Affix, Group, Overlay, Stack, Text, Transition } from "@mantine/core";
import { useColorScheme } from "@mantine/hooks";
import { MdPhoto, MdPinDrop } from "react-icons/md";
import { Plus } from "framework7-icons-plus/react";
import React, { useContext, useRef } from 'react';
import { PlaceType, Trip } from "../lib/interfaces";
import { showAddEditPlace } from "./AddEditPlace/addeditplace";
import { SheetsContext } from "./Sheets";
import { useSafeArea } from "../lib/hooks";
import { showAddEditTrip } from "./AddEditTrip/AddEditTrip";
import { PlaceTypeIcon } from "./PlaceDetailIcons";
import { ImageUploader, ImageUploaderTrigger } from './ImageUploader/ImageUploader';
import { UserContext } from "../lib/context";

export function SpeedDialAffix({ isVisible, closeSpeedDial, trip }:
    { isVisible: boolean, closeSpeedDial: () => unknown, trip?: Trip | undefined }) {
    const user = useContext(UserContext);
    const sheets = useContext(SheetsContext);
    const safeArea = useSafeArea();
    const colorScheme = useColorScheme();
    const imageUploaderRef = useRef<ImageUploaderTrigger>(undefined as any as ImageUploaderTrigger);

    const items = [
        {
            text: "Flight",
            onClick: () => {
                showAddEditPlace(sheets, {
                    tripdocid: trip?.docid,
                    activityType: PlaceType.Flight,
                }, trip);
                closeSpeedDial();
            },
            icon: <PlaceTypeIcon type={PlaceType.Flight} fallback size={36} strokeColor='white' />,
        },
        {
            text: 'Lodging',
            onClick: () => {
                showAddEditPlace(sheets, {
                    tripdocid: trip?.docid,
                    activityType: PlaceType.Lodging,
                }, trip);
                closeSpeedDial();
            },
            icon: <PlaceTypeIcon type={PlaceType.Lodging} fallback size={36} strokeColor='white' />,
        },
        {
            text: 'Meal',
            onClick: () => {
                showAddEditPlace(sheets, {
                    tripdocid: trip?.docid,
                    activityType: PlaceType.Meal,
                }, trip);
                closeSpeedDial();
            },
            icon: <PlaceTypeIcon type={PlaceType.Meal} fallback size={36} strokeColor='white' />,
        },
        {
            text: 'Activity',
            onClick: () => {
                showAddEditPlace(sheets, {
                    tripdocid: trip?.docid,
                    activityType: PlaceType.Activity,
                }, trip);
                closeSpeedDial();
            },
            icon: <Plus height={30} width={30} color='white' />,
        },
    ];

    if (trip && trip.docid) {
        items.push({
            text: 'Photos',
            onClick: async () => {
                closeSpeedDial();
                imageUploaderRef.current?.click();
            },
            icon: <MdPhoto size={28} color='white' />,
        });
    } else {
        items.push({
            text: 'Trip',
            onClick: () => {
                showAddEditTrip(sheets, {});
                closeSpeedDial();
            },
            icon: <MdPinDrop size={30} color='white' />,
        });
    }

    const stack =
        <Stack align="end">
            {items.map(((item, index) =>
                <Group key={index}>
                    <Text size="xl" fw='medium' className='no-pointer'>{item.text}</Text>
                    <ActionIcon
                        variant='filled'
                        size={48}
                        radius='xl'
                        onClick={item.onClick}
                        style={{
                            backgroundColor: 'var(--mantine-primary-color-5)',
                        }}
                    >
                        {item.icon}
                    </ActionIcon>
                </Group>))}
        </Stack>;

    const transitionDuration = 500;
    return <>
        { trip?.docid && <ImageUploader
            uploaderRef={imageUploaderRef}
            tripId={trip?.docid}
            user={user}
        /> }
        <Transition
            mounted={isVisible}
            transition="fade"
            duration={transitionDuration / 2}
            timingFunction="ease"
        >
            {(styles) => <Overlay
                style={{ ...styles, zIndex: 102 }}
                fixed
                opacity={0.8}
                blur={2}
                color={colorScheme === 'light' ? "#fff" : "#000"}
                zIndex={102}
                onClick={() => closeSpeedDial()}
            />}
        </Transition>
        <Transition
            transition="pop-bottom-right"
            duration={transitionDuration}
            mounted={isVisible}
            timingFunction="cubic-bezier(.57,1.50,.52,.96)"
        >
            {css => <Affix
                style={css}
                position={{
                    bottom: 100 + safeArea.safeAreaInsetBottom,
                    right: 20,
                }}
                zIndex={102}
            >
                {stack}
            </Affix>}
        </Transition>
    </>;
}
