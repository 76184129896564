import { useEffect } from "react";

const doubleTouchThreshold = 500;

export function useTouchMagnifierSuppressor() {
    useEffect(() => {
        // on iOS, double-tapping on a UI element brings up a little magnifier and puts the webview
        // into selection mode. This suppresses that logic.
        // based on https://discourse.threejs.org/t/iphone-how-to-remove-text-selection-magnifier/47812/11
        const magnifierSelectorSuppressor = onDoubleTouchEvent(event => event.preventDefault());
        document.addEventListener('touchstart', magnifierSelectorSuppressor, { passive: false });
        return () => document.removeEventListener('touchstart', magnifierSelectorSuppressor);
    }, []);
}

function onDoubleTouchEvent(callback: (event: TouchEvent) => unknown): (event: TouchEvent) => unknown {
    let lastEventTime = 0;
    return (event: TouchEvent) => {
        const now = Date.now();
        if (now - lastEventTime < doubleTouchThreshold) {
            callback(event);
        }
        lastEventTime = now;
    };
}
