export type Position = {
    lat: number,
    lng: number,
};

export type Bounds = {
    center: Position,
    northEast: Position,
    southWest: Position,
};

export function arePositionsEqual(a: Position | undefined, b: Position | undefined) {
    return a === b || (a?.lat === b?.lat && a?.lng === b?.lng);
}

export function areBoundsEqual(a: Bounds | undefined, b: Bounds | undefined) {
    return a === b || (
        arePositionsEqual(a?.center, b?.center)
        && arePositionsEqual(a?.northEast, b?.northEast)
        && arePositionsEqual(a?.southWest, b?.southWest));
}

export type PositionKey = string;
export function positionToKey(position: Position): PositionKey {
    return `${position.lat}:${position.lng}`;
}

export function positionFromLatitudeLongitude(
    values: { latitude?: string | number | undefined | null, longitude?: string | number | undefined | null } | undefined | null
): Position | undefined {
    if (values?.latitude && values?.longitude) {
        return positionFromLatLng({ lat: values.latitude, lng: values.longitude });
    } else {
        return undefined;
    }
}

export function positionFromLatLng(
    values: { lat?: string | number | undefined | null, lng?: string | number | undefined | null } | undefined | null
): Position | undefined {
    if (!values || !Object.prototype.hasOwnProperty.call(values, 'lat') || !Object.prototype.hasOwnProperty.call(values, 'lng')) {
        return undefined;
    }

    let lat: number;
    let lng: number;
    if (typeof values.lat === 'string') {
        lat = Number.parseFloat(values.lat);
    } else if (values.lat === undefined || values.lat === null || Number.isNaN(values.lat)) {
        return undefined;
    } else {
        lat = values.lat;
    }

    if (typeof values.lng === 'string') {
        lng = Number.parseFloat(values.lng);
    } else if (values.lng === undefined || values.lng === null || Number.isNaN(values.lng)) {
        return undefined;
    } else {
        lng = values.lng;
    }

    return { lat, lng };
}

export function positionFromGoogleLatLng({ lat, lng }: { lat: () => number, lng: () => number }) {
    return { lat: lat(), lng: lng() };
}

export function isPositionWithinBounds(position: Position, bounds: Bounds) {
    // Check latitude is within bounds
    const isLatWithinBounds = position.lat >= bounds.southWest.lat && position.lat <= bounds.northEast.lat;

    // Determine if bounds cross the International Date Line
    const crossesDateLine = bounds.southWest.lng > bounds.northEast.lng;

    let isLngWithinBounds: boolean;

    if (crossesDateLine) {
        // If crossing the Date Line, position is within bounds if it's not within the 'gap'.
        isLngWithinBounds = position.lng >= bounds.southWest.lng || position.lng <= bounds.northEast.lng;
    } else {
        // If not crossing the Date Line, position must be between the southWest.lng and northEast.lng.
        isLngWithinBounds = position.lng >= bounds.southWest.lng && position.lng <= bounds.northEast.lng;
    }

    return isLatWithinBounds && isLngWithinBounds;
}
