import { Text, TextInput } from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import { useForm, UseFormReturnType } from '@mantine/form';
import { useRouter } from "next/navigation";
import { useFirebaseRefs } from "../../lib/firebase";
import { Trip } from '../../lib/interfaces';
import { SheetsData } from "../Sheets";
import { blurOnEnterKey } from "../../lib/eventHandlers";
import { StepWizard } from "../StepWizard/StepWizard";
import { ClearTextAreaRightSection } from "../AddEditTrip/ClearTextAreaRightSection";
import { deleteTrip } from "../../lib/tripFunctions";
import { useKeyboardInfo, useKeyboardScrollEffect } from '../../lib/keyboard';

import stepWizardClasses from "../StepWizard/StepWizard.module.css";

/**
 * Shows a series of modal sheets collecting information to create or edit a trip
 * including travelers
 */
export async function showDeleteTrip(sheets: SheetsData, tripTemplate: Partial<Trip>) {
    const title = `Delete Trip`;

    sheets.showSheetWith(
        context => <ConfirmDeleteTrip onClose={() => context.removeSheet()} trip={tripTemplate} />,
        {
            title,
            disableDrag: true,
        }
    );
}

type DeleteTripForm = UseFormReturnType<{
    title: string;
},
    (values: {
        title: string;
    }) => {
        title: string;
    }>;

function SectionOneDeleteTrip(props: {
    innerRef: React.RefObject<HTMLInputElement>,
    trip: Partial<Trip>,
    form: DeleteTripForm,
    onInput: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onClear: () => void }) {
    return <div
        className={stepWizardClasses.stepSection}
        aria-label='Trip Deletion Confirmation'
    >
        <Text style={{ textAlign: 'center' }}>Confirm you want to delete this trip by typing the title {props.trip.title ?? ''}</Text>
        <div
            className={stepWizardClasses.progress}
            style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <TextInput
                onFocus={() => props.innerRef.current?.scrollIntoView()}
                ref={props.innerRef}
                py="sm"
                required
                autoFocus
                placeholder={props.trip.title ?? ''}
                label="Trip Title:"
                style={{ display: "inline-block", flex: 1 }}
                {...props.form.getInputProps("title")}
                onInput={props.onInput}
                enterKeyHint="done"
                onKeyUp={blurOnEnterKey}
                rightSection={<ClearTextAreaRightSection onClear={props.onClear} />}
            />
        </div>
    </div>;
}

export function ConfirmDeleteTrip(
    {
        trip,
        onClose,
    }: {
        trip: Partial<Trip>,
        onClose: () => unknown,
    }) {
    const firebaseRefs = useFirebaseRefs();

    const inputTextRef = useRef<HTMLInputElement>(null);
    useKeyboardScrollEffect(inputTextRef);

    const form = useForm({
        initialValues: {
            title: '',
        },

        validate: {
            title: (value) => (value === trip.title ?? '' ? null : 'Your title does not match the trip'),
        },
    });
    const nextStep = () => 0;
    const [ currentStep, setCurrentStep ] = useState(0);

    const steps = [ '' ];
    const router = useRouter();

    return <StepWizard
        updateSection='Delete'
        steps={steps}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        nextStep={nextStep}
        onDone={async () => {
            if (form.validate().hasErrors) {
                return setCurrentStep((current) => current);
            } else {
                const title = form.values.title ?? '';
                const detail = title || '';
                deleteTrip(firebaseRefs, trip);
                form.reset();
                router.push('/?tab=Trips');
                onClose();
            }
        }}
    >

        { currentStep === 0 &&
            <SectionOneDeleteTrip
                innerRef={inputTextRef}
                trip={trip}
                form={form}
                onInput={e => form.setFieldValue('title', e.currentTarget.value)}
                onClear={() => form.setFieldValue('title', '')} />}
    </StepWizard>;
}
