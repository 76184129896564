import { collection } from "firebase/firestore";
import { useContext, useEffect, useMemo, useState } from "react";
import { useToday } from "../components/TripActivityScreen/dates";
import { AllTripActivitiesContext, HomeContext } from "./context";
import { useFirebaseRefs } from "./firebase";
import { Place, PlaceClassType, PlaceComment, placeConverter, PlaceType } from "./interfaces";
import { executeQueriesAgainstCacheThenServer, hasPosition, placeTypeFromPlace } from "./placeFunctions";
import { Bounds, isPositionWithinBounds, positionFromLatLng } from "./position";
import { EmptyQueryBehavior, SearchQuery } from "./search";
import { isIdea, TSDate } from "./time";

export type SavedPlaceResult = {
    results: [Place, ...Place[]] // an array with at least one element
};

export type PlaceFilterConfig = {
    includeSavedPlaces: boolean
    includeActivities: boolean
    emptyQueryBehavior: 'match-all' | 'match-none'
};

function placeMatchesFilter(
    place: Place,
    comments: PlaceComment[] | undefined,
    emptyQueryBehavior: EmptyQueryBehavior,
    searchQuery: SearchQuery,
    today: TSDate,
) {
    if (place.placeclass === PlaceClassType.Activity) {
        if (searchQuery.filters.tripChip === 'today' && !place.startdatetime?.equals(today)) {
            return false;
        } else if (searchQuery.filters.tripChip === 'upcoming' && !place.startdatetime?.isEqualOrAfter(today)) {
            return false;
        } else if (searchQuery.filters.tripChip === 'ideas' && !isIdea(place.startdatetime)) {
            return false;
        }
    }

    if (!filterPlaceByPlaceType(place, searchQuery)) {
        return false;
    }

    // This belongs after all the chip-based filtration, so we don't return true early.
    if (!searchQuery.searchTerm) {
        return emptyQueryBehavior === 'match-all'; // This might differ from searchQuery.emptyQueryBehavior
    }

    function commentsForPlace() {
        return (comments ?? [])
            .filter(c => c.activitydocid === place.docid)
            .map(c => c.comment);
    }

    const candidateStrings = [
        place.title,
        place.address,
        place.location,
        place.review,
        place.locality,
        ...commentsForPlace(),
    ];
    if (isSearchMatch(searchQuery.searchTerm, candidateStrings)) {
        return true;
    }

    // TODO fetch the author name and private review; perhaps fetch related activities and match against them too
    return false;
}

function filterPlaceByPlaceType(place: Place, searchQuery: SearchQuery) {
    switch (placeTypeFromPlace(place) ?? PlaceType.Activity) {
        case PlaceType.Lodging:
        case PlaceType.Hotel:
            return searchQuery.filters.lodging;
        case PlaceType.Meal:
            return searchQuery.filters.meals;
        case PlaceType.Flight:
            return searchQuery.filters.flights;
        case PlaceType.Activity:
        default:
            return searchQuery.filters.activities;
    }
}

export function isSearchMatch(filter: string, candidateStrings: (string | null | undefined)[]) {
    const loweredFilter = filter.trim().toLocaleLowerCase();
    if (candidateStrings
        .map(text => text?.toLocaleLowerCase())
        .find(text => text?.includes(loweredFilter))) {
        return true;
    } else {
        return false;
    }
}

export function useFilteredPlaces(
    places: Place[],
    comments: PlaceComment[] | undefined,
    emptyQueryBehavior: EmptyQueryBehavior,
    searchQuery: SearchQuery,
): Place[] {
    const today = useToday();

    return useMemo(
        () => filterPlaces(places, comments, emptyQueryBehavior, searchQuery, today),
        [ places, searchQuery, emptyQueryBehavior, comments, today ]);
}

function filterPlaces(
    places: Place[],
    comments: PlaceComment[] | undefined,
    emptyQueryBehavior: EmptyQueryBehavior,
    searchQuery: SearchQuery,
    today: TSDate
): Place[] {
    return places.filter(place => placeMatchesFilter(place, comments, emptyQueryBehavior, searchQuery, today));
}

export function useSavedPlaceSearchResults(
    searchQuery: SearchQuery,
    useYourPlaces: boolean,
    useOtherPlaces: boolean)
: SavedPlaceResult[] {
    const firebaseRefs = useFirebaseRefs();
    const { uidPlaces, tripAdjacentUsersById } = useContext(HomeContext);
    const today = useToday();

    const filteredSavedPlaces = useFilteredPlaces(
        uidPlaces,
        undefined,
        'match-all',
        searchQuery,
    );
    const [ candidateOtherPeoplesPlaces, setCandidateOtherPeoplesPlaces ] = useState<Place[][] | undefined>();

    useEffect(() => {
        // We only fetch this if the list is undefined, aka if we have not yet loaded the list.
        // This means other-saveds will be a bit stale, but that's ok.
        // TODO make sure it clears at the right time in the lifecycle -- perhaps on a timeout, or perhaps every search
        if (useOtherPlaces && candidateOtherPeoplesPlaces === undefined && tripAdjacentUsersById.size > 0) {
            const queries = [ ...tripAdjacentUsersById.keys() ]
                .map(userId => collection(firebaseRefs.firestore, "users", userId, "places").withConverter(placeConverter));
            executeQueriesAgainstCacheThenServer(queries,
                snapshot => snapshot
                    ? snapshot.docs.map(d => d.data())
                    : [],
                results => setCandidateOtherPeoplesPlaces(results));
        }
    }, [ useOtherPlaces, tripAdjacentUsersById, firebaseRefs ]);

    const filteredOtherPeoplesPlaces = useMemo(() => {
        return candidateOtherPeoplesPlaces?.map(candidates => filterPlaces(
            candidates,
            undefined,
            'match-all',
            searchQuery,
            today
        )) ?? [];
    }, [ searchQuery, today, candidateOtherPeoplesPlaces ]);

    return useMemo(() => {
        const flattenedResults: Place[] = [];
        if (useYourPlaces) {
            flattenedResults.push(...(filteredSavedPlaces ?? []));
        }
        if (useOtherPlaces) {
            flattenedResults.push(...filteredOtherPeoplesPlaces.flat());
        }

        type PlaceResult = { savedPlace: SavedPlaceResult, firstIndex: number };
        const placeResultsByPlaceId = new Map<string, PlaceResult>();
        const placeResultsWithoutPlaceIds: PlaceResult[] = [];
        flattenedResults.forEach((place, index) => {
            if (place.placeid) {
                if (!placeResultsByPlaceId.has(place.placeid)) {
                    placeResultsByPlaceId.set(place.placeid,
                        {
                            savedPlace: {
                                results: [ place ],
                            },
                            firstIndex: index,
                        });
                } else {
                    placeResultsByPlaceId.get(place.placeid)!.savedPlace.results.push(place);
                }
            } else {
                placeResultsWithoutPlaceIds.push({ savedPlace: { results: [ place ] }, firstIndex: index });
            }
        });

        const values: PlaceResult[] = [ ...placeResultsWithoutPlaceIds ];
        placeResultsByPlaceId.forEach(value => values.push(value));
        return values
            .sort((a, b) => a.firstIndex - b.firstIndex)
            .map(placeResult => placeResult.savedPlace);
    }, [ useYourPlaces, useOtherPlaces, filteredSavedPlaces, filteredOtherPeoplesPlaces ]);
}

export function useRecentActivityResultsFromTrips(
    searchQuery: SearchQuery,
    bounds: Bounds | undefined,
    emptyQueryBehavior: EmptyQueryBehavior,
): Place[] {
    const allTripActivities = useContext(AllTripActivitiesContext);
    const { userTripsById } = useContext(HomeContext);

    const candidates = useMemo(
        () => {
            return [ ...allTripActivities.allActivities.values() ]
                .sort((a, b) => {
                    // Sort so that ideas use their trip's start date
                    const aDate = isIdea(a.startdatetime)
                        ? userTripsById?.get(a.tripdocid!)?.startdate
                        : a.startdatetime;
                    const bDate = isIdea(b.startdatetime)
                        ? userTripsById?.get(b.tripdocid!)?.startdate
                        : b.startdatetime;
                    if (!aDate && !bDate) {
                        return 0;
                    } else if (!aDate) {
                        return -1;
                    } else if (!bDate) {
                        return 1;
                    } else {
                        return aDate.compareTo(bDate);
                    }
                })
                .filter(place => hasPosition(place));
        },
        [ allTripActivities, userTripsById ]
    );

    const filteredToBounds = useMemo(
        () => {
            return candidates.filter(place => {
                const position = positionFromLatLng(place);
                if (position && bounds) {
                    return isPositionWithinBounds(position, bounds);
                }
            });
        },
        [ candidates, bounds ]
    );

    const recentActivitiesHits = useFilteredPlaces(
        filteredToBounds,
        undefined, // TODO should we try to bring in all comments for all the trips in question?
        emptyQueryBehavior,
        searchQuery
    );

    return recentActivitiesHits;
}
