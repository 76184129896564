import { ActionIcon } from "@mantine/core";
import { IconX } from "@tabler/icons-react";

export function ClearTextAreaRightSection(props: {
    onClear: () => unknown
}) {
    return <ActionIcon onClick={props.onClear}>
        <IconX height={16} width={16} />
    </ActionIcon>;
}
