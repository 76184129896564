import { DatePickerInput, DayOfWeek } from "@mantine/dates";
import { isIdea, TSDate } from "../lib/time";
import { Place, PlaceType } from "../lib/interfaces";
import { isLodging } from "../lib/placeFunctions";
import { datePickerDayRenderer, useToday } from "./TripActivityScreen/dates";

type CoreProps = {
    onChange: (value: [ TSDate | null, TSDate | null ]) => unknown,
    placeholder?: string
    label?: string
    activityType?: PlaceType
};
type DatesProps = CoreProps & { dates: [ TSDate | null, TSDate | null ] };
type PlaceProps = CoreProps & { place: Place };
export function TSDatePicker(props: DatesProps | PlaceProps) {
    const today = useToday();

    if (('place' in props && isLodging(props.place)) || isLodging({ activityType: props.activityType })) {
        // Mantine's DatePickerInput has a bug in which it invokes onChange with [null, null]
        // after successfully calling onChange with the correct values. We use this boolean
        // to filter out the buggy calls. This appears to only be an issue with the multi-picker.
        let callbackInvoked = false;

        const startEndAsRange: [Date | null, Date | null] | undefined = (() => {
            if ('place' in props) {
                if (isIdea(props.place.startdatetime)) {
                    return undefined;
                } else {
                    return [
                        props.place.startdatetime?.toLocalJSDate() ?? null,
                        isIdea(props.place.enddatetime) ? null : props.place.enddatetime?.toLocalJSDate() ?? null,
                    ];
                }
            } else {
                return props.dates
                    .map(tsdate => tsdate?.toLocalJSDate() ?? null) as [ Date | null, Date | null ];
            }
        })();

        return <DatePickerInput
            valueFormat='ll'
            type="range"
            renderDay={day => datePickerDayRenderer(day, today)}
            label={props.label ?? "Arrival & Departure Dates"}
            clearable
            placeholder={props.placeholder ?? "Idea (No Date Yet)"}
            style={{ flex: 2 }}
            value={startEndAsRange}
            firstDayOfWeek={firstDayOfWeek()}
            onChange={value => {
                if (callbackInvoked) {
                    console.log(`Suppressing extra onChange() invocation`);
                } else {
                    callbackInvoked = true;
                    props.onChange([
                        value[0] ? TSDate.fromDate(value[0]) ?? null : null,
                        value[1] ? TSDate.fromDate(value[1]) ?? null : null,
                    ]);
                }
            }}
        />;
    } else {
        const date = 'dates' in props ? props.dates[0] : props.place.startdatetime ?? null;
        return <DatePickerInput
            valueFormat='ll'
            renderDay={day => datePickerDayRenderer(day, today)}
            clearable
            label={props.label}
            placeholder={props.placeholder ?? "Idea (No Date Yet)"}
            style={{ flex: 2 }}
            value={isIdea(date) ? null : date?.toLocalJSDate()}
            firstDayOfWeek={firstDayOfWeek()}
            onChange={newValue => {
                props.onChange([ newValue ? TSDate.fromDate(newValue) ?? null : null, null ]);
            }}
        />;
    }
}

export function firstDayOfWeek(): DayOfWeek {
    const language = (navigator.languages?.length ?? 0) > 0 ? navigator.languages[0] : navigator.language;
    const locale = new Intl.Locale(language);
    const { weekInfo } = locale as any;
    if (weekInfo) {
        const { firstDay } = weekInfo;
        if (firstDay && Number.isInteger(firstDay)) {
            return firstDay % 7 as DayOfWeek;
        }
    }
    return 0; // default to Sunday
}
