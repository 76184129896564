import { Card, Group, Space, Text, Timeline, Title } from '@mantine/core';
import { collection, query, where } from 'firebase/firestore';
import { useRouter } from 'next/navigation';
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { LuArrowRightFromLine, LuArrowRightToLine } from 'react-icons/lu';
import { HomeContext, AllTripActivitiesContext } from "../../lib/context";
import { useFirebaseRefs } from "../../lib/firebase";
import { byImageActivityOrder, imageConverter, Place, PlaceImage, PlaceType, Trip } from '../../lib/interfaces';
import { executeQueriesAgainstCacheThenServer, isLodging, placeForwardSorter } from "../../lib/placeFunctions";
import { TSDate } from '../../lib/time';
import { useToday } from "../TripActivityScreen/dates";
import { TSCachedImg } from "../TSImageCache";
import { ActivityIcon, PlaceTypeIcon } from "../PlaceDetailIcons";
import { showAddEditTrip } from "../AddEditTrip/AddEditTrip";
import { SheetsContext } from "../Sheets";
import { MultiMap } from '../../../shared/collections';

export function Overview() {
    return <>
        <TodayCard />
        <Space h={10} />
        <OverviewCard timeperiod='near-future' />
        <Space h={10} />
        <OverviewCard timeperiod='recent-past' />
    </>;
}

function TodayCard() {
    const today = useToday();
    const allTripActivities = useContext(AllTripActivitiesContext);

    const todaysActivities = useMemo(
        () => {
            // Look for hotels that are active today
            return [ ...allTripActivities.allActivities.values() ].filter(place => {
                return (place.startdatetime?.isEqualOrBefore(today) && place.enddatetime?.isEqualOrAfter(today))
                    || place.startdatetime?.equals(today);
            }).sort(placeForwardSorter);
        },
        [ allTripActivities.allActivities, today ]
    );

    const todaysHotels = useMemo(
        () => todaysActivities.filter(place => isLodging(place)),
        [ todaysActivities ]
    );

    const todaysNonHotelActivities = useMemo(
        () => todaysActivities.filter(place => !isLodging(place)),
        [ todaysActivities ]
    );

    const images = useOverviewImages(todaysActivities);

    if (todaysActivities.length > 0) {
        return <Card>
            <Title size="h2">Today</Title>
            <Timeline pt="sm" lineWidth={2} bulletSize={30}>
                {todaysHotels.map(hotel => {
                    const icon = hotel.startdatetime?.equals(today)
                        ? <LuArrowRightToLine />
                        : hotel.enddatetime?.equals(today)
                            ? <LuArrowRightFromLine />
                            : <PlaceTypeIcon type={PlaceType.Lodging} fallback={false} />;
                    return <ActivityLine
                        key={`lodging-${hotel.docid}`}
                        activity={hotel}
                        icon={icon}
                        images={images}
                    />;
                })}
                {todaysNonHotelActivities.map(place =>
                    <ActivityLine
                        key={`today-${place.placeid}`}
                        activity={place}
                        icon={<ActivityIcon activity={place} fallback />}
                        images={images}
                    />)}
            </Timeline>
        </Card>;
    } else {
        return null;
    }
}

type OverviewSection = {
    date: TSDate;
    tripId: string,
    activities: Place[];
};

function OverviewCard({ timeperiod }: { timeperiod: 'recent-past' | 'near-future' }) {
    const router = useRouter();
    const sheets = useContext(SheetsContext);
    const today = useToday();

    const allTripActivities = useContext(AllTripActivitiesContext);
    const homeContext = useContext(HomeContext);

    const allOverviewPlaces = useMemo(() => {
        const places = timeperiod === 'recent-past'
            ? allTripActivities.past
            : allTripActivities.future;
        return places?.slice(0, 10);
    }, [ timeperiod, allTripActivities.past, allTripActivities.future ]);

    const allOverviewImages = useOverviewImages(allOverviewPlaces);

    const content = useMemo(() => {
        if (allOverviewPlaces === undefined) {
            return undefined;
        }

        const overviewPlacesDates = (allOverviewPlaces
            .map((plc) => plc.startdatetime)
            .filter(date => !!date) as TSDate[])
            .filter((val, index, array) => array.findIndex(o => o.equals(val)) === index);

        // group day activities by trip id
        const updatedContent: Array<OverviewSection> = [];
        overviewPlacesDates.forEach(iteratedDate => {
            const overviewPlacesInDate = allOverviewPlaces
                .filter((plc) => plc.startdatetime && iteratedDate.equals(plc.startdatetime));
            const overviewTripIdsInDate = new Set(overviewPlacesInDate
                .map(plc => plc.tripdocid)
                .filter(id => !!id) as string[]);
            if (overviewTripIdsInDate.size > 0) {
                overviewTripIdsInDate.forEach(tripId => {
                    const overviewPlacesInDateInTrip = overviewPlacesInDate
                        .filter((plc) => plc.tripdocid === tripId);
                    overviewPlacesInDateInTrip.sort((a, b) => (a.dayorder ?? 1) - (b.dayorder ?? 1));
                    updatedContent.push({
                        date: iteratedDate,
                        tripId,
                        activities: overviewPlacesInDateInTrip,
                    });
                });
            }
        });

        return updatedContent;
    }, [ allOverviewPlaces ]);

    const tripsById = new Map<string, Trip>();
    homeContext.userTrips?.forEach(trip => {
        if (trip.docid) {
            tripsById.set(trip.docid, trip);
        }
    });

    const timeperiodName = timeperiod === 'recent-past' ? 'Past' : 'Upcoming';

    if (content === undefined) {
        return <Card>
            <Title size="h2">{timeperiodName}</Title>
            <Timeline pt="sm" lineWidth={2} bulletSize={30}>
                <Timeline.Item
                    styles={{
                        itemBullet: {
                            border: 0,
                            backgroundColor: 'var(--mantine-primary-color-5)',
                        },
                    }}
                    title={<Group justify="space-between" wrap='nowrap'>
                        <Text lineClamp={1} style={{ fontStyle: 'italic' }}>Loading your trips...</Text>
                    </Group>}
                />
            </Timeline>
        </Card>;
    } else if (content.length === 0) {
        return <Card>
            <Title size="h2">{timeperiodName}</Title>
            <Space h={10} />
            {timeperiod === 'near-future' && <>
                <Text onClick={() => showAddEditTrip(sheets, {})}>You have no upcoming travel</Text>
                <Text onClick={() => showAddEditTrip(sheets, {})}>Plan a Trip!</Text>
            </>}
            {timeperiod === 'recent-past' && <>
                <Text onClick={() => router.push('/?tab=Trips')}>You have no travel in the past 3 months.</Text>
                <Text onClick={() => router.push('/?tab=Trips')}>View past Trips!</Text>
            </>}
        </Card>;
    } else {
        const items: JSX.Element[] = [];
        content.forEach((timelineItem, headlineIndex) => {
            const headline = timelineItem.date.equals(today)
                ? 'Today'
                : timelineItem.date.toLocalDateTime().toLocaleString({ month: 'short', day: 'numeric', weekday: 'short' });
            const trip = tripsById.get(timelineItem.tripId);
            items.push(<Timeline.Item
                styles={{
                    itemBullet: {
                        border: 0,
                        backgroundColor: 'var(--mantine-primary-color-5)',
                    },
                }}
                title={<Group justify="space-between" wrap='nowrap'>
                    <Text lineClamp={1}>{headline}</Text>
                    { trip && <Text
                        lineClamp={1}
                        size='sm'
                        color='dimmed'
                        style={{ textAlign: 'right', textOverflow: 'ellipsis', overflow: 'hidden' }}
                        onClick={() => router.push(`/trip/${trip.docid}`)}>
                        {trip.title}
                    </Text> }
                </Group>}
                key={`headline-${headlineIndex}`}
            />);

            items.push(...timelineItem.activities.map((activity, timelineIndex) => {
                return <ActivityLine
                    activity={activity}
                    icon={<ActivityIcon activity={activity} fallback />}
                    key={`activity-${headlineIndex}.${timelineIndex}`}
                    images={allOverviewImages}
                />;
            }));
        });

        return <Card>
            <Title size="h2">{timeperiodName}</Title>
            <Timeline pt="sm" lineWidth={2} bulletSize={30}>
                {...items}
            </Timeline>
        </Card>;
    }
}

function ActivityLine({ activity, images, icon, key }: {
    activity: Place,
    images: MultiMap<string, PlaceImage>,
    icon: ReactNode,
    key: string
}) {
    const router = useRouter();

    const activityImages = activity.docid ? images.get(activity.docid) : undefined;
    const sorted = activityImages ? [ ...activityImages.values() ].sort(byImageActivityOrder) : [];
    const image = sorted.length > 0 ? sorted[0] : undefined;

    return <Timeline.Item
        key={key}
        bullet={
            <div
                onClick={() => router.push(`/trip/${activity.tripdocid}/${activity.docid}`)}
                style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {icon}
            </div>
        }
        title={<div
            style={{ display: "flex" }}
            onClick={() => router.push(`/trip/${activity.tripdocid}/${activity.docid}`)}
        >
            <Text style={{ width: '100%', flex: '1' }}>
                {activity.title}
            </Text>
            {image && <TSCachedImg
                style={{ height: '30px', width: '30px', minWidth: '30px', maxHeight: '30px', maxWidth: '30px' }}
                src={image.url400h!}
                alt={`${activity.title}`}
                lazyLoading
            />}
        </div>}
    />;
}

function useOverviewImages(allOverviewPlaces: Place[] | undefined) {
    const firebaseRefs = useFirebaseRefs();

    const [ allOverviewImages, setAllOverviewImages ] = useState(new MultiMap<string, PlaceImage>());

    useEffect(() => {
        if (allOverviewPlaces === undefined) {
            setAllOverviewImages(new MultiMap());
            return;
        }

        const queries = allOverviewPlaces
            .map(plc => query(
                collection(firebaseRefs.firestore, "trips", plc.tripdocid!, "pictures").withConverter(imageConverter),
                where('activityId', '==', plc.docid)));
        executeQueriesAgainstCacheThenServer(queries,
            result => result
                ? result.docs.map((document) => document.data())
                    .filter(img => img.status !== 'upload-pending')
                : [],
            results => {
                // TODO trickle-load these on render instead of doing them all at once each time we get new tplaces
                const overviewImages = new MultiMap<string, PlaceImage>();
                results
                    .forEach(imgs => {
                        imgs?.forEach(img => {
                            if (img.placedocid) {
                                overviewImages.set(img.placedocid!, img);
                            }
                        });
                    });
                setAllOverviewImages(overviewImages);
            });
    }, [ allOverviewPlaces, firebaseRefs ]);

    return allOverviewImages;
}
