import { List, Text, ThemeIcon } from "@mantine/core";
import { IconCircleDashed } from "@tabler/icons-react";
import { useRouter } from "next/navigation";
import { useContext, useMemo } from "react";
import { HomeContext } from "../lib/context";
import { useTripById } from "../lib/hooks";
import { Place, PlaceClassType, Trip, UserClass } from "../lib/interfaces";
import { getLogger } from "../lib/logger";
import { useCoincidentActivities, useCoincidentSavedPlaces } from "../lib/placeFunctions";
import { positionFromLatLng } from "../lib/position";
import { isIdea, TSDate } from "../lib/time";
import { tripDescription } from "../lib/tripFunctions";

const logger = getLogger('activity');

export function RelatedPlaces(props: { place: Place }) {
    const { tripAdjacentUsersById } = useContext(HomeContext);
    const coincidentSavedPlaces = useCoincidentSavedPlaces(props.place);
    const coincidentPlaces = useCoincidentActivities(props.place);

    const combined = [ ...coincidentSavedPlaces, ...coincidentPlaces ];
    if (combined.length > 0) {
        return <List px="xs">{
            combined.map(place =>
                <RelatedPlace
                    key={place.docid}
                    place={place}
                    tripAdjacentUsersById={tripAdjacentUsersById}
                />)
        }</List>;
    } else {
        return null;
    }
}

function RelatedPlace({ place, tripAdjacentUsersById }: {
    place: Place,
    tripAdjacentUsersById: Map<string, UserClass>
}) {
    const router = useRouter();

    const adjacentPerson = useMemo(
        () => place.creatorId ? tripAdjacentUsersById.get(place.creatorId) : undefined,
        [ tripAdjacentUsersById, place ]
    );
    const itemTrip = useTripById(place.tripdocid);
    const description = useMemo(
        () => descriptionForPlace(place, itemTrip ?? undefined, adjacentPerson),
        [ place, adjacentPerson, itemTrip ]
    );

    const isTripActivity = place.placeclass === PlaceClassType.Activity;
    const route = isTripActivity
        ? `/trip/${itemTrip?.docid}/${place.docid}`
        : `/traveler/${place.creatorId}/saved/${place.docid}`;

    return <List.Item
        icon={
            <ThemeIcon color={isTripActivity ? "blue" : "red"} size={24} radius="xl">
                <IconCircleDashed size={16} />
            </ThemeIcon>
        }
        style={{ lineClamp: 1, textOverflow: 'ellipsis', overflow: 'hidden' }}
    >
        <Text
            lineClamp={1}
            color='grey'
            fw={400}
            size="xs"
            style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
            onClick={() => route && router.push(route)}
        >
            {description}
        </Text>
    </List.Item>;
}

function descriptionForPlace(place: Place, trip: Trip | undefined, placePerson: UserClass | undefined)
    : string {
    const category = place.categories ? place.categories[0] : undefined;
    switch (place.placeclass) {
        case PlaceClassType.Saved:
            if (placePerson) {
                return `${placePerson.displayName} - ${category}`;
            } else {
                return `Saved Place - ${category}`;
            }
        case PlaceClassType.Activity:
            if (isIdea(place.startdatetime)) {
                return `${tripDescription(trip)} - Idea`;
            } else {
                if (place.startdatetime.isBefore(TSDate.today().minus({ days: 30 }))) {
                    return `${tripDescription(trip)}`;
                } else {
                    return `${tripDescription(trip)} - ${place.startdatetime.toRelative()}`;
                }
            }
        default:
            logger.warn(`Unknown place class: ${place.placeclass}`);
            return '';
    }
}
