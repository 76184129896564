import { CountryInfo } from "./country-types";

import countries from './country-data.json';

export function allCountries(): CountryInfo[] {
    return countries;
}

export function countryNameForCode(code: string) {
    const country = allCountries()
        .find(c => c.countryCode === code);
    return country?.name ?? code;
}

// TODO add date ranges to schengen
const schengenCodes = new Set<string>([
    "AT",
    "BE",
    "CH",
    "CZ",
    "DE",
    "DK",
    "ES",
    "EE",
    "FI",
    "FR",
    "HR",
    "HU",
    "IS",
    "IT",
    "LT",
    "LV",
    "LU",
    "FL",
    "MT",
    "NL",
    "NO",
    "PL",
    "PT",
    "SE",
    "SI",
    "SK",
]);

export function isSchengen(code: string) {
    return schengenCodes.has(code);
}
