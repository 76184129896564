import { getDownloadURL, ref, StorageReference, uploadBytesResumable } from 'firebase/storage';
import { DateTime } from 'luxon';
import { addDoc, collection } from 'firebase/firestore';
import { notifications } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { MantineColor } from "@mantine/core";
import Pica from 'pica';
import { MutableRefObject, useCallback, useContext, useMemo, useRef } from 'react';
import { Capacitor } from "@capacitor/core";
import { Camera } from "@capacitor/camera";
import { CapacitorNativeSupport } from '@travelscroll/capacitor-native-support';
import { FirebaseRefs, useFirebaseRefs } from '../../lib/firebase';
import { getLogger } from '../../lib/logger';
import { imageConverter, PlaceImage } from '../../lib/interfaces';
import { UserData } from '../../lib/userdata';
import { convertAndroidExif, ExifSubset, photoTimestampFromExif, pruneExif } from '../../lib/exif';
import { usePlaceImages } from '../SavedPlacesAppShell/PanelImageGallery';
import { BackgroundJobs } from '../../lib/background-jobs';
import { BackgroundJobContext } from '../../lib/context';
import { showModalAsync } from '../TSModal';
import { SheetsContext } from '../Sheets';

const pica = new Pica();
const logger = getLogger('uploadImage');

export type ImageUploaderTrigger = { click: () => unknown };

export function ImageUploader(props: {
    uploaderRef: MutableRefObject<ImageUploaderTrigger>,
    tripId: string,
    placeDocId?: string | undefined,
    user: UserData,
    relatedImages?: PlaceImage[] | undefined,
}) {
    const firebaseRefs = useFirebaseRefs();
    const backgroundJobs = useContext(BackgroundJobContext);
    const inputRef = useRef<HTMLInputElement>(undefined as any as HTMLInputElement);
    const sheets = useContext(SheetsContext);

    const relatedImages = props.relatedImages ?? usePlaceImages(
        { docid: props.tripId ?? undefined },
        { docid: props.placeDocId ?? null });

    const imageHighWaterMark = useMemo(
        () => Math.max(...relatedImages.map(img => img.activityOrder ?? 0)),
        [ relatedImages ]);

    const showAndroidPickerAndUpload = useCallback(
        async () => {
            if (Capacitor.getPlatform() !== 'android') {
                // We should never use this on iOS, since iOS WebKit has a bug that causes
                // IndexedDB to crash if a native view is overlaid for more than ~10 seconds.
                console.error("showAndroidPickerAndUpload() is only viable on Android");
                return;
            }

            const photos = await Camera.pickImages({});
            const fileSpecs: FileSpec[] = photos.photos.map(photo => ({
                webPath: photo.webPath,
                exif: convertAndroidExif(photo.exif),
                contentType: `image/${photo.format}`,
            }));

            await uploadImages(
                firebaseRefs,
                fileSpecs,
                props.tripId,
                props.placeDocId,
                props.user,
                imageHighWaterMark,
                backgroundJobs
            );
        },
        [ sheets, firebaseRefs ]
    );

    props.uploaderRef.current = useMemo(
        () => ({
            click: async () => {
                if (Capacitor.getPlatform() === 'android') {
                    const selection = await showModalAsync(
                        sheets,
                        'Camera or Gallery Image?',
                        [
                            { title: 'Camera', id: 'camera' },
                            { title: 'Gallery', id: 'gallery' },
                            { title: 'Cancel', id: 'cancel' },
                        ]
                    );
                    if (inputRef.current && selection.type === 'choice') {
                        if (selection.choice.id === 'camera') {
                            // @ts-ignore
                            inputRef.current.capture = true;
                            inputRef.current.click();
                            // TODO inject GPS when taking photo
                        } else if (selection.choice.id === 'gallery') {
                            showAndroidPickerAndUpload();
                        }
                    }
                } else {
                    inputRef.current?.click();
                }
            },
        }),
        []);

    return <input
        ref={inputRef}
        type="file"
        multiple
        accept="image/*"
        style={{ display: "none" }}
        onChange={async () => {
            if (props.tripId && inputRef.current?.files) {
                const fileSpecs: FileSpec[] = [];
                for (let index = 0; index < (inputRef.current?.files?.length ?? 0); index++) {
                    const file = inputRef.current?.files![index];
                    const url = URL.createObjectURL(file);

                    // We don't have an Android implementation of this yet. However, we only end up here on Android
                    // when using the camera, which will never match a file, in any case.
                    // eslint-disable-next-line no-await-in-loop
                    const exif = Capacitor.getPlatform() === 'android' ? undefined : await CapacitorNativeSupport.fetchExifData({ title: file.name, lastModified: new Date(file.lastModified) });

                    const spec = {
                        contentType: file.type,
                        webPath: url,
                        exif,
                    };
                    fileSpecs.push(spec);
                }
                try {
                    await uploadImages(
                        firebaseRefs,
                        fileSpecs,
                        props.tripId,
                        props.placeDocId,
                        props.user,
                        imageHighWaterMark,
                        backgroundJobs
                    );
                } finally {
                    fileSpecs.forEach(spec => URL.revokeObjectURL(spec.webPath));
                }
            } else {
                if (!props.tripId) {
                    console.warn("No trip ID was provided! Canceling upload.");
                } else {
                    console.info("No files were selected! Canceling upload.");
                }
            }
        }}
    />;
}

type FileSpec = {
    contentType: string
    webPath: string
    exif: any
};

async function uploadImages(
    firebaseRefs: FirebaseRefs,
    fileSpecs: FileSpec[],
    tripDocId: string,
    placeDocId: string | undefined,
    user: UserData,
    imageHighWaterMark: number,
    backgroundJobs: BackgroundJobs
) {
    try {
        // create ImagePicture in firestore
        const onComplete = async (newImage: Partial<PlaceImage>) => {
            const imageRef = collection(firebaseRefs.firestore, 'trips', newImage.tripId!, 'pictures')
                .withConverter(imageConverter);
            logger.log(`Adding image record. Collection path=${imageRef.path}, record=${JSON.stringify(newImage)}`);
            await addDoc(imageRef, newImage);
            notifications.show({
                icon: <IconCheck size="1.1rem" />,
                color: 'teal' as MantineColor,
                title: "Photo Saved",
                message: "Your photo is saved!",
                autoClose: true,
            });
        };

        logger.log(`Uploading ${fileSpecs.length} files`);

        for (let index = 0; index < fileSpecs.length; index++) {
            const spec = fileSpecs[index];
            const prunedExif = pruneExif(spec.exif);
            const imageSortOrder = boundedNumber(imageHighWaterMark + index + 1); // add one so that we're above the current max

            // We process images one at a time, since concurrent access to Firebase appears to cause issues sometimes.
            // If we sort that out, we should still iterate in chunks rather than loading all images at the same time,
            // to avoid blowing up memory.
            // eslint-disable-next-line no-await-in-loop
            await uploadImage(
                firebaseRefs,
                tripDocId,
                placeDocId,
                user,
                spec,
                prunedExif,
                imageSortOrder,
                backgroundJobs,
                uploadImageBytes,
                onComplete);
        }
    } catch (err) {
        console.error('Error loading or uploading images', err);
        notifications.show({ message: 'Something went wrong!' });
    }
}

let imageSequenceGenerator = 0;

export function sanitizeImageParams(downloadUrl: string) {
    const parsed = new URL(downloadUrl);
    return { parsed, sanitized: `${parsed.origin}${parsed.pathname}?alt=media` };
}

// Strips the provided URL of any parameters, including auth tokens, and returns a data structure containing
// the stripped URL and the 400h-converted value. Adds the 'alt=media' selector to both.
export function convertToStorageUrls(downloadUrl: string): { url: string, url400h: string | undefined } {
    const { parsed, sanitized } = sanitizeImageParams(downloadUrl);

    const returnValue = {
        url: sanitized,
        url400h: undefined as string | undefined,
    };

    const [ prefix, suffix ] = parsed.pathname.split('/o/', 2);
    const parts = suffix?.split(/(%2F)|(%2f)/)
        .filter(part => part && part !== '%2F' && part !== '%2f');
    let bucketPathParts: string[] | undefined;
    if (parts && parts[0] === 'images' && parts.length > 1) {
        const lastPart = parts.pop();
        const lastDot = lastPart?.lastIndexOf('.');
        if (lastPart && lastDot && lastDot > 0) {
            const basename = lastPart.substring(0, lastDot);
            const ext = lastPart.substring(lastDot);
            parts.shift(); // drop the leading 'images'; we re-add it below
            bucketPathParts = [ 'images', '400h', ...parts, `${basename}_2000x400${ext}?alt=media` ];
        }
    } else if (parts && parts[0] === 'uploads' && parts.length > 1) {
        const lastPart = parts.pop();
        const lastDot = lastPart?.lastIndexOf('.');
        if (lastPart && lastDot && lastDot > 0) {
            const basename = lastPart.substring(0, lastDot);
            const ext = lastPart.substring(lastDot);
            bucketPathParts = [ ...parts, `${basename}-400h${ext}?alt=media` ];
        }
    }

    if (bucketPathParts) {
        // if the filename matches one of the expected formats, compute the thumbnail name
        returnValue.url400h = `${parsed.origin}${prefix}/o/${bucketPathParts.join('%2F')}`;
    }

    return returnValue;
}

async function uploadImageBytes(
    fileRef: StorageReference,
    photo: PhotoSpec,
    uploadIndex: number,
    onProgress?: (percent: number) => unknown
): Promise<string> {
    const result = await fetch(photo.webPath);
    const blob = await result.blob();
    const uploadTask = uploadBytesResumable(fileRef, blob, { contentType: photo.contentType });
    return new Promise<string>(resolve => {
        uploadTask.on('state_changed',
            snapshot => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and
                // the total number of bytes to be uploaded
                const percentage = snapshot.bytesTransferred / snapshot.totalBytes;
                logger.log(`Upload ${uploadIndex} is ${percentage * 100}% done. State: ${snapshot.state}`);
                if (onProgress) {
                    onProgress(percentage);
                }
            },
            error => {
                // Handle unsuccessful uploads
                notifications.show({
                    title: "Photo Upload Error",
                    message: `Your photo did not upload. Error - ${error.message}`,
                    autoClose: true,
                });
                logger.warn(`Error storing photo for upload ${uploadIndex}: ${error.message}`);
            },
            async () => {
                // Handle successful uploads on complete
                const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
                resolve(downloadUrl);
            });
    });
}

type PhotoSpec = { contentType: string, webPath: string, path?: string };

async function resizeImage(photo: PhotoSpec): Promise<PhotoSpec> {
    logger.log(`Resizing a ${photo.contentType}`);
    const start = Date.now();

    const sourceImage = document.createElement('img');
    const destCanvas = document.createElement('canvas');

    try {
        const loadPromise = new Promise(resolve => {
            sourceImage.onload = resolve;
        });
        sourceImage.src = photo.webPath;
        await loadPromise;

        const sourceWidth = sourceImage.naturalWidth ?? sourceImage.width;
        const sourceHeight = sourceImage.naturalHeight ?? sourceImage.height;
        destCanvas.height = 400;
        destCanvas.width = sourceWidth * (400 / sourceHeight);

        await pica.resize(sourceImage, destCanvas);

        const resizedBlob = await pica.toBlob(destCanvas, photo.contentType);
        return { contentType: resizedBlob.type, webPath: URL.createObjectURL(resizedBlob) };
    } finally {
        sourceImage.remove();
        destCanvas.remove();
        logger.log(`Done resizing a ${photo.contentType} in ${Date.now() - start} millis`);
    }
}

let uploadIndexGenerator = 0;
export async function uploadImage(
    firebaseRefs: FirebaseRefs,
    tripDocId: string,
    placeDocId: string | undefined,
    user: Pick<UserData, 'firebaseUserId'>,
    photo: PhotoSpec,
    exif: ExifSubset | undefined,
    activityOrder: number,
    backgroundJobs: BackgroundJobs,
    doUpload: (
        ref: StorageReference,
        photo: PhotoSpec,
        uploadIndex: number,
        onProgress?: (percent: number) => unknown
    ) => Promise<string>,
    onComplete: (image: Partial<PlaceImage>) => Promise<unknown>
) {
    const uploadIndex = uploadIndexGenerator++;
    const resizedUploadIndex = uploadIndexGenerator++;
    const backgroundJob = backgroundJobs.registerJob("Photo Upload");
    try {
        const userId = user.firebaseUserId;
        if (!tripDocId || !userId) {
            throw Error(
                'Cannot upload images to place without the tripdocid field, or without a logged-in user id'
            );
        }

        const extension = photo.contentType.split('/')[1];
        const plcImage: Partial<PlaceImage> = {
            useruid: user.firebaseUserId,
            type: 'participant-upload',
            publisher: user.firebaseUserId,
            docCreated: DateTime.now(),
            phototaken: photoTimestampFromExif(exif),
            exifGps: exif?.GPS ?? null,
            activityId: placeDocId,
            tripId: tripDocId,
            activityOrder,
        };

        const sequence = imageSequenceGenerator++;
        const basename = `${user.firebaseUserId}-${DateTime.now().toFormat('yMdHms')}-${sequence}`;
        const fileRef = ref(firebaseRefs.storage, `uploads/trip/${tripDocId}/images/${basename}.${extension}`);
        const thumbnailFileRef = ref(
            firebaseRefs.storage,
            `uploads/trip/${tripDocId}/images/${basename}-400h.${extension}`
        );

        const downloadUrlPromise = doUpload(
            fileRef,
            photo,
            uploadIndex,
            percent => backgroundJob.updatePercentComplete(Math.min(0.99, percent)) // Avoid marking as actually complete until  all bookkeeping is done
        );
        let thumbnailUrl: string | undefined;
        try {
            const resized = await resizeImage(photo);
            try {
                const uploadedThumbnailUrl = await doUpload(
                    thumbnailFileRef,
                    resized,
                    resizedUploadIndex
                );
                thumbnailUrl = sanitizeImageParams(uploadedThumbnailUrl).sanitized;
            } finally {
                if (resized.webPath) {
                    URL.revokeObjectURL(resized.webPath);
                }
            }
        } catch (err) {
            logger.warn(
                `Trapped an error making or uploading thumbnail for upload ${uploadIndex}: ${err}`
            );
        }

        plcImage.url = sanitizeImageParams(await downloadUrlPromise).sanitized;
        plcImage.url400h = thumbnailUrl;
        logger.log(
            `File available for upload ${uploadIndex} at ${plcImage.url}; thumbnail ${resizedUploadIndex} at ${plcImage.url400h}`
        );
        await onComplete(plcImage);
        backgroundJob.updatePercentComplete(1);
    } catch (err: any) {
        notifications.show({
            title: 'Photo Upload Error',
            message: `An error occurred while loading your photo: ${err.message ?? err}`,
        });
        logger.warn(`Upload error for photo ${uploadIndex}: ${err.message ?? err}`);
        backgroundJob.failJob();
    }
}

function boundedNumber(val: number) {
    if (val >= Number.MAX_SAFE_INTEGER || val === Infinity) {
        return Number.MAX_SAFE_INTEGER;
    } else if (val <= Number.MIN_SAFE_INTEGER || val === -Infinity) {
        return Number.MIN_SAFE_INTEGER;
    } else if (Number.isNaN(val)) {
        return 0;
    } else {
        return val;
    }
}
