import { User } from "@firebase/auth";
import { useContext, useEffect, useMemo, useState } from 'react';
import {
    collection,
    collectionGroup,
    doc,
    getDoc, or,
    query,
    setDoc,
    where,
} from 'firebase/firestore';
import { and } from "@firebase/firestore";
import {
    useFirebaseAuth,
    FirebaseRefs,
    useFirebaseRefs,
    useFirebaseUserInfoFromIndexedDB,
    useDoc,
    useCollection,
    useMemoizedQuery,
} from './firebase';
import {
    ChangeLog,
    changeLogConverter,
    firebaseRecordToUserClass,
    HomeData,
    Place,
    placeConverter,
    Trip,
    tripConverter,
    UserClass,
} from './interfaces';
import { LoggedInUserData, PrivateUserData, PublicUserData, UserData } from "./userdata";
import { HomeContext } from "./context";
import { getLogger, setNeedsFirebaseAuthTimeoutHack } from "./logger";
import {executeQueriesAgainstCacheThenServer} from "./placeFunctions";
import {splitArray} from "../../shared/collections";

const logger = getLogger('hooks');

export function useUserDataContextState(): UserData {
    const firebaseRefs = useFirebaseRefs();

    const auth = useFirebaseAuth();

    const [ authStateUser, setAuthStateUser ] = useState<User | null>();
    const [ isLoadingAuthState, setIsLoadingAuthState ] = useState(true);

    const indexedDBInfo = useFirebaseUserInfoFromIndexedDB();

    useEffect(() => {
        if (auth) {
            auth.beforeAuthStateChanged(() => {
                setIsLoadingAuthState(true);
            });
            auth.onAuthStateChanged(user => {
                logger.log(`Auth state changed. User id=${user?.uid}`);
                setAuthStateUser(user);
                setIsLoadingAuthState(false);
            });
        } else {
            setIsLoadingAuthState(true);
        }
    }, [ auth ]);

    const publicUserDataFromDatabase = useDoc(
        authStateUser?.uid ? doc(firebaseRefs.firestore, 'users', authStateUser.uid) : undefined,
        'useUserData:public-user-data'
    );
    const publicUserData: PublicUserData | undefined = useMemo(
        () => {
            if (authStateUser) {
                if (publicUserDataFromDatabase.isLoading) {
                    return {
                        isLoggedIn: true,
                        status: 'logged-in',
                        firebaseUserId: authStateUser.uid,
                    };
                } else {
                    const data = publicUserDataFromDatabase.result;
                    return {
                        isLoggedIn: true,
                        status: 'logged-in',
                        username: data?.username,
                        firebaseUserId: authStateUser.uid,
                        displayName: data?.displayName,
                        devTools: data?.devTools ?? null,
                        photoURL: data?.photoURL,
                    };
                }
            } else if (isLoadingAuthState) {
                if (!indexedDBInfo.isLoading && indexedDBInfo.firebaseUserId) {
                    return {
                        isLoggedIn: false,
                        status: 'cached-data-available',
                        firebaseUserId: indexedDBInfo.firebaseUserId,
                    };
                } else {
                    return {
                        isLoggedIn: false,
                        status: 'unknown',
                    };
                }
            } else {
                return {
                    isLoggedIn: false,
                    status: 'logged-out',
                };
            }
        },
        [ indexedDBInfo.firebaseUserId, indexedDBInfo.isLoading, publicUserDataFromDatabase, authStateUser, isLoadingAuthState ]
    );

    const privateUserDataDoc = useDoc(
        authStateUser?.uid ? privateDocRef(firebaseRefs, authStateUser.uid) : undefined,
        'useUserData:private-user-data'
    );
    const privateUserData: PrivateUserData = useMemo(
        () => {
            if (privateUserDataDoc?.isLoading) {
                return { privateDataLoaded: false };
            } else {
                return {
                    countryTracking: privateUserDataDoc.result?.countryTracking === 'enabled' ? 'enabled' : 'disabled',
                    searchProviders: privateUserDataDoc.result?.searchProviders ?? undefined,
                    privateDataLoaded: true,
                };
            }
        },
        [ privateUserDataDoc ]
    );

    useEffect(() => {
        if (authStateUser) {
            setNeedsFirebaseAuthTimeoutHack(false); // if we've gotten this far, then the Firebase Auth initialization woes are behind us
        }
    }, [ authStateUser ]);

    return useMemo(
        () => ({
            ...publicUserData,
            ...privateUserData,
        }),
        [ privateUserData, publicUserData ]
    );
}

function privateDocRef(firebaseRefs: FirebaseRefs, userId: string) {
    return doc(firebaseRefs.firestore, 'private-user-data', userId);
}

export async function mergePrivateUserData(firebaseRefs: FirebaseRefs, userData: LoggedInUserData, fields: Partial<PrivateUserData>) {
    await setDoc(privateDocRef(firebaseRefs, userData.firebaseUserId), fields, { merge: true });
}

export function useUserById(userid: string | undefined): UserClass | null {
    const firebaseRefs = useFirebaseRefs();
    const docRef = userid ? doc(firebaseRefs.firestore, 'users', userid) : undefined;
    const { result } = useDoc(docRef, 'useUserById');

    return useMemo(
        () => firebaseRecordToUserClass(result),
        [ result ]
    );
}

export function useUsersById(userIds: string[] | undefined | null): UserClass[] | undefined {
    const firebaseRefs = useFirebaseRefs();

    const userDocsQuery = useMemo(
        () => (userIds?.length ?? 0) > 0
            ? query(
                collection(firebaseRefs.firestore, 'users'),
                where('uid', 'in', userIds) // TODO this needs to be broken into chunks of 30 or less
            )
            : undefined,
        [ firebaseRefs.firestore, userIds ]
    );
    const userDocs = useMemoizedQuery(userDocsQuery, 'useUsersById');

    return useMemo(
        () => userDocs === undefined ? undefined : userDocs
            .map(firebaseRecordToUserClass)
            .filter(record => !!record) as UserClass[],
        [ userDocs ]
    );
}

export function useTripById(tripid: string | null | undefined) {
    const firebaseRefs = useFirebaseRefs();

    const docResult = useDoc(
        tripid ? doc(firebaseRefs.firestore, 'trips', tripid).withConverter(tripConverter) : undefined,
        'useTripById'
    );

    return docResult.result;
}

export function useTripPlacesById(tripid: string | undefined): Place[] | undefined {
    const firebaseRefs = useFirebaseRefs();
    return useCollection(
        tripid ? collection(firebaseRefs.firestore, "trips", tripid, "activities").withConverter(placeConverter) : undefined,
        'useTripPlacesById'
    );
}

export function useTripChangeLogsById(tripid: string | undefined): ChangeLog[] | undefined {
    const firebaseRefs = useFirebaseRefs();
    return useCollection(
        tripid ? collection(firebaseRefs.firestore, "trips", tripid, "changes").withConverter(changeLogConverter) : undefined,
        'useTripChangeLogsById'
    );
}

export function useSavedPlaceById(userId: string, placeDocId: string): Place | undefined {
    const firebaseRefs = useFirebaseRefs();
    const { uidPlaces } = useContext(HomeContext);

    const placeFromCache = useMemo(
        () => uidPlaces.find(p => p.docid === placeDocId),
        [ placeDocId, uidPlaces ]
    );

    const placeFromDatabase = useDoc(
        !placeFromCache && userId && placeDocId
            ? doc(firebaseRefs.firestore, 'users', userId, 'places', placeDocId).withConverter(placeConverter)
            : undefined,
        'useSavedPlaceById'
    );

    return placeFromCache ?? placeFromDatabase?.result;
}

export function useTripActivityById(tripDocId: string | undefined, placeDocId: string | undefined): Place | undefined {
    const firebaseRefs = useFirebaseRefs();

    const placeResult = useDoc(
        tripDocId && placeDocId
            ? doc(firebaseRefs.firestore, 'trips', tripDocId, 'activities', placeDocId).withConverter(placeConverter)
            : undefined,
        'useTripActivityById'
    );

    return placeResult.result;
}

export function usePlacesDataContextState(userData: UserData): HomeData {
    const firebaseRefs = useFirebaseRefs();
    const [ tripAdjacentUsersById, setTripAdjacentUsersById ] = useState<Map<string, UserClass>>(new Map());

    const uidPlaces = useCollection(
        userData.isLoggedIn && userData.firebaseUserId
            ? collection(firebaseRefs.firestore, "users", userData.firebaseUserId, "places").withConverter(placeConverter)
            : undefined,
        'usePlacesData:user-places',
    );

    const memoizedQuery = useMemo(
        () => userData.status !== 'logged-out' && userData.firebaseUserId
            ? query(
                collection(firebaseRefs.firestore, "trips"),
                where('users', 'array-contains', userData.firebaseUserId)
            ).withConverter(tripConverter)
            : undefined,
        [ firebaseRefs.firestore, userData.firebaseUserId, userData.status ]
    );
    const unprocessedUserTrips = useMemoizedQuery(
        memoizedQuery,
        'usePlacesData:user-trips'
    );
    const { userTrips, userTripsById } = useMemo(
        () => {
            const trips = unprocessedUserTrips ? filterAndSortTrips(unprocessedUserTrips) : undefined;
            if (trips) {
                const byId = new Map<string, Trip>();
                for (const trip of trips) {
                    if (trip.docid) {
                        byId.set(trip.docid, trip);
                    }
                }
                return { userTrips: trips, userTripsById: byId };
            } else {
                return { userTrips: trips, userTripsById: undefined };
            }
        },
        [ unprocessedUserTrips ]
    );

    //tripAdjacentUsers & currentUser
    useEffect(() => {
        if (userTrips !== undefined) {
            const tripAdjacentUserIds = new Set<string>();
            userTrips.forEach(trip => {
                if (Array.isArray(trip.users)) {
                    trip.users.forEach(userId => tripAdjacentUserIds.add(userId));
                } else if (trip.users) {
                    // We have some buggy trips whose users are dicts, not arrays
                    Object.values(trip.users).forEach(value => {
                        if (typeof value === 'string') {
                            tripAdjacentUserIds.add(value);
                        }
                    });
                }
            });

            const tripAdjacentUserclassPromises = Array.from(tripAdjacentUserIds)
                .map(async (uid) => {
                    const tripAdjacentUserclassRef = doc(firebaseRefs.firestore, "users", uid);
                    const tripAdjacentUserclassSnapshot = await getDoc(tripAdjacentUserclassRef); // TODO use executeQueriesAgainstCacheThenServer() here
                    return firebaseRecordToUserClass(tripAdjacentUserclassSnapshot.data());
                });
            Promise.all(tripAdjacentUserclassPromises).then((tripAdjacentUserclass) => {
                const usersById = new Map<string, UserClass>();
                tripAdjacentUserclass.forEach(user => {
                    // filter out the current user and any records that couldn't be loaded
                    if (user && user.uid && user.uid !== userData.firebaseUserId) {
                        usersById.set(user.uid, user);
                    }
                });
                const newAdjacentUsers: UserClass[] = [];
                usersById.forEach(value => {
                    newAdjacentUsers.push(value);
                });
                setTripAdjacentUsersById(usersById);
            });
        } else {
            setTripAdjacentUsersById(new Map());
        }
    }, [ firebaseRefs.firestore, userData.firebaseUserId, userTrips ]);

    return {
        uidPlaces: uidPlaces ?? [],
        tripAdjacentUsersById,
        userTrips,
        userTripsById,
    };
}

export function useTripAdjacentUserFavorites(tripAdjacentUsersById: Map<string, UserClass>){
    const firebaseRefs = useFirebaseRefs();
    const [ tripAdjacentUserFavorites, setTripAdjacentUserFavorites ] = useState<Place[] | undefined>(undefined);

    useEffect(() => {
        if (tripAdjacentUsersById.size > 0) {
            const chunkedUsers = splitArray([ ...tripAdjacentUsersById.keys() ], 15);
            const queries = chunkedUsers.map((chunk) => query(
                collectionGroup(firebaseRefs.firestore, 'places'),
                and(
                    where('userid', 'in', chunk),
                    or(
                        where('categories', 'array-contains', 'Favorite'),
                        where('category', '==', 'Favorite')
                    )
                )).withConverter(placeConverter));
            executeQueriesAgainstCacheThenServer(queries,
                snapshot => snapshot
                    ? snapshot.docs.map(d => d.data())
                    : [],
                results => setTripAdjacentUserFavorites(results.flatMap((fav) => fav)));
        }
    }, [ tripAdjacentUsersById, firebaseRefs ]);

    return tripAdjacentUserFavorites;
}

function filterAndSortTrips(trips: Trip[]) {
    return trips
        .filter(trip => trip.lat !== null && trip.lat !== 0) // TODO 0-lat is valid! Change our filters and data accordingly
        .sort((a, b) => {
            if (a.startdate && b.startdate) {
                return b.startdate.compareTo(a.startdate);
            } else if (a.startdate) {
                return -1;
            } else if (b.startdate) {
                return 1;
            } else {
                return 0;
            }
        });
}

export function useSafeArea() {
    if (global.window) {
        // This logic depends on the safe area variable assignment CSS code in the MantineProvider definition
        const [ top ] = useState<number>(() => parseInt(getComputedStyle(document.documentElement).getPropertyValue("--tscroll-safe-area-inset-top"), 10) ?? 0);
        const [ bottom ] = useState<number>(() => parseInt(getComputedStyle(document.documentElement).getPropertyValue("--tscroll-safe-area-inset-bottom"), 10) ?? 0);

        return { safeAreaInsetTop: top, safeAreaInsetBottom: bottom };
    } else {
        return { safeAreaInsetTop: 0, safeAreaInsetBottom: 0 };
    }
}

export function useWindowHeight() {
    const [ height, setHeight ] = useState<number>(() => window.innerHeight);

    useEffect(() => {
        const listener = () => {
            setHeight(window.innerHeight);
        };
        window.addEventListener('resize', listener);
        return () => window.removeEventListener('resize', listener);
    }, []);

    return useMemo(() => ({ windowHeight: height }), [ height ]);
}

export function usePromiseValue<T>(promiseProvider: () => Promise<T | undefined>): T | undefined {
    const [ value, setValue ] = useState<T>();

    useEffect(() => {
        if (promiseProvider) {
            promiseProvider().then(setValue);
        } else {
            setValue(undefined);
        }
    }, [ promiseProvider ]);

    return value;
}
