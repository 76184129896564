// Returns all the elements of `a` that are not in `b`
export function setDifference<T>(a: Set<T>, b: Set<T>): Set<T> {
    const difference = new Set<T>(a);
    b.forEach(val => difference.delete(val));
    return difference;
}

export function setDisjunction<T>(a: Set<T>, b: Set<T>): Set<T> {
    const aDiff = setDifference(a, b);
    const bDiff = setDifference(b, a);
    return setUnion(aDiff, bDiff);
}

export function setUnion<T>(set: Set<T>, ...sets: Array<Set<T>>): Set<T> {
    const union = new Set<T>(set);
    sets?.forEach(s => s.forEach(o => union.add(o)));
    return union;
}

export function shallowEquals<T extends Record<string, any>>(a: T, b: T): boolean {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);
    if (setDisjunction(new Set(aKeys), new Set(bKeys)).size > 0) {
        return false;
    } else {
        return aKeys.every(k => a[k] === b[k]);
    }
}

export function splitArray<T>(array: T[], maxLength: number): T[][] {
    const chunks: T[][] = [];
    for (let i = 0; i < array.length; i += maxLength) {
        chunks.push(array.slice(i, i + maxLength));
    }
    return chunks;
}

export class MultiMap<TKey, TVal> {
    private map = new Map<TKey, Set<TVal>>();

    keys() {
        return this.map.keys();
    }

    set(key: TKey, val: TVal) {
        let values = this.map.get(key);
        if (!values) {
            values = new Set();
            this.map.set(key, values);
        }
        values.add(val);
    }

    get(key: TKey): Set<TVal> | undefined {
        return this.map.get(key);
    }

    deleteAll(key: TKey): boolean {
        return this.map.delete(key);
    }

    delete(key: TKey, value: TVal): boolean {
        const values = this.map.get(key);
        const deleted = values?.delete(value) ?? false;
        if (values?.size === 0) {
            this.map.delete(key);
        }
        return deleted;
    }
}
