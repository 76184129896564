import { notifications } from "@mantine/notifications";
import { getLogger } from "./logger";
import { errorHaptic, saveConfirmationHaptic } from "./haptics";

const logger = getLogger('promises');

export async function race<T>(promise: Promise<T>, delayMillis: number)
    : Promise<{ status: 'timeout' } | { status: 'completed', value: T }> {
    const timeoutPromise = new Promise<'timeout'>(resolve => {
        setTimeout(() => resolve('timeout'), delayMillis);
    });
    const value = await Promise.race([ promise, timeoutPromise ]);
    if (value === 'timeout') {
        return { status: 'timeout' };
    } else {
        return { status: 'completed', value: value as T };
    }
}

export async function raceOrNotify(
    promise: Promise<unknown>,
    delayMillis: number,
    lateSuccessMessage: string,
    failureMessage: string
) {
    // Emit the haptic at the beginning, when the UI work is done. The actual save might take
    // more time over a slow network.
    saveConfirmationHaptic();
    let promiseLostRace = false;
    const wrapped = promise
        .then(() => {
            if (promiseLostRace) {
                notifications.show({ message: lateSuccessMessage });
            }
        })
        .catch(err => {
            logger.warn(`Failure trapped in raceOrNotify. err.message: ${err.message}`);
            notifications.show({ message: failureMessage });
            errorHaptic();
        });
    const result = await race(wrapped, delayMillis);
    if (result.status === 'timeout') {
        promiseLostRace = true;
    }
}
