import { Chip, Text, Textarea, TextInput } from '@mantine/core';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from '@mantine/form';
import { notifications } from "@mantine/notifications";
import { DateTime } from "luxon";
import Sheet from "react-modal-sheet";
import { createComment } from "../../lib/comments";
import { HomeContext, TripContext, UserContext } from '../../lib/context';
import { useFirebaseRefs } from "../../lib/firebase";
import { Place, PlaceClassType, PlaceType, SavedType, Trip } from '../../lib/interfaces';
import { SearchChipsAndList } from "../SearchUI/SearchUI";
import { Position, positionFromLatLng } from "../../lib/position";
import {
    geocodeAndUpdatePlace,
    insertSavedPlace,
    insertTripActivity,
    isLodging,
    placeTypeFromPlace,
    updateSavedPlace,
    updateTripActivity,
} from "../../lib/placeFunctions";
import { raceOrNotify } from "../../lib/promises";
import { SheetsData } from "../Sheets";
import { newSearchQuery } from "../../lib/search";
import { ModalChoiceOptions, showModalAsync } from "../TSModal";
import { blurOnEnterKey } from "../../lib/eventHandlers";
import { isIdea, TSDate } from '../../lib/time';
import { StepWizard } from '../StepWizard/StepWizard';
import { ClearTextAreaRightSection } from "../AddEditTrip/ClearTextAreaRightSection";
import { useTripById } from "../../lib/hooks";
import { computeBias } from "../../lib/geolocation";
import { TSDatePicker } from "../TSDatePicker";

import stepWizardClasses from '../StepWizard/StepWizard.module.css';
import placeClasses from "../SavedPlacesList/SavedPlacesList.module.css";

/**
 * Shows a series of modal sheets collecting information to add a trip activity or saved place.
 *
 * This is akin to {@link showAddEditPlace}, but always runs in add-mode; this is particularly
 * suitable when adding a saved place to a trip.
 */
export async function showAddPlace(sheets: SheetsData, placeTemplate: Partial<Place>, currentTrip: Trip | undefined) {
    const copy: Partial<Place> = {
        placeid: placeTemplate.placeid,
        placetype: placeTemplate.placetype,
        activityType: placeTemplate.activityType,
        title: placeTemplate.title,
        location: placeTemplate.location,
        address: placeTemplate.address,
        lat: placeTemplate.lat,
        lng: placeTemplate.lng,
        locality: placeTemplate.locality,
    };

    await showAddEditPlace(sheets, copy, currentTrip);
}

function addCategory(placeTemplate: Partial<Place>, category: SavedType) {
    placeTemplate.categories = [
        category,
        ...(placeTemplate.categories ?? []).filter(c => c !== category),
    ];
}

function removeCategory(placeTemplate: Partial<Place>, category: SavedType) {
    placeTemplate.categories = (placeTemplate.categories ?? []).filter(c => c !== category);
}

/**
 * Shows a series of modal sheets collecting information to create or edit a trip activity
 * or saved place.
 *
 * If the `placeTemplate` parameter contains a `placeclass` field, the add / edit experience
 * is configured for that type. Else, if `placeTemplate.tripdocid` is specified, we assume
 * that this is an add / edit of a trip activity.
 *
 * Otherwise, a modal sheet is presented to the user to gather the appropriate place type.
 *
 * Once the place type has been determined, the user is presented with a sheet with a little
 * configurator wizard.
 */
export async function showAddEditPlace(sheets: SheetsData, placeTemplate: Partial<Place>, currentTrip: Trip | undefined) {
    let placeClassType: PlaceClassType;
    if (placeTemplate.placeclass) {
        placeClassType = placeTemplate.placeclass;
        if ((placeTemplate.categories ?? []).length === 0) {
            const choices: ModalChoiceOptions[] = [
                { title: "Favorite", type: "normal" },
                { title: "Want to go", type: "normal" },
            ];
            const response = await showModalAsync(sheets, "Pick a Saved Place Type", choices);
            if (response.type === 'dismiss') {
                return;
            } else if (response.choice.title === 'Favorite') {
                addCategory(placeTemplate, 'Favorite');
            } else if (response.choice.title === 'Want to go') {
                addCategory(placeTemplate, 'Want to go');
            } else {
                console.error(`Disregarding unknown category response choice: ${response.choice.title}`);
                return;
            }
        }
    } else if (placeTemplate.tripdocid) {
        placeClassType = PlaceClassType.Activity;
    } else {
        const choices: ModalChoiceOptions[] = [
            { title: "Favorite", type: "normal" },
            { title: "Want to go", type: "normal" },
            { title: "Trip Activity", type: "normal" },
        ];
        const response = await showModalAsync(sheets, "Add to Travel Scroll", choices);
        if (response.type === 'dismiss') {
            return;
        } else if (response.choice.title === 'Favorite') {
            placeClassType = PlaceClassType.Saved;
            addCategory(placeTemplate, 'Favorite');
        } else if (response.choice.title === 'Want to go') {
            placeClassType = PlaceClassType.Saved;
            addCategory(placeTemplate, 'Want to go');
        } else if (response.choice.title === 'Trip Activity') {
            placeClassType = PlaceClassType.Activity;
            if (currentTrip) {
                placeTemplate = { ...placeTemplate, tripdocid: currentTrip!.docid };
            } else {
                const selectedTrip = await showGatherTripSheet(sheets);
                if (selectedTrip) {
                    placeTemplate = { ...placeTemplate, tripdocid: selectedTrip.docid };
                } else {
                    return;
                }
            }
        } else {
            console.error(`Disregarding unknown response choice: ${response.choice.title}`);
            return;
        }
    }

    placeTemplate.placeclass = placeClassType;
    const title = (() => {
        const categoryText = placeTemplate.categories?.length === 1 ? placeTemplate.categories[0] : 'saved place';
        if (placeTemplate.docid) {
            if (placeClassType === PlaceClassType.Activity) {
                return `Edit ${placeTemplate.title ?? 'trip activity'}`;
            } else if (placeClassType === PlaceClassType.Saved) {
                return `Edit ${categoryText}`;
            } else {
                return 'Edit';
            }
        } else if (placeClassType === PlaceClassType.Activity) {
                return `Add ${placeTemplate.title ?? 'new activity'} to ${currentTrip?.title ?? 'trip'}`;
            } else if (placeClassType === PlaceClassType.Saved) {
                return `Add new ${categoryText}`;
            } else {
                return 'Add';
            }
    })();

    await new Promise<void>(resolve => {
        sheets.showSheetWith(
            context => <AddEditPlace
                onClose={() => {
                    context.removeSheet();
                    resolve();
                }}
                place={placeTemplate}
                sheetState={context.sheetState}
            />,
            {
                title,
                disableDrag: true, // TODO enable dragging, and figure out why the scroll area fails to drag properly in this context
            }
        );
    });
}

export async function showGatherTripSheet(sheets: SheetsData) {
    return new Promise<Trip | undefined>(resolve => {
        sheets.showSheetWith(
            context => <Sheet.Scroller draggableAt='top'>
                <GatherTrip onTripSelected={trip => {
                    context.removeSheet();
                    resolve(trip);
                }} />
            </Sheet.Scroller>,
            {
                detent: "content-height",
                disableDrag: false,
                title: "Choose a trip",
                onSheetClosed: () => resolve(undefined),
            });
    });
}

export function GatherTrip(props: { onTripSelected: (trip: Trip | undefined) => unknown }) {
    const { userTrips } = useContext(HomeContext);

    return <div
        style={{ display: 'flex', flexDirection: 'column', gap: "0.5rem", height: '20rem' }}
        className='safeAreaPaddingBottom'
        >
        { userTrips?.map((trip, index) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div
                key={index}
                className={placeClasses.item}
                onClick={() => props.onTripSelected(trip)}
            >
                <Text>{trip.title}</Text>
                { trip.startdate && <Text size='sm' color='dimmed'>
                    {trip.startdate.toLocalDateTime().toLocaleString(DateTime.DATE_HUGE)}
                </Text> }
            </div>
        )) }
    </div>;
}

export function AddEditPlace(
    {
        place, onClose, sheetState,
    }: {
        place: Partial<Place>,
        onClose: () => unknown,
        sheetState: 'closed' | 'opening' | 'opened',
    }) {
    const firebaseRefs = useFirebaseRefs();
    const { firebaseUserId: currentUserId } = useContext(UserContext);
    const user = useContext(UserContext);

    const [ currentStep, setCurrentStep ] = useState(0);
    const [ position, setPosition ] = useState<Position | undefined>(positionFromLatLng(place));
    const [ locality, setLocality ] = useState(place.locality);
    const [ activityType, setActivityType ] = useState<PlaceType | undefined>(placeTypeFromPlace(place));
    const [ googlePlaceId, setGooglePlaceId ] = useState<string | undefined>(place.placeid ?? undefined);
    const [ categories, setCategories ] = useState(place.categories ?? []);
    const trip = useTripById(place?.tripdocid ?? null);
    const { currentTrip } = useContext(TripContext);

    const mainFocusRef = useRef<HTMLInputElement>(null as unknown as HTMLInputElement);

    const form = useForm({
        initialValues: {
            title: place.title,
            dateRange: [
                isIdea(place.startdatetime) ? null : place.startdatetime ?? null,
                isIdea(place.enddatetime) ? null : place.enddatetime ?? null,
            ] as [ TSDate | null, TSDate | null ],
            address: place.address ?? '',
            location: place.location ?? '',
            comment: '',
        },

        validate: (values) => {
            if (currentStep >= 1) {
                return {
                    title: !values.title || values.title.trim().length < 1 ? 'A title is required' : null,
                };
            } else {
                return { };
            }
        },
    });

    useEffect(() => {
        if (sheetState === 'opened' && mainFocusRef.current) {
            mainFocusRef.current.select();
        }
    }, [ sheetState, mainFocusRef.current ]);

    useEffect(() => {
        if (place.title && place.location && place.address) {
            setCurrentStep(1);
        }
    }, [ place.title, place.location, place.address ]);

    useEffect(() => {
        if (currentStep === 1) {
            // When entering the second (zero-based) step, copy the location field to the title if
            // we haven't assigned a title yet.
            if (form.values.title === undefined || form.values.title === null) {
                // only set the 'title' field value if we haven't done so already, either
                // via out-of-order components or via pre-existing values (e.g., an edit)
                form.setFieldValue('title', form.values.location);
            }
        }
    }, [ currentStep, form.values.title, form.values.location ]);

    const upsertActivity = async () => {
        const tripDocId = place.tripdocid;
        const placeDocId = place.docid;

        if (!tripDocId) {
            notifications.show({ message: `Failed to save your activity!` });
            console.warn("Refusing to update a record with no trip id!");
            return;
        }

        const partialActivity = await buildPlaceRecordAndResetForm();

        if (placeDocId) {
            await updateTripActivity(firebaseRefs, tripDocId, placeDocId, partialActivity);
        } else {
            await insertTripActivity(
                firebaseRefs,
                tripDocId,
                user,
                partialActivity,
                async insertedDocId => {
                    if (form.values.comment) {
                        await createComment(firebaseRefs, user, { docid: insertedDocId, tripdocid: tripDocId }, form.values.comment);
                    }
                });
        }
    };

    const upsertSavedPlace = async () => {
        const placeDocId = place.docid;
        const partialActivity = await buildPlaceRecordAndResetForm();

        if (placeDocId) {
            await updateSavedPlace(firebaseRefs, user, placeDocId, partialActivity);
        } else {
            await insertSavedPlace(firebaseRefs, user, partialActivity);
        }
    };

    const upsertRecord = async () => {
        switch (place.placeclass) {
            case PlaceClassType.Activity:
                await upsertActivity();
                break;
            case PlaceClassType.Saved:
                await upsertSavedPlace();
                break;
            default:
                notifications.show({ message: `Failed to save changes! Place class: ${place.placeclass}` });
                break;
        }
    };

    async function buildPlaceRecordAndResetForm() {
        const partialActivity: Partial<Place> = {
            location: form.values.location,
            address: form.values.address,
            title: form.values.title ?? '',
            activityType,
            placetype: activityType,
            categories,
            locality,
            startdatetime: form.values.dateRange[0],
            enddatetime: form.values.dateRange[1],
        };

        if (position) {
            partialActivity.lat = position.lat;
            partialActivity.lng = position.lng;
        } else {
            partialActivity.lat = null;
            partialActivity.lng = null;
        }

        partialActivity.placeid = googlePlaceId ?? place.placeid;

        if (locality) {
            partialActivity.locality = locality;
        } else {
            partialActivity.locality = null;
        }

        // Now that we've extracted all the data, we reset the form. We do this here so that it happens
        // before any of the await logic in the various upsert functions, so that we don't end up clearing
        // after a delay.
        form.reset();

        await geocodeAndUpdatePlace(place, partialActivity);
        return partialActivity;
    }

    const steps = [ "Location", "Details", "Address" ];
    const nextStep = () =>
        setCurrentStep((current) => {
            if (form.validate().hasErrors) {
                return current;
            }
            return current < steps.length ? Math.min(current + 1, steps.length - 1) : current;
        });

    const manageCategories = (checked: boolean, category: SavedType) => {
        const template = { categories };
        if (checked) {
            addCategory(template, category);
        } else {
            removeCategory(template, category);
            if (template.categories?.length === 0) {
                addCategory(template, category === 'Favorite' ? 'Want to go' : 'Favorite');
            }
        }
        setCategories(template.categories);
    };

    return <StepWizard
        steps={steps}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        nextStep={nextStep}
        onDone={() => {
            const { title } = form.values;
            const detail = title ? ` to ${title}` : '';
            raceOrNotify(
                upsertRecord(),
                500,
                `Saved your changes${detail}`,
                `Failed to save your changes${detail}!`
            );
            onClose();
        }}
    >

        { currentStep === 0 && <div
            className='fade-in'
            style={{ flex: '1', display: 'flex', flexDirection: 'column' }}
        >
            <TextInput
                ref={mainFocusRef}
                autoFocus={sheetState === 'opened'}
                {...form.getInputProps('location')}
                placeholder="Optional"
                onKeyUp={blurOnEnterKey}
                rightSection={<ClearTextAreaRightSection onClear={
                    () => {
                        form.setFieldValue('location', '');
                        mainFocusRef.current?.select();
                }} />}
            />
            <SearchChipsAndList
                searchQuery={newSearchQuery({
                        searchTerm: (form.values as any).location,
                        chipConfig: { tripChips: true, recents: true, savedPlaces: true, searchEngine: true },
                        emptyQueryBehavior: 'match-none',
                        bias: computeBias([ positionFromLatLng(trip), positionFromLatLng(currentTrip) ]),
                    },
                    () => { })
                }
                places={[]}
                userTrips={[]}
                currentUserId={currentUserId ?? undefined}
                uiState='inline'
                detailMode="results"
                notifySearchHits={() => { }}
                onClick={async result => {
                    if (result.type === 'place') {
                        if (result.place.location) {
                            form.setFieldValue('location', result.place.location);
                        }
                        if (result.place.address) {
                            form.setFieldValue('address', result.place.address);
                        }
                        setPosition(positionFromLatLng(result.place));
                        setLocality(result.place.locality ?? undefined);
                        setActivityType(result.place.placetype ?? undefined);
                        setGooglePlaceId(result.place.placeid ?? undefined);
                    } else {
                        if (result.title) {
                            form.setFieldValue('location', result.title);
                        }
                        setLocality(await result.locality());
                        setPosition(await result.position());
                        setActivityType(result.placeType);
                        form.setFieldValue('address', await result.full_address() ?? '');
                        if (result.type === 'google-search') {
                            setGooglePlaceId(result.value.place_id);
                        }
                    }
                    nextStep();
                }} />
        </div>}

        { currentStep === 1 && <div className={`${stepWizardClasses.stepSection} fade-in`}>
            <hr style={{ width: '100%' }} />
            <div
                className={stepWizardClasses.progress}
                style={{ display: 'flex', alignItems: "center", gap: '0.5rem' }}>
                <div>Title: </div>
                <TextInput
                    ref={mainFocusRef}
                    autoFocus={sheetState === 'opened'}
                    style={{ display: 'inline-block', flex: 1 }}
                    {...form.getInputProps('title')}
                    value={form.values.title ?? ''}
                    onInput={e => form.setFieldValue('title', e.currentTarget.value)}
                    enterKeyHint='done'
                    onKeyUp={blurOnEnterKey}
                    rightSection={<ClearTextAreaRightSection onClear={() => {
                        form.setFieldValue('title', '');
                        mainFocusRef.current?.select();
                    }} />}
                />
            </div>
            <div className={stepWizardClasses.progress}>
                Location: <span className={stepWizardClasses.fieldValue}>{form.values.location}</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className={stepWizardClasses.progress}>Choose a type:</span>
                <div style={{ display: 'flex', flexWrap: 'nowrap', overflowY: 'auto' }}>
                    <Chip.Group
                        multiple={false}
                        value={activityType}
                        onChange={value => {
                            if (isLodging({ activityType: value as PlaceType })) {
                                form.setValues({
                                    dateRange: [
                                        place.startdatetime ?? null,
                                        place.startdatetime?.plus({ days: 1 }) ?? null,
                                    ],
                                });
                            }
                            setActivityType(PlaceType[value as 'Meal' | 'Lodging' | 'Flight' | 'Activity']);
                        }
                        }
                    >
                        <Chip value='Meal' size='xs'>Meal</Chip>
                        <Chip value='Lodging' size='xs'>Lodging</Chip>
                        <Chip value='Flight' size='xs'>Flight</Chip>
                        <Chip value='Activity' size='xs'>Activity</Chip>
                    </Chip.Group>
                </div>
            </div>
            {place.placeclass === PlaceClassType.Activity
                && <TSDatePicker
                    dates={form.values.dateRange}
                    activityType={activityType}
                    onChange={value => form.setFieldValue('dateRange', value)}
                />}
            { place.placeclass === PlaceClassType.Saved &&
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className={stepWizardClasses.progress}>Saved Place Category:</span>
                    <div style={{ display: 'flex', flexWrap: 'nowrap', overflowY: 'auto' }}>
                        <Chip.Group multiple>
                            <Chip
                                value='Favorite'
                                size='xs'
                                checked={categories.includes('Favorite')}
                                onClick={event => manageCategories(event.currentTarget.checked, 'Favorite')}
                            >
                                Favorite
                            </Chip>
                            <Chip
                                value='Want to go'
                                size='xs'
                                checked={categories.includes('Want to go')}
                                onClick={event => manageCategories(event.currentTarget.checked, 'Want to go')}
                            >
                                Want to go
                            </Chip>
                        </Chip.Group>
                    </div>
                </div>
            }
        </div> }

        { currentStep === 2 && <div
                className={`${stepWizardClasses.stepSection} keyboard-height-padded fade-in`}
            >
            <hr style={{ width: '100%' }} />
            <div className={stepWizardClasses.progress}>
                Title: <span className={stepWizardClasses.fieldValue}>{form.values.title ?? ''}</span>
            </div>
            <div className={stepWizardClasses.progress}>
                Location: <span className={stepWizardClasses.fieldValue}>{form.values.location}</span>
            </div>
            <div className={stepWizardClasses.progress}>
                Type: <span className={stepWizardClasses.fieldValue}>{activityType}</span>
            </div>
            <Textarea
                label="Address"
                placeholder="Address"
                {...form.getInputProps('address')}
                onChange={event => {
                    // onChange is fired on blur, not incrementally. So we should just call this ~once per edit.
                    const newValue = event.currentTarget.value;
                    form.setFieldValue('address', newValue);
                    setLocality(undefined);
                    setPosition(undefined);
                }}
            />
            { !place.docid && place.placeclass === PlaceClassType.Activity && <Textarea
                label="Comment"
                placeholder="Enter a comment..."
                {...form.getInputProps('comment')}
                onChange={event => {
                    // onChange is fired on blur, not incrementally. So we should just call this ~once per edit.
                    const newValue = event.currentTarget.value;
                    form.setFieldValue('comment', newValue);
                }}
            /> }
        </div> }
    </StepWizard>;
}
