import {
    DocumentData,
    DocumentSnapshot,
    FirestoreDataConverter,
    QueryDocumentSnapshot,
    SnapshotOptions,
    Timestamp,
    WithFieldValue,
} from "firebase/firestore";
import { DateTime } from "luxon";
import { Suggestion } from "use-places-autocomplete";
import { Position } from "./position";
import { IDEA_TSDATE, timestampToDateTime, TSDate } from "./time";
import { ExifGps } from "./exif";

export enum PlaceClassType {
    Activity,
    Saved,
}

export type SavedType = 'Favorite' | 'Want to go';

export enum PlaceType {
    Activity = 'Activity',
    Lodging = 'Lodging',
    Meal = 'Meal',
    Flight = 'Flight',
    Hotel = 'Hotel'
}

export enum AppShellType {
    Saveds,
    Home,
    Trip,
    Activity
}

export type UpdateTripSectionType = 'TitleDate' | 'Location' | 'Delete';

export type ImageType = 'location-search' | 'participant-upload' | 'placeholder' | 'v0-image' | 'unknown';

export interface Place {
    schemaVersion: number | null;
    placeclass: PlaceClassType;
    title: string | null;
    location: string | null;
    address: string | null;
    lat: number | null;
    lng: number | null;
    review: string | null;
    docid: string | null;
    rating: number | null;
    order: number | null;
    dayorder: number | null;
    categories: SavedType[] | null;
    placetype: PlaceType | null;
    searchtype: string | null;
    creatorId: string | null;
    userid: string | null;
    calculateduseridname: string | null;
    totalvotes: number | null;
    tripdocid: string | null;
    isfavorite: boolean | null;
    startdatetime: TSDate | null;
    enddatetime: TSDate | null;
    updatedAt: DateTime | null;
    created: DateTime | null;
    locality: string | null;
    placeid: string | null;
    activityType: PlaceType | null | undefined;
}

export type AbstractMappableRecord = {
    id: string,
    title: string | undefined,
    location: string | undefined,
    address: () => Promise<string | undefined>,
    position: Position,
    locality: () => Promise<string | undefined>,
};

export type GooglePlacePhoto = {
    url: string,
    url400h: string,
    height?: number,
    width?: number,
    html_attributions: string[]
};

type BaseSearchResult = {
    title: string,
    placeType: PlaceType | undefined,

    // A potentially-abbreviated address that can be provided without any
    // additional network requests. For Google Maps searches, this will be
    // a partial street address. To get the complete address, use `full_address()`
    cheap_address: string | undefined,

    position: () => Promise<Position | undefined>,
    photos: () => Promise<GooglePlacePhoto[]>,
    locality: () => Promise<string | undefined>,
    full_address: () => Promise<string | undefined>,
    distance_meters: () => Promise<number | undefined>,
};

type GoogleSearchResult = BaseSearchResult & {
    type: 'google-search',
    value: Suggestion,
};

type AppleSearchResult = BaseSearchResult & {
    type: 'apple-search',
};

type MockSearchResult = BaseSearchResult & {
    type: 'mock-search',
};

export type SearchEngineResult = GoogleSearchResult | AppleSearchResult | MockSearchResult;

export type PlaceRecord = {
    type: 'place',
    place: Place
};

export type SearchEngineMappableRecord = {
    type: 'search-engine-result',
    searchEngineResult: SearchEngineResult,
} & AbstractMappableRecord;
export type MappableRecord = SearchEngineMappableRecord | (PlaceRecord & AbstractMappableRecord);

function modificationTimestampsFromSnapshot(
    doc: DocumentSnapshot | undefined,
    fallbacks: { created: Timestamp | null, updated: Timestamp | null }
) {
    let created: DateTime | undefined;
    const creationTimestamp = (doc as any)?._document?.createTime?.timestamp as Timestamp | null;
    if (creationTimestamp && creationTimestamp.toMillis() !== 0) {
        // always use the firebase data if it's available
        created = DateTime.fromMillis(creationTimestamp.toMillis());
    } else {
        created = fallbacks.created ? DateTime.fromMillis(fallbacks.created.toMillis()) : undefined;
    }

    let updated: DateTime | undefined;
    const updatedTimestamp = (doc as any)?._document?.updateTime?.timestamp as Timestamp | null;
    if (updatedTimestamp && updatedTimestamp.toMillis() !== 0) {
        // always use the firebase data if it's available
        updated = DateTime.fromMillis(updatedTimestamp.toMillis());
    } else {
        updated = fallbacks.updated ? DateTime.fromMillis(fallbacks.updated.toMillis()) : undefined;
    }

    return { created, updated };
}

function firebaseRecordToPlace(doc: DocumentSnapshot, tripDocId?: string): Place {
    const data = doc.data();
    if (!data) {
        throw Error("Document snapshot contains no data!");
    }

    const { created, updated } = modificationTimestampsFromSnapshot(
        doc, { created: data.created as Timestamp, updated: data.updatedAt as Timestamp });

    const place = <Place>{ // TODO get rid of this cast
        ...data,
        tripdocid: tripDocId,
        docid: doc.id,
        startdatetime: TSDate.fromTimestamp(data.startdatetime) ?? IDEA_TSDATE,
        enddatetime: TSDate.fromTimestamp(data.enddatetime) ?? undefined,
        created: created ?? DateTime.now(), // TODO should we really do this, or just leave it undefined?
        updatedAt: updated ?? created ?? DateTime.now(), // TODO should we really do this, or just leave it undefined?
        order: data.order ?? 10000,
        dayorder: data.dayorder ?? 10000,
        creatorId: data.creatorId ?? data.userid,
        placeclass: PlaceClassType.Activity,
    };

    if ((place.schemaVersion ?? 0) < 2 && place.lat === 0 && place.lng === 0) {
        // Place records of schemaVersion 0 and 1 sometimes have lat=0,lng=0 when they should have nulls.
        place.lat = null;
        place.lng = null;
    }

    // Old records might contain these fields; we clear them here since they should not be used any more.
    delete (place as any).img;
    delete (place as any).images;
    delete (place as any).img400h;

    return place;
}

function firebaseRecordToSaved(doc: DocumentSnapshot): Place {
    const place = firebaseRecordToPlace(doc);
    return {
        ...place,
        creatorId: place.userid,
        categories: place.categories ?? [ 'Favorite' ],
        placeclass: PlaceClassType.Saved,
    };
}

export const placeConverter: FirestoreDataConverter<Place> = {
    toFirestore(placeFields: WithFieldValue<Place>): DocumentData {
        const data = convertUndefinedsAndDateTimes(placeFields, [ 'placeclass' ]);

        // TODO perform cleanup of known-bad fields here, so that we incrementally clean up on write
        return data;
    },

    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions): Place {
        const tripActivityMatch = snapshot.ref.path.match(/^trips\/([^/]+)\/activities/);
        if (tripActivityMatch) {
            return firebaseRecordToPlace(snapshot, tripActivityMatch[1]);
        } else if (snapshot.ref.path.match(/^users\/[^/]+\/places/)) {
            return firebaseRecordToSaved(snapshot);
        } else {
            throw Error(`Unrecognized snapshot path: ${snapshot.ref.path}`);
        }
    },
};

export const changeLogConverter: FirestoreDataConverter<ChangeLog> = {
    toFirestore(commentFields: WithFieldValue<ChangeLog>): DocumentData {
        return convertUndefinedsAndDateTimes(commentFields, []);
    },

    fromFirestore(doc: QueryDocumentSnapshot, options?: SnapshotOptions): ChangeLog {
        const data = doc?.data();
        if (!data) {
            throw Error("Snapshot was null for ChangeLog document!");
        }

        const { created } = modificationTimestampsFromSnapshot(
            doc, { created: data.timestamp as Timestamp, updated: data.timestamp as Timestamp });

        return <ChangeLog>{
            ...data,
            docid: doc?.id,
            timestamp: timestampToDateTime(data.timestamp) ?? created,
        };
    },
};

export const commentConverter: FirestoreDataConverter<PlaceComment> = {
    toFirestore(commentFields: WithFieldValue<PlaceComment>): DocumentData {
        return convertUndefinedsAndDateTimes(commentFields, []);
    },

    fromFirestore(doc: QueryDocumentSnapshot, options?: SnapshotOptions): PlaceComment {
        return firebaseRecordToPlaceComment(doc);
    },
};

export const imageConverter: FirestoreDataConverter<PlaceImage> = {
    toFirestore(modelObject: WithFieldValue<PlaceImage>): DocumentData {
        return convertUndefinedsAndDateTimes(modelObject, []);
    },

    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions): PlaceImage {
        const data = snapshot.data();
        if (!data) {
            throw Error("Document snapshot contains no data!");
        }

        const { created, updated } = modificationTimestampsFromSnapshot(
            snapshot, { created: data.created as Timestamp, updated: data.updatedAt as Timestamp });

        return {
            docid: snapshot.id,
            url: data.url,
            url400h: data.url400h,
            useruid: data.useruid,
            type: data.type,
            publisher: data.publisher,
            docCreated: created,
            phototaken: timestampToDateTime(data.phototaken),
            placedocid: data.placedocid,
            placeId: data.placeId,
            activityId: data.activityId,
            tripId: data.tripId,
            activityOrder: data.activityOrder,
        } as PlaceImage;
    },
};

/* Additional old Activity attributes - needed?
    int? segmentedControl;
    int? temptotalvotes;
    int? dayorder;
    string? favoritedocid;
    string? triptitle;
    string? description;
    string? originaltype;
    string? wikiTitle;
    string? wikiUrl;
    string? wikiImage;
    string? wikiExtract;
    string? subtitle;
    double? distanceFrom;
    ImageList images;
    string? activityType;
    string? updateType;
    string? placeType;
    DateTime? oldstartdate;
    string? privatenote;
    string? shortName;
    List<Vote>? votes; */

export interface UserClass {
    uid: string | null;
    displayName: string | null;
    email: string | null;
    tripsOnboarded: boolean | null;
    homeScreenOnboarded: boolean | null;
    photoURL: string | null;
    total: number | null;
    trips: string[] | null;
    loggedin: boolean | null;
    hasfavorites: boolean | null;
    distanceFromMe: boolean | null;
    lastActivity: DateTime | null;
    updatedAt: DateTime | null;
    createdAt: DateTime | null;
}

export function firebaseRecordToUserClass(data: DocumentData | undefined): UserClass | null {
    if (!data) {
        return null;
    }

    return <UserClass>{
        ...data,
        lastActivity: timestampToDateTime(data.lastActivity) ?? DateTime.now(),
        createdAt: timestampToDateTime(data.createdAt) ?? DateTime.now(), // TODO use the firebase metadata instead?
        updatedAt: timestampToDateTime(data.updatedAt) ?? DateTime.now(),  // TODO use the firebase metadata instead? Fall back to created?
    };
}

export interface Trip {
    totalvotes: number | null;
    docid: string | null;
    title: string | null;
    review: string | null;
    lat: number | null;
    lng: number | null;
    placeid: string | null;
    searchtype: ['google'] | null;
    location: string | null;
    address: string | null;
    img: string | null;
    img400h: string | null;
    locality: string | null;
    startdate: TSDate | null;
    createdAt: DateTime | null;
    updatedAt: DateTime | null;
    isfavorite: boolean | null;
    users: string[] | null;
    creatorId: string | null;
    schemaVersion: number | null;
}

export function convertUndefinedsAndDateTimes<T>(fields: WithFieldValue<T> & {}, exclusions: (keyof T)[]) {
    const data: any = {};
    Object.keys(fields)
        .forEach(key => {
            if ((exclusions as string[]).includes(key)) {
                return;
            }

            // @ts-ignore
            const value = fields[key];
            if (value === undefined) {
                data[key] = null;
            } else if (value instanceof TSDate) {
                data[key] = value.toTimestamp();
            } else if (value instanceof DateTime) {
                data[key] = Timestamp.fromMillis((value as DateTime).toMillis());
            } else if (value instanceof Date) {
                data[key] = Timestamp.fromMillis((value as Date).getMilliseconds());
            } else {
                data[key] = value;
            }
        });
    return data;
}

function filterGoogleImageUrl(url: string | null) {
    // If the image URL looks like a Google Maps URL, return null instead; we no longer
    // cache Google Place image URLs in firebase, and should ignore any old data.
    if (url?.startsWith("https://maps.googleapis.com/")) {
        return null;
    } else {
        return url;
    }
}

export const tripConverter: FirestoreDataConverter<Trip> = {

    toFirestore(tripFields: WithFieldValue<Trip>): DocumentData {
        return convertUndefinedsAndDateTimes(tripFields, []);
    },

    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions): Trip {
        const data = snapshot.data();

        const { created, updated } = modificationTimestampsFromSnapshot(
            snapshot, { created: data.createdAt as Timestamp, updated: data.updatedAt as Timestamp });

        return <Trip>{
            ...data,
            img: filterGoogleImageUrl(data.img),
            img400h: filterGoogleImageUrl(data.img400h),
            docid: snapshot.id,
            startdate: TSDate.fromTimestamp(data.startdate),
            createdAt: created ?? DateTime.now(),
            updatedAt: updated ?? DateTime.now(), // TODO fall back to created?
        };
    },
}

/* DO we need these old Trip attributes??
    int? temptotalvotes;
    int? segmentedControl;
    string? description;
    string? shortName;
    List? selectevents;
    dynamic votes;
    dynamic activities;
*/

export interface Vote {
    vote: number | null;
    useruid: string | null;
    activitydocid: string | null;
    tripdocid: string | null;
    updatedAt: DateTime | null;
    createdAt: DateTime | null;
}

export function firebaseRecordToVote(doc: DocumentData): Vote {
    const data = doc;
    if (!data) {
        throw Error("No data found for vote snapshot!");
    }

    return <Vote>{
        ...data,
        createdAt: timestampToDateTime(data.createdAt) ?? DateTime.now(), // TODO use the firebase metadata instead?
        updatedAt: timestampToDateTime(data.updatedAt) ?? DateTime.now(), // TODO use the firebase metadata instead? Fall back to created?
    };
}

export interface PlaceComment {
    docid: string | null;
    comment: string | null;
    useruid: string | null;
    activitydocid: string | null;
    tripdocid: string | null;
    timestamp: DateTime | null;
    schemaVersion: number | null;

    // Transient field indicating whether this comment comes from an activity-scoped collection (legacy)
    // or the trip-scoped comment collection.
    isLegacyPlaceComment?: boolean;
}

export function firebaseRecordToPlaceComment(doc: DocumentSnapshot): PlaceComment {
    const data = doc?.data();
    if (!data) {
        throw Error("Snapshot was null for PlaceComment document!");
    }

    const { created } = modificationTimestampsFromSnapshot(
        doc, { created: data.timestamp as Timestamp, updated: data.timestamp as Timestamp });

    const isLegacyPlaceComment = doc.ref.path.includes('/activities/');
    return <PlaceComment>{
        ...data,
        docid: doc?.id,
        timestamp: timestampToDateTime(data.timestamp) ?? created,
        isLegacyPlaceComment,
    };
}

export interface ChangeLog {
    docid: string | null;
    userName: String | null;
    userId: string | null;
    activityId: string | null;
    changeType: string | null;
    timestamp: DateTime | null;

    // Certain of these will be populated, depending on the change type
    imageUrls?: string[];
    pictureId?: string;
    vote?: number;
    voteComment?: string;
    comment?: string;
}

export interface PlaceImage {
    docid: string | null;
    url: string | null;
    url400h: string | null;
    useruid: string | null;
    type: ImageType | null;
    publisher: string | null;
    docCreated: DateTime | null;
    phototaken: DateTime | null;
    placedocid: string | null;
    placeId: string | null;
    activityId: string | null;
    tripId: string | null;
    activityOrder: number | null;
    exifGps: ExifGps | null;
    status: 'upload-pending' | 'uploaded' | null;
}

export function byImageActivityOrder(a: PlaceImage, b: PlaceImage): number {
    return (a?.activityOrder ?? 0) - (b?.activityOrder ?? 0);
}

export function compareUsers(a: UserClass | undefined | null, b: UserClass | undefined | null): number {
    if ((!a && !b) || (a === b)) {
        return 0;
    } else if (!a) {
        return -1;
    } else if (!b) {
        return 1;
    } else if (a.displayName && b.displayName) {
        return a.displayName.localeCompare(b.displayName);
    } else if (a.email && b.email) {
        return a.email.localeCompare(b.email);
    } else {
        return (a.uid ?? '').localeCompare(b.uid ?? '');
    }
}

export type HomeData = {
    /**
     * The currently-logged-in user's trips, or 'undefined' if the user is definitively logged out or if the trips have
     * not yet been loaded.
     */
    userTrips: Trip[] | undefined,

    userTripsById: Map<string, Trip> | undefined,

    tripAdjacentUsersById: Map<string, UserClass>,

    /**
     * The currently-logged-in user's saved places -- favorites and wish-list
     */
    uidPlaces: Place[],
};

export type TripData = {
    currentTrip: Trip | undefined
};
