import { Geolocation, Position } from "@capacitor/geolocation";
import { useEffect, useState } from "react";
import { getLogger } from "./logger";
import { SearchBias } from "./search";
import * as positionModule from "./position";

const logger = getLogger("geolocation");

export type GeolocationData = {
    isGeolocationAvailable: boolean
    isGeolocationEnabled: boolean
    isFirstPositionEstablished: boolean

    /**
     * Trigger a location lookup. If the geolocation library has not yet been initialized,
     * this will initialize it, which might result in a permission check.
     */
    getPosition: () => Promise<Position | undefined>

    currentPosition: Position | undefined
};

function emptyData(): GeolocationData {
    return {
        isGeolocationAvailable: false,
        isGeolocationEnabled: false,
        isFirstPositionEstablished: false,
        currentPosition: undefined,
        getPosition: async () => undefined,
    };
}

const globalGeolocationData = emptyData();

export function useGeolocationData(watchPosition: boolean): GeolocationData {
    const [ geolocationData, setGeolocationData ] = useState<GeolocationData>(() => ({ ...globalGeolocationData }));
    const [ cancelFunc, setCancelFunc ] = useState<{ cancel:() => void } | undefined>();

    useEffect(() => {
        if (cancelFunc && geolocationData.isFirstPositionEstablished) {
            cancelFunc.cancel();
        }
    }, [ geolocationData, cancelFunc ]);

    useEffect(() => {
        if (watchPosition) {
            setCancelFunc(undefined);
            return watchLocation(setGeolocationData);
        } else {
            // If we don't want to watch the position, do the normal watch process and then tear it down once we're through.
            const cancel = watchLocation(newData => {
                setGeolocationData(oldData => {
                    if (!oldData.isGeolocationEnabled
                        || !oldData.isGeolocationAvailable
                        || !oldData.isFirstPositionEstablished
                        || ((oldData.currentPosition?.timestamp ?? 0) - (newData.currentPosition?.timestamp ?? 0) > 5000)
                    ) {
                        logger.log("Updating geolocation data");
                        return newData;
                    } else {
                        return oldData;
                    }
                });
            });
            setCancelFunc({ cancel });
            return cancel;
        }
    }, [ watchPosition ]);

    return geolocationData;
}

const watchers = new Set<(value: GeolocationData) => void>();
let watchClearFunction = () => { };
function releaseWatch(callback: (value: GeolocationData) => void) {
    watchers.delete(callback);
    if (watchers.size === 0) {
        watchClearFunction();
        watchClearFunction = () => { };
    }
}

function watchLocation(setGeolocationData: (value: GeolocationData) => void) {
    const hasWatchers = watchers.size > 0;

    watchers.add(setGeolocationData);

    if (!hasWatchers) {
        globalGeolocationData.currentPosition = undefined;
        globalGeolocationData.isFirstPositionEstablished = false;
        globalGeolocationData.isGeolocationEnabled = false;
        globalGeolocationData.isGeolocationAvailable = false;
        globalGeolocationData.getPosition = async () => undefined;

        watchGeolocationData(globalGeolocationData);
    }

    return () => releaseWatch(setGeolocationData);
}

function watchGeolocationData(geolocationData: GeolocationData) {
    let initializationStatus: 'uninitialized' | 'starting' | 'done';

    // on first invocation, check to see if we happen to have permissions from a prior run
    Geolocation.checkPermissions()
        .then(async result => {
            if (result.location === 'granted') {
                geolocationData.isGeolocationEnabled = true;
                geolocationData.isGeolocationAvailable = true;
                initializationStatus = 'done';
                startLocating();
            }
        })
        .catch(err => { }); // ignore for now -- we can try again if the user requests location

    const setCurrentPosition = (pos: Position | undefined) => {
        globalGeolocationData.currentPosition = pos;
        if (!globalGeolocationData.isFirstPositionEstablished) {
            // TODO consider checking for accuracy, since the first is sometimes quite coarse
            globalGeolocationData.isFirstPositionEstablished = true;
        }
        watchers.forEach(w => w({ ...globalGeolocationData }));
    };

    const setGeolocationAvailable = (available: boolean) => {
        geolocationData.isGeolocationAvailable = available;
        geolocationData.isGeolocationEnabled = true;
        watchers.forEach(w => w({ ...globalGeolocationData }));
        const watchPromise = Geolocation.watchPosition(
            {
                // We use low accuracy to keep iPhones cool -- with high accuracy enabled,
                // the geolocation plugin requests navigation-level accuracy, and the phone
                // becomes a heater. TODO we might consider using our own geolocation plugin
                // and requesting some medium-level accuracy.
                enableHighAccuracy: false,
            },
            result => setCurrentPosition(result ?? undefined)
        );
        watchClearFunction = () => {
            watchPromise.then(watchId => {
                Geolocation.clearWatch({ id: watchId });
            });
        };
    };
    const startLocating = () => {
        Geolocation.checkPermissions()
            .then(async result => {
                switch (result.location) {
                    case "denied":
                        initializationStatus = 'done';
                        setGeolocationAvailable(false);
                        break;
                    case "prompt":
                    case "prompt-with-rationale": {
                        const requestResult = await Geolocation.requestPermissions(
                            { permissions: [ 'location' ] });
                        if (requestResult.location === 'granted') {
                            initializationStatus = 'done';
                            setGeolocationAvailable(true);
                        } else if (requestResult.location === 'denied') {
                            initializationStatus = 'done';
                            setGeolocationAvailable(true);
                        } else {
                            // ignore the other cases, so we don't go around in circles
                            initializationStatus = 'uninitialized';
                        }
                        break;
                    }
                    case "granted":
                        initializationStatus = 'done';
                        setGeolocationAvailable(true);
                        break;
                }
            })
            .catch(err => {
                initializationStatus = 'done';
                geolocationData.isGeolocationAvailable = false;
                geolocationData.isGeolocationEnabled = false;
                watchers.forEach(w => w({ ...globalGeolocationData }));
            });
    };

    const waitForInitialization = async () => {
        return new Promise<Position | undefined>(resolve => {
            setTimeout(() => {
                if (geolocationData.isFirstPositionEstablished) {
                    resolve(geolocationData.currentPosition);
                } else {
                    resolve(waitForInitialization()); // TODO apply a timeout?
                }
            }, 100);
        });
    };

    geolocationData.getPosition = async (): Promise<Position | undefined> => {
        if (initializationStatus === 'uninitialized') {
            startLocating();
            return waitForInitialization();
        } else if (initializationStatus === 'done') {
            const position = await Geolocation.getCurrentPosition();
            setCurrentPosition(await Geolocation.getCurrentPosition());
            return position;
        } else {
            return waitForInitialization();
        }
    };
}

/**
 * Captures a snapshot of the current geolocation data, or waits for a position
 * if geolocation is not yet available. This does not update as position changes,
 * so is suitable for use in UI components that should remain stable after render.
 *
 * This is its own `use` function instead of being rolled into the context so that
 * its lifecycle tracks the lifecycle of the component using it. That way, if new
 * components are created, they will have the latest position data as of their time
 * of creation, rather than the first data recorded in the context.
 */
export function usePositionSnapshot(): Position | undefined {
    const geo = useGeolocationData(false);

    const [ snapshot, setSnapshot ] = useState<Position | undefined>();

    useEffect(() => {
        if (geo.isFirstPositionEstablished) {
            setSnapshot(geo.currentPosition);
        } else {
            setSnapshot(undefined);
        }
        // This intentionally does not depend on geo.currentPosition to prevent updates
    }, [ geo.isFirstPositionEstablished ]);

    return snapshot;
}

export function currentLocation(): Position | undefined {
    return globalGeolocationData?.currentPosition;
}

export function computeBias(positionOptions: (positionModule.Position | undefined)[]): SearchBias | undefined {
    const opts = positionOptions.filter(pos => pos);
    if (opts.length > 0) {
        return { center: opts[0]!, radius: 10000 };
    } else {
        const coords = currentLocation()?.coords;
        const pos = positionModule.positionFromLatitudeLongitude(coords);
        if (pos) {
            return { center: pos, radius: 10000 };
        } else {
            return 'IP_BIAS';
        }
    }
}
