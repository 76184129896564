import { ActionIcon, Menu, MenuDropdownProps } from "@mantine/core";
import { IconDots } from "@tabler/icons-react";
import React, { ReactElement } from "react";

export function TSRightSideMenu(
    { ariaLabel, children }:
    {
        ariaLabel: string,
        children: ReactElement<MenuDropdownProps>,
    }) {
    return <Menu shadow="md" width={200}>
        <Menu.Target>
            <ActionIcon
                style={{ marginRight: 'auto', alignItems: 'center', height: '100%', paddingLeft: '2rem', paddingRight: '2rem' }}
                size="lg"
                pr='md'
                aria-label={ariaLabel}
            >
                <IconDots size="24" color='white' />
            </ActionIcon>
        </Menu.Target>
        {children}
    </Menu>;
}
