import { getDetails, GetDetailsArgs, Suggestion } from "use-places-autocomplete";
import { useEffect, useState } from "react";
import { Loader } from '@googlemaps/js-api-loader';
import { Capacitor } from "@capacitor/core";
import { Position, positionFromLatLng } from "./position";
import { defaultCache, newCache } from "./durable-cache";
import { GooglePlacePhoto } from "./interfaces";
import { getLogger } from "./logger";
import { googleMapsLoaderOptions } from "./config";
import PlaceResult = google.maps.places.PlaceResult;

const logger = getLogger('google');

// Google Maps Photos API returns photo URLs that are valid for 3 days. We maintain a cache for
// better performance, but limit it to 2 days 23 hours in the web client, since the web client
// does not cache the underlying images. In the native client, we can cache for longer, since
// the image cache is longer as well.
const cacheDuration = Capacitor.isNativePlatform() ? 3 * 24 * 60 - 60 : 7 * 24 * 60;
const photosCache = newCache("localstorage-google-photos-cache", cacheDuration);

export type ParsedDetails = {
    suggestion: Suggestion,
    position: Position | undefined,
    locality: string | undefined
};
export async function getDetailsForSuggestion(suggestion: Suggestion): Promise<ParsedDetails> {
    const details = await cacheAwareDetailsFetch(suggestion.place_id);
    if (!details) {
        return { suggestion, position: undefined, locality: undefined };
    } else {
        return {
            suggestion,
            position: details.position,
            locality: details.locality,
        };
    }
}

export async function getDetailsForPlaceId(placeId: string) {
    const details = await cacheAwareDetailsFetch(placeId);
    if (details) {
        return {
            position: details.position,
            locality: details.locality,
        };
    } else {
        return undefined;
    }
}

async function cacheAwareDetailsFetch(placeId: string) {
    return defaultCache().memoize(getConvertedDetails, { placeId, fields: [ 'geometry', 'address_components' ] });
}

export async function getLocalityForPlaceId(placeId: string) {
    const cachedDetails = await defaultCache().fetchFromCache(
        getConvertedDetails, { placeId, fields: [ 'geometry', 'address_components' ] });
    if (cachedDetails) {
        // if we've cached the full details, use that data
        return cachedDetails.locality;
    } else {
        // otherwise, do a cheaper fetch for just the address data
        const details = await defaultCache().memoize(
            getConvertedDetails, { placeId, fields: [ 'address_components' ] });
        return details?.locality;
    }
}

async function getConvertedDetails(args: GetDetailsArgs) {
    const details = await getDetails(args);
    return convertDetails(details);
}

export function getPhotosForSuggestion(suggestion: Suggestion): Promise<GooglePlacePhoto[] | undefined> {
    return cacheAwarePhotosFetch(suggestion.place_id);
}

async function cacheAwarePhotosFetch(placeId: string) {
    return photosCache.memoize(getPhotos, placeId);
}

export function revokeCachedGooglePlaceImages(placeId: string) {
    const key = photosCache.keyForSignature(getPhotos, placeId);
    photosCache.revokeKey(key);
}

async function getPhotos(placeId: string) {
    const details = await getDetails({ placeId, fields: [ 'photo' ] });
    return convertPhotos(details);
}

function convertDetails(details: google.maps.places.PlaceResult | string) {
    if (typeof details === 'string') {
        console.warn(`Received a string when getting details:`, details);
        return undefined;
    } else {
        const lat = details.geometry?.location?.lat();
        const lng = details.geometry?.location?.lng();
        const addressComponents = details.address_components;
        let locality: string | undefined;

        addressComponents?.find((addComp) => {
            if (addComp.types.includes('locality')) {
                locality = addComp.short_name;
            }
            return addComp.types.includes('locality');
        });

        return {
            position: positionFromLatLng({ lat, lng }),
            photos: convertPhotos(details),
            locality: locality ?? undefined,
        };
    }
}

function convertPhotos(details: PlaceResult | string): GooglePlacePhoto[] | undefined {
    if (typeof details === 'string') {
        console.warn(`Received a string when getting details:`, details);
        return undefined;
    } else {
        return (details.photos ?? []).map(p => ({
            height: p.height,
            width: p.width,
            html_attributions: p.html_attributions,
            url: p.getUrl(),
            url400h: p.getUrl({ maxHeight: 400 }),
        }));
    }
}

export function useGoogleImages(placeId: string | null | undefined) {
    const [ googlePics, setGooglePics ] = useState<GooglePlacePhoto[] | undefined>(undefined);

    useEffect(() => {
        if (!placeId) {
            return;
        }

        const capturedId = placeId;
        let fetchAttempts = 0;
        const fetchGetDetails = async () => {
            fetchAttempts++;
            if (fetchAttempts > 2) {
                console.warn("Exceeded the fetch attempts for a Google Place record!");
                return;
            }

            try {
                const photos = await cacheAwarePhotosFetch(capturedId);
                setGooglePics(photos?.slice(0, 3) ?? []);
            } catch (error) {
                if (!(global as any).google?.maps) {
                    console.warn(
                        'Trapped an error while loading Google Place details, and the Google Maps SDK appears '
                        + 'not to be loaded! Will attempt to load Google Maps',
                        error);
                    const loader = new Loader({
                        apiKey: googleMapsLoaderOptions.googleMapsApiKey,
                        libraries: googleMapsLoaderOptions.libraries,
                    });

                    try {
                        await loader.load();
                        console.log(`Re-loading Google Places data for ${capturedId} now that Google Maps API is loaded`);
                        await fetchGetDetails();
                    } catch (err) {
                        console.warn('Received an error while loading Google Maps', err);
                    }
                }
            }
        };

        fetchGetDetails();
    }, [ placeId ]);

    return { googlePics };
}
