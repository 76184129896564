import { Button } from "@mantine/core";
import { useRouter } from "next/navigation";
import classes from './ShellStyles.module.css';

export function UnauthenticatedRightSide() {
    const router = useRouter();

    return <Button
        className={classes.login}
        onClick={() => router.push(`/enter?redirect=${document.location.href}`)}
    >
        Log In
    </Button>;
}
