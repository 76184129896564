import { Accordion, ActionIcon, Chip, Text, TextInput } from "@mantine/core";
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
    IconArrowDown,
    IconArrowsSort,
    IconFilter,
    IconFlag,
    IconHeart,
    IconList,
    IconMapPin,
    IconSearch,
} from '@tabler/icons-react';
import Sheet from "react-modal-sheet";
import getDistance from "geolib/es/getDistance";
import { HomeContext } from "../../lib/context";
import { usePromiseValue } from "../../lib/hooks";
import {
    Place,
    PlaceClassType,
    PlaceComment,
    PlaceRecord,
    PlaceType,
    SearchEngineResult,
    Trip,
} from "../../lib/interfaces";
import { PlaceFilterConfig, SavedPlaceResult } from "../../lib/searchHooks";
import { addressForPlace, headlineForPlace } from "../../lib/placeFunctions";
import {
    ChipConfig, EmptyQueryBehavior,
    SearchConfig,
    SearchQuery,
    SearchResults,
    TripChipOption,
    useSearchResults
} from "../../lib/search";
import { blurOnEnterKey } from "../../lib/eventHandlers";
import { metersToMetricMessage } from "../../lib/distance";
import { Bounds, Position, positionFromLatitudeLongitude, positionFromLatLng } from "../../lib/position";
import { useGeolocationData, usePositionSnapshot } from "../../lib/geolocation";
import { tripDescription } from "../../lib/tripFunctions";
import { ClearTextAreaRightSection } from "../AddEditTrip/ClearTextAreaRightSection";
import { Banner } from "../Banner";

import classes from "./SearchUI.module.css";
import placeClasses from "../SavedPlacesList/SavedPlacesList.module.css";

function SearchResultComponent(props: {
    headline: string
    address: string
    label: string
    distanceInMeters: number | undefined
    icon?: JSX.Element | null
    citation?: string | undefined
    onSelected: () => void
}) {
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
    return <div role='listitem'
                aria-label={props.label}
                style={{ display: 'flex', gap: '10px' }}
                className={placeClasses.item}
                onClick={() => props.onSelected()}>
        {props.icon}
        <div style={{ flex: '1' }}>
            <Text aria-label='headline'>{props.headline}</Text>
            <Text aria-label='address' size="xs" color="dimmed">{props.address}</Text>
            {props.citation && <Text size='xs' color='dimmed'>{props.citation}</Text>}
            {props.distanceInMeters !== undefined && !Number.isNaN(props.distanceInMeters) &&
                <Text size='xs' color='dimmed'>
                    {metersToMetricMessage(props.distanceInMeters)}
                </Text>}
        </div>
    </div>;
}

function SearchEngineResultComponent(props: {
    hit: SearchEngineResult
    onSelected: () => void
}) {
    const distanceInMeters = usePromiseValue(props.hit.distance_meters);

    return <SearchResultComponent
        label='Search result'
        onSelected={props.onSelected}
        icon={<IconMapPin />}
        headline={props.hit.title ?? '<unknown>'}
        address={props.hit.cheap_address ?? ''}
        distanceInMeters={distanceInMeters}
    />;
}

function SearchCandidateComponent(props: {
    hit: Place
    userTrips: Trip[]
    onSelected: () => void
}) {
    const positionSnapshot = usePositionSnapshot();

    const placePosition = positionFromLatLng(props.hit);
    const distanceInMeters = placePosition && positionSnapshot
        ? getDistance(placePosition, { lat: positionSnapshot.coords.latitude, lng: positionSnapshot.coords.longitude })
        : undefined;

    const trip = props.userTrips.find(t => t.docid === props.hit.tripdocid);

    const icon = props.hit.placeclass === PlaceClassType.Activity
        ? <IconList />
        : props.hit.categories?.includes('Favorite') ? <IconHeart /> : <IconFlag />;
    return <SearchResultComponent
        label='Activity'
        onSelected={props.onSelected}
        icon={icon}
        headline={headlineForPlace(props.hit)}
        address={addressForPlace(props.hit)}
        citation={tripDescription(trip)}
        distanceInMeters={distanceInMeters}
    />;
}

function SavedPlaceResultComponent(props: {
    hit: SavedPlaceResult
    currentUserId: string | undefined
    onSelected: () => void
}) {
    const { tripAdjacentUsersById } = useContext(HomeContext);
    const positionSnapshot = usePositionSnapshot();

    const isSelfRecord = props.hit.results[0].userid === props.currentUserId;

    const citation: string | undefined = isSelfRecord
        ? undefined
        : props.hit.results
            .map(p => p.userid)
            .filter(userId => userId !== props.currentUserId)
            .map(userId => userId ? tripAdjacentUsersById.get(userId)?.displayName : null)
            .filter(userId => userId)
            .join(', ');

    // TODO it'd be nice if hit.results[0].categories were aggregated and visualized better, like in MapList,
    //   rather than just using the first result

    const placePosition = positionFromLatLng(props.hit.results[0]);
    const distanceInMeters = placePosition && positionSnapshot
        ? getDistance(placePosition, { lat: positionSnapshot.coords.latitude, lng: positionSnapshot.coords.longitude })
        : undefined;

    return <SearchResultComponent
        label='Saved Place'
        onSelected={props.onSelected}
        icon={props.hit.results[0].categories?.includes('Favorite') ? <IconHeart /> : <IconFlag />}
        headline={headlineForPlace(props.hit.results[0])}
        address={addressForPlace(props.hit.results[0])}
        citation={citation}
        distanceInMeters={distanceInMeters}
    />;
}

function EmptyListBanner() {
    return <Text size="sm" color="dimmed">No Results</Text>;
}

function SearchGroupComponent<T extends (SearchEngineResult | SavedPlaceResult | Place)>(props: {
    groupTitle: string | undefined,
    groupType: 'search-candidate' | 'search-engine-suggestion' | 'saved-place',
    hits: T[],
    userTrips: Trip[],
    currentUserId: string | undefined,
    overflowBehavior?: 'truncate' | 'show-all',
    onSelectionChanged: (result: T) => unknown
}) {
    const resultPageSize = 5;
    const [ maxResults, setMaxResults ] = useState(resultPageSize);

    const truncated = props.overflowBehavior === 'show-all' ? props.hits : props.hits.slice(0, maxResults);
    const hasMore = truncated.length !== props.hits.length;
    const groupLabel = (() => {
        switch (props.groupType) {
            case "saved-place":
                return "Saved Places";
            case "search-candidate":
                return "Trip Activities";
            case "search-engine-suggestion":
                return "Search Engine Results";
        }
    })();
    return <>
        { props.groupTitle && props.hits.length === 0 && <Banner text={`${props.groupTitle} (no results)`} /> }
        { props.groupTitle && props.hits.length > 0 && <Banner text={props.groupTitle} /> }
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}
            aria-label={groupLabel}
        >
            {truncated.map((d, i) => {
                switch (props.groupType) {
                    case "search-engine-suggestion":
                        return <SearchEngineResultComponent
                            key={i}
                            hit={d as SearchEngineResult}
                            onSelected={() => props.onSelectionChanged(d)} />;

                    case "saved-place":
                        return <SavedPlaceResultComponent
                            key={i}
                            hit={d as SavedPlaceResult}
                            currentUserId={props.currentUserId}
                            onSelected={() => props.onSelectionChanged(d)} />;

                    case "search-candidate":
                        return <SearchCandidateComponent
                            key={i}
                            hit={d as Place}
                            userTrips={props.userTrips}
                            onSelected={() => props.onSelectionChanged(d)} />;

                    default:
                        return null;
                }
            })}
        </div>
        { !props.groupTitle && props.hits.length === 0 && <EmptyListBanner /> }
        { hasMore &&
            <Text size='xs' color="dimmed" onClick={() => setMaxResults(maxResults + resultPageSize)}>
                Show more ({props.hits.length - truncated.length})
            </Text> }
    </>;
}

type CoreSearchBarProps = {
    places: Place[],
    comments?: PlaceComment[],
    userTrips: Trip[],
    currentUserId: string | undefined,
    notifySearchHits: (hits: SearchResults) => unknown
    bounds?: Bounds
    recentActivitiesEmptyQueryBehavior?: EmptyQueryBehavior
};

export type DetailMode = 'collapsed' | 'filter' | 'results';

function hasFilter(chipConfig: ChipConfig) {
    return chipConfig.searchEngine || chipConfig.savedPlaces || chipConfig.tripChips || chipConfig.recents;
}

export function SearchUI(props: CoreSearchBarProps & {
    onResultSelect: (result: PlaceRecord | SearchEngineResult) => unknown,
    height: 'collapsed' | 'half' | 'full',
    setDetailMode: (mode: DetailMode) => unknown,
    searchQuery: SearchQuery,
    setSearchQuery: (newQuery: SearchQuery) => unknown,
}): JSX.Element | null {
    const searchInputRef = useRef<HTMLInputElement>(null as unknown as HTMLInputElement);

    useEffect(() => {
        if (props.height !== 'full') {
            searchInputRef.current?.blur();
        }
    }, [ props.height ]);

    const detailMode: DetailMode = useMemo(() => {
        switch (props.height) {
            case 'collapsed':
                return 'collapsed';
            case 'half':
                if (props.searchQuery?.hasQuery) {
                    return 'results';
                } else {
                    return 'filter';
                }
            case 'full':
            default:
                return 'results';
        }
    }, [ props.height, props.searchQuery?.hasQuery ]);

    const listUIState = detailMode === 'filter' || detailMode === 'collapsed'
        ? 'controls'
        : 'inline';
    const innards = <SearchChipsAndList
        {...props}
        detailMode={detailMode}
        searchQuery={props.searchQuery}
        uiState={listUIState}
        onClick={props.onResultSelect}
        recentActivitiesEmptyQueryBehavior={props.recentActivitiesEmptyQueryBehavior}
    />;

    const hasFilterSection = hasFilter(props.searchQuery.chipConfig);
    const header = <>
        <div>
            <div className={`${classes.searchRow}`}>
                <TextInput
                    ref={searchInputRef}
                    style={{ flex: "1" }}
                    placeholder='Search...'
                    onFocus={() => props.setDetailMode('results')}
                    onChange={event => props.setSearchQuery(props.searchQuery.setTerm(event.currentTarget.value))}
                    value={props.searchQuery.searchTerm}
                    rightSection={props.searchQuery?.hasQuery && <ClearTextAreaRightSection onClear={() => props.searchQuery.setTerm('')} />}
                    enterKeyHint='done'
                    onKeyUp={blurOnEnterKey}
                />

                { (detailMode === 'results' || detailMode === 'filter') &&
                    <ActionIcon
                        style={{ flex: "0" }}
                        onClick={() => props.setDetailMode('collapsed')}
                    >
                        <IconArrowDown height={24} width={24} className={classes.searchIcon} />
                    </ActionIcon> }
                { detailMode === 'collapsed' &&
                    <ActionIcon
                        style={{ flex: "0" }}
                        onClick={() => {
                            if (hasFilterSection) {
                                // if we can filter, show the filter section after expanding collapsed
                                props.setDetailMode('filter');
                            } else {
                                props.setDetailMode('results');
                            }
                        }}
                    >
                        { hasFilterSection && <IconFilter height={24} width={24} className={classes.searchIcon} /> }
                        { !hasFilterSection && <IconSearch height={24} width={24} className={classes.searchIcon} /> }
                    </ActionIcon> }
            </div>
        </div>
    </>;

    return <div className={classes.inline}>
        {header}
        {innards}
    </div>;
}

export function SearchChipsAndList(
    {
        places,
        comments,
        userTrips,
        currentUserId,
        uiState,
        notifySearchHits,
        onClick,
        searchQuery,
        detailMode,
        bounds,
        recentActivitiesEmptyQueryBehavior,
    }: CoreSearchBarProps & {
        onClick: (result: PlaceRecord | SearchEngineResult) => unknown
        searchQuery: SearchQuery
        uiState: 'hidden' | 'controls' | 'inline'
        detailMode: DetailMode
    }
) {
    const geo = useGeolocationData(false);
    const [ locationSnapshot, setLocationSnapshot ] = useState<Position | undefined>();
    const searchResults = useSearchResults(
        searchQuery,
        places,
        comments,
        locationSnapshot,
        bounds,
        recentActivitiesEmptyQueryBehavior ?? 'match-none');

    useEffect(() => {
        if (searchResults) {
            notifySearchHits(searchResults);
        }
    }, [ uiState, searchResults ]);

    useEffect(() => {
        // snapshot the first location we get, so that we don't constantly update the search results.
        // TODO consider timing this out somehow -- perhaps on UI close?
        if (!locationSnapshot && geo.isGeolocationAvailable && geo.currentPosition) {
            setLocationSnapshot(positionFromLatitudeLongitude(geo.currentPosition.coords));
        }
    }, [ geo.isGeolocationAvailable, geo.isFirstPositionEstablished ]); // we don't depend on geo.currentPosition here, to avoid blowing out effect stack depth

    if (uiState === 'hidden') {
        return null;
    }

    const tripChips = !searchQuery.chipConfig.tripChips ? null : <Chip.Group
            multiple={false}
            value={searchQuery.filters.tripChip}
            onChange={value => searchQuery.setTripChipOption(value as TripChipOption)}>
            <Chip
                size='xs'
                value='all'>
                All
            </Chip>
            <Chip
                size='xs'
                value='today'>
                Today
            </Chip>
            <Chip
                size='xs'
                value='upcoming'>
                Upcoming
            </Chip>
            <Chip
                size='xs'
                value='ideas'>
                Ideas
            </Chip>
        </Chip.Group>;
    const typeChips = <>
            <Chip
                size='xs'
                checked={searchQuery.filters.meals}
                onChange={checked => searchQuery.includeActivityType(PlaceType.Meal, checked)}>
                Food
            </Chip>
            <Chip
                size='xs'
                checked={searchQuery.filters.lodging}
                onChange={checked => searchQuery.includeActivityType(PlaceType.Lodging, checked)}>
                Lodging
            </Chip>
            <Chip
                size='xs'
                checked={searchQuery.filters.flights}
                onChange={checked => searchQuery.includeActivityType(PlaceType.Flight, checked)}>
                Flights
            </Chip>
            <Chip
                size='xs'
                checked={searchQuery.filters.activities}
                onChange={checked => searchQuery.includeActivityType(PlaceType.Activity, checked)}>
                Other
            </Chip>
        </>;
    const sourceChips = <>
        { searchQuery.chipConfig.recents && <Chip
            size='xs'
            checked={searchQuery.sources.recentTrips}
            onChange={() => searchQuery.toggleRecentTrips()}>
            Your History
        </Chip> }
        { searchQuery.chipConfig.savedPlaces && <Chip
            size='xs'
            checked={searchQuery.sources.yourSaved}
            onChange={() => searchQuery.toggleYourSaved()}>
            Saved Places
        </Chip> }
        { searchQuery.chipConfig.savedPlaces && <Chip
            size='xs'
            checked={searchQuery.sources.otherSaved}
            onChange={() => searchQuery.toggleOtherSaved()}>
            Friends&apos; Places
        </Chip> }
        { searchQuery.chipConfig.searchEngine && <Chip
            size='xs'
            checked={searchQuery.sources.searchEngine}
            onChange={() => searchQuery.toggleSearchEngine()}>
            Web Search
        </Chip> }
    </>;

    const hasFilterSection = hasFilter(searchQuery.chipConfig);

    // Don't show the sorts if we have multiple sections -- perhaps that's too confusing
    const hasSortSection = !searchQuery.chipConfig.searchEngine && !searchQuery.chipConfig.savedPlaces;

    const filterChips = !hasFilterSection ? null
        : <>
            <div className={classes.chipsDiv}>
                {tripChips}
            </div>
            <div className={classes.chipsDiv}>
                {typeChips}
            </div>
            <div className={classes.chipsDiv}>
                {sourceChips}
            </div>
        </>;

    const sortChips = <div className={classes.chipsDiv}>
        <Chip
            size='xs'
            checked={searchQuery.sorts.distance}
            onChange={async () => {
                await geo.getPosition();
                searchQuery.toggleSortByDistance(); // TODO add some sort of pending state to the chip
            }}>
            Distance
        </Chip>
        <Chip
            size='xs'
            checked={searchQuery.sorts.rating}
            onChange={() => searchQuery.toggleSortByRating()}>
            Rating
        </Chip>
    </div>;

    const controls = <Accordion
        chevronPosition='right'
        styles={{
            label: {
                paddingTop: 0,
                paddingBottom: 0,
            },

            control: {
                paddingLeft: 0,
                paddingRight: 0,
            },

            content: {
                paddingLeft: 0,
                paddingRight: 0,
            },

            item: {
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
                borderBottom: 0,
            },
        }}
        value={hasFilterSection && detailMode === 'filter' ? 'filter' : undefined}
        >
        { hasFilterSection && <Accordion.Item value='filter'>
            <Accordion.Control icon={<IconFilter size={16} />}>
                <Text size='sm'>Filters and Sources</Text>
            </Accordion.Control>
            <Accordion.Panel>{filterChips}</Accordion.Panel>
        </Accordion.Item> }
        { hasSortSection && <Accordion.Item value='sort'>
            <Accordion.Control icon={<IconArrowsSort size={16} />}>
                <Text size='sm'>Sort By</Text>
            </Accordion.Control>
            <Accordion.Panel>{sortChips}</Accordion.Panel>
        </Accordion.Item> }
    </Accordion>;

    if (uiState === 'controls') {
        return controls;
    }

    const hasRecentsOrProvidedPlaces = (searchQuery.sources.recentTrips || places.length > 0);
    const hasSavedSection = (searchQuery.sources.yourSaved || searchQuery.sources.otherSaved);
    const hasSearchEngineSection = searchQuery.sources.searchEngine;
    const mainGroupTitle = hasRecentsOrProvidedPlaces
        ? searchQuery.chipConfig.recents
            ? 'Recent Places'
            : 'Trip Activities'
        : undefined;
    const tripResults = hasRecentsOrProvidedPlaces &&
        <SearchGroupComponent<Place>
            groupTitle={mainGroupTitle}
            groupType='search-candidate'
            hits={searchResults?.activities ?? []}
            userTrips={userTrips}
            currentUserId={currentUserId}
            overflowBehavior={hasSavedSection || hasSearchEngineSection ? 'truncate' : 'show-all'}
            onSelectionChanged={selection => onClick({ type: 'place', place: selection })}
        />;
    const savedResults = hasSavedSection &&
        <SearchGroupComponent<SavedPlaceResult>
            groupTitle='Saved Places'
            groupType='saved-place'
            hits={searchResults?.query?.hasQueryOrFilter ? searchResults?.savedPlaces ?? [] : []}
            userTrips={userTrips}
            currentUserId={currentUserId}
            onSelectionChanged={selection => onClick({ type: 'place', place: selection.results[0] })}
        />;
    const searchEngineResults = hasSearchEngineSection &&
        <SearchGroupComponent<SearchEngineResult>
            groupTitle='Search Engine Results'
            groupType='search-engine-suggestion'
            hits={searchResults?.searchEngineResults ?? []}
            userTrips={userTrips}
            currentUserId={currentUserId}
            onSelectionChanged={onClick}
        />;

    return <>
        {controls}
        <Sheet.Scroller style={{ flex: '1' }}>
            <div
                className={`${classes.results} keyboard-height-padded`}
                style={{ display: 'flex', flexDirection: 'column', flex: '1' }}
            >
                {tripResults}
                {savedResults}
                {searchEngineResults}
            </div>
        </Sheet.Scroller>
    </>;
}
