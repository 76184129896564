import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Group,
    ActionIcon,
    Text,
    Card,
    Rating,
    Menu,
    Textarea,
    Box,
} from '@mantine/core';
import { useRouter } from 'next/navigation';
import { IconCheck, IconEdit, IconMap, IconPlus } from "@tabler/icons-react";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context";
import { Flag, Heart } from "framework7-icons-plus/react";
import { useFirebaseRefs } from "../../lib/firebase";
import { RelatedPlaces } from "../RelatedPlaces";
import { PlaceImageGallery, usePlaceImages } from '../SavedPlacesAppShell/PanelImageGallery';
import { Place } from '../../lib/interfaces';
import { TripContext, UserContext } from '../../lib/context';
import { useSavedPlaceById, useUserById } from "../../lib/hooks";
import { SAVED_PLACES_TAB_NAME } from "../TravelScrollMenu";
import { TSRightSideMenu } from "../TSAppShell/TSRightSideMenu";
import { showModalAsync } from "../TSModal";
import { TSSheet } from "../TSSheet/TSSheet";
import { AddEditPlace, showAddPlace } from "../AddEditPlace/addeditplace";
import { deletePlace, updateSavedPlace } from "../../lib/placeFunctions";
import { SheetsContext, SheetState } from "../Sheets";
import { TSLoader } from "../TSLoader";
import { TSScrollable } from "../TSScrollable";
import { raceOrNotify } from "../../lib/promises";
import { useKeyboardScrollEffect } from '../../lib/keyboard';
import { TSAppShell } from "../TSAppShell/TSAppShell";
import { ActivityIcon } from "../PlaceDetailIcons";

function showInMapClicked(router: AppRouterInstance, place: Place | undefined) {
    const userId = place?.creatorId ?? place?.userid;
    if (place?.docid && userId) {
        router.push(`/traveler/${userId}?tab=Map&place=${place?.docid}`);
    }
}

export function SavedPlaceScreen({ uid, docid }: { uid: string, docid: string }): JSX.Element {
    const sheets = useContext(SheetsContext);
    const firebaseRefs = useFirebaseRefs();
    const place = useSavedPlaceById(uid, docid);
    const screenUser = useUserById(uid);
    const router = useRouter();
    const user = useContext(UserContext);
    const [ mode, setMode ] = useState<'view' | 'edit'>('view');
    const [ sheetState, setSheetState ] = useState<SheetState>();

    useEffect(() => {
        if (mode === 'view') {
            setSheetState(undefined);
        }
    }, [ mode ]);

    const placeImages = place?.tripdocid ? usePlaceImages({ docid: place.tripdocid }, place) : [];

    return (
        <TSAppShell
            ariaLabel='Saved Place'
            header={{
                title: screenUser ? `${screenUser.displayName} - ${place?.categories![0]}` : undefined,
                rightSide: !!screenUser?.uid && screenUser.uid === user.firebaseUserId && <TSRightSideMenu
                        ariaLabel='Edit Saved Place'
                    >
                        <Menu.Dropdown>
                            <Menu.Item
                                leftSection={<IconEdit height={18} />}
                                onClick={() => setMode('edit')}>
                                Edit Saved Place
                            </Menu.Item>
                            <Menu.Item
                                leftSection={<IconEdit height={18} />}
                                onClick={async () => {
                                    if (place) {
                                        const response = await showModalAsync(
                                            sheets,
                                            "Confirm Deletion",
                                            [
                                                { title: 'Cancel', id: 'cancel' },
                                                { title: 'Remove', type: 'destructive', id: 'remove' },
                                            ],
                                            `Are you sure you want to delete ${place?.title}`
                                        );
                                        if (response.type === 'choice' && response.choice.id === 'remove') {
                                            deletePlace(firebaseRefs, place);
                                            router.push(`/?tab=${SAVED_PLACES_TAB_NAME}`);
                                        }
                                    }
                                }}>
                                Delete Saved Place
                            </Menu.Item>
                        </Menu.Dropdown>
                    </TSRightSideMenu>,
            }}
        >
            { !place && <TSLoader id='SavedPlaceScreen' layout='fill-content-area' /> }
            { place && <>
                <TSSheet
                    onOpenEnd={() => setSheetState('opened')}
                    isOpen={mode === 'edit'}
                    onClose={() => setMode('view')}
                    title={`Edit ${place.title}`}>
                    <AddEditPlace
                        onClose={() => setMode('view')}
                        place={place}
                        sheetState={sheetState ?? (mode === 'edit' ? 'opening' : 'closed')}
                    />
                </TSSheet>
                <div style={{ height: '100%' }} className='tscroll-appshell-main'>
                    <TSScrollable keyboardHeightAware>
                        <PlaceImageGallery placeImages={placeImages} place={place} panel={false} tripUsers={[]} />
                        <Text style={{ textAlign: 'center' }} px="md" pt='md' fw={500}>{place.location}</Text>
                        <Text
                            style={{ textAlign: 'center' }}
                            px='md'
                            onClick={() => showInMapClicked(router, place)}
                            color='dimmed'
                            size="sm"
                        >
                            {place.address}
                        </Text>
                        <SavedPlaceBottom place={place} showMapIcon />
                    </TSScrollable>
                </div>
            </> }
        </TSAppShell>
    );
}

SavedPlaceScreen.debugId = 'SavedPlace';

export function SavedPlaceBottom({ place, showMapIcon }: { place: Place, showMapIcon: boolean }) {
    const sheets = useContext(SheetsContext);
    const userData = useContext(UserContext);
    const router = useRouter();
    const { currentTrip } = useContext(TripContext);
    const category = place.categories && place.categories.length > 0
        ? place.categories[0]
        : undefined;

    return <div key={place.docid}>
        <Group px="md" pt="md" justify="space-between">
            <RatingBlock place={place} />
            <div style={{ display: 'flex', gap: '1rem' }}>
                <div style={{ paddingTop: 4 }}>
                    <ActivityIcon activity={place} fallback={false} />
                </div>
                <Box pt={4}>
                    { category === 'Favorite' && <Heart height={24} width={24} /> }
                    { category === 'Want to go' && <Flag height={24} width={24} /> }
                </Box>
                { showMapIcon && <ActionIcon
                    onClick={() => showInMapClicked(router, place)}
                    variant='outline'
                    radius='xl'
                    size='lg'
                >
                    <IconMap size={18} />
                </ActionIcon> }
                <ActionIcon
                    onClick={async () => {
                        if (userData.status === 'logged-out') {
                            const choice = await showModalAsync(
                                sheets,
                                'Log In / Sign Up',
                                [
                                    { title: 'Log In', href: `/enter?redirect=${document.location.href}` },
                                    { title: 'Sign Up', href: `/enter?redirect=${document.location.href}` },
                                ]
                            );
                            if (choice.type === 'choice') {
                                router.push(choice.choice.href);
                            }
                        } else {
                            showAddPlace(sheets, place, currentTrip);
                        }
                    }}
                    variant='outline'
                    radius='xl'
                    size='lg'
                >
                    <IconPlus size={18} />
                </ActionIcon>
            </div>
        </Group>
        <SavedPlaceReview place={place} />
        <RelatedPlaces place={place} />
        <SavedAttribution place={place} />
    </div>;
}

function SavedPlaceReview(props: { place: Place }) {
    const firebaseRefs = useFirebaseRefs();
    const userInfo = useContext(UserContext);
    const [ review, setReview ] = useState<string | null>(props.place.review);
    const reviewTextAreaRef = useRef<HTMLTextAreaElement>(null as unknown as HTMLTextAreaElement);
    useKeyboardScrollEffect(reviewTextAreaRef);

    const editable = userInfo.firebaseUserId === props.place.userid;

    if (editable) {
        const reviewModified = (review ?? '') !== (props.place.review ?? '');
        return <Card m='md' withBorder>
            <Text style={{ textAlign: "left" }} mb="xs" fw={500}>Review</Text>
            <div style={{ display: 'flex', gap: '0.5rem' }}>
                <Textarea
                    style={{ flex: '1' }}
                    size="sm"
                    ref={reviewTextAreaRef}
                    color="dimmed"
                    value={review ?? ''}
                    onChange={event => {
                        setReview(event.currentTarget?.value);
                    }}
                />
                <ActionIcon
                    style={{
                        alignSelf: 'end',
                        backgroundColor: reviewModified ? 'green' : undefined,
                    }}
                    onClick={() => {
                        if (reviewModified) {
                            raceOrNotify(
                                updateSavedPlace(firebaseRefs, userInfo, props.place.docid!, { review }),
                                500,
                                `Saved changes to ${props.place.title ?? props.place.location}`,
                                `Failed to save changes to ${props.place.title ?? props.place.location}`
                            );
                        }
                    }}>
                    <IconCheck
                        size={24}
                        color={reviewModified ? 'white' : 'grey'}
                    />
                </ActionIcon>
            </div>
        </Card>;
    } else if (props.place.review && props.place.review.trim().length > 0) {
        return <Card m='md' withBorder>
            <Text style={{ textAlign: 'left' }} mb="xs" fw={500}>Review</Text>
            <Text style={{ textAlign: 'left' }} size="sm" color="dimmed">{props.place.review}</Text>
        </Card>;
    } else {
        return null;
    }
}

function RatingBlock(props: { place: Place }) {
    const firebaseRefs = useFirebaseRefs();
    const userInfo = useContext(UserContext);

    const convertedRatingNumber = (props.place.rating ?? 1) - 1;
    const isEditable = userInfo.firebaseUserId === props.place.userid;

    const onChange = (newValue: number | undefined) => {
        if (newValue === undefined) {
            if (props.place.rating !== null) {
                updateSavedPlace(firebaseRefs, userInfo, props.place.docid!, { rating: null });
            }
        } else {
            const deconvertedRating = newValue + 1;
            if (isEditable && deconvertedRating !== props.place.rating) {
                updateSavedPlace(firebaseRefs, userInfo, props.place.docid!, { rating: deconvertedRating });
            }
        }
    };

    return <Group gap={5}>
        <Rating defaultValue={0} value={convertedRatingNumber} count={3} readOnly={!isEditable} onChange={onChange} />
        { convertedRatingNumber === 0 && <Text size='sm' color='dimmed'>Unrated</Text> }
        { convertedRatingNumber !== 0 &&
            <Text size='sm' color='dimmed' onClick={() => onChange(undefined)}>
                Clear rating
            </Text> }
    </Group>;
}

function SavedAttribution(props: { place: Place }) {
    const userInfo = useContext(UserContext);
    const isSelf = userInfo.firebaseUserId === props.place.userid;
    const user = isSelf ? null : useUserById(props.place.userid ?? undefined);

    if (!isSelf && !user) {
        // wait for the user to get loaded before rendering anything, to avoid data flicker
        return null;
    } else {
        const attribution = isSelf ? 'You' : user?.displayName;
        return <Text
            style={{ paddingLeft: '1.0rem', paddingRight: '1.0rem' }}
            size='sm'
            color='dimmed'>
            {attribution} saved this {props.place.created?.toRelative()}
        </Text>;
    }
}
