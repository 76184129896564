import { terminate, waitForPendingWrites } from "@firebase/firestore";
import { useColorScheme } from "@mantine/hooks";
import { AppProps } from "next/app";
import Head from "next/head";
import { createTheme, MantineProvider } from "@mantine/core";
import { Notifications } from '@mantine/notifications';
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Capacitor, PluginListenerHandle } from "@capacitor/core";
import { StatusBar, Style } from '@capacitor/status-bar';
import { ScreenOrientation as ScreenOrientationCap } from "@capacitor/screen-orientation";
import { App, AppState } from "@capacitor/app";
import { CapacitorNativeSupport } from "@travelscroll/capacitor-native-support/src";
import useLatest from "use-latest";
import { DeferredLoad } from "../components/DeferredLoad";
import { useToday } from "../components/TripActivityScreen/dates";
import { FirebaseRefs, newFirebaseRefs, pauseFirebase, resumeFirebase } from "../lib/firebase";
import { usePlacesDataContextState, useUserDataContextState } from "../lib/hooks";
import {
    BackgroundJobContext,
    FirebaseContext,
    HomeContext,
    AllTripActivitiesContext,
    UserContext,
} from "../lib/context";
import {
    ConnectivityContext,
    useConnectivityDataContextState,
} from "../lib/connectivity";
import { getLogger, initLogger } from "../lib/logger";
import ErrorBoundary from "../components/ErrorBoundary";
import { useAllTripActivitiesContextState } from "../lib/placeFunctions";
import { race } from "../lib/promises";
import { usePushNotifications } from "../lib/PushNotifications";
import { newSheetsData, Sheets, SheetsContext, SheetsData } from "../components/Sheets";
import TravelerHistory from "./traveler/[uid]/history";
import { summarize } from "../lib/activitySummarizer";
import { useTouchMagnifierSuppressor } from "../lib/touchHandlers";
import { useKeyboardInfo } from '../lib/keyboard';
import { BackgroundJobs, useBackgroundJobsContextState } from '../lib/background-jobs';
import { useLocationTracker } from "../lib/visa-analysis";

import '@mantine/carousel/styles.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';

import './_app.css';
import HomePage from "./index";

initLogger(); // we do this before the React lifecycle starts so that all our early lifecycle is captured in logs
const logger = getLogger('app');

const theme = createTheme({
    primaryShade: 8,

    /**
     These sizes are tuned for iOS. Of particular note: if an input box is smaller than 16pt, iOS will
     automatically zoom into the app a bit when the input box has focus. Many of the Mantine elements
     default to 'sm', so we use 1rem here, as that lines up with 16pt.
     */
    fontSizes: {
        xs: '0.8rem',
        sm: '1rem',
        md: '1.2rem',
        lg: '1.5rem',
        xl: '1.8rem',
    },
});

type DatabaseState = 'ready' | 'paused' | 'timeout' | 'failed';

let lastReloadHackTimestamp = 0;
export default function TravelScrollApp(props: AppProps) {
    return <ErrorBoundary>
        <TravelScrollAppBasics {...props} />
    </ErrorBoundary>;
}

function TravelScrollAppBasics(props: AppProps) {
    const [ sheets ] = useState<SheetsData>(() => newSheetsData());
    const backgroundJobs = useBackgroundJobsContextState();
    const { keyboardInfo } = useKeyboardInfo();

    const [ appStateHasBeenActive, setAppStateHasBeenActive ] = useState(false);
    const [ errorStatus, setErrorStatus ] = useState<'normal' | 'error'>('normal');

    const { databaseState, firebaseRefs } = useFirebaseMaintainer(errorStatus);

    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            ScreenOrientationCap.lock({ orientation: 'portrait' });
            StatusBar.setStyle({ style: Style.Dark });
            // TODO Lock Web Screen Orientation to Portrait if not native
        }
        const onAppState = (appState: AppState) => {
            if (appState.isActive) {
                setAppStateHasBeenActive(true);
            }
        };
        App.getState().then(onAppState);
        const listener = App.addListener("appStateChange", onAppState);

        if (Capacitor.getPlatform() !== 'android') {
            // TODO implement an Android plugin
            CapacitorNativeSupport.getProcessIds().then(processIds => {
                logger.log(`Process IDs: current=${processIds?.currentProcessId}, initial=${processIds?.initialProcessId}`);
            });
        }

        const deregistration = registerCapacitorAppListeners();
        return () => {
            deregistration();
            listener.remove();
        };
    }, []);

    useEffect(() => {
        const height = keyboardInfo?.keyboardHeight ?? 0;
        document.body.style.setProperty('--keyboard-height', height === 0 ? '0' : `${height}px`);
    }, [ keyboardInfo ]);

    useEffect(() => {
        logger.log(`Loaded page '${document.location.pathname}${document.location.search}'. Component: ${(props.Component as any).debugId ?? props.Component.name}`);
    }, [ global.document?.location.pathname, global.document?.location.search, props.Component ]);

    useEffect(() => {
        if (document.location.pathname !== '/' && props.Component === HomePage) {
            const componentName = (props.Component as any).debugId ?? props.Component.name;
            const reloadDelta = Date.now() - lastReloadHackTimestamp;
            if (reloadDelta > 10000) {
                // For reasons as yet unknown, sometimes the native client launches into an inconsistent state,
                // in which the location is a previously-navigated-to value, but the component does not reflect
                // that state. This logic detects that inconsistency and hacks around it. Unsatisfying, but effective.
                // We do this at most once every 10 seconds to avoid redirect hell.
                logger.warn(`\
Detected a mismatch between the NextJS component and the document location!\
 Re-navigating to the index page. Pathname: '${document.location.pathname}',\
 window pathname: '${window.location.pathname}', component: ${componentName}`);
                document.location = '/';
                lastReloadHackTimestamp = Date.now();
            } else {
                logger.warn(`\
Detected a mismatch again! Ignoring to avoid a redirect cycle. Pathname: '${document.location.pathname}',\
 window pathname: '${window.location.pathname}', component: ${componentName}, time since last: ${reloadDelta}`);
            }
        }
    }, [ global.document?.location.pathname, props.Component ]);

    useTouchMagnifierSuppressor();

    // We render the basics even when the app has never been active so that we avoid a flash
    // during initial load.
    return <DeferredLoad
        trigger={appStateHasBeenActive}
        provider={() =>
            <ErrorBoundary onError={() => setErrorStatus('error')}>
                <FirebaseContext.Provider value={firebaseRefs}>
                    <ActiveApp
                        {...props}
                        sheets={sheets}
                        backgroundJobs={backgroundJobs}
                    />
                    <DatabaseStateOverlay databaseState={databaseState} />
                </FirebaseContext.Provider>
            </ErrorBoundary>
        }
        fallback={<OuterParts>
            <div />
        </OuterParts>}
    />;
}

function useFirebaseMaintainer(errorStatus: 'normal' | 'error') {
    const [ databaseState, setDatabaseState ] = useState<DatabaseState>('ready');
    const [ firebaseRefs, setFirebaseRefs ] = useState<FirebaseRefs>(
        () => newFirebaseRefs('React container initialization'));
    const [ databaseInitializerLock, setDatabaseInitializerLock ] = useState(false);
    const latestFirebaseFirestore = useLatest(firebaseRefs.firestore);

    useEffect(() => {
        if (databaseInitializerLock) {
            logger.log(`Ignoring database state transition -- database re-initialization is ongoing. databaseState=${databaseState}, errorStatus=${errorStatus}`);
            return;
        }

        if (databaseState === 'timeout') {
            logger.warn("Firestore network resumption timed out! Re-loading page");
            document.location.reload();
        } else if (databaseState === 'failed' || errorStatus === 'error') {
            logger.log(`Starting database re-initialization due to earlier failure. databaseState=${databaseState}, errorStatus=${errorStatus}`);
            setDatabaseInitializerLock(true);
            (async () => {
                try {
                    await waitForPendingWrites(latestFirebaseFirestore.current);
                } catch (err) {
                    logger.log(`Trapped an error waiting for pending writes: ${err}`);
                }

                try {
                    await terminate(latestFirebaseFirestore.current);
                } catch (err) {
                    logger.log(`Trapped an error terminating firestore: ${err}`);
                }
                try {
                    const newRefs = newFirebaseRefs('database failure');
                    setFirebaseRefs(newRefs);
                    setDatabaseState('ready');
                    logger.log("Completed database re-initialization due to earlier failure");
                } catch (err) {
                    logger.warn(`Failed to re-initialize database after earlier failure: ${err}`);
                    throw err;
                } finally {
                    setDatabaseInitializerLock(false);
                }
            })();
        }
    }, [ databaseState, errorStatus ]); // we ignore databaseInitializerLock since we mutate it in here as well

    useEffect(() => {
        return registerFirebaseAppListeners(firebaseRefs, setDatabaseState);
    }, [ firebaseRefs ]);

    (global as any).setDatabaseState = setDatabaseState;

    return { databaseState, firebaseRefs };
}

function OuterParts(props: PropsWithChildren) {
    return <>
        <Head>
            <title>Travel Scroll</title>
            <link rel="shortcut icon" href="/favicon.svg" />
            <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, maximum-scale=1, user-scalable=no, width=device-width, viewport-fit=cover"
            />
        </Head>

        {props.children}
    </>;
}

function DatabaseStateOverlay(props: { databaseState: DatabaseState }) {
    switch (props.databaseState) {
        case 'paused':
        case 'timeout':
        case 'failed':
            return <div className='database-state-overlay' />;
        case 'ready':
            return null;
    }
}

function ActiveApp(
    {
        Component,
        pageProps,
        sheets,
        backgroundJobs,
    }: AppProps & {
        sheets: SheetsData,
        backgroundJobs: BackgroundJobs,
    }
) {
    const windowPrefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    const systemColorScheme = useColorScheme(windowPrefersDark.matches ? 'dark' : 'light');
    const colorScheme = Component === TravelerHistory ? 'dark' : systemColorScheme;

    const connectivityData = useConnectivityDataContextState();
    const userData = useUserDataContextState();
    const placesData = usePlacesDataContextState(userData);
    const allTripActivities = useAllTripActivitiesContextState(userData);

    const today = useToday();

    usePushNotifications(userData?.firebaseUserId ?? undefined);
    useLocationTracker(userData);

    const travelSummary = useMemo(
        () => userData.status === 'logged-in' && Capacitor.isNativePlatform()
            ? summarize(
                [ ...(allTripActivities.today ?? []), ...(allTripActivities.future ?? []) ],
                placesData.userTrips ?? [],
                today)
            : undefined,
        [ userData.status, placesData.userTrips, allTripActivities.future, allTripActivities.today, today ]
    );
    useEffect(() => {
        Capacitor.getPlatform() !== 'android' && CapacitorNativeSupport.updateWidgetData({ summary: travelSummary ? JSON.stringify(travelSummary) : undefined }); // TODO create an android impl
    }, [ travelSummary ]);

    return (
        <MantineProvider theme={theme} forceColorScheme={colorScheme}>
            <SheetsContext.Provider value={sheets}>
                <UserContext.Provider value={userData}>
                    <HomeContext.Provider value={placesData}>
                        <ConnectivityContext.Provider value={connectivityData}>
                            <AllTripActivitiesContext.Provider value={allTripActivities}>
                                <BackgroundJobContext.Provider value={backgroundJobs}>
                                    <OuterParts>
                                        <Component {...pageProps} />
                                        <Notifications position='top-center' className='safeAreaPaddingTop' aria-label='Notification' />
                                        <Sheets />
                                    </OuterParts>
                                </BackgroundJobContext.Provider>
                            </AllTripActivitiesContext.Provider>
                        </ConnectivityContext.Provider>
                    </HomeContext.Provider>
                </UserContext.Provider>
            </SheetsContext.Provider>
        </MantineProvider>
    );
}

let capacitorListenerGenerator = 0;
function registerCapacitorAppListeners() {
    const handles: PluginListenerHandle[] = [];

    const listenerIndex = capacitorListenerGenerator++;
    const logPrefix = `capacitor[${listenerIndex}]`;

    logger.log(`${logPrefix} Registering listeners`);
    if (Capacitor.isNativePlatform()) {
        App.getInfo().then(appInfo => logger.log(
            `${logPrefix} appInfo name=${appInfo.name}, id=${appInfo.id}, version=${appInfo.version}, build=${appInfo.build}`));
        App.getState().then(appState => logger.log(`${logPrefix} initial appState.isActive=${appState.isActive}`));
    }
    App.getLaunchUrl().then(launchUrl => logger.log(`${logPrefix} Initial app launch url: ${launchUrl?.url}`));

    handles.push(App.addListener('appStateChange', state => logger.log(`${logPrefix} appState.isActive=${state.isActive}`)));
    handles.push(App.addListener('appUrlOpen', event => logger.log(
        `${logPrefix} appUrlOpen iosOpenInPlace=${event.iosOpenInPlace}, iosSourceApplication=${event.iosSourceApplication}, url=${event.url}`)));
    handles.push(App.addListener('appRestoredResult', event => logger.log(
        `${logPrefix} restored pluginId=${event.pluginId} methodName=${event.methodName} success=${event.success} error.message=${event.error?.message}`)));

    handles.push(App.addListener('backButton', (event) => {
        if (event.canGoBack) {
            window.history.back();
        } else {
            App.exitApp();
        }
    }));

    return () => {
        handles.forEach((h) => h.remove());
        logger.log(`${logPrefix} Removed listeners`);
    };
}

function registerFirebaseAppListeners(firebaseRefs: FirebaseRefs, setDatabaseState: (state: DatabaseState) => void) {
    const handles: PluginListenerHandle[] = [];

    let cancelFunc: () => unknown;
    handles.push(App.addListener('pause', () => {
        if (Capacitor.isNativePlatform()) {
            // We've only seen the Firestore consistency issues with background execution
            // in iOS. Presumably Android suffers the same behavior, but web perhaps does
            // not, due to its different network permissions model.
            cancelFunc = pauseFirebase(
                firebaseRefs,
                () => setDatabaseState('paused'),
                error => {
                    logger.warn(`Trapped an error pausing Firebase -- will reconnect. Error: ${error}`);
                    setDatabaseState('failed');
                }
            );
        }
    }));
    handles.push(App.addListener('resume', () => {
        if (Capacitor.isNativePlatform()) {
            if (cancelFunc) {
                cancelFunc();
            }
            race(
                (async () => {
                    try {
                        return await resumeFirebase(firebaseRefs);
                    } catch (error) {
                        logger.warn(`Trapped an error resuming Firebase -- will reconnect. Error: ${error}`);
                        setDatabaseState('failed');
                        return 'database-failure';
                    }
                })(),
                5000
            )
                .then(result => {
                    if (result.status === 'completed') {
                        if (result.value !== 'database-failure') {
                            setDatabaseState('ready');
                        }
                    } else {
                        setDatabaseState('timeout');
                    }
                });
        }
    }));

    return () => {
        handles.forEach((h) => h.remove());
    };
}
