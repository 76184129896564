import { Indicator } from "@mantine/core";
import { useEffect, useState } from "react";
import { TSDate } from "../../lib/time";

export function datePickerDayRenderer(date: Date, today: TSDate) {
    const tsdate = TSDate.fromDate(date);
    if (tsdate?.equals(today) ?? false) {
        return <Indicator
            size={6}
            color="orange"
            style={{ fontWeight: 'bold' }}
            styles={{
                indicator: {
                    top: "6px",
                    right: "-6px",
                },
            }}
        >
            {date.getDate()}
        </Indicator>;
    } else {
        return date.getDate();
    }
}

export function useToday() {
    const [ today, setToday ] = useState<TSDate>(TSDate.today());

    useEffect(() => {
        const now = Date.now();
        const localToday = TSDate.today();
        const midnight = localToday.toLocalJSDate().getMilliseconds();
        const timeouts = new Set<NodeJS.Timeout>();
        const setTimeouts = () => {
            timeouts.add(setTimeout(
                () => setToday(TSDate.today()),
                midnight - now + 1000 // one second past midnight
            ));
            timeouts.add(setTimeout(
                () => {
                    setToday(TSDate.today());
                    setTimeouts();
                },
                midnight - now + 10000 // ten seconds past midnight, in case we have timer skew
            ));
        };
        return () => {
            timeouts.forEach(timeout => clearTimeout(timeout));
        };
    }, []);

    return today;
}
