import { ActionIcon, Title } from "@mantine/core";
import Sheet, { SheetRef } from "react-modal-sheet";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { IconCircleX } from "@tabler/icons-react";
import { sheetsMountPointId } from "../Sheets";

import classes from "./TSSheet.module.css";

export function TSSheetHeader(props: { title?: string, showCloseIcon?: boolean, onClose?: () => void }) {
    const needsClose = props.showCloseIcon ?? true;
    return <Sheet.Header
        className={classes.sheetHeader}>
        { (props.title || needsClose) && <div style={{
            width: '100%',
            paddingBottom: '1rem',
            display: 'flex',
            alignItems: 'center',
        }}>
            { needsClose && <div style={{ width: '48px' }} /> }
            { props.title
                ? <Title
                    size='lg'
                    style={{
                        textAlign: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingTop: '0.5rem',
                        textOverflow: 'ellipsis',
                        flex: '1',
                    }}
                    >
                    {props.title}
                </Title>
                : <div style={{ marginLeft: 'auto' }} />
            }
            { needsClose && <ActionIcon
                size={48}
                onClick={() => props.onClose && props.onClose()}
            >
                <IconCircleX size={30} />
            </ActionIcon> }
        </div> }
    </Sheet.Header>;
}

export function TSSheet(props: {
    title?: string,
    isOpen: boolean,
    onOpenEnd?: () => void,
    onClose: () => void,
    rootId?: string,
    detent?: 'full-height' | 'content-height',
    disableDrag?: boolean,
    showCloseIcon?: boolean,
} & PropsWithChildren) {
    const sheetRef = useRef<SheetRef>();
    const [ internalIsOpen, setInternalIsOpen ] = useState(false);

    useEffect(() => {
        // Assign the open status only after we've mounted the sheet, so that we get a nice sheet animation
        if (sheetRef.current) {
            setInternalIsOpen(props.isOpen);
        }
    }, [ sheetRef.current, props.isOpen ]);

    const mountPoint = document.getElementById(sheetsMountPointId);
    return <Sheet
        style={{
            // '300' is what Mantine uses for its headers and footers.
            // This puts the sheets above the footer, which is what we
            // generally want. Our map sheets use different values, as
            // they should keep the footer visible.
            zIndex: 300,
        }}
        ref={sheetRef}
        disableDrag={props.disableDrag}
        isOpen={internalIsOpen}
        onOpenEnd={props.onOpenEnd}
        onClose={props.onClose}
        detent={props.detent ?? 'full-height'}
        rootId={props.rootId}
        mountPoint={mountPoint ?? undefined}
    >
        <Sheet.Container>
            <TSSheetHeader title={props.title} onClose={props.onClose} showCloseIcon={props.showCloseIcon} />
            <Sheet.Content
                className={classes.sheetContent}
                disableDrag={props.disableDrag}
            >
                {props.children}
            </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop style={{ backgroundColor: '#00000055' }}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div style={{
                width: '100%',
                height: '100%',
                pointerEvents: 'all',
            }}
                 onClick={props.onClose} />
        </Sheet.Backdrop>
    </Sheet>;
}
