import React, { createContext, useContext, useEffect, useState } from "react";
import { TSSheet } from "./TSSheet/TSSheet";

export type SheetsData = {
    showSheetWith: (content: SheetContentProvider, sheetOptions?: SheetOptions) => unknown
};

type InternalSheetsData = SheetsData & {
    removeSheetWith: (content: SheetContentProvider) => unknown
    sheetProviders: { provider: SheetContentProvider, sheetOptions?: SheetOptions }[]
    sheetRenderers: ((providers: { provider: SheetContentProvider, sheetOptions?: SheetOptions }[]) => unknown)[]
};

export const SheetsContext = createContext<SheetsData>(null as unknown as SheetsData);
export type SheetState = 'closed' | 'opening' | 'opened';
export type SheetContentContext = {
    /**
     * Call this function to close the sheet due to a user action, such as tapping
     * a completion button in the UI of the sheet.
     */
    removeSheet: () => void

    sheetState: 'closed' | 'opening' | 'opened'
};
export type SheetOptions = {
    title?: string,

    /**
     * The detent for the sheet. If unset, 'full-height' will be used.
     */
    detent?: 'full-height' | 'content-height',

    /**
     * Invoked when the sheet is closed by the user, either by shrinking its size
     * to 0 or by tapping outside the sheet. There is no need to call
     * {@link SheetContentContext.removeSheet} in this case.
     */
    onSheetClosed?: () => unknown,

    disableDrag?: boolean,

    showCloseIcon?: boolean,
};
export type SheetContentProvider = (context: SheetContentContext) => JSX.Element;

export function newSheetsData(): SheetsData {
    const data: InternalSheetsData = {
        showSheetWith: (content: SheetContentProvider, sheetOptions?: SheetOptions) => {
            data.sheetProviders.push({ provider: content, sheetOptions });
            data.sheetRenderers.forEach(c => c([ ...data.sheetProviders ]));
        },
        removeSheetWith: (content: SheetContentProvider) => {
            data.sheetProviders = data.sheetProviders
                .filter(p => p.provider !== content);
            data.sheetRenderers.forEach(c => c([ ...data.sheetProviders ]));
        },
        sheetProviders: [],
        sheetRenderers: [],
    };
    return data;
}
export const sheetsMountPointId = 'sheets-mount-point';

// As of Mantine 7, the app shell is positioned differently, and so we need to explicitly
// put the sheets mount point inside the app shell instead of relying on HTML ordering as
// we did previously. Once we consolidate our app shells, this will be a non-issue.
export function SheetsMountPoint() {
    return <div id={sheetsMountPointId} />;
}

export function Sheets() {
    const sheets = useContext(SheetsContext) as InternalSheetsData;

    const [ sheetsToRender, setSheetsToRender ] = useState<{ provider: SheetContentProvider, sheetOptions?: SheetOptions }[]>([]);

    useEffect(() => {
        if (sheets?.sheetRenderers) {
            sheets.sheetRenderers.push(setSheetsToRender);
            return () => {
                sheets.sheetRenderers = sheets.sheetRenderers.filter(c => c !== setSheetsToRender);
            };
        } else {
            return () => { };
        }
    }, [ sheets ]);

    return <>{
        sheetsToRender.map((provider, index) =>
            <RenderedSheet key={index} sheets={sheets} provider={provider.provider} sheetOptions={provider.sheetOptions} />)
     }</>;
}

function RenderedSheet({ sheets, provider, sheetOptions }: {
    sheets: InternalSheetsData,
    provider: SheetContentProvider,
    sheetOptions?: SheetOptions
}) {
    const [ sheetState, setSheetState ] = useState<SheetState>('opening');

    const removeSheet = () => sheets?.removeSheetWith(provider);
    const context: SheetContentContext = { removeSheet, sheetState };
    const content = provider(context);

    return <TSSheet
        title={sheetOptions?.title}
        isOpen
        onOpenEnd={() => setSheetState('opened')}
        detent={sheetOptions?.detent ?? 'full-height'}
        disableDrag={sheetOptions?.disableDrag}
        showCloseIcon={sheetOptions?.showCloseIcon}
        onClose={() => {
            setSheetState('closed');
            removeSheet();
            if (sheetOptions?.onSheetClosed) {
                sheetOptions.onSheetClosed();
            }
        }}
    >
        {content}
    </TSSheet>;
}
