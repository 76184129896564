import {
    DaySummary,
    TravelSummary }
    from "@travelscroll/capacitor-native-support";
import { Place, PlaceType, Trip } from "./interfaces";
import { TSDate } from "./time";

export function summarize(activities: Place[] | undefined, trips: Trip[], today: TSDate): TravelSummary {
    // TODO include the idea count in the current trip
    const yesterday = today.minus({ days: 1 });
    const candidates = (activities ?? [])
        .filter(p => (p.activityType !== PlaceType.Hotel && p.activityType !== PlaceType.Lodging)
            && (p.startdatetime?.isAfter(yesterday) || (
                p.startdatetime?.isBefore(today) && p.enddatetime?.isAfter(yesterday))));

    const sortedTrips = [ ...trips ]
        .filter(t => t.startdate?.isAfter(today))
        .sort((a, b) => (a.startdate?.daysSinceEpoch ?? 0) - (b.startdate?.daysSinceEpoch ?? 0));
    const nextTrip = sortedTrips.length > 0 && !!sortedTrips[0].title && !!sortedTrips[0].startdate
        ? { trip: { title: sortedTrips[0].title }, startDate: sortedTrips[0].startdate.toString()! }
        : undefined;

    if (candidates.length === 0) {
        if (nextTrip) {
            return [ { date: nextTrip.startDate, trip: nextTrip.trip } ];
        } else {
            return [];
        }
    } else {
        const daySummaries = new Map<string, DaySummary>();

        for (let i = 0; i < 5; i++) {
            const target = today.plus({ days: i });
            const dayCandidates = candidates
                .filter(p => (p.startdatetime?.equals(target)
                    || (p.startdatetime?.isBefore(target) && p.enddatetime?.isAfter(target.minus({ days: 1 })))));
            if (dayCandidates.length > 0) {
                daySummaries.set(target.toString()!, { date: target.toString()!, activities: dayCandidates.length });
            }
        }

        if (nextTrip) {
            const nextTripDaySummary = daySummaries.get(nextTrip.startDate);
            if (nextTripDaySummary) {
                nextTripDaySummary.trip = nextTrip.trip;
            } else {
                daySummaries.set(nextTrip.startDate, { date: nextTrip.startDate, trip: nextTrip.trip });
            }
        }

        return [ ...daySummaries.values() ].sort((a, b) => a.date.localeCompare(b.date));
    }
}
