import { ActionIcon, Input, Loader, Text } from "@mantine/core";
import { IconSquareRoundedChevronUp, IconSquareRoundedPlus } from "@tabler/icons-react";
import { Delayed } from "../Delayed";
import placeClasses from "../SavedPlacesList/SavedPlacesList.module.css";

type SearchResult<T> = {
    record: T,
    headline?: string,
    secondaryLine?: string,
};
export type ResultGroup<T> = {
    groupName?: string,
    results: SearchResult<T>[]
};
export function SearchList<T>(props: {
    addText: string,
    queryInProgress: boolean,
    showAddComponent: boolean,
    setShowAddComponent: (val: boolean) => unknown,
    query: string,
    setQuery: (val: string) => unknown,
    queryFailure?: string,
    results: ResultGroup<T>[],
    addRecord: (record: T) => unknown,
}) {
    return <>
        <div
            className={placeClasses.item}
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '2rem',
                paddingTop: '0.25rem',
                paddingBottom: '0.25rem',
            }}>
            <div style={{ width: '100%', display: 'flex' }}>
                <div style={{ flex: 1, display: 'flex', gap: '0.5rem' }}>
                    <div>{props.addText}</div>
                    { props.queryInProgress && <Delayed delay={50}><Loader size='sm' style={{ alignSelf: 'center' }} /></Delayed> }
                </div>
                { !props.showAddComponent &&
                    <ActionIcon onClick={() => props.setShowAddComponent(true)}><IconSquareRoundedPlus /></ActionIcon> }
                { props.showAddComponent &&
                    <ActionIcon onClick={() => props.setShowAddComponent(false)}><IconSquareRoundedChevronUp /></ActionIcon> }
            </div>
            { props.showAddComponent &&
                <Input
                    style={{ width: '100%', paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
                    placeholder='Search...'
                    value={props.query}
                    onInput={e => props.setQuery(e.currentTarget.value)}
                /> }
        </div>
        { props.showAddComponent && props.queryFailure && <Text color='red'>{props.queryFailure}</Text> }
        { props.showAddComponent && props.results.map((resultGroup, index) =>
            <>
                {resultGroup.groupName && <div
                    key={`group-${index}`}
                    style={{ textAlign: 'center' }}
                >
                    {resultGroup.groupName}
                </div>}
                {resultGroup.results.map(result =>
                    <SearchResultComponent result={result} addRecord={props.addRecord} />)}
            </>
        )}
    </>;
}

function SearchResultComponent<T>(props: { result: SearchResult<T>, addRecord: (record: T) => unknown }) {
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    return <div
        className={placeClasses.item} style={{ display: 'flex' }}
        onClick={() => props.addRecord(props.result.record)}
    >
        <div style={{ flex: 1 }}>
            <Text>{props.result.headline}</Text>
            { props.result.secondaryLine && <Text size='sm' color='dimmed'>{props.result.secondaryLine}</Text> }
        </div>
        <ActionIcon><IconSquareRoundedPlus /></ActionIcon>
    </div>;
}
