import { addDoc, collection, deleteDoc, doc } from "firebase/firestore";
import { useMemo } from "react";
import { DateTime } from "luxon";
import { commentConverter, Place, PlaceComment } from "./interfaces";
import {
    FirebaseRefs,
    useCollection,
    useFirebaseRefs,
} from "./firebase";
import { UserData } from "./userdata";

export function useTripComments(tripid: string | undefined) {
    const firebaseRefs = useFirebaseRefs();

    return useCollection<PlaceComment>(
        tripid ? collection(firebaseRefs.firestore, "trips", tripid, "comments").withConverter(commentConverter) : undefined,
        'useTripComments'
    );
}

// TODO consider requiring the tripComments collection here, so that we don't set up multiple Firebase queries
export function usePlaceComments(place: Place) {
    if (!place.tripdocid) {
        throw Error("place.tripdocid must be non-null");
    }
    if (!place.docid) {
        throw Error("place.docid must be non-null");
    }

    const tripComments = useTripComments(place.tripdocid);
    const placeComments = useMemo(
        () => tripComments?.filter(c => c.activitydocid === place.docid),
        [ tripComments, place.docid ]);

    const legacyPlaceComments = useLegacyPlaceComments(place);

    return useMemo(() => {
        if (placeComments === undefined && legacyPlaceComments === undefined) {
            return undefined;
        } else {
            const commentsById = new Map<string, PlaceComment>();
            legacyPlaceComments?.forEach(c => c.docid && commentsById.set(c.docid, c));
            placeComments?.forEach(c => c.docid && commentsById.set(c.docid, c));
            return [ ...commentsById.values() ];
        }
    }, [ placeComments, legacyPlaceComments ]);
}

// Old (pre-Nov-2023) activities stored their comments in a nested collection under the activity itself.
// Load those also, so we can render old data.
function useLegacyPlaceComments(place: Place) {
    const firebaseRefs = useFirebaseRefs();

    if (!place.tripdocid) {
        throw Error("place.tripdocid must be non-null");
    }
    if (!place.docid) {
        throw Error("place.docid must be non-null");
    }

    const commentsCollectionRef = collection(
        firebaseRefs.firestore, 'trips', place.tripdocid, 'activities', place.docid, 'comments')
        .withConverter(commentConverter);
    return useCollection(commentsCollectionRef, 'useLegacyPlaceComments');
}

export async function deleteComment(firebaseRefs: FirebaseRefs, c: PlaceComment) {
    if (c.isLegacyPlaceComment) {
        const ref = doc(firebaseRefs.firestore, 'trips', c.tripdocid!, 'activities', c.activitydocid!, 'comments', c.docid!);
        await deleteDoc(ref);
    } else {
        const ref = doc(firebaseRefs.firestore, 'trips', c.tripdocid!, 'comments', c.docid!);
        await deleteDoc(ref);
    }
}

export async function createComment(
    firebaseRefs: FirebaseRefs,
    user: UserData,
    place: Pick<Place, 'docid' | 'tripdocid'>,
    newComment: string | undefined
) {
    if (newComment === undefined || !place.docid || !place.tripdocid || !user.firebaseUserId) {
        return;
    }

    const ref = collection(firebaseRefs.firestore, 'trips', place.tripdocid, 'comments')
        .withConverter(commentConverter);
    await addDoc(ref, {
        timestamp: DateTime.now(),
        activitydocid: place.docid,
        comment: newComment,
        schemaVersion: 0,
        tripdocid: place.tripdocid,
        useruid: user.firebaseUserId,
    } as PlaceComment);
}
