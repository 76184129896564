import React, { useContext, useState } from 'react';
import {
    ActionIcon,
    AppShell,
    Center,
    Group,
    Space,
    Tabs,
    TextInput,
    Title,
} from '@mantine/core';
import {
    Helm,
    Location,
    LocationFill,
    Placemark,
    PlacemarkFill,
    Plus,
    Star,
    StarFill,
} from 'framework7-icons-plus/react';
import { useColorScheme } from "@mantine/hooks";
import { IconSearch } from '@tabler/icons-react';
import { AppShellType, Place, PlaceClassType, Trip } from '../../lib/interfaces';
import { DeferredLoad } from "../DeferredLoad";
import { Overview } from "../Overview/Overview";
import { SavedPlacesSegmentedControl } from '../SavedPlacesList/SavedPlacesList';
import { TripsList } from '../TripsList/TripsList';
import { UserContext } from '../../lib/context';
import {
    MAP_TAB_NAME,
    OVERVIEW_TAB_NAME,
    SAVED_PLACES_TAB_NAME,
    TRIPS_TAB_NAME,
} from "../TravelScrollMenu";
import { showAddEditPlace } from '../AddEditPlace/addeditplace';
import { SearchableMap } from "../GoogleMapPlaces/SearchableMap";
import { SheetsContext } from "../Sheets";
import { blurOnEnterKey } from "../../lib/eventHandlers";
import { useQueryParams } from '../../lib/urls';
import { SpeedDialAffix } from "../SpeedDialAffix";
import { TSLoader } from "../TSLoader";
import { ClearTextAreaRightSection } from "../AddEditTrip/ClearTextAreaRightSection";
import { extraFooterClasses } from "../../lib/appShellUtils";
import { tabsProps } from "../tabs";
import { TSAppShell } from '../TSAppShell/TSAppShell';

import shellClasses from "../TSAppShell/ShellStyles.module.css";

function ListsHeaderSearchBar({ onSearchQueryChanged }: { onSearchQueryChanged: (newQuery: string) => unknown }) {
    const [ searchVisible, setSearchVisible ] = useState(false);

    if (searchVisible) {
        return <>
            <TextInput
                placeholder='Search...'
                autoFocus
                onChange={event => onSearchQueryChanged(event.currentTarget.value)}
                enterKeyHint='done'
                onKeyUp={blurOnEnterKey}
                rightSection={<ClearTextAreaRightSection onClear={() => {
                    onSearchQueryChanged('');
                    setSearchVisible(false);
                }} />}
            />
        </>;
    } else {
        return <ActionIcon onClick={() => setSearchVisible(true)}>
            <IconSearch height={24} width={24} color='white' />
        </ActionIcon>;
    }
}

export function HomeAppShell({ userid, tab, selected, userTrips, savedUidPlaces }: {
    userid: string,
    tab: string,
    selected: string | null,
    userTrips: Trip[] | undefined,
    savedUidPlaces: Place[] | null,
}) {
    const colorScheme = useColorScheme();
    const searchParams = useQueryParams();
    const user = useContext(UserContext);
    const sheets = useContext(SheetsContext);
    const [ showSpeedDial, setShowSpeedDial ] = useState(false);

    const [ savedListsSearchQuery, setSavedListsSearchQuery ] = useState<string | undefined>();
    const [ isDrawerOpen, setDrawerOpen ] = useState(false);

    const savedPlacesLoaded = savedUidPlaces !== null;

    return (
        <>
            <SpeedDialAffix isVisible={showSpeedDial} closeSpeedDial={() => setShowSpeedDial(false)} />

            <Tabs
                {...tabsProps(colorScheme)}
                value={tab}
                onChange={value => {
                    searchParams.replace(
                        {
                            tab: value ?? OVERVIEW_TAB_NAME,
                            place: selected ?? undefined,
                            seg: value === 'Saved' ? 'MySaveds' : undefined,
                        },
                        [ 'seg' ]);
                }}
            >
                <TSAppShell
                    ariaLabel='Travel Scroll'
                    drawerState={{ isDrawerOpen, setDrawerOpen }}
                    header={tab === MAP_TAB_NAME ? undefined : {
                        title: tab,
                        rightSide: tab === SAVED_PLACES_TAB_NAME
                            ? <div style={{
                                display: 'flex',
                                flex: '1',
                                marginLeft: 'auto',
                                alignItems: 'center',
                                flexDirection: 'row-reverse',
                                paddingRight: '0.5rem',
                            }}>
                                <ListsHeaderSearchBar onSearchQueryChanged={setSavedListsSearchQuery} />
                            </div>
                        : undefined,
                    }}
                >
                    <Tabs.Panel value={SAVED_PLACES_TAB_NAME}>
                        { !savedPlacesLoaded &&
                            <TSLoader id='HomeAppShell-SavedPlaces' layout='fill-content-area' />}
                        { savedPlacesLoaded &&
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                {!user.isLoggedIn && <Space h={73} />}
                                <SavedPlacesSegmentedControl
                                    searchQuery={savedListsSearchQuery}
                                    places={savedUidPlaces!}
                                    uid={userid}
                                />
                            </div> }
                    </Tabs.Panel>
                    <Tabs.Panel value={TRIPS_TAB_NAME}>
                        { !userTrips && <TSLoader id='HomeAppShell-Trips' layout='fill-content-area' /> }
                        { !!userTrips &&
                            <>
                                {user.status === 'logged-out' && <Space h={73} />}
                                <DeferredLoad
                                    trigger={tab === TRIPS_TAB_NAME}
                                    provider={() => <TripsList userTrips={userTrips} />}
                                    fallback={null}
                                />
                            </> }
                    </Tabs.Panel>
                    <Tabs.Panel value={OVERVIEW_TAB_NAME}>
                        <>
                            {user.status === 'logged-out' && <Space h={73} />}
                            <DeferredLoad
                                trigger={tab === OVERVIEW_TAB_NAME}
                                provider={() => <Overview />}
                                fallback={null}
                            />
                        </>
                    </Tabs.Panel>
                    <Tabs.Panel style={{ paddingTop: 0 }} value={MAP_TAB_NAME} id='mapplacescontainer'>
                        <DeferredLoad
                            trigger={tab === MAP_TAB_NAME}
                            provider={() =>
                                <SearchableMap
                                    placesToSearch={[]}
                                    currentUserId={userid}
                                    isMapVisible={tab === 'Map'}
                                    appShellType={AppShellType.Home}
                                    selected={selected}
                                    setSelected={id => searchParams.route({ place: id }, [], 'replace')}
                                    emptyQueryBehavior='match-none'
                                    refreshRoute={`/?tab=${MAP_TAB_NAME}`}
                                    chipConfig={{
                                        tripChips: true,
                                        recents: true,
                                        savedPlaces: true,
                                        searchEngine: true,
                                    }}
                                    toggleTravelScrollDrawer={() => setDrawerOpen(!isDrawerOpen)}
                                />}
                            fallback={null}
                        />
                    </Tabs.Panel>
                    <AppShell.Footer className={`${shellClasses.footer} ${extraFooterClasses(tab)}`}>
                        <Group justify='space-between' gap="xs">
                            <Group justify='space-between' grow gap="xs" style={{ flex: 7 }}>
                                <Tabs.List>
                                    <Tabs.Tab style={{ flex: 2 }} value={OVERVIEW_TAB_NAME} w={30}>
                                        <Center>
                                            {tab === OVERVIEW_TAB_NAME ?
                                                (<LocationFill height={18} width={18} />) :
                                                (<Location height={18} width={18} />)}
                                        </Center>
                                        <Space h='xs' />
                                        <Title style={{ textAlign: 'center' }} order={5}>
                                            {OVERVIEW_TAB_NAME}
                                        </Title>
                                    </Tabs.Tab>
                                    <Tabs.Tab style={{ flex: 1 }} value={TRIPS_TAB_NAME} w={30} aria-label={TRIPS_TAB_NAME}>
                                        <Center>
                                            {tab === TRIPS_TAB_NAME ?
                                                (<Helm height={18} width={18} />) :
                                                (<Helm height={18} width={18} />)}
                                        </Center>
                                        <Space h='xs' />
                                        <Title style={{ textAlign: 'center' }} order={5}>
                                            {TRIPS_TAB_NAME}
                                        </Title>
                                    </Tabs.Tab>
                                    <Tabs.Tab style={{ flex: 1 }} value={SAVED_PLACES_TAB_NAME} w={30}>
                                        <Center>
                                            {tab === SAVED_PLACES_TAB_NAME ?
                                                (<StarFill height={18} width={18} />) :
                                                (<Star height={18} width={18} />)}
                                        </Center>
                                        <Space h='xs' />
                                        <Title style={{ textAlign: 'center' }} order={5}>
                                            {SAVED_PLACES_TAB_NAME}
                                        </Title>
                                    </Tabs.Tab>
                                    <Tabs.Tab style={{ flex: 1 }} value={MAP_TAB_NAME} w={30}>
                                        <Center>
                                            {tab === MAP_TAB_NAME ?
                                                (<PlacemarkFill height={18} width={18} />) :
                                                (<Placemark height={18} width={18} />)}
                                        </Center>
                                        <Space h='xs' />
                                        <Title style={{ textAlign: 'center' }} order={5}>
                                            {MAP_TAB_NAME}
                                        </Title>
                                    </Tabs.Tab>
                                </Tabs.List>
                            </Group>
                            <Center style={{ flex: 1 }} pr='xs'>
                                <ActionIcon
                                    className='tscroll-floating-action-button'
                                    variant="filled"
                                    size="xl"
                                    radius='xl'
                                    onClick={() => {
                                        if (tab === SAVED_PLACES_TAB_NAME) {
                                            showAddEditPlace(sheets, { placeclass: PlaceClassType.Saved }, undefined);
                                        } else {
                                            setShowSpeedDial(true);
                                        }
                                    }}>
                                    <Plus height={18} width={18} />
                                </ActionIcon>
                            </Center>
                        </Group>
                    </AppShell.Footer>
                </TSAppShell>
            </Tabs>
        </>
    );
}
