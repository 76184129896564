import { Capacitor } from "@capacitor/core";

export type DeviceConfig = {
    apiBaseUrl: string
    firebaseCacheSizeMB: number
};

const EMPTY_DEVICE_CONFIG: DeviceConfig = {
    apiBaseUrl: 'https://us-central1-firetodo2.cloudfunctions.net/api',
    firebaseCacheSizeMB: Capacitor.isNativePlatform() ? 100 : 100, // Currently, we persist firestore data in native but not web mode.
};

function loadDeviceConfig(): DeviceConfig {
    if (!global.localStorage) {
        return EMPTY_DEVICE_CONFIG;
    }

    const json = global.localStorage.getItem("travelscroll-device-config");
    if (!json) {
        return EMPTY_DEVICE_CONFIG;
    }

    try {
        const deviceConfig = JSON.parse(json) as DeviceConfig;
        console.log('Loaded device-specific configuration overrides from local storage', deviceConfig);
        return deviceConfig;
    } catch (err) {
        console.warn('Failed to parse travelscroll-device-config JSON! Falling back to empty config', err);
        return EMPTY_DEVICE_CONFIG;
    }
}

export const deviceConfig = loadDeviceConfig();
