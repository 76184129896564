import { Capacitor } from "@capacitor/core";
import { Address, NativeGeocoder } from "@capgo/nativegeocoder";
import GeocoderResult = google.maps.GeocoderResult;
import { Position, positionFromLatitudeLongitude, positionFromLatLng } from "./position";
import { getLogger } from './logger';
import { IDBCache } from "./durable-cache";

const logger = getLogger("geocoder");

// eslint-disable-next-line global-require
const idbcache = require('idbcache').default as IDBCache;

/**
 * @returns whether or not forward geocoding is available in this context. If false,
 * calls to {@link forwardGeocode} will fall back to Google, and calls to
 * {@link reverseGeocode} will fail with an exception.
 */
export function canNativelyGeocode(): boolean {
    return Capacitor.isNativePlatform();
}

export type ForwardGeocodeResponse = {
    position: Position | undefined,
    locality: string | undefined,
    countryCode: string | undefined,
};

export async function forwardGeocode(searchText: string): Promise<ForwardGeocodeResponse | undefined> {
    const canForward = canNativelyGeocode();
    if (canForward) {
        const geocoded = await forwardGeocodeViaNativeGeocoder(searchText);
        if (geocoded) {
            return geocoded;
        }
    }

    logger.warn(`Failed to forward-geocode natively; falling back to Google. Native geocoder available: ${canForward}`);
    return forwardGeocodeViaGoogle(searchText);
}

const nativeGeocodeCache = new Map<string, { addresses: Address[] }>();
export async function forwardGeocodeViaNativeGeocoder(searchText: string): Promise<ForwardGeocodeResponse | undefined> {
    if (!canNativelyGeocode()) {
        throw Error("Geocoding is not available!");
    }

    try {
        let response = nativeGeocodeCache.get(searchText);
        if (!response) {
            response = await NativeGeocoder.forwardGeocode({ addressString: searchText, maxResults: 1 });
            if (nativeGeocodeCache.size > 1000) {
                nativeGeocodeCache.delete(nativeGeocodeCache.keys().next().value);
            }
            nativeGeocodeCache.set(searchText, response);
        }
        if (!response.addresses || response.addresses.length === 0) {
            return undefined;
        } else {
            return {
                position: positionFromLatitudeLongitude(response.addresses[0]),
                locality: response.addresses[0].locality,
                countryCode: response.addresses[0].countryCode,
            };
        }
    } catch (err) {
        logger.warn(`Native geocoder failure: ${err}`);
        console.warn("Native geocoder failure: ", err);
        return undefined;
    }
}

const googleGeocodeCache = new Map<string, GeocoderResult[]>();
export async function forwardGeocodeViaGoogle(searchText: string): Promise<ForwardGeocodeResponse | undefined> {
    if (!(window as any).google) {
        throw Error("Google geocoder is not available yet!");
    }

    let response = googleGeocodeCache.get(searchText);
    if (!response) {
        const geocoder = new google.maps.Geocoder();
        response = await new Promise<GeocoderResult[] | undefined>((resolve, reject) => {
            geocoder.geocode({ address: searchText }, (results, status) => {
                if (status === 'OK') {
                    resolve(results ?? undefined);
                } else {
                    reject(status);
                }
            });
        });
        if (googleGeocodeCache.size > 1000) {
            googleGeocodeCache.delete(googleGeocodeCache.keys().next().value);
        }
        googleGeocodeCache.set(searchText, response ?? []);
    }

    if (!response || response.length === 0) {
        return undefined;
    }

    const location = response[0].geometry?.location;
    if (location) {
        return {
            position: positionFromLatLng({ lat: location.lat(), lng: location.lng() }),
            locality: response[0].postcode_localities && response[0].postcode_localities.length > 0
                ? response[0].postcode_localities[0]
                : undefined,
            countryCode: undefined,
        };
    } else {
        return undefined;
    }
}

export type ReverseGeocodeResult = {
    latitude: number
    longitude: number
    countryCode: string
    postalCode: string
    administrativeArea: string
    subAdministrativeArea: string
    locality: string
    subLocality: string
    thoroughfare: string
    subThoroughfare: string
    areasOfInterest: string[]

    // Android-only
    addressLines?: string[]
};
export type ReverseGeocodeResponse =
    { type: 'success', result: ReverseGeocodeResult | undefined }
    | { type: 'busy' }
    | { type: 'rate-limited', backoff: number }
    | { type: 'error', message: any };
const cacheDurationSeconds = 30 * 24 * 60;
export async function reverseGeocode(position: Position): Promise<ReverseGeocodeResponse> {
    if (!canNativelyGeocode()) {
        throw Error("Geocoding is not available!");
    }

    const memoKey = `${position.lat}:${position.lng}`;
    const value = await idbcache.get(memoKey);
    if (value) {
        return JSON.parse(value);
    } else {
        try {
            const geocodeResponse = await NativeGeocoder.reverseGeocode({
                latitude: position.lat,
                longitude: position.lng,
            });
            let response: ReverseGeocodeResponse;
            if (!geocodeResponse.addresses || geocodeResponse.addresses.length === 0) {
                response = {
                    type: 'success',
                    result: undefined,
                };
            } else {
                response = {
                    type: 'success',
                    result: geocodeResponse.addresses[0],
                };
            }
            await idbcache.set(memoKey, JSON.stringify(response), cacheDurationSeconds);
            return response;
        } catch (err) {
            if (err === 'Geocoder is busy. Please try again later.') {
                return { type: 'busy' };
            } else if (err === 'CLGeocoder:reverseGeocodeLocation Error') {
                // Let's assume that this means we've been rate-limited. The rules are 50 requests per minute.
                const response: ReverseGeocodeResponse = { type: 'rate-limited', backoff: 60 };
                await idbcache.set(memoKey, JSON.stringify(response), 60);
                return response;
            } else {
                const response: ReverseGeocodeResponse = { type: 'error', message: err };
                await idbcache.set(memoKey, JSON.stringify(response), cacheDurationSeconds);
                return response;
            }
        }
    }
}
