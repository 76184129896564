import {
    CollectionReference,
    DocumentReference,
    FirestoreError,
    QueryConstraint,
    waitForPendingWrites,
} from "@firebase/firestore";
import { initializeApp, FirebaseApp, FirebaseError } from "firebase/app";
import { indexedDBLocalPersistence, initializeAuth } from "firebase/auth";
import {
    Firestore,
    initializeFirestore,
    persistentLocalCache,
    FirestoreSettings,
    disableNetwork,
    enableNetwork,
    Query,
    QuerySnapshot,
    onSnapshot,
    getDocsFromCache, getDocFromCache, query,
} from "firebase/firestore";
import { FirebaseStorage, getStorage } from "firebase/storage";
import { Database, getDatabase } from "@firebase/database";
import { useContext, useEffect, useMemo, useState } from "react";
import { FirebaseContext } from "./context";
import { deviceConfig } from "./deviceconfig";
import { firebaseConfig } from "./config";
import { getLogger } from "./logger";

const logger = getLogger('firebase');

export type FirebaseRefs = {
    app: FirebaseApp
    firestore: Firestore
    storage: FirebaseStorage
    database: Database
    id: number
};

export function useFirebaseRefs() {
    const firebaseRefsFromContext = useContext(FirebaseContext);
    if (!firebaseRefsFromContext) {
        throw new Error("FirebaseContext has not been properly configured!");
    } else {
        return firebaseRefsFromContext;
    }
}

let firebaseSequenceGenerator = 0;
export function newFirebaseRefs(reason: string) {
    const sequence = firebaseSequenceGenerator++;
    logger.log(`Initializing Firebase ${sequence}. Reason: ${reason}`);

    const firebaseApp = initializeApp(firebaseConfig);

    const firestoreSettings: FirestoreSettings = deviceConfig.firebaseCacheSizeMB > 0
        ? { localCache: persistentLocalCache({ cacheSizeBytes: deviceConfig.firebaseCacheSizeMB * 1024 * 1024 }) }
        : { };
    const firestore = initializeFirestore(firebaseApp, firestoreSettings);
    //instructions to setup firestore emulation
    //'firebase init firestore' to generate local firestore.rules for emulator
    //'firebase emulators:start --only firestore' to run emulator
    //scripts to seed firestore in emulator - https://medium.com/firebase-developers/seeding-firestore-data-in-emulator-c8485e797135

    //uncomment to use firestore emulator in development
    //const db = getFirestore();
    //if (process.env.NODE_ENV === "development") {
    //    connectFirestoreEmulator(db, '127.0.0.1', 8080);
    //}

    return {
        app: firebaseApp,
        firestore,
        storage: getStorage(firebaseApp),
        database: getDatabase(firebaseApp),
        id: sequence,
    };
}

export function useFirebaseAuth() {
    const { app, id } = useFirebaseRefs() as FirebaseRefs;

    return useMemo(
        () => {
            logger.log(`Initializing Firebase Auth for ref id ${id}`);
            return initializeAuth(app,
                { persistence: [ indexedDBLocalPersistence ] }); // Always persist authorization data
        },
        [ app, id ]
    );
}

function defaultOnSnapshotErrorHandler(context: string) {
    return (error: FirebaseError) => logger.warn(`\
An error occurred in onSnapshot(). context=${context}, \
name=${error.name}, code=${error.code}, message=${error.message}`);
}

export function pauseFirebase(
    firebaseRefs: FirebaseRefs,
    completionCallback: () => unknown,
    errorCallback: (error: any) => unknown,
) {
    let isRunning = true;
    (async () => {
        try {
            await waitForPendingWrites(firebaseRefs.firestore);
            if (isRunning) {
                // if we suspend and resume really fast, we can get into a race in which
                // the resume happens before the disableNetwork call. This avoids that.
                await disableNetwork(firebaseRefs.firestore);
                logger.log("Firestore networking paused");
                completionCallback();
            }
        } catch (err) {
            errorCallback(err);
        }
    })();
    return () => { isRunning = false; };
}

export async function resumeFirebase(firebaseRefs: FirebaseRefs) {
    const start = Date.now();
    await enableNetwork(firebaseRefs.firestore);
    logger.log(`Firestore networking resumed in ${Date.now() - start} millis`);
}

type IndexedDBUserInfo = { firebaseUserId: string | undefined, isLoading: boolean };
export function useFirebaseUserInfoFromIndexedDB(): IndexedDBUserInfo {
    const [ firebaseUserInfo, setFirebaseUserInfo ] = useState<IndexedDBUserInfo>({
        firebaseUserId: undefined,
        isLoading: true,
    });

    useEffect(() => {
        firebaseUserIdFromIndexedDB()
            .then(firebaseUserId => setFirebaseUserInfo({ firebaseUserId, isLoading: false }));
    }, []);

    return firebaseUserInfo;
}

// Digs into the Firebase Auth storage internals to obtain the Firebase ID for the current
// user. If there is no storage info in IndexedDB, this returns `undefined`.
async function firebaseUserIdFromIndexedDB(): Promise<string | undefined> {
    if (!('indexedDB' in global)) {
        return undefined;
    }

    const databases = await global.indexedDB.databases();
    const target = databases.find(db => db.name === 'firebaseLocalStorageDb');
    if (!target) {
        return undefined;
    }

    const [ firebaseUserId, error ] = await new Promise<[ string | undefined, string | undefined ]>(resolve => {
        const dbRequest = indexedDB.open('firebaseLocalStorageDb'); // We intentionally do not specify a version
        dbRequest.addEventListener('error', () => resolve([ undefined, 'db-error' ]));
        dbRequest.addEventListener('upgradeneeded', () => resolve([ undefined, 'upgradeneeded' ]));
        dbRequest.addEventListener('success', () => {
            const tx = dbRequest.result.transaction('firebaseLocalStorage', 'readonly');
            const objectStore = tx.objectStore('firebaseLocalStorage');
            const key = `firebase:authUser:${firebaseConfig.apiKey}:[DEFAULT]`;
            const recordRequest = objectStore.get(key);
            recordRequest.addEventListener('error', () => resolve([ undefined, 'request-error' ]));
            recordRequest.addEventListener('success', () => {
                if (recordRequest.result) {
                    const { value } = recordRequest.result;
                    const { uid } = value;
                    if (uid && typeof uid === 'string') {
                        resolve([ uid, undefined ]);
                    } else {
                        resolve([ undefined, 'uid' ]);
                    }
                } else {
                    resolve([ undefined, 'no-result' ]);
                }
            });
        });
    });

    return firebaseUserId;
}

// This issues a query directly against cache only, and concurrently sets up a snapshot
// subscription. The snapshot query will eventually hit cache, but it appears to do some
// waiting first; the initial cache-only query will typically resolve much earlier.
//
// If the first proper onSnapshot response comes in before the cache response, the cache
// response will be discarded.
function onCacheFirstSnapshot<T>(
    q: Query<T>,
    onNext: (snapshot: QuerySnapshot<T>, type: 'initial-cache' | 'cache' | 'server') => void,
    onError: (error: FirestoreError) => void
) {
    let hasReceivedResponse = false;

    getDocsFromCache(q)
        .then(snapshot => {
            if (!hasReceivedResponse) {
                onNext(snapshot, 'initial-cache');
            }
        })
        .catch(onError);

    return onSnapshot( // eslint-disable-line no-restricted-syntax
        q,
        snapshot => {
            hasReceivedResponse = true;
            onNext(snapshot, snapshot.metadata.fromCache ? 'cache' : 'server');
        },
        onError
    );
}

// It is important to memoize or other stabilize the query argument provided; otherwise, this
// can easily devolve into an infinite render-loop
export function useMemoizedQuery<T>(
    q: Query<T> | undefined,
    context: string
): T[] | undefined {
    const [ results, setResults ] = useState<T[] | undefined>();

    useEffect(() => {
        if (!q) {
            setResults(undefined);
            return () => { };
        }

        return onCacheFirstSnapshot(
            q,
            (snapshot, type) => {
                if (type === 'initial-cache' && snapshot.size === 0) {
                    setResults(undefined);
                } else {
                    setResults(snapshot.docs.map(doc => doc.data()));
                }
            },
            defaultOnSnapshotErrorHandler(context)
        );
    }, [ q, context ]);

    return results;
}

export function useCollection<T>(
    collection: CollectionReference<T> | undefined,
    context: string
): T[] | undefined {
    const [ results, setResults ] = useState<T[] | undefined>();

    const [ stabilizedCollection, setStabilizedCollection ] = useState(collection);

    useEffect(() => {
        if (collection) {
            setStabilizedCollection(original => {
                if (original
                    && original.path === collection.path
                    && original.id === collection.id
                    && original.firestore === collection.firestore
                ) {
                    return original;
                } else {
                    return collection;
                }
            });
        } else {
            setStabilizedCollection(undefined);
        }
    }, [ collection ]);

    return useMemoizedQuery(stabilizedCollection, context);
}

export function useDoc<T>(
    ref: DocumentReference<T> | undefined,
    context: string,
): { result: T | undefined, isLoading: boolean } {
    const [ result, setResult ]
        = useState<{ result: T | undefined, isLoading: boolean }>({ result: undefined, isLoading: true });

    const [ stabilizedRef, setStabilizedRef ] = useState(ref);

    useEffect(() => {
        if (ref) {
            setStabilizedRef(original => {
                if (original && original.path === ref.path && original.firestore === ref.firestore) {
                    return original;
                } else {
                    return ref;
                }
            });
        } else {
            setStabilizedRef(undefined);
        }
    }, [ ref ]);

    useEffect(() => {
        if (!stabilizedRef) {
            setResult({ result: undefined, isLoading: false });
            return () => { };
        } else {
            getDocFromCache(stabilizedRef)
                .then(doc => {
                    setResult(current => {
                        if (current?.isLoading) {
                            return { result: doc.data(), isLoading: false }; // TODO should we include source metadata?
                        } else {
                            return current;
                        }
                    });
                })
                .catch(() => {
                }); // we do nothing -- wait for the proper DB query

            return onSnapshot( // eslint-disable-line no-restricted-syntax
                stabilizedRef,
                snapshot => {
                    setResult({ result: snapshot.data(), isLoading: false }); // TODO should we include source metadata?
                },
                defaultOnSnapshotErrorHandler(context)
            );
        }
    }, [ stabilizedRef, context ]);

    return result;
}
