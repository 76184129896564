import { base64Decode, base64Encode } from "@firebase/util";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context";
import { useRouter } from "next/router";
import * as navigation from "next/navigation";
import { useMemo } from "react";

export function encodeUrlStateForRedirect(): string {
    return base64Encode(document.location.pathname + document.location.search);
}

export function decodeRedirectToUrlPath(redirect: string): string {
    const decoded = base64Decode(redirect);
    if (decoded === null) {
        console.warn(`Failed to decode redirect! Value: '${redirect}'. Will redirect to /`);
        return '/';
    } else {
        return decoded;
    }
}

/**
 * This implementation de-duplicates query params, keeping the last value only
 */
export function buildQueryString(
    query: QueryParams,
    toAppend: { [key: string]: string | string[] | undefined },
    toOmit: string[]
) {
    const omitSet = new Set(toOmit);
    const appendSet = new Set(Object.keys(toAppend));

    const params: [string, string][] = [];
    query.keys().forEach(k => {
        if (query.has(k) && !omitSet.has(k) && !appendSet.has(k)) {
            query.all(k)?.forEach(v => {
                params.push([ k, v ]);
            });
        }
    });

    Object.keys(toAppend).forEach(k => {
        const v = toAppend[k];
        if (typeof v === 'string') {
            params.push([ k, v ]);
        } else if (Array.isArray(v)) {
            v.forEach(value => {
                params.push([ k, value ]);
            });
        }
    });

    return params.length === 0
        ? undefined
        : params.map(([ k, v ]) => `${k}=${encodeURIComponent(v)}`).join('&');
}

export function routeToQuery(router: AppRouterInstance, params: string | undefined, behavior: 'push' | 'replace' | 'browser-replace' = 'push') {
    let url: string;
    if (params) {
        url = `${document.location.pathname}?${params}`;
    } else {
        url = document.location.pathname;
    }

    // We run the router operation in a timeout so that multiple re-routings executed at the same time have
    // an opportunity to stack on top of each other, instead of overwriting
    if (behavior === 'push') {
        setTimeout(() => router.push(url));
    } else if (behavior === 'replace') {
        setTimeout(() => router.replace(url));
    } else {
        setTimeout(() => window.history.replaceState(window.history.state, '', url));
    }
}

/**
 * This returns the path params and perhaps also the query params.
 *
 * TODO investigate if we can use next/navigation's useParams() instead.
 */
export function usePathParams(): { [key: string]: string | undefined } {
    // We use the next/router object here, so that we can use its parsing logic. next/navigation's usePath()
    // function doesn't seem to work in our page contexts.
    const q = useRouter().query;
    const values: { [key: string]: string } = { };
    Object.keys(q).forEach(k => {
        const v = q[k];
        if (typeof v === 'string') {
            values[k] = v;
        } else if (Array.isArray(v)) {
            values[k] = v[0];
        }
    });
    return values;
}

export type QueryParams = {
    first(key: string): string | undefined
    all(key: string): string[] | undefined
    has(key: string): boolean
    keys(): string[]
};

export type Appendables = { [key: string]: string | string[] | undefined };

export type QueryParamsAndMutators = QueryParams & {
    route(toAppend: Appendables, toOmit: string[], behavior: 'push' | 'replace' | 'browser-replace'): void
    push(toAppend: Appendables, toOmit: string[]): void
    replace(toAppend: Appendables, toOmit: string[]): void
};

export function useQueryParams(): QueryParamsAndMutators {
    const router = navigation.useRouter();

    const parse = (candidate: string) => {
        let firstMeaningfulChar = 0;
        while (candidate[firstMeaningfulChar] === '?') {
            firstMeaningfulChar++;
        }
        const trimmed = candidate.substring(firstMeaningfulChar);
        return parseQueryParams(trimmed);
    };

    const queryParams = useMemo(() => parse(document.location.search), [ document.location.search ]);

    const returnValue: QueryParamsAndMutators = {
        ...queryParams,
        route(toAppend, toOmit, behavior) {
            const query = buildQueryString(queryParams, toAppend, toOmit);
            routeToQuery(router, query, behavior);
        },
        push(toAppend, toOmit) { returnValue.route(toAppend, toOmit, 'push'); },
        replace(toAppend, toOmit) { returnValue.route(toAppend, toOmit, 'replace'); },
    };
    return returnValue;
}

export function parseQueryParams(query: string): QueryParams {
    if (!query || query.length === 0) {
        return {
            first: (key: string) => undefined,
            all: (key: string) => undefined,
            has: (key: string) => false,
            keys: () => [],
        };
    }

    const params: { [key: string]: string[] } = { };
    query.split('&').forEach(term => {
        const kv = term.split('=');
        if (kv.length === 2) {
            const [ k, v ] = kv;
            const value = decodeURIComponent(v);
            if (params[k]) {
                params[k].push(value);
            } else {
                params[k] = [ value ];
            }
        }
    });

    return {
        first(key: string): string | undefined {
            return params[key] && params[key].length > 0 ? params[key][0] : undefined;
        },
        all(key: string): string[] {
            return params[key];
        },
        has(key: string): boolean {
            return Object.prototype.hasOwnProperty.call(params, key);
        },
        keys: () => Object.keys(params),
    };
}
