import { Text } from "@mantine/core";

export function Banner({ text }: { text: string | null }) {
    return <div style={{
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        paddingTop: '10px',
        paddingBottom: '10px',
    }}>
        <hr style={{
            display: 'inline-block',
            flex: '1',
        }} />
        <Text style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
        }} size="sm" color="dimmed">
            {text}
        </Text>
        <hr style={{
            display: 'inline-block',
            flex: '1',
        }} />
    </div>;
}
