import React, { Component, ErrorInfo, ReactNode } from "react";
import { getLogger } from "../lib/logger";

const logger = getLogger("EB");

interface Props {
    children?: ReactNode
}

interface State {
    hasError: boolean
}

class ErrorBoundary extends Component<Props & { onError?: () => unknown }, State> {
    public state: State = { hasError: false };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        logger.error(`Uncaught error: ${error.toString()} ${errorInfo.componentStack}`);
        this.props.onError && this.props.onError();
    }

    public render() {
        if (this.state.hasError) {
            return <div style={{ height: '100dvh', display: "flex", flexDirection: 'column' }}>
                <h1>Sorry.. something went wrong!</h1>
                <p>
                    <button
                        type='button'
                        onClick={() => {
                            // TODO write to local storage, and do a reload instead of a page transition
                            logger.log("Navigating to /. This will restart the JS container.");
                            document.location = '/';
                        }}
                    >
                        Take me home!
                    </button>
                </p>
            </div>;
        } else {
            return this.props.children;
        }
    }
}

export default ErrorBoundary;
