import { PropsWithChildren } from "react";
import { PlaceType, SavedType } from "../lib/interfaces";

export type IconURLs = {
    map: string
    markup: string
};

export type URLGroup = {
    BlueDot: IconURLs
    Lodging: IconURLs
    Food: IconURLs
    Flight: IconURLs
};

export const URLs = {

    ActiveTrip: {
        TripActivity: {
            BlueDot: {
                map: '/g/images/fc/dot-rounded-pointed-outline.svg',
                markup: '/g/images/fc/dot.svg',
            },
            Lodging: {
                map: '/g/images/fc/lodging-rounded-pointed-outline.svg',
                markup: '/g/images/fc/lodging.svg',
            },
            Food: {
                map: '/g/images/fc/food-rounded-pointed-outline.svg',
                markup: '/g/images/fc/food.svg',
            },
            Flight: {
                map: '/g/images/fc/flight-rounded-pointed-outline.svg',
                markup: '/g/images/fc/flight.svg',
            },
        } as URLGroup,

        TripAndFavorite: {
            BlueDot: {
                map: '/g/images/fc/dot-rounded-pointed-outline-with-favorite.svg',
                markup: '/g/images/fc/dot.svg',
            },
            Lodging: {
                map: '/g/images/fc/lodging-rounded-pointed-outline-with-favorite.svg',
                markup: '/g/images/fc/lodging.svg',
            },
            Food: {
                map: '/g/images/fc/food-rounded-pointed-outline-with-favorite.svg',
                markup: '/g/images/fc/food.svg',
            },
            Flight: {
                map: '/g/images/fc/flight-rounded-pointed-outline-with-favorite.svg',
                markup: '/g/images/fc/flight.svg',
            },
        } as URLGroup,

        TripAndWantToGo: {
            BlueDot: {
                map: '/g/images/fc/dot-rounded-pointed-outline-with-want-to-go.svg',
                markup: '/g/images/fc/dot.svg',
            },
            Lodging: {
                map: '/g/images/fc/lodging-rounded-pointed-outline-with-want-to-go.svg',
                markup: '/g/images/fc/lodging.svg',
            },
            Food: {
                map: '/g/images/fc/food-rounded-pointed-outline-with-want-to-go.svg',
                markup: '/g/images/fc/food.svg',
            },
            Flight: {
                map: '/g/images/fc/flight-rounded-pointed-outline-with-want-to-go.svg',
                markup: '/g/images/fc/flight.svg',
            },
        } as URLGroup,
    },

    OtherTrip: {
        TripActivity: {
            BlueDot: {
                map: '/g/images/fc/muted-dot-rounded-pointed-outline.svg',
                markup: '/g/images/fc/dot.svg',
            },
            Lodging: {
                map: '/g/images/fc/muted-lodging-rounded-pointed-outline.svg',
                markup: '/g/images/fc/lodging.svg',
            },
            Food: {
                map: '/g/images/fc/muted-food-rounded-pointed-outline.svg',
                markup: '/g/images/fc/food.svg',
            },
            Flight: {
                map: '/g/images/fc/muted-flight-rounded-pointed-outline.svg',
                markup: '/g/images/fc/flight.svg',
            },
        } as URLGroup,

        TripAndFavorite: {
            BlueDot: {
                map: '/g/images/fc/muted-dot-rounded-pointed-outline-with-favorite.svg',
                markup: '/g/images/fc/dot.svg',
            },
            Lodging: {
                map: '/g/images/fc/muted-lodging-rounded-pointed-outline-with-favorite.svg',
                markup: '/g/images/fc/lodging.svg',
            },
            Food: {
                map: '/g/images/fc/muted-food-rounded-pointed-outline-with-favorite.svg',
                markup: '/g/images/fc/food.svg',
            },
            Flight: {
                map: '/g/images/fc/muted-flight-rounded-pointed-outline-with-favorite.svg',
                markup: '/g/images/fc/flight.svg',
            },
        } as URLGroup,

        TripAndWantToGo: {
            BlueDot: {
                map: '/g/images/fc/muted-dot-rounded-pointed-outline-with-want-to-go.svg',
                markup: '/g/images/fc/dot.svg',
            },
            Lodging: {
                map: '/g/images/fc/muted-lodging-rounded-pointed-outline-with-want-to-go.svg',
                markup: '/g/images/fc/lodging.svg',
            },
            Food: {
                map: '/g/images/fc/muted-food-rounded-pointed-outline-with-want-to-go.svg',
                markup: '/g/images/fc/food.svg',
            },
            Flight: {
                map: '/g/images/fc/muted-flight-rounded-pointed-outline-with-want-to-go.svg',
                markup: '/g/images/fc/flight.svg',
            },
        } as URLGroup,
    },

    SavedFavorite: {
        BlueDot: {
            map: '/g/images/fc/red-dot-rounded-pointed-outline-for-saved-favorite.svg',
            markup: '/g/images/fc/dot.svg',
        },
        Lodging: {
            map: '/g/images/fc/lodging-rounded-pointed-outline-for-saved-favorite.svg',
            markup: '/g/images/fc/lodging.svg',
        },
        Food: {
            map: '/g/images/fc/food-rounded-pointed-outline-for-saved-favorite.svg',
            markup: '/g/images/fc/food.svg',
        },
        Flight: {
            map: '/g/images/fc/flight-rounded-pointed-outline-for-saved-favorite.svg',
            markup: '/g/images/fc/flight.svg',
        },
    } as URLGroup,

    SavedWantToGo: {
        BlueDot: {
            map: '/g/images/fc/dot-rounded-pointed-outline-for-saved-want-to-go.svg',
            markup: '/g/images/fc/dot.svg',
        },
        Lodging: {
            map: '/g/images/fc/lodging-rounded-pointed-outline-for-saved-want-to-go.svg',
            markup: '/g/images/fc/lodging.svg',
        },
        Food: {
            map: '/g/images/fc/food-rounded-pointed-outline-for-saved-want-to-go.svg',
            markup: '/g/images/fc/food.svg',
        },
        Flight: {
            map: '/g/images/fc/flight-rounded-pointed-outline-for-saved-want-to-go.svg',
            markup: '/g/images/fc/flight.svg',
        },
    } as URLGroup,
};

export type IconContext = 'map' | 'markup';
export function ActivitySVG(props: {
    type: PlaceType,
    context: IconContext,
    size?: number,
    strokeColor?: string,
    mode: 'dark' | 'light',
    modifier: SavedType | undefined,
    styleTarget: 'trip' | 'saveds' | 'inline' | 'other-trip',
}) {
    const icon = props.type === PlaceType.Lodging || props.type === PlaceType.Hotel
        ? <Lodging />
        : props.type === PlaceType.Flight
            ? <Flight />
            : props.type === PlaceType.Meal
                ? <Food />
                : <Dot />;

    const fillColor = props.styleTarget === 'trip'
        ? 'deepskyblue'
        : props.styleTarget === 'other-trip'
            ? 'lightblue'
            : props.styleTarget === 'saveds'
                ? 'tomato'
                : undefined;

    return <InContext
        strokeColor={props.strokeColor}
        context={props.context}
        fillColor={fillColor}
        size={props.size}
        mode={props.mode}
        modifier={props.modifier}
    >
        {icon}
    </InContext>;
}

export function SavedSVG(props: {
    type: SavedType,
    size?: number,
    strokeColor?: string,
    mode: 'dark' | 'light',
}) {
    return <InContext
        context='markup'
        strokeColor={props.strokeColor}
        fillColor='tomato'
        mode={props.mode}
        size={props.size}
        modifier={undefined}
    >
        { props.type === 'Favorite' && <Heart scale={1} /> }
        { props.type === 'Want to go' && <Flag scale={1} /> }
    </InContext>;
}

function InContext(props: {
    context: IconContext,
    strokeColor?: string | undefined,
    fillColor: string | undefined,
    size?: number,
    mode: 'dark' | 'light'
    modifier: SavedType | undefined,
} & PropsWithChildren) {
    const defaultStroke = props.mode === 'dark' ? '#e9ecef' : '#231F20';
    switch (props.context) {
        case 'map':
            return <svg viewBox='-6 -6 52 55' stroke={props.strokeColor ?? defaultStroke} width={props.size} xmlns="http://www.w3.org/2000/svg">
                <BulgingSquareWithPoint fill={props.fillColor} />
                {props.children}
                { props.modifier === 'Favorite' && <g transform='translate(31, -6)' fill='tomato'>
                    <Heart scale={0.4} />
                </g> }
                { props.modifier === 'Want to go' && <g transform='translate(34, -6)' fill='tomato'>
                    <Flag scale={0.4} />
                </g> }
            </svg>;
        case 'markup':
        default:
            return <svg viewBox='0 0 40 40' stroke={props.strokeColor ?? defaultStroke} width={props.size} xmlns="http://www.w3.org/2000/svg">
                {props.children}
            </svg>;
    }
}

function BulgingSqaure(props: { fill: string | undefined }) {
    return <path d="
                M0,0
                C2,-2 38,-2 40,0
                C42,2 42,38 40,40
                C38,42 2,42 0,40
                C-2,38 -2,2 0,0
                "
                 stroke="black"
                 fill={props.fill}
    />;
}

function BulgingSquareWithPoint(props: { fill: string | undefined }) {
    return <g>
        <BulgingSqaure fill={props.fill} />
        <path d="
                M0,40
                C10,45 20,42 20,50
                C20,42 30,45 40,40
                C38,42 2,42 0,40
                Z
                "
              stroke="black"
              fill="black" />
    </g>;
}

function Lodging() {
    return <g fill="none">
        <path
            d="M7.68 22.59H32.32C33.73 22.59 34.87 23.73 34.87 25.14V31.3H5.13V25.14C5.13 23.73 6.27 22.59 7.68 22.59Z"
            strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.13 28.56H34.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.94 31.3V33.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M33.06 31.3V33.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.85 19.51H16.92C18.62 19.51 20 20.89 20 22.59H7.77002C7.77002 20.89 9.15002 19.51 10.85 19.51Z"
              strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M23.08 19.51H29.15C30.85 19.51 32.23 20.89 32.23 22.59H20C20 20.89 21.38 19.51 23.08 19.51Z"
              strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M30.69 6.5H9.29999V13.54H30.69V6.5Z" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round" />
    </g>;
}

function Heart(props: { scale: number }) {
    const internalScaleFactor = 0.4;
    const computedScale = props.scale * internalScaleFactor;
    return <g transform={`scale(${computedScale})`} strokeWidth={1 / computedScale}>
        <path d="M 10,30 A 20,20 0,0,1 50,30 A 20,20 0,0,1 90,30 Q 90,60 50,90 Q 10,60 10,30 z" />
    </g>;
}

function Food() {
    return <g fill="none">
        <path
            d="M17.52 11.61V14.15C17.52 16.35 15.96 18.19 13.89 18.62L14.06 31.83C14.07 32.45 13.57 32.95 12.96 32.95C12.35 32.95 11.85 32.45 11.85 31.83L12.02 18.61C9.95001 18.18 8.39001 16.35 8.39001 14.15V11.61C8.39001 9.07998 10.44 7.03998 12.96 7.03998C14.22 7.03998 15.36 7.54998 16.19 8.37998C17.02 9.20998 17.52 10.35 17.52 11.61Z"
            strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.63 11.79C10.63 10.56 11.63 9.57001 12.85 9.57001" strokeWidth="1.5"
              strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M31.41 8.04999L31.61 13.16C31.61 15.36 30.05 17.2 27.98 17.63L28.15 31.84C28.16 32.46 27.66 32.96 27.05 32.96C26.44 32.96 25.94 32.46 25.94 31.84L26.11 17.62C24.04 17.19 22.48 15.36 22.48 13.16L22.7 8.04999"
            strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M25.52 8.04999V13.87" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round" />
        <path d="M28.56 8.04999V13.87" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round" />
    </g>;
}

function Flight() {
    return <g fill="none">
        <path d="M6.47003 24.99L4.16003 19.44C4.16003 19.44 5.57003 18.53 6.53003 19.44C7.16003 20.05 8.31003 20.78 9.01003 21.2C9.38003 21.42 9.83003 21.46 10.23 21.29L29.97 12.87C29.97 12.87 33.49 11.67 35.63 13.62C35.98 13.94 35.92 14.52 35.5 14.76L26.93 19.75L21.61 31.03C21.61 31.03 20.57 33.23 18.74 32.9L20.57 22.72L12.47 25.88C12.47 25.88 10.36 27.01 8.35003 26.59C7.50003 26.41 6.81003 25.79 6.47003 24.98V24.99Z"
              strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M22.05 16.25L13.92 13.79C13.92 13.79 11.84 13.13 10.6 14.79L16.65 18.56"
              strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>;
}

function Flag(props: { scale: number }) {
    const internalScaleFactor = 40 / 350;
    const computedScale = internalScaleFactor * props.scale;
    return <g transform={`scale(${computedScale})`}>
        <path strokeWidth={2 / computedScale} d="M42 327l0 -291" strokeLinecap='round' />
        <path strokeWidth={1 / computedScale}
            d="M49 50c70,30 104,28 178,2 -21,42 -21,74 0,116 -72,25 -101,25 -178,0l0 -118z" />
    </g>;
}

function Dot() {
    return <circle cx="20" cy="20" r="10" strokeWidth="2" fill="none" />;
}

function Cafe() {
    return <g fill="none">
        <path d="M12.61 19.4H25.26V23.05C25.26 26.54 22.43 29.37 18.94 29.37C15.45 29.37 12.62 26.54 12.62 23.05V19.4H12.61Z"
              strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M24.31 26.38C25.69 26.38 28.54 25.46 28.54 22.96C28.54 21.58 26.7 21.39 25.32 21.39"
              strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.16 10.63C19.16 10.63 20.78 11.72 19.16 12.76C17.54 13.8 19.16 15.13 19.16 15.13"
              strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.88 11.58C15.88 11.58 17.5 12.67 15.88 13.71C14.26 14.75 15.88 16.08 15.88 16.08"
              strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21.99 11.58C21.99 11.58 23.61 12.67 21.99 13.71C20.37 14.75 21.99 16.08 21.99 16.08"
              strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>;
}
