import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { GoogleMap, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api';
import { ActionIcon, Group, Text, useMantineTheme } from '@mantine/core';
import { useColorScheme } from "@mantine/hooks";
import Sheet, { SheetRef } from 'react-modal-sheet';
import { IconMap, IconMenu2 } from '@tabler/icons-react';
import { MdGpsFixed, MdRefresh, MdZoomInMap } from 'react-icons/md';
import { useRouter } from 'next/navigation';
import { Capacitor } from '@capacitor/core';
import { Position } from '@capacitor/geolocation';
import { notifications } from '@mantine/notifications';
import { StatusBar, Style } from '@capacitor/status-bar';
import { FaAngleDoubleDown, FaAngleDoubleUp } from 'react-icons/fa';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import {
    GoogleImageGallery,
    PlaceImageGallery,
    usePlaceImages,
} from '../SavedPlacesAppShell/PanelImageGallery';
import {
    AppShellType,
    MappableRecord,
    Place,
    PlaceClassType,
    PlaceRecord,
    SavedType,
} from '../../lib/interfaces';
import { getLogger } from '../../lib/logger';
import { googleMapsLoaderOptions } from '../../lib/config';
import { Bounds, positionFromGoogleLatLng, positionFromLatLng, PositionKey, positionToKey } from '../../lib/position';
import { mapIconUrlForPlace, navigateToPlace } from '../../lib/placeFunctions';
import { showAddEditPlace } from '../AddEditPlace/addeditplace';
import { SheetsContext, SheetsData, sheetsMountPointId } from '../Sheets';
import { HomeContext, TripContext } from '../../lib/context';
import { SavedPlaceBottom } from '../SavedPlaceScreen/SavedPlaceScreen';
import { useGeolocationData } from '../../lib/geolocation';
import { TSLoader } from '../TSLoader';
import { ActivityDetailsBottom } from '../TripActivityScreen/TripActivityScreen';
import { URLs } from '../PlaceSVGs';
import { showModalAsync } from "../TSModal";

import classes from './GoogleMapPlaces.module.css';

const logger = getLogger('MapList');
const snapPoints = [ // +ve integers: px from bottom; -ve integers: px from top; fractions: percentages
    0.9,     // most of the way open
    0.4,     // a bit less than halfway open
    120,     // just the top of the sheet, including the handle
];

// All three of these are about the same on a mobile device
const TIGHT_ZOOM = 15;
const MIN_BOUNDS_WIDTH = 0.01;
const MIN_BOUNDS_HEIGHT = 0.01;

// TODO
//   - propagate the full query in the refresh action
//   - when centering the map, take the sheet height into account
//   - put the zoom level and center into the url
type GoogleMapPlacesProps = {
    /**
     * All the place records to display on the map. This might be a mix of search
     * results, saved places and trip activities. Only those with positions will
     * be displayed.
     */
    recordsToMap: MappableRecord[],

    isMapVisible: boolean,
    selected: MappableRecord | undefined,
    onSelectionChanged: (record: MappableRecord | undefined) => unknown,
    appShellType: AppShellType,
    refreshRoute: string,

    /**
     * A unique identifier for a given mapping session. This might be a search result ID,
     * or a random number assigned when the map is first loaded. Automatic bounds recomputation
     * happens when the session ID changes, except when changing to a non-'undefined' value.
     */
    sessionId: any | undefined,

    setCurrentViewport?: (bounds: Bounds | undefined, zoomLevel: number | undefined) => unknown,
    toggleTravelScrollDrawer: () => unknown,
    currentUserId: string,
    activeTripId: string | undefined,
};

function SheetComponent({ record, showInMap, onClick }: {
    record: MappableRecord,
    showInMap: (record: MappableRecord) => unknown
    onClick: (record: MappableRecord) => unknown
}) {
    const router = useRouter();
    const { tripAdjacentUsersById } = useContext(HomeContext);
    const sheets = useContext(SheetsContext);
    const { currentTrip } = useContext(TripContext);
    const placeImages = record.type === 'place'
        ? usePlaceImages({ docid: record.place.tripdocid ?? undefined }, record.place)
        : [];

    const [ address, setAddress ] = useState<string | undefined>();

    useEffect(() => {
        if (record.address) {
            record.address().then(setAddress);
        }
    }, [ record ]);

    const titleAndLocation = record.title && record.title !== ''
        && record.location && record.location !== '' && record.title !== record.location
        ? `${record.title} - ${record.location}`
        : record.title
            ? record.title
            : record.location;
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
    return <div role='listitem' onClick={() => onClick(record)}>
        {record.type === 'place' && <PlaceImageGallery
            place={record.place}
            panel
            placeImages={placeImages}
            tripUsers={[ ...tripAdjacentUsersById.values() ]}
        />}
        { record.type === 'search-engine-result' && record.searchEngineResult.type === 'google-search' &&
            <GoogleImageGallery placeId={record.searchEngineResult.value.place_id} /> }
        { titleAndLocation &&
            <Text
                aria-label='Place Name'
                style={{ textAlign: 'center' }}
                pt="xs"
                px="md"
                fw={500}
            >
                {titleAndLocation}
            </Text>
        }
        <Text
            aria-label='Address'
            style={{ textAlign: 'center', textOverflow: 'ellipsis', overflow: 'hidden' }}
            px='md'
            lineClamp={1}
            color='dimmed'
            size="sm">
            {address}
        </Text>
        <Group px="md" py="xs" justify='space-between'>
            { record.type === 'place' && record.place.placeclass === PlaceClassType.Saved &&
                <Text
                    size='sm'
                    onClick={() => navigateToPlace(router, (record as PlaceRecord).place)}
                >
                    Show Saved Place
                </Text>
            }
            { record.type === 'place' && record.place.placeclass === PlaceClassType.Activity &&
                <Text
                    size='sm'
                    onClick={() => navigateToPlace(router, (record as PlaceRecord).place)}
                >
                    Show in Trip
                </Text>
            }
            <Text size='sm' onClick={() => showInMap(record)}>
                Open in Maps
            </Text>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                { record.type === 'place' && record.place.placeclass === PlaceClassType.Activity
                    && <ActivityDetailsBottom place={(record as PlaceRecord).place} /> }
                { record.type === 'place' && record.place.placeclass === PlaceClassType.Saved
                    && <SavedPlaceBottom place={record.place} showMapIcon={false} /> }
                { (record.type === 'search-engine-result')
                    && <Text
                        size='sm'
                        onClick={async () => {
                            const result = record.searchEngineResult;
                            // TODO extract the activity type and propagate it somehow: extractPlaceTypeFromGoogleSuggestion(result.value)
                            //   This will take a bit of care since the data is stored in different fields for saved vs trip activities
                            showAddEditPlace(
                                sheets,
                                {
                                    title: record.title,
                                    activityType: record.searchEngineResult.placeType,
                                    location: record.location,
                                    address: await record.address(),
                                    locality: await record.locality(),
                                    placeid: result.type === 'google-search' ? result.value.place_id : undefined,
                                    lat: record.position.lat,
                                    lng: record.position.lng,
                                },
                                currentTrip
                            );
                        }}>
                        Save to Travel Scroll
                    </Text>
                }
            </div>
        </Group>
    </div>;
}

export function GoogleMapPlaces(props: GoogleMapPlacesProps) {
    const homeData = useContext(HomeContext);

    if (homeData.userTrips || props.isMapVisible) {
        // If we haven't gotten fully connected yet (per userTrips) and we're rendering
        // the map in the background, don't bother loading. This reduces initial network load,
        // since Google Maps does a network fetch.
        return <GoogleMapPlacesInternal {...props} />;
    } else {
        return <>
            <div>
                <TSLoader id='GoogleMapPlaces' layout='fill-content-area' />
            </div>
        </>;
    }
}

function GoogleMapPlacesInternal(
    {
        recordsToMap,
        isMapVisible,
        selected,
        onSelectionChanged,
        appShellType,
        refreshRoute,
        sessionId: sessionIdParam,
        setCurrentViewport,
        toggleTravelScrollDrawer,
        currentUserId,
        activeTripId,
    }: GoogleMapPlacesProps)
    : JSX.Element {
    // TODO if this hasn't been loaded, we should periodically try again when we go online,
    //  perhaps using the 'nonce' option.
    const { isLoaded } = useJsApiLoader(googleMapsLoaderOptions);

    const theme = useMantineTheme();
    const colorScheme = useColorScheme();
    const [ mapRef, setMapRef ] = useState<google.maps.Map>();
    const [ mapMode, setMapMode ] = useState<'roadmap' | 'hybrid'>('roadmap'); // TODO remember user preference
    const { getPosition } = useGeolocationData(false); // We never track here -- we only use position on-demand within this component
    const [ renderState, setRenderState ] = useState<'unrendered' | 'first-with-zero' | 'first-with-results' | 'steady'>('unrendered');
    const sheets = useContext(SheetsContext);
    const { currentTrip } = useContext(TripContext);

    // Since we perform side effects (re-fitting) based on state, we need to use a state-based sessionId as well.
    // Otherwise, we end up performing re-fitting operations with meta-stable values -- params from a render
    // invocation, and state from the previously-set values. By using state for both, we line up the lifecycles.
    const [ sessionId, setSessionId ] = useState(sessionIdParam);

    const [ center, setCenter ] = useState<google.maps.LatLngLiteral>({
        lat: !selected ? 40 : selected?.position?.lat ?? 0,
        lng: !selected ? -70 : selected?.position?.lng ?? 0,
    });

    const selectedSheetRef = useRef<SheetRef>();

    const router = useRouter();

    const zoomToAtLeast = (targetZoom: number) => {
        const currentZoom = mapRef?.getZoom();
        const rounded = Math.round(targetZoom);
        if (currentZoom === undefined || currentZoom < rounded) {
            logger.log(`zoomToAtLeast(${targetZoom}) (rounded to ${rounded}). Prev: ${currentZoom}`);
            mapRef?.setZoom(rounded);
        }
    };

    const zoomTo = (item: MappableRecord) => {
        zoomToAtLeast(TIGHT_ZOOM);
        mapRef?.setCenter(item.position);
        mapRef?.panBy(0, 50); // TODO compute this based on the map height and the sheet reveal position
    };

    const jumpToCoordinates = async (position: Position) => {
        const coords = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        };
        logger.log(`jump to coordinates: ${JSON.stringify(coords)}`);
        zoomToAtLeast(TIGHT_ZOOM);
        mapRef?.setCenter(coords);
    };

    useEffect(() => {
        if (Capacitor.isNativePlatform() && mapMode === 'roadmap' && isMapVisible) {
            const promise = StatusBar.setStyle({ style: Style.Light });
            return () => { promise.finally(() => StatusBar.setStyle({ style: Style.Dark })); };
        }

        return undefined;
    }, [ mapMode, isMapVisible ]);

    useEffect(() => {
        if (selected) {
            selectedSheetRef?.current?.snapTo(1);
            zoomTo(selected);
        }
    }, [ selected?.id ]);

    useEffect(() => {
        // See commentary on sessionId state declaration
        setSessionId(sessionIdParam);
    }, [ sessionIdParam ]);

    const [
        mappableRecordsByPosition,
        mappableRecordPositionKeys, // distinct and in-order wrt. 'recordsToMap'
    ] = useMemo(() => {
        if (!recordsToMap) {
            return [ new Map<PositionKey, [MappableRecord, ...MappableRecord[]]>(), [] as string[] ];
        }

        // create map with key of placeids and arrays of corresponded places
        const recordsByPosition = new Map<PositionKey, [MappableRecord, ...MappableRecord[]]>();
        const recordPositions: string[] = [];
        recordsToMap.forEach(record => {
            if (record.position) {
                const key = positionToKey(record.position);
                const recordsAtPosition = recordsByPosition.get(key);
                if (!recordsAtPosition) {
                    recordsByPosition.set(key, [ record ]);
                    recordPositions.push(key); // only add an id to the list when we encounter a new place
                } else {
                    recordsAtPosition.push(record);
                }
            }
        });

        // we reverse the position keys due to the way that the marker clusterer assigns z-indexes
        recordPositions.reverse();

        return [ recordsByPosition, recordPositions ];
    }, [ recordsToMap ]);

    const recomputeBounds = (recordsToConsider: Map<string, [MappableRecord, ...MappableRecord[]]> | undefined) => {
        const bounds = new google.maps.LatLngBounds();
        let itemsFound = false;
        recordsToConsider?.forEach((item, key) => {
            const first = item[0];
            // When rendering a trip map, we don't want to use the non-trip stuff when computing bounds.
            if (appShellType !== AppShellType.Trip
                || (first.type === 'place' && first.place.placeclass === PlaceClassType.Activity)) {
                itemsFound = true;
                bounds!.extend(first.position);
            }
        });

        if (!itemsFound) {
            bounds.extend({ lat: 90, lng: -90 });
            bounds.extend({ lat: -90, lng: 30 });
        }

        const width = bounds.getSouthWest().lng() - bounds.getNorthEast().lng();
        const height = bounds.getNorthEast().lat() - bounds.getSouthWest().lat();

        if (width < MIN_BOUNDS_WIDTH) {
            const boundsCenter = bounds.getCenter();
            bounds.extend({ lat: boundsCenter.lat() + MIN_BOUNDS_WIDTH / 2, lng: boundsCenter.lng() });
            bounds.extend({ lat: boundsCenter.lat() - MIN_BOUNDS_WIDTH / 2, lng: boundsCenter.lng() });
        }
        if (height < MIN_BOUNDS_HEIGHT) {
            const boundsCenter = bounds.getCenter();
            bounds.extend({ lat: boundsCenter.lat(), lng: boundsCenter.lng() + MIN_BOUNDS_HEIGHT / 2 });
            bounds.extend({ lat: boundsCenter.lat(), lng: boundsCenter.lng() - MIN_BOUNDS_HEIGHT / 2 });
        }

        try {
            mapRef?.fitBounds(bounds!);
        } catch (ex) {
            console.error(ex);
        }
    };

    const refresh = () => {
        onSelectionChanged(undefined);
        recomputeBounds(mappableRecordsByPosition);
        router.push(refreshRoute);
    };

    useEffect(() => {
        if (!mapRef) {
            return;
        }
        switch (renderState) {
            case 'steady':
                return;
            case 'unrendered':
                if (recordsToMap.length === 0) {
                    setRenderState('first-with-zero');
                } else {
                    setRenderState('first-with-results');
                }
                break;
            case 'first-with-zero':
                if (recordsToMap.length > 0) {
                    setRenderState('first-with-results');
                }
                break;
            case 'first-with-results':
                setRenderState('steady');
                break;
        }
    }, [ mapRef, recordsToMap ]);

    useEffect(() => {
        // When the map 'sessionId' changes, do a bounds recomputation. This allows us to avoid
        // re-fitting just due to changes in hits to be mapped.
        if (mapRef && (sessionId !== undefined || renderState !== 'steady')) {
            if (selected) {
                zoomTo(selected);
            } else {
                recomputeBounds(mappableRecordsByPosition);
            }
        }
    }, [ mapRef, sessionId, renderState ]);

    const clusterOptions = useMemo(
        () => ({
            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
        }),
        []
    );

    //show in Map - takes to external google maps or apple maps depending on platform
    const showInMap = (record: MappableRecord) => {
        switch (record.type) {
            case 'place':
                openNatively(sheets, record.place);
                break;
            case undefined:
                notifications.show({ message: 'No place is selected!!' });
                break;
            case 'search-engine-result':
                notifications.show({ message: 'TODO Opening search results is not yet implemented' });
                break;
        }
    };

    const selectionSheet = <Sheet
        aria-label='Map Selection Detail'
        style={{
            zIndex: 100, // In _app.tsx, we set the header to 99 and the footer to 101, so we can put this in between
        }}
        ref={selectedSheetRef}
        mountPoint={document.getElementById(sheetsMountPointId) ?? undefined}
        isOpen={isMapVisible && !!selected}
        onClose={() => onSelectionChanged(undefined)}
        snapPoints={snapPoints}
        initialSnap={1}
    >
        <Sheet.Container>
            <Sheet.Header
                style={{
                    backgroundColor: colorScheme === 'dark'
                        ? theme.colors.dark[5]
                        : theme.colors.light as unknown as string }} />
            <Sheet.Content
                className='tscroll-footer'
                style={{
                    backgroundColor: colorScheme === 'dark'
                        ? theme.colors.dark[5]
                        : theme.colors.light as unknown as string,
                }}>
                <Sheet.Scroller>
                    { selected && <SheetComponent
                        key='selected-sheet-component'
                        record={selected}
                        showInMap={showInMap}
                        onClick={() => { }}
                    /> }
                </Sheet.Scroller>
            </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop style={{ backgroundColor: 'transparent' }} />
    </Sheet>;

    if (!isLoaded) {
        return <>
            {selectionSheet}
            <div>
                <TSLoader id='GoogleMapPlacesInternal' layout='fill-content-area' />
            </div>
        </>;
    }

    const jumpToCurrentPosition = async () => {
        const position = await getPosition();
        if (position) {
            // TODO detect if we've manually reframed in the meantime, and suppress the position jump if so
            await jumpToCoordinates(position);
        }
    };

    const toggleMapMode = () => {
        switch (mapMode) {
            case 'hybrid':
                setMapMode('roadmap');
                break;
            case 'roadmap':
                setMapMode('hybrid');
                break;
        }
    };

    return (
        <>
            {selectionSheet}
            {<div style={{ height: '100%', position: 'relative', zoom: '110%' }}>
                <GoogleMap
                    mapContainerStyle={{ height: '100%' }}
                    center={center}
                    onLoad={map => setMapRef(map)}
                    tilt={0} // prevent tilting even if 45° imagery is available
                    options={{
                        minZoom: 2,
                        mapTypeId: mapMode,
                        mapTypeControl: false,
                        streetViewControl: false,
                        fullscreenControl: false,
                        keyboardShortcuts: false,
                        zoomControl: false,
                        gestureHandling: 'greedy',
                        isFractionalZoomEnabled: false,
                    }}
                    onBoundsChanged={() => {
                        if (setCurrentViewport) {
                            const googleBounds = mapRef?.getBounds();
                            const newCenter = googleBounds && validateLatLng('center', googleBounds.getCenter());
                            const sw = googleBounds && validateLatLng('sw', googleBounds.getSouthWest());
                            const ne = googleBounds && validateLatLng('ne', googleBounds.getNorthEast());
                            if (googleBounds && newCenter && sw && ne) {
                                const bounds = {
                                    center: positionFromGoogleLatLng(newCenter),
                                    southWest: positionFromGoogleLatLng(sw),
                                    northEast: positionFromGoogleLatLng(ne),
                                };
                                setCurrentViewport(bounds, mapRef?.getZoom());
                            } else {
                                setCurrentViewport(undefined, mapRef?.getZoom());
                            }
                        }
                    }}
                    onClick={(e: google.maps.MapMouseEvent | google.maps.IconMouseEvent) => {
                        onSelectionChanged(undefined);

                        if (selected) {
                            // When we click anywhere on a map (including on a POI) while a selection is active,
                            // simply dismiss the selection. This avoids opening POIs when the intention is probably
                            // just to dismiss the selection.
                            e.stop();
                            return;
                        }

                        const { placeId } = e as google.maps.IconMouseEvent;
                        if (!placeId) {
                            // We've clicked in non-POI map space -- close the existing POI window
                            closePOIWindow();
                            return;
                        }

                        const position = positionFromLatLng({ lat: e.latLng?.lat(), lng: e.latLng?.lng() });
                        const editPoiWindow = () => {
                            const poiWindow = document.querySelector('.poi-info-window');
                            if (poiWindow) {
                                const link: HTMLAnchorElement | null = poiWindow.querySelector('.view-link a');
                                if (link) {
                                    link.href = '';
                                    link.text = "Add to Travel Scroll";
                                    link.onclick = async event => {
                                        event.preventDefault(); // prevent the actual link traversal from happening

                                        // It's important to fetch these values inside the click listener. In Safari,
                                        // the same strings captured at link edit time get cleared to the empty string
                                        // by the time the event handler click happens.
                                        const title = (poiWindow.querySelector('.title') as HTMLDivElement)?.innerText;
                                        const address = [ ...poiWindow.querySelectorAll('.address-line').values() ]
                                            .map(line => (line as HTMLDivElement).innerText)
                                            .join(', ');
                                        await showAddEditPlace(
                                            sheets,
                                            {
                                                placeid: placeId,
                                                title,
                                                location: title,
                                                address,
                                                lat: position?.lat,
                                                lng: position?.lng,
                                            },
                                            currentTrip);
                                        closePOIWindow();
                                    };
                                }
                                return true;
                            } else {
                                return false;
                            }
                        };

                        // The div isn't added in time for the onClick event, but will be there after a render cycle.
                        if (!editPoiWindow()) {
                            setTimeout(editPoiWindow);
                        }
                    }}
                    onDblClick={() => {
                        const zoom = mapRef?.getZoom();
                        if (zoom) {
                            const scaled = Math.round(zoom + 1);
                            logger.log(`Double-click: setting zoom to ${scaled}`);
                            mapRef?.setZoom(scaled);
                        }
                    }}
                >
                    <>
                        {mappableRecordPositionKeys && isMapVisible && <MarkerClusterer
                            options={clusterOptions}
                            maxZoom={TIGHT_ZOOM}
                            gridSize={30}
                            zoomOnClick
                            minimumClusterSize={5}
                            calculator={calculator}
                        >
                            {clusterer => {
                                return <div>{mappableRecordPositionKeys?.map((id, index) => {
                                    // We compare IDs instead of instances since our instances may have changed
                                    // due to selection logic.
                                    // TODO consider re-mapping the selection in an effect to allow for strict equality
                                    const results = mappableRecordsByPosition?.get(id);
                                    if (!results) {
                                        return null;
                                    } else {
                                        const item = results[0];

                                        return <Marker
                                            zIndex={index}
                                            key={item.id}
                                            title={item.title}
                                            position={item.position}
                                            onClick={() => onSelectionChanged(item)}
                                            icon={iconForItem(results, selected, currentUserId, activeTripId)}
                                            clusterer={clusterer}
                                        />;
                                    }
                                })}</div>;
                            }}
                        </MarkerClusterer>}
                        <PositionMarker isMapVisible={isMapVisible} />
                    </>
                </GoogleMap>
                { isMapVisible &&
                    [
                        {
                            top: "calc(1rem + env(safe-area-inset-top))",
                            left: "1rem",
                            right: undefined,
                            icon: <IconMenu2 size={18} />,
                            onClick: () => toggleTravelScrollDrawer(),
                        },
                        {
                            top: "calc(4rem + env(safe-area-inset-top))",
                            left: "1rem",
                            right: undefined,
                            icon: <IconMap size={18} />,
                            onClick: toggleMapMode,
                        },
                        {
                            top: "calc(1rem + env(safe-area-inset-top))",
                            left: undefined,
                            right: "1rem",
                            icon: <MdRefresh size={18} />,
                            onClick: refresh,
                        },
                        {
                            top: "calc(4rem + env(safe-area-inset-top))",
                            left: undefined,
                            right: "1rem",
                            icon: <MdGpsFixed size={18} />,
                            onClick: jumpToCurrentPosition,
                        },
                    ].map((icon, index) => (
                        <ActionIcon
                            key={index}
                            className={mapMode === 'roadmap' || colorScheme === 'dark' ? classes.darkButton : classes.lightButton}
                            style={{
                                position: "absolute",
                                top: icon.top,
                                left: icon.left,
                                right: icon.right,
                            }}
                            size="lg"
                            radius="xl"
                            onClick={icon.onClick}
                        >
                            {icon.icon}
                        </ActionIcon>
                    ))
                }
                { isMapVisible && <SlideZoomer
                    minZoom={2}
                    maxZoom={21}
                    mapRef={mapRef}
                    background={mapMode === 'roadmap' || colorScheme === 'dark' ? 'dark' : 'light'}
                /> }
            </div>}
        </>
    );
}

function calculator(markers: string | any[], numStyles: number) {
    const index = 2; //corresponds to color of cluster icon out of ~5
    const count = markers.length;
    //var dv = count;
    //while (dv !== 0) {
    //dv = parseInt((dv / 10).toString, 10);
    //index++;
    //}
    //index = Math.min(index, numStyles);

    return {
        text: count.toString(),
        index,
        //title: "s"
    };
}

function closePOIWindow() {
    const existingPoiWindow = document.querySelector('.poi-info-window');
    const dialog = existingPoiWindow?.parentElement?.closest('[role="dialog"]');
    dialog?.querySelector('button')?.click();
}

const smallSize = 40;
const largeSize = 80;
function iconForItem(
    results: [MappableRecord, ...MappableRecord[]],
    selected: { id: string } | undefined,
    currentUserId: string,
    activeTripId: string | undefined,
) {
    const item = results[0];
    const isSelected = item.id === selected?.id;

    // scan the other records to see if the place is a favorite or want-to-go of the current user
    const selfSavedCategories = Array.from(new Set(results
        .filter(record =>
            record.type === 'place'
            && (record as PlaceRecord).place.userid === currentUserId
            && record.place.placeclass === PlaceClassType.Saved)
        .flatMap(record => (record as PlaceRecord).place.categories)
        .filter(savedType => !!savedType) as SavedType[]));

    if (item.type === 'place') {
        const url = mapIconUrlForPlace(item.place, selfSavedCategories, activeTripId);

        const scaledSize = isSelected
            ? new google.maps.Size(largeSize, largeSize)
            : new google.maps.Size(smallSize, smallSize);

        return { url, scaledSize };
    } else {
        // TODO come up with different icons for search results
        const smallBlueIcon = { url: URLs.ActiveTrip.TripActivity.BlueDot.map, scaledSize: new google.maps.Size(smallSize, smallSize) };
        const largeBlueIcon = { url: URLs.ActiveTrip.TripActivity.BlueDot.map, scaledSize: new google.maps.Size(largeSize, largeSize) };
        return isSelected ? largeBlueIcon : smallBlueIcon;
    }
}

function isPositionValid(position: Position | undefined): position is Position {
    if (!position) {
        return false;
    } else if (position.coords?.latitude !== null && position.coords?.latitude !== undefined
            && position.coords?.longitude !== null && position.coords?.longitude !== undefined) {
        return true;
    } else {
        const msg = `Received a non-null userPosition that contains null coords!
         coords=${position.coords}; lat=${position.coords?.latitude}; lng=${position.coords?.longitude}`;
        notifications.show({
            title: `Google Maps precondition failure`,
            message: msg,
            autoClose: true,
        });
        logger.warn(msg);
        return false;
    }
}

function validateLatLng(context: string, latLng: google.maps.LatLng) {
    const isValid = !!latLng.lat && !!latLng.lng;
    if (isValid) {
        return latLng;
    } else {
        const msg = `Invalid latLng for '${context}'! lat: ${latLng.lat}; lng: ${latLng.lng}`;
        notifications.show({ autoClose: true, title: 'Google Maps precondition failure', message: msg });
        logger.warn(msg);
        return undefined;
    }
}

function SlideZoomer(props: {
    minZoom: number,
    maxZoom: number,
    mapRef: google.maps.Map | undefined,
    background: 'dark' | 'light',
}) {
    const [ dragStartData, setDragStartData ] = useState<{ y: number, zoom: number }>();
    const [ lastY, setLastY ] = useState<number>();

    useEffect(() => {
        if (dragStartData === undefined) {
            setLastY(undefined);
        }
    }, [ dragStartData ]);

    function handleMove(clientY: number) {
        setLastY(clientY);
        if (dragStartData !== undefined) {
            const offset = clientY - dragStartData.y;
            const newZoom = dragStartData.zoom + offset / 60;
            const targetZoom = Math.min(
                props.maxZoom,
                Math.max(
                    props.minZoom,
                    Math.round(newZoom)));
            if (targetZoom !== props.mapRef?.getZoom()) {
                logger.log(`Slide-zooming to ${targetZoom}`);
                props.mapRef?.setZoom(targetZoom);
                Haptics.impact({ style: ImpactStyle.Light });
            }
        }
    }

    const delta = dragStartData === undefined || lastY === undefined ? 0 : lastY - dragStartData.y;
    return <div
        style={{
            position: 'absolute',
            top: `calc(15rem + env(safe-area-inset-top) + ${delta}px)`,
            right: '1rem',
            // we translate this so that both the button and the slider div end up
            // sharing the same centerpoint regardless of extra affordances
            transform: 'translate(0, -50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
        }}
        onMouseDown={event => {
            if (event.buttons === 1) {
                const zoom = props.mapRef?.getZoom();
                if (zoom) {
                    setLastY(event.clientY);
                    setDragStartData({ y: event.clientY, zoom });
                }
            } else {
                setDragStartData(undefined);
            }
        }}
        onMouseMove={event => {
            if (event.buttons === 1) {
                handleMove(event.clientY);
            }
        }}
        onMouseUp={event => {
            setDragStartData(undefined);
        }}
        onTouchStart={event => {
            const zoom = props.mapRef?.getZoom();
            if (zoom) {
                setLastY(event.touches[0].clientY);
                setDragStartData({ y: event.touches[0].clientY, zoom });
            }
        }}
        onTouchMove={event => {
            handleMove(event.touches[0].clientY);
        }}
        onTouchEnd={() => {
            setDragStartData(undefined);
        }}
    >
        <FaAngleDoubleUp
            style={{
                visibility: dragStartData === undefined ? 'hidden' : 'visible',
            }}
            color={props.background === 'dark' ? 'black' : 'white'}
        />
        <ActionIcon
            className={props.background === 'dark' ? classes.darkButton : classes.lightButton}
            variant={props.background === 'dark' ? "filled" : "light"}
            size="lg"
            radius="xl"
        >
            <MdZoomInMap size={18} />
        </ActionIcon>
        <FaAngleDoubleDown
            style={{
                visibility: dragStartData === undefined ? 'hidden' : 'visible',
            }}
            color={props.background === 'dark' ? 'black' : 'white'}
        />
    </div>;
}

async function openNatively(sheets: SheetsData, place: Place) {
    const processedAddress = place.address?.replaceAll('\n', ' ') ?? '';

    const googleParams = [ `q=${place.title} ${place.location} ${processedAddress}` ];
    if (place.placeid) {
        googleParams.unshift(`query_place_id=${place.placeid}`);
    }
    const googleMapsUrl = `https://maps.google.com/maps?${googleParams.join('&')}`;

    if ("platform" in navigator) {
        if ((navigator.platform.indexOf("iPhone") !== -1) ||
            (navigator.platform.indexOf("iPad") !== -1) ||
            (navigator.platform.indexOf("iPod") !== -1)) {
            const appleMapsParams = [ `q=${place.title}` ];
            if (place.address) {
                appleMapsParams.push(`address=${place.location}, ${place.address.replaceAll('\n', ',')}`);
            } else {
                const position = positionFromLatLng(place);
                if (position) {
                    appleMapsParams.push(`ll=${position.lat},${position.lng}`);
                }
            }
            const appleMapsUrl = `https://maps.apple.com/?${appleMapsParams.join('&')}`;

            // if we're on iOS, ask the user for Apple Maps or Google
            const choice = await showModalAsync(
                sheets,
                "Open With...",
                [
                    { title: "Google Maps", url: googleMapsUrl },
                    { title: "Apple Maps", url: appleMapsUrl },
                ]);
            if (choice.type === 'choice') {
                window.open(choice.choice.url);
            }
        } else {
            // else use Google
            window.open(googleMapsUrl);
        }
    } else {
        window.open(googleMapsUrl);
    }
}

function PositionMarker({ isMapVisible }: { isMapVisible: boolean }) {
    const { currentPosition } = useGeolocationData(isMapVisible); // TODO clear the position if it's stale

    // TODO it'd be good to add an accuracy circle here, perhaps via somehow setting the icon
    //   to a well-known block of SVG and searching the DOM for it, and then adding an accuracy
    //   circle around it. Also, it'd be nice if the circle stroke throbbed, again perhaps via css
    if (isPositionValid(currentPosition)) {
        return <Marker
            position={{ lat: currentPosition.coords.latitude, lng: currentPosition.coords.longitude }}
            icon={{
                path: (window as any).google?.maps?.SymbolPath?.CIRCLE,
                fillColor: 'blue',
                fillOpacity: 1,
                strokeColor: 'blue',
                strokeWeight: 6,
                strokeOpacity: 0.3,
                scale: 5,
            }}
        />;
    } else {
        return null;
    }
}
