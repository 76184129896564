import { WebPlugin } from '@capacitor/core';

import type { CapacitorNativeSupportPlugin } from './definitions';
export class CapacitorNativeSupportWeb extends WebPlugin implements CapacitorNativeSupportPlugin {
    async updateWidgetData(): Promise<void> {
    }

    async fetchExifData(): Promise<void> {
    }

    async getProcessIds(): Promise<{ currentProcessId: number, initialProcessId: number } | undefined> {
        return undefined;
    }
    async mapSearch(): Promise<any> {
        throw new Error("mapSearch() not supported in web");
    }
}
