import {
    collection, doc,
    DocumentData,
    FirestoreDataConverter,
    QueryDocumentSnapshot,
    SnapshotOptions,
    WithFieldValue, writeBatch,
} from "firebase/firestore";
import { useEffect, useRef } from "react";
import { Capacitor } from "@capacitor/core";
import { BackgroundRunner } from "@capacitor/background-runner";
import { App } from "@capacitor/app";
import { notifications } from "@mantine/notifications";
import { isSchengen } from "./countries/countries";
import { TSDate } from "./time";
import { getLogger } from "./logger";
import { Position } from "./position";
import { forwardGeocodeViaNativeGeocoder, reverseGeocode } from "./geocoder";
import { convertUndefinedsAndDateTimes } from "./interfaces";
import { FirebaseRefs, useCollection, useFirebaseRefs } from "./firebase";
import { LoggedInUserData, UserData } from "./userdata";
import { MultiMap, splitArray } from "../../shared/collections";
import { TrackingRecord } from "../capacitor-scripts/background-util";

const logger = getLogger("visa-analysis");

function hasSchengen(record: DateRecord): boolean {
    return record.locations.find(location => isSchengen(location.countryCode)) !== undefined;
}

function hasLocation(record: DateRecord, countryCode: string): boolean {
    return record.locations.find(location => location.countryCode === countryCode) !== undefined;
}

export const VISA_ANALYSIS_LOOKBACK = 180;
const allowed = 90;

export type RegionStats = {
    startOfCurrentWindow: TSDate
    endOfCurrentWindow: TSDate
    daysRemainingInCurrentWindow: number
    daysAvailableInCurrentWindow: number
    plannedDaysInCurrentWindow: number
};
export type VisaAnalysis = {
    start: TSDate
    dateRecords: DateRecord[]
    schengenDays: DateRecord[]
    usDays: DateRecord[]
    nonSchengenCounts: Map<string, number>
    schengenPosture: 'good' | 'borderline' | 'bad'
    usPosture: 'good' | 'borderline' | 'bad'
    schengen: RegionStats,
    us: RegionStats,
    toString(): string
};

function analyze(dateRecords: DateRecord[], from: TSDate, until: TSDate): VisaAnalysis {
    const recordsInWindow = dateRecords
        .filter(record => record.date.isEqualOrAfter(from) && record.date.isEqualOrBefore(until))
        .sort(byField('date'));

    const futureRecords = dateRecords.filter(record => record.date.isAfter(until));

    const schengenDays = recordsInWindow.filter(record => hasSchengen(record));
    const usDays = recordsInWindow.filter(record => hasLocation(record, "US"));
    const nonSchengenCountries = new Set(dateRecords
        .map(record => record.locations)
        .reduce((previousValue, currentValue) => previousValue.concat(currentValue), [])
        .flatMap(location => location.countryCode)
        .filter(countryCode => !isSchengen(countryCode))
    );
    const nonSchengenCounts = new Map<string, number>();
    nonSchengenCountries.forEach(countryCode => {
        nonSchengenCounts.set(countryCode, recordsInWindow.filter(record => hasLocation(record, countryCode)).length);
    });

    const calculatePosture = (count: number) =>
        count < allowed
            ? 'good'
            : count === allowed
                ? 'borderline'
                : 'bad';

    const calculateStats = (days: DateRecord[], hasRegionFunc: (dateRecord: DateRecord) => boolean): RegionStats => {
        // First, jump forward in time by the number of days we have remaining
        const naiveDaysRemaining = allowed - days.length;
        const naiveWindowEnd = until.plus({ days: naiveDaysRemaining });

        // Next, find the earliest in-region day starting 180 days prior (inclusive) to that day
        const naiveWindowStart = naiveWindowEnd.minus({ days: VISA_ANALYSIS_LOOKBACK - 1 });
        const currentWindowRecords = days
            .filter(d => d.date === naiveWindowStart || d.date.isAfter(naiveWindowStart));
        const beginningOfCurrentWindow = currentWindowRecords.length > 0 ? currentWindowRecords[0].date : until;

        // Next, move 180 days (inclusive) into the future. This is the end of the current region window.
        const endOfCurrentWindow = beginningOfCurrentWindow.plus({ days: VISA_ANALYSIS_LOOKBACK - 1 });

        // Finally, look at the planned activities to see if we remain within limits
        const plans = futureRecords.filter(record => record.date.isEqualOrBefore(endOfCurrentWindow));
        const plannedInRegion = plans.filter(record => hasRegionFunc(record));

        return {
            startOfCurrentWindow: beginningOfCurrentWindow,
            endOfCurrentWindow,
            daysRemainingInCurrentWindow: endOfCurrentWindow.compareTo(until),
            daysAvailableInCurrentWindow: allowed - currentWindowRecords.length,
            plannedDaysInCurrentWindow: plannedInRegion.length,
        };
    };

    return {
        start: from,
        dateRecords,
        schengenDays,
        usDays,
        nonSchengenCounts,
        schengenPosture: calculatePosture(schengenDays.length),
        usPosture: calculatePosture(usDays.length),
        schengen: calculateStats(schengenDays, hasSchengen),
        us: calculateStats(usDays, record => hasLocation(record, 'US')),
        toString(): string {
            return analysisToString(this);
        },
    };
}

export function byField<T>(field: keyof T): (a: T, b: T) => number {
    return (a, b) => {
        const aField = a[field];
        const bField = b[field];
        if (aField === bField) {
            return 0;
        } else if (typeof aField === 'object' && aField
            && 'compareTo' in aField && typeof aField.compareTo === 'function') {
            return aField.compareTo(bField);
        } else {
            return `${aField}`.localeCompare(`${bField}`);
        }
    };
}

function analysisToString(analysis: VisaAnalysis) {
    const strings = [];
    strings.push(`180-day window starts on ${analysis.start.toLocaleString()}\n`);
    strings.push(`Schengen count: ${analysis.schengenDays.length} days`);
    strings.push(`US count: ${analysis.usDays.length} days\n`);

    if (analysis.schengenDays.length > 0) {
        strings.push(`First Schengen day: ${analysis.schengenDays[0].date.toString()}`);
    }

    if (analysis.schengenPosture === 'good') {
        strings.push(`💃 You have ${allowed - analysis.schengenDays.length} days left in Schengen!`);
    } else if (analysis.schengenDays.length === allowed) {
        strings.push(`🏃‍ You have zero Schengen days!`);
    } else {
        strings.push(`👮 You are ${-1 * (allowed - analysis.schengenDays.length)} days over your Schengen quota!`);
    }
    strings.push(regionStatsToString('Schengen', analysis.schengen));
    strings.push("");

    if (analysis.usDays.length > 0) {
        strings.push(`First US day: ${analysis.usDays[0].date.toString()}`);
    }

    if (analysis.usPosture === 'good') {
        strings.push(`💃 You have ${allowed - analysis.usDays.length} days left in US!`);
    } else if (analysis.usDays.length === allowed) {
        strings.push(`🏃‍ You have zero US days!`);
    } else {
        strings.push(`👮 You are ${-1 * (allowed - analysis.usDays.length)} days over your US quota!`);
    }
    strings.push(regionStatsToString('US', analysis.us));
    strings.push("");

    const nonSchengenLines = [ ...analysis.nonSchengenCounts.entries() ]
        .map(([ location, days ]) => `${location}: ${days}`);
    strings.push(`Non-Schengen locations:`);
    strings.push(...nonSchengenLines);

    return strings.join('\n');
}

function regionStatsToString(region: string, regionStats: RegionStats) {
    return `Your current ${region} window`
        + ` starts on ${regionStats.startOfCurrentWindow.toString()}`
        + ` and ends in ${regionStats.daysRemainingInCurrentWindow} days`
        + ` on ${regionStats.endOfCurrentWindow.toString()}.`
        + ` You have ${regionStats.daysAvailableInCurrentWindow} days available,`
        + ` and your current plans include ${regionStats.plannedDaysInCurrentWindow} more days in region.`;
}

export type LocationAggregator = {
    values: () => DateRecord[]
    analyze: (from: TSDate, until: TSDate) => VisaAnalysis
};
export function newLocationAggregator(locationRecords: CoreLocationRecord[]): LocationAggregator {
    const locationsByDate = new MultiMap<string, CoreLocationRecord>();
    locationRecords.forEach(record => {
        const dateKey = record.date.toString();
        if (dateKey) {
            locationsByDate.set(dateKey, record);
        }
    });
    return {
        values: () => {
            const keys = locationsByDate.keys();
            const dateRecords: DateRecord[] = [];
            [ ...keys ].forEach(key => {
                const date = TSDate.fromString(key);
                const locationsForDay = locationsByDate.get(key);
                if (locationsForDay && locationsForDay.size > 0) {
                    dateRecords.push({ date, locations: [ ...locationsForDay ] });
                }
            });
            dateRecords.sort(byField('date'));
            return dateRecords;
        },

        analyze(from, until) {
            return analyze(this.values(), from, until);
        },
    };
}

export type CountryCode = string;
export type DateRecord = { date: TSDate, locations: CoreLocationRecord[] };

export type GeolocateRequest = { date: TSDate, coord: Position, address: string | undefined };
export async function geolocate(
    toLookUp: GeolocateRequest[],
    statusConsumer: (processed: number, total: number) => unknown
) {
    logger.log(`Starting location analysis. Record count: ${toLookUp.length}`);
    statusConsumer(0, toLookUp.length);

    const queue = [ ...toLookUp ];
    const records = new Array<CoreLocationRecord>();

    const handler = async ({ date, coord, address }: GeolocateRequest) => {
        const forwardResponse = address ? await forwardGeocodeViaNativeGeocoder(address) : undefined;
        if (forwardResponse?.countryCode) {
            records.push({ date, countryCode: forwardResponse.countryCode, source: 'activity-analysis' });
        } else {
            const reverseResponse = await reverseGeocode(coord);
            if (reverseResponse.type === 'busy') {
                queue.push({ date, coord, address });
            } else if (reverseResponse.type === 'rate-limited') {
                queue.push({ date, coord, address });
                statusConsumer(toLookUp.length - queue.length, toLookUp.length);
                logger.log(`Encountered a rate limit; sleeping ${reverseResponse.backoff} seconds`);
                await new Promise(resolve => {
                    setTimeout(resolve, reverseResponse.backoff * 1000);
                });
            } else if (reverseResponse.type === 'error') {
                logger.warn(`Failed to geocode ${JSON.stringify(coord)}: ${JSON.stringify(reverseResponse.message)}`);
            } else if (reverseResponse.result?.countryCode) {
                records.push({ date, countryCode: reverseResponse.result.countryCode, source: 'activity-analysis' });
            } else {
                logger.warn(`Got geocoding result with no country code! ${JSON.stringify(coord)} => ${JSON.stringify(reverseResponse)}`);
            }
        }
    };

    for (let record = queue.shift(); record; record = queue.shift()) {
        // eslint-disable-next-line no-await-in-loop
        await handler(record);
    }

    logger.log(`Done with location analysis. Discovered ${records.length} locations.`);
    statusConsumer(toLookUp.length, toLookUp.length);
    return records;
}

export function runVisaAnalysis(locationAggregator: LocationAggregator): VisaAnalysis {
    // We subtract 1 from the lookback window since today counts as part of the window
    return locationAggregator.analyze(
        TSDate.today().minus({ days: VISA_ANALYSIS_LOOKBACK - 1 }), TSDate.today());
}

export function dateRecordsToLocationRecords(dateRecords: DateRecord[]) {
    const convertedRecords: Omit<LocationRecord, 'id'>[] = [];
    dateRecords.forEach(record => {
        record.locations.forEach(locationRecord => {
            convertedRecords.push(locationRecord);
        });
    });
    return convertedRecords;
}

// `allLocationRecords` should contain all the records, including those in `knownLocationRecords`.
export function extractNewLocationRecords(
    allLocationRecords: CoreLocationRecord[],
    knownLocationRecords: CoreLocationRecord[]
) {
    const newLocationRecords: CoreLocationRecord[] = [];

    const knownCountryCodesByDate = new Map<string, Set<CountryCode>>();
    knownLocationRecords.forEach(location => {
        const dateString = location.date.toString();
        if (dateString) {
            if (!knownCountryCodesByDate.has(dateString)) {
                knownCountryCodesByDate.set(dateString, new Set());
            }
            knownCountryCodesByDate.get(dateString)?.add(location.countryCode);
        }
    });

    allLocationRecords.forEach(record => {
        const dateString = record.date.toString();
        if (dateString) {
            const knownCountryCodesForDate = knownCountryCodesByDate.get(dateString) ?? new Set<CountryCode>();
            if (!knownCountryCodesForDate.has(record.countryCode)) {
                knownCountryCodesForDate.add(record.countryCode);
                knownCountryCodesByDate.set(dateString, knownCountryCodesForDate);
                newLocationRecords.push(record);
            }
        }
    });

    return newLocationRecords;
}

export type CoreLocationRecord = {
    date: TSDate
    countryCode: CountryCode
    source: 'manual-entry' | 'activity-analysis' | 'provisional' | 'test' | TrackingRecord['source']
    id?: string
};
export type LocationRecord = CoreLocationRecord & {
    id: string
};

export const locationRecordConverter: FirestoreDataConverter<CoreLocationRecord | LocationRecord | undefined> = {
    toFirestore(locationRecordFields: WithFieldValue<CoreLocationRecord | LocationRecord>): DocumentData {
        return convertUndefinedsAndDateTimes(locationRecordFields, [ 'id' ]);
    },

    fromFirestore(snapshot: QueryDocumentSnapshot, options?: SnapshotOptions): CoreLocationRecord | LocationRecord | undefined {
        const date = TSDate.fromTimestamp(snapshot.get('date'));
        const countryCode = snapshot.get('countryCode');
        if (!date || !countryCode) {
            return undefined;
        }
        return {
            id: snapshot.id,
            source: snapshot.get('source'),
            date,
            countryCode,
        };
    },
};

export function useServerLocations(userData: UserData) {
    const firebaseRefs = useFirebaseRefs();
    return useCollection(
        userData.isLoggedIn && userData.firebaseUserId
            ? locationRecordsRef(firebaseRefs, userData.firebaseUserId)
            : undefined,
        'useServerLocations'
    ) as LocationRecord[] | undefined;
}

export function locationRecordsRef(firebaseRefs: FirebaseRefs, userId: string) {
    return collection(
        firebaseRefs.firestore, 'private-user-data', userId, 'location-records')
        .withConverter(locationRecordConverter);
}

export async function uploadLocationRecords(
    firebaseRefs: FirebaseRefs,
    userData: LoggedInUserData,
    allLocationRecords: CoreLocationRecord[],
    serverLocationRecords: LocationRecord[],
) {
    if (!countryTrackingEnabled(userData)) {
        return;
    }

    const recordsToUpload = extractNewLocationRecords(allLocationRecords, serverLocationRecords);
    logger.log(`Found ${recordsToUpload.length} new country records to upload`);

    const chunkedRecords = splitArray(recordsToUpload, 500); // Firebase limits batch writes to 500
    const promises = chunkedRecords.map(chunk => {
        const batch = writeBatch(firebaseRefs.firestore);
        chunk.forEach(record => {
            const docRef = doc(locationRecordsRef(firebaseRefs, userData.firebaseUserId));
            batch.set(docRef, record);
        });
        return batch.commit().catch(err => logger.warn(`Error uploading a country-tracker location batch: ${err?.toString()}`));
    });
    await Promise.all(promises);
}

export async function deleteLocationRecords(firebaseRefs: FirebaseRefs, userData: LoggedInUserData, locations: LocationRecord[] | undefined) {
    if (!locations || locations.length === 0) {
        return;
    }

    const chunkedLocations = splitArray(locations, 500);
    chunkedLocations.forEach(chunk => {
        const batch = writeBatch(firebaseRefs.firestore);
        chunk.forEach(record => {
            const docRef = doc(
                firebaseRefs.firestore, 'private-user-data', userData.firebaseUserId, 'location-records', record.id);
            batch.delete(docRef);
        });
        batch.commit().catch(err => {
            logger.warn(`Error deleting a country-tracker location batch: ${err?.toString()}`);
            notifications.show({
                title: "Error clearing location records!",
                message: "An error occurred while deleting your location records. Please try again.",
                autoClose: false,
                color: 'var(--mantine-color-red-1)',
            });
        });
    });
}

export function countryTrackingEnabled(userData: UserData) {
    return userData.countryTracking === 'enabled';
}

export function useLocationTracker(userData: UserData) {
    const firebaseRefs = useFirebaseRefs();

    const serverLocations = useServerLocations(userData);
    const serverLocationsRef = useRef(serverLocations);

    useEffect(() => { serverLocationsRef.current = serverLocations; }, [ serverLocations ]);

    const hasServerLocations = serverLocations !== undefined;
    useEffect(() => {
        if (Capacitor.isNativePlatform() && hasServerLocations) {
            const appStateChangeListener = App.addListener('appStateChange', async state => {
                if (state.isActive && userData.isLoggedIn && userData.countryTracking !== undefined) {
                    manageBackgroundActivity(
                        firebaseRefs,
                        userData.countryTracking,
                        userData as LoggedInUserData,
                        serverLocationsRef.current ?? []);
                }
            });

            let interval: NodeJS.Timer;
            if (userData.isLoggedIn && userData.countryTracking !== undefined) {
                const capturedCountryTracking = userData.countryTracking;
                App.getState().then(async state => {
                    if (state.isActive) {
                        // This instructs iOS (but not Android!) to start watching for significant location updates.
                        // Doing so will help ensure we receive updates even after the app is killed.
                        // We do this when the process first launches, but not on every re-activation, to reduce
                        // geolocation pressure, which is a battery killer.
                        await BackgroundRunner.dispatchEvent({
                            label: 'com.travelscroll.app.country-tracker',
                            event: 'startLocationWatch',
                            details: { countryTracking: capturedCountryTracking },
                        });

                        manageBackgroundActivity( // run this once immediately, for the current app activation
                            firebaseRefs,
                            capturedCountryTracking,
                            userData as LoggedInUserData,
                            serverLocationsRef.current ?? []);
                        if (capturedCountryTracking === 'enabled') {
                            interval = setInterval(
                                () => recordCurrentCountry(firebaseRefs, userData as LoggedInUserData, serverLocationsRef.current ?? []),
                                60 * 60 * 1000); // 1 hour
                        }
                    }
                });
            }

            return () => {
                appStateChangeListener.remove();
                clearInterval(interval);
            };
        } else {
            return () => {};
        }
    }, [ userData.isLoggedIn, userData.countryTracking, hasServerLocations, firebaseRefs ]);
}

async function manageBackgroundActivity(
    firebaseRefs: FirebaseRefs,
    countryTracking: 'enabled' | 'disabled',
    userData: LoggedInUserData,
    serverLocations: LocationRecord[]
) {
    const locations = await updateLocationTrackerState(countryTracking);
    if (countryTracking === 'enabled') {
        await recordCountriesForLocations(firebaseRefs, locations, userData, serverLocations);
    }
}

async function recordCurrentCountry(firebaseRefs: FirebaseRefs, userData: LoggedInUserData, serverLocations: LocationRecord[]) {
    const latestRecords = await fetchLocationsAndTranscribeLogs();
    await recordCountriesForLocations(firebaseRefs, latestRecords, userData, serverLocations);
}

async function fetchLocationsAndTranscribeLogs() {
    // TODO if tracking is enabled, it'd be good to validate permissions, if possible.
    const tracked = await BackgroundRunner.dispatchEvent({
        label: 'com.travelscroll.app.country-tracker',
        event: 'fetchLocations',
        details: {},
    }) as unknown as { [key: string]: TrackingRecord };
    await transcribeLogs();
    if (tracked) {
        const items = Object.values(tracked);
        return items.filter(record => !!record.coords);
    } else {
        return [];
    }
}

async function transcribeLogs() {
    const logs = await BackgroundRunner.dispatchEvent({
        label: 'com.travelscroll.app.country-tracker',
        event: 'fetchLogs',
        details: {},
    }) as unknown as { [key: string]: string };
    if (logs) {
        Object.values(logs).forEach(msg => {
            logger.log(`background log: ${msg}`);
        });
    }
}

async function updateLocationTrackerState(countryTracking: "enabled" | "disabled"): Promise<TrackingRecord[]> {
    if (countryTracking === 'enabled') {
        return fetchLocationsAndTranscribeLogs();
    } else {
        await BackgroundRunner.dispatchEvent({
            label: 'com.travelscroll.app.country-tracker',
            event: 'stopLocationWatch',
            details: {},
        });
        await transcribeLogs();
        return [];
    }
}

export async function triggerBackgroundRunner() {
    if (Capacitor.isNativePlatform()) {
        await BackgroundRunner.dispatchEvent({
            label: 'com.travelscroll.app.country-tracker',
            event: 'recordCountryLocationIfEnabled',
            details: {},
        });
        await transcribeLogs();
    }
}

async function recordCountriesForLocations(
    firebaseRefs: FirebaseRefs,
    locations: TrackingRecord[],
    userData: LoggedInUserData,
    serverLocations: LocationRecord[],
) {
    logger.log(`Reverse-geocoding ${locations.length} location records to get country information`);

    const geocodedRecords: CoreLocationRecord[] = [];
    for (let i = 0; i < locations.length; i++) {
        const location = locations[i];
        try {
            // eslint-disable-next-line no-await-in-loop
            const geocoded = await reverseGeocode({ lat: location.coords.latitude, lng: location.coords.longitude });
            if (geocoded.type === 'success' && geocoded.result) {
                geocodedRecords.push({
                    date: TSDate.fromString(location.localDate),
                    countryCode: geocoded.result.countryCode,
                    source: location.source,
                });
            } else {
                // TODO we could do better here -- we could enqueue values for retry, for example.
                logger.warn(`Failed to geocode location: ${JSON.stringify(location)}. ${JSON.stringify(geocoded)}`);
            }
        } catch (err) {
            logger.warn(`Error trapped while reverse-geocoding: ${JSON.stringify(err)}`);
        }
    }

    // Delete the records now that we've gotten this far. The upload promise won't complete
    // until after the records have made it to the server, which might be an eternity if offline.
    // And we want to clean up so that we don't end up with an ever-growing backlog of records
    // to process.
    await BackgroundRunner.dispatchEvent({
        label: 'com.travelscroll.app.country-tracker',
        event: 'deleteLocations',
        details: {},
    });

    if (geocodedRecords.length > 0) {
        // We only continue processing if we've found records and haven't encountered any
        // geocoding errors.

        const sourceBreakdown: { [key: string]: number } = {};
        for (const record of geocodedRecords) {
            const count = sourceBreakdown[record.source] ?? 0;
            sourceBreakdown[record.source] = count + 1;
        }
        logger.log(`Analyzing ${geocodedRecords.length} geocoded records. Breakdown: ${JSON.stringify(sourceBreakdown)}`);

        await uploadLocationRecords(firebaseRefs, userData, geocodedRecords, serverLocations);
    }
}
