import { useCallback, useMemo, useState } from 'react';

export type BackgroundJob = {
    name: string
    status: 'pending' | 'success' | 'failure'
    percentComplete: number
    updatePercentComplete: (newPercentage: number) => unknown
    failJob: () => unknown
};

export type BackgroundJobs = {
    jobs: BackgroundJob[]
    registerJob: (name: string) => BackgroundJob
};

export function useBackgroundJobsContextState(): BackgroundJobs {
    // TODO we could reduce state notifications if we break this up and manage state by moving between collections.
    //   That seems harder to get right, howewver, and for probably minimal benefit.
    const [ jobs, setJobs ] = useState<BackgroundJob[]>([]);

    const register = useCallback((name: string) => {
        const job: BackgroundJob = {
            name,
            status: 'pending',
            percentComplete: 0,
            updatePercentComplete: (newPercentage: number) => {
                job.percentComplete = newPercentage;
                if (newPercentage >= 1) {
                    job.status = 'success';
                    setTimeout(() => {
                        // after 30 seconds, clear the successful job from the list
                        setJobs(current => {
                            return current.filter(j => j !== job);
                        });
                    }, 30000);
                }
                setJobs(current => ([ ...current ]));
            },
            failJob: () => {
                job.status = 'failure';
                setJobs(current => ([ ...current ]));
            }
        };
        setJobs(current => ([ ...current, job ]));
        return job;
    }, []);

    return useMemo(
        () => ({
            jobs,
            registerJob: register,
        }),
        [ jobs ]);
}
