import { useMemo, useState } from 'react';
import { Carousel } from '@mantine/carousel';
import { Container, Space, Text } from '@mantine/core';
import { useColorScheme } from "@mantine/hooks";
import { Globe } from 'framework7-icons-plus/react';
import { collection } from 'firebase/firestore';
import { useGoogleImages, revokeCachedGooglePlaceImages } from "../../lib/google";
import {
    imageConverter,
    Place,
    PlaceImage,
    UserClass,
} from '../../lib/interfaces';
import { useCollection, useFirebaseRefs } from "../../lib/firebase";
import { ImageLightbox, ImageSpec } from "../ImageLightbox";
import { TSCachedImg } from "../TSImageCache";
import { TSLoader } from "../TSLoader";

export type PlaceImageWithUrl = PlaceImage & { url: string };
export function useTripImages({ docid }: { docid: string | undefined | null }): PlaceImageWithUrl[] {
    const firebaseRefs = useFirebaseRefs();
    const picturesCollectionRef = docid
        ? collection(firebaseRefs.firestore, 'trips', docid, 'pictures').withConverter(imageConverter)
        : undefined;

    const results = useCollection(picturesCollectionRef, 'useTripImages');

    return useMemo(
        () => {
            return (results ?? [])
                .filter(result => result.status !== 'upload-pending')
                .filter(result => !!result.url) as PlaceImageWithUrl[];
        },
        [ results ]
    );
}

export function usePlaceImages({ docid }: { docid: string | undefined }, place: Pick<Place, "docid"> | undefined): PlaceImage[] {
    const plcImages = useTripImages({ docid })
        .filter((pic) => place?.docid === pic.activityId) ?? [] as PlaceImage[];
    return plcImages.sort((a, b) => a.activityOrder! > b.activityOrder! ? 1 : -1);
}

function buildGoogleImages(
    pics: { url400h: string, html_attributions: string[] }[] | undefined,
    forceAttribution: boolean,
    imageHeight: number,
    onFetchFailure: () => unknown,
) {
    return pics?.map(pic =>
        <TSCachedImg
            key={pic.url400h}
            style={{ height: imageHeight, position: 'relative' }}
            src={pic.url400h}
            lazyLoading={false}
            alt='Google image'
            onFetchFailure={onFetchFailure}
        >
            { forceAttribution && <Globe
                height={24}
                width={24}
                color="white"
                style={{
                    position: "absolute",
                    bottom: "5px",
                    left: "5px",
                }}
            /> }
        </TSCachedImg>);
}

function ImageCarousel(props: { isPanel: boolean, children: JSX.Element[] }) {
    const colorScheme = useColorScheme();
    return <Container
        p={0}
        m={0}
        style={{
            backgroundColor: colorScheme === 'dark'
                ? `var(--mantine-color-dark-${props.isPanel ? 5 : 8})`
                : `var(--mantine-color-gray-${props.isPanel ? 2 : 0})`,
        }}
    >
        {props.children.length > 0 && <Carousel
            controlSize={props.isPanel ? 14 : 26}
            px={5}
            align={props.isPanel && props.children.length > 1 ? 'start' : 'center'}
            includeGapInSize={false}
            slideGap={props.isPanel ? '5px' : 'md'}
            slideSize={props.isPanel ? '40%' : '70%'}
            dragFree={props.isPanel}
            styles={{
                control: {
                    '&[ dataInactive ]': {
                        opacity: 0,
                        cursor: 'default',
                    },
                },
            }}
        >
            {props.children}
        </Carousel>}
    </Container>;
}

export function GoogleImageGallery(props: { placeId: string }) {
    const [ isLightboxOpen, setLightboxOpen ] = useState(false);
    const [ lightboxImageIndex, setLightboxImageIndex ] = useState(0);
    const { googlePics } = useGoogleImages(props.placeId);

    if (googlePics) {
        const slides = buildGoogleImages(googlePics, true, 100, () => revokeCachedGooglePlaceImages(props.placeId)) ?? [];
        return <>
            <ImageCarousel isPanel>
                {slides.map((slide, index) =>
                    <Carousel.Slide
                        key={index}
                        onClick={() => {
                            setLightboxImageIndex(index);
                            setLightboxOpen(true);
                        }}
                    >
                        {slide}
                    </Carousel.Slide>
                )}
            </ImageCarousel>
            <ImageLightbox
                images={googlePics}
                isOpen={isLightboxOpen}
                close={() => {
                    setLightboxImageIndex(0);
                    setLightboxOpen(false);
                }}
                placeholderText={() => "Google Image"}
                initialImageIndex={lightboxImageIndex}
            />
        </>;
    } else {
        // TODO replace this with a skeleton
        return <TSLoader id='google-images-loading' layout='inline-centered' />;
    }
}

export function PlaceImageGallery(
{
    place,
    placeImages,
    panel,
    tripUsers,
}: {
    place: Place,
    placeImages: PlaceImage[],
    panel: boolean,
    tripUsers: UserClass[]
}): JSX.Element {
    const [ isOpen, setOpen ] = useState(false);
    const [ lightboxImages, setLightboxImages ] = useState<{ url: string }[]>([]);
    const pictureSlides = placeImages?.map(pic => {
        const pictureUser = (tripUsers ?? []).find(user => user.uid === pic.publisher);
        return <ImageWithFallback key={pic.docid} pictureUser={pictureUser} panel={panel} pic={pic} />;
    }) as JSX.Element[] ?? [];

    const googleHeight = panel
        ? 100
        : placeImages.length > 0
            ? 75
            : 200;
    const { googlePics } = useGoogleImages(place.placeid);
    const googleSlides = buildGoogleImages(
        googlePics,
        false,
        googleHeight,
        () => place.placeid && revokeCachedGooglePlaceImages(place.placeid), // TODO trigger a retry
    ) ?? [];

    const placeImageList: (ImageSpec | { url: string })[] = placeImages.map((img) => ImageSpecInit(img));
    //Google images are just a url.
    const googleImageList = googlePics ?? [];

    if (panel) {
        placeImageList.push(...googleImageList);
        pictureSlides.push(...googleSlides);
    }

    const [ lightboxImageIndex, setLightboxImageIndex ] = useState(0);

    return (
        <>
            <ImageCarousel isPanel={panel}>
                {pictureSlides.map((_slide: JSX.Element, index: number) => (
                    <Carousel.Slide
                        key={index}
                        onClick={() => {
                            setLightboxImageIndex(index);
                            setLightboxImages(placeImageList);
                            setOpen(true);
                        }}
                    >
                        {_slide}
                    </Carousel.Slide>
                ))}
            </ImageCarousel>
            {!panel && <Space h={10} />}
            {!panel &&
                <ImageCarousel isPanel={panel}>
                    {googleSlides.map((_slide: JSX.Element, index: number) => (
                        <Carousel.Slide
                            key={index}
                            onClick={() => {
                                setLightboxImageIndex(index);
                                setLightboxImages(googlePics ?? []);
                                setOpen(true);
                            }}
                        >
                            {_slide}
                        </Carousel.Slide>
                    ))}
                </ImageCarousel> }

            <ImageLightbox
                images={lightboxImages}
                isOpen={isOpen}
                close={() => {
                    setLightboxImageIndex(0);
                    setOpen(false);
                }}
                placeholderText={img => 'activityId' in img && img.activityId ? "Photo" : "Google Image"}
                initialImageIndex={lightboxImageIndex}
            />
        </>
    );
}
export function ImageSpecInit(img: PlaceImage):
    { url: string; url400h: string; tripId: string; docid: string; activityId: string; } {
    return {
        url: img.url ?? '',
        url400h: img.url400h ?? '',
        tripId: img.tripId ?? '',
        docid: img.docid ?? '',
        activityId: img.activityId ?? '',
    };
}

export function ImageWithFallback({ pictureUser, panel, pic }:
    { pictureUser: UserClass | undefined, panel: boolean, pic: PlaceImage }) {
    const initials = pictureUser?.displayName!.match(/(^\S\S?|\s\S)?/g)!.map(v =>
        v.trim()).join("").match(/(^\S|\S$)?/g)!.join("").toLocaleUpperCase() ?? '';
    const [ imageSrc, setImageSrc ] = useState<string | null>(pic.url400h ?? null);
    return <TSCachedImg
        style={{ height: panel ? '100px' : '200px' }}
        src={imageSrc ?? null}
        onFetchFailure={() => {
            setImageSrc(pic.url ?? null);
        }}
        alt='Trip Photo'
        lazyLoading={false}
    >
        <Text
            size='xl'
            color="white"
            style={{
                position: "relative",
                top: panel ? "75px" : "170px",
                left: panel ? "0px" : "5px",
            }}
        >{initials}
        </Text>
    </TSCachedImg>;
}
