import { ReactElement, useMemo, useState } from 'react';

// Invokes `provider` at most one time, once `trigger` transitions to `true`.
// Before that happens, returns `children`. Once it's been rendered the first time,
// the same instance is re-used for the rest of the lifecycle.
//
// This is useful for deferring loading until some condition is met, to reduce
// the amount of initial load activity etc.
export function DeferredLoad({ trigger, provider, fallback }: {
    trigger: boolean,
    provider: () => ReactElement | null,
    fallback: ReactElement | null,
}) {
    const [ hasBeenLoaded, setHasBeenLoaded ] = useState(trigger);
    const shouldRender = hasBeenLoaded || trigger;

    return useMemo(
        () => {
            if (shouldRender) {
                setHasBeenLoaded(true);
                return provider();
            } else {
                return fallback;
            }
        },
        [ provider, shouldRender, fallback ]
    );
}
