import { BackgroundImage, Button, Center, Image, Stack, Title, useMantineTheme } from "@mantine/core";
import { useColorScheme } from "@mantine/hooks";
import { useContext } from "react";
import Link from "next/link";
import { signInWithEmailAndPassword } from "firebase/auth";
import { UserContext } from "../lib/context";
import { encodeUrlStateForRedirect } from "../lib/urls";
import { useFirebaseAuth } from "../lib/firebase";
import { testConfig } from "../lib/config";
import { TSAppShell } from "./TSAppShell/TSAppShell";
import { TSLoader } from "./TSLoader";
import { LoggedInUserData } from "../lib/userdata";

export function RequiresAuthnAndData(
    { isLoading, childProvider, supportsCachedAuth }:
    {
        isLoading: boolean,
        childProvider: (user: LoggedInUserData) => JSX.Element,
        supportsCachedAuth?: boolean,
    }): JSX.Element {
    const user = useContext(UserContext);

    switch (user.status) {
        case "cached-data-available":
        case "logged-in": {
            const isLoggedInEnough = supportsCachedAuth || user.status === 'logged-in';
            if (!isLoggedInEnough || isLoading || user.firebaseUserId === undefined) {
                return <TSAppShell header={{ canOpenMenu: false }} ariaLabel='Loading...'>
                    <TSLoader id='RequiresAuthAndData-loading' layout='fill-content-area' />
                </TSAppShell>;
            } else {
                return childProvider(user as LoggedInUserData);
            }
        }

        case "logged-out":
            return <LoginAppShell />;

        case "unknown":
            return <TSAppShell header={{ canOpenMenu: false }} ariaLabel='Loading...'>
                <TSLoader id='RequiresAuthAndData-unknown' layout='fill-content-area' />
            </TSAppShell>;
    }
}

function LoginAppShell() {
    const auth = useFirebaseAuth();

    const colorScheme = useColorScheme();
    const theme = useMantineTheme();

    return <TSAppShell
        header={{ title: 'Sign In', canOpenMenu: false }}
        ariaLabel='Sign In'
    >
        <Center mih="100%">
            <Stack align="center">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Image style={{ width: '50px' }} src="./images/logo.png" />
                    <Title>Travel Scroll</Title>
                </div>
                <BackgroundImage
                    h={300}
                    radius='md'
                    src="./images/tslogin.jpg"
                    style={{
                        backgroundColor: colorScheme === 'dark' ? 'var(--mantine-color-dark-6)' : 'var(--mantine-color-gray-5)',
                        padding: 1,
                        borderRadius: theme.radius.md,
                        border: 1,
                    }} />
                <Title order={3}>Travel Like a Pro</Title>
                { testConfig.isTestEnv && <Button onClick={async () => {
                    await signInWithEmailAndPassword(
                        auth, testConfig.testUsers[0].username, testConfig.testUsers[0].password);
                }}>
                    Fake Log in
                </Button> }
                <Link passHref href={`/enter?redirect=${(encodeUrlStateForRedirect())}`}>
                    <Button className="btn-blue">Log in</Button>
                </Link>
            </Stack>
        </Center>
    </TSAppShell>;
}
