import { Haptics, ImpactStyle, NotificationType } from "@capacitor/haptics";
import { testConfig } from "./config";

const hapticsEnabled = !testConfig.isTestEnv || process.env.NEXT_PUBLIC_HAPTICS_MODE === 'enabled';

export async function saveConfirmationHaptic() {
    if (hapticsEnabled) {
        await Haptics.impact({ style: ImpactStyle.Medium });
    }
}

export async function errorHaptic() {
    if (hapticsEnabled) {
        await Haptics.notification({ type: NotificationType.Error });
    }
}
