import { PropsWithChildren } from "react";
import { Button, Text, useMantineTheme } from "@mantine/core";
import { useColorScheme } from "@mantine/hooks";
import { UpdateTripSectionType } from "../../lib/interfaces";

import classes from './StepWizard.module.css';

export function StepWizard(props: {
    steps: string[],
    currentStep: number,
    setCurrentStep: (step: number) => unknown,
    nextStep: () => unknown,
    onDone: () => unknown,
    updateSection?: UpdateTripSectionType
} & PropsWithChildren) {
    const theme = useMantineTheme();
    const colorScheme = useColorScheme();

    const prevStep = () => props.setCurrentStep(Math.max(props.currentStep - 1, 0));
    return <div
        className={classes.contentSection}
        style={{paddingBottom: 'calc(env(safe-area-inset-bottom, 0) + 1rem)'}}>
        <div
            style={{
                display: 'flex',
                width: '100%',
                paddingBottom: '0.5rem',
                justifyContent: 'space-between',
                alignItems: 'baseline',
            }}
            aria-label='Steps'
        >
            { (props.updateSection !== 'Delete') &&
            (props.steps.map((step, index) => {
                const style = index === props.currentStep
                    ? {backgroundColor: colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}
                    : undefined;
                return <Text
                    key={index}
                    fw={index === props.currentStep ? '600' : undefined}
                    size={index === props.currentStep ? 'md' : 'xs'}
                    style={{
                        ...style,
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                        borderRadius: "0.5rem",
                    }}
                    aria-current={index === props.currentStep ? 'step' : false}
                    aria-label={step}
                    onClick={() => props.setCurrentStep(index)}
                >
                    {index + 1}. {step}
                </Text>;
            }))
        }</div>
        {props.children}
        <div style={{
            marginTop: "auto",
            display: "flex",
            gap: '1rem',
            paddingTop: '0.5rem',
        }}>
            <div id="buttonSpacer" style={{marginRight: "auto"}}/>
            {props.currentStep !== 0 && !(props.updateSection === 'Location' || props.updateSection === 'TitleDate') &&
                <Button variant="default" onClick={prevStep}>
                    Back
                </Button>
            }
            {(props.currentStep < 2 && !(props.updateSection === 'Location' || props.updateSection === 'Delete' || props.updateSection === 'TitleDate'))
                && <Button onClick={props.nextStep} aria-label='Continue'>Continue</Button>}
            {(props.currentStep > 1 || props.updateSection === 'TitleDate') &&
                <Button onClick={props.onDone} aria-label='Done'>
                    Done
                </Button>
            }
            {(props.updateSection === 'Delete') &&
                <Button color='red' onClick={props.onDone}>
                    Permanently Delete Trip
                </Button>
            }
        </div>
    </div>;
}
