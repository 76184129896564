import { useColorScheme } from "@mantine/hooks";
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
    ActionIcon,
    Button,
    FocusTrap,
    Group,
    List,
    Menu,
    Space, Tabs,
    Text,
    Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from "@mantine/notifications";
import { IconEdit } from '@tabler/icons-react';
import { collection, doc, setDoc } from 'firebase/firestore';
import {
    CheckmarkCircleFill,
    Flag,
    HandThumbsdown,
    HandThumbsdownFill,
    HandThumbsup,
    HandThumbsupFill,
    Heart,
    PhotoOnRectangle,
    PlusBubbleFill,
    Star,
    Trash,
} from 'framework7-icons-plus/react';
import { useRouter } from 'next/navigation';
import { createComment, usePlaceComments } from "../../lib/comments";
import { UserContext } from '../../lib/context';
import { useCollection, useDoc, useFirebaseRefs } from "../../lib/firebase";
import { saveConfirmationHaptic } from "../../lib/haptics";
import { useTripById, useUserById, useUsersById } from "../../lib/hooks";
import {
    firebaseRecordToVote,
    Place,
    placeConverter,
    PlaceImage,
    Trip,
    Vote,
} from '../../lib/interfaces';
import { useKeyboardScrollEffect } from '../../lib/keyboard';
import {
    deletePlace,
    insertSavedPlace,
    navigateToPlace,
    updateTripActivity, useCoincidentSavedPlaces,
} from "../../lib/placeFunctions";
import { positionFromLatLng } from "../../lib/position";
import { raceOrNotify } from "../../lib/promises";
import { AddEditPlace } from '../AddEditPlace/addeditplace';
import { CommentSimple } from '../Comment/Comment';
import { ImageUploader, ImageUploaderTrigger } from '../ImageUploader/ImageUploader';
import { RearrangeImages } from '../RearrangeImages/RearrangeImages';
import { RelatedPlaces } from "../RelatedPlaces";
import { PlaceImageGallery, usePlaceImages } from '../SavedPlacesAppShell/PanelImageGallery';
import { SheetsContext, SheetState } from "../Sheets";
import { tabsProps } from "../tabs";
import { TripAppShellFooter } from "../TripAppShell/TripAppShellFooter";
import { TSAppShell } from '../TSAppShell/TSAppShell';
import { TSRightSideMenu } from "../TSAppShell/TSRightSideMenu";
import { TSDatePicker } from "../TSDatePicker";
import { TSLoader } from "../TSLoader";
import { showModalAsync } from "../TSModal";
import { TSScrollable } from "../TSScrollable";
import { TSSheet } from "../TSSheet/TSSheet";

import classes from "./TripActivityScreen.module.css";
import { ActivityIcon } from "../PlaceDetailIcons";
import { TravelersSheet } from "../TripAppShell/TravelersSheet";
import { useQueryParams } from "../../lib/urls";

export function StarHeartOrFlag(props: { place: Place }) {
    const firebaseRefs = useFirebaseRefs();
    const router = useRouter();
    const sheets = useContext(SheetsContext);
    const userData = useContext(UserContext);

    const coincidentSaved = useCoincidentSavedPlaces(props.place);
    const savedPlace = coincidentSaved && coincidentSaved.length > 0 ? coincidentSaved[0] : undefined;

    const savePlace = (category: 'Favorite' | 'Want to go') => {
        raceOrNotify(
            insertSavedPlace(firebaseRefs, userData, props.place, category),
            500,
            `Marked ${props.place.location} as a ${category}`,
            `Failed to mark ${props.place.location} as a ${category}!`,
        );
    };

    const canBeSaved = !!positionFromLatLng(props.place);
    const category = savedPlace?.categories && savedPlace.categories.length > 0
        ? savedPlace.categories[0]
        : undefined;
    return <>
        <ActionIcon
            variant="transparent"
            size="md"
            radius='xl'
            onClick={async () => {
                if (!canBeSaved) {
                    notifications.show({ message: "You can only save activities that have coordinates!" });
                } else if (savedPlace) {
                    navigateToPlace(router, savedPlace);
                } else {
                    const response = await showModalAsync(sheets, 'Pick a category', [
                        { title: 'Favorite', id: 'favorite' },
                        { title: 'Want to go', id: 'want' },
                    ]);
                    if (response.type === 'choice') {
                        switch (response.choice.id) {
                            case 'favorite':
                                savePlace('Favorite');
                                break;
                            case 'want':
                                savePlace('Want to go');
                                break;
                        }
                    }
                }
            }}
        >
            { !savedPlace && <Star height={24} width={24} /> }
            { category === 'Favorite' && <Heart height={24} width={24} /> }
            { category === 'Want to go' && <Flag height={24} width={24} /> }
        </ActionIcon>
    </>;
}

function ActivityDetailsInnards(
    {
        place,
        titleAtTop,
        placeImages,
    }: {
        place: Place,
        titleAtTop: boolean,
        placeImages: PlaceImage[],
    }): JSX.Element {
    const router = useRouter();
    const trip = useTripById(place.tripdocid!);
    const tripUsers = useUsersById(trip?.users);

    const showOnMap = () => {
        router.push(`/trip/${place.tripdocid!}?tab=Map&place=${place.docid}`);
    };

    return <TSScrollable keyboardHeightAware>
        { titleAtTop && <Text fw={500} style={{ textAlign: 'center' }}>{place.title}</Text> }
        <PlaceImageGallery placeImages={placeImages} place={place} panel={false} tripUsers={tripUsers ?? []} />
        <Text style={{ textAlign: 'center' }} px='md' pt='md' fw={500}>{place?.location}</Text>
        <Text
            style={{ textAlign: 'center' }}
            px='md'
            pb='xs'
            onClick={showOnMap}
            color='dimmed'
            size="sm"
        >
            {place.address}
        </Text>
        <ActivityDetailsBottom place={place} showOnMap={showOnMap} />
    </TSScrollable>;
}

export function ActivityDetailsBottom({ place, showOnMap }: { place: Place, showOnMap?: () => unknown }) {
    const tripid = place.tripdocid!;
    const placedocid = place.docid!;

    const firebaseRefs = useFirebaseRefs();
    const user = useContext(UserContext);

    const creator = useUserById(place.creatorId ?? undefined);

    const [ myVote, setMyVote ] = useState({} as Vote);
    const votesCollectionRef = collection(firebaseRefs.firestore, 'trips', tripid, 'activities', placedocid, 'votes');
    const votesDocs = useCollection(votesCollectionRef, 'ActivityDetailsBottom');
    const votes = useMemo(
        () => (votesDocs ?? []).map(voteDoc => firebaseRecordToVote(voteDoc)),
        [ votesDocs ]
    );

    const comments = usePlaceComments(place) ?? [];

    const [ showCommentEditor, setShowCommentEditor ] = useState(false);
    const commentTextAreaRef = useRef<HTMLTextAreaElement>(null as unknown as HTMLTextAreaElement);

    const trip = useTripById(place.tripdocid);
    const tripUsers = useUsersById(trip?.users);

    useKeyboardScrollEffect(commentTextAreaRef);

    useEffect(() => {
        setMyVote((votes ?? []).find((vote) => vote.useruid === user.firebaseUserId) ?? {} as Vote);
    }, [ votes ]);

    let totalVotes = 0;
    const voteList =
        votes?.map((item, index) => {
            //calculate display name with last initial
            const voteUser = tripUsers ? tripUsers.find(tripUser => tripUser.uid === item.useruid) : null;
            item.useruid ??= '';
            if ((item?.vote ?? 0) !== 0) {
                totalVotes += item.vote!;
                return <List.Item
                    key={index + item.useruid}
                    icon={
                        (item.vote === 1)
                            ? <HandThumbsupFill className={classes.actionitem} height={24} width={24} />
                            : (item?.vote === -1)
                                ? <HandThumbsdownFill className={classes.actionitem} height={24} width={24} />
                                : null
                    }
                    style={{ lineClamp: 1, textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                    <Text lineClamp={1} fw={400} size="xs" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        {voteUser?.displayName}
                    </Text>
                </List.Item>;
            } else {
                return null;
            }
        }) as JSX.Element[];

    //1) Sort Comments - Loop through sorted Comments
    comments?.sort((a, b) => b.timestamp!.toMillis() - a.timestamp!.toMillis());

    const commentList = [] as JSX.Element[];
    comments.forEach((comment, index) => {
        //Relative Time Ago
        const currentAgo = comment.timestamp!.toRelative();
        //calculate display name with last initial
        const commentUser = tripUsers ? tripUsers.find(tripUser => tripUser.uid === comment.useruid) : null;
        //2) Creaete Realtive - if index 0 print relative
        //3) Compare previous Realtive to current Raltiave
        //4) If new add timetowrite to itemChildren
        let agoToWrite: string | undefined;
        if (index === 0) {
            agoToWrite = currentAgo!;
        } else {
            // previous index is actually more recent, since the list
            // is reverse-sorted
            const previousIndexAgo =
                comments[index - 1].timestamp!.toRelative();
            if (previousIndexAgo !== currentAgo) {
                agoToWrite = currentAgo!;
            }
        }

        commentList.push(<CommentSimple
            key={index}
            comment={comment}
            commentUser={commentUser ?? undefined}
            authorimage={undefined}
            timestamp={agoToWrite}
        />);
    });

    // Update vote in firestore
    const updateVote = async (newVote: Vote) => {
        newVote.vote ??= 0;
        const ref = doc(firebaseRefs.firestore, 'trips', place?.tripdocid!, 'activities', place?.docid!, 'votes', user.firebaseUserId!);
        saveConfirmationHaptic();
        await setDoc(ref, {
            vote: newVote.vote,
            useruid: newVote.useruid ?? user.firebaseUserId!,
            activitydocid: newVote.activitydocid ?? place?.docid,
            tripdocid: newVote.tripdocid ?? place?.tripdocid,
        }, { merge: true });
    };

    const form = useForm({
        initialValues: {
            comment: '',
        },

        validate: {
            comment: (value) => (value.length < 1 ? 'Enter a comment.' : null),
        },
    });

    return <>
        <Group justify='space-between' px='md'>
            <TSDatePicker
                place={place}
                onChange={value => {
                    saveConfirmationHaptic();
                    updateTripActivity(
                        firebaseRefs,
                        place.tripdocid!,
                        place.docid!,
                        {
                            startdatetime: value[0],
                            enddatetime: value[1],
                        });
                }}
            />
            <Group gap={5} justify='right' px='0' style={{ flex: 2, gap: "0.5rem" }}>
                <ActivityIcon activity={place} fallback={false} />
                <StarHeartOrFlag place={place} />
                <CheckmarkCircleFill height={24} width={24} />
                <Text style={{ fontSize: 18 }}>{totalVotes}</Text>
            </Group>
        </Group>
        <Group px='md' pt='lg' justify="space-between">
            <ActionIcon
                variant="transparent"
                size="md"
                radius='xl'
                onClick={() => setShowCommentEditor(!showCommentEditor)}
            >
                <PlusBubbleFill height={24} width={24} />
            </ActionIcon>
            <Space />
            { showOnMap && <Text style={{ textAlign: 'center' }} size='md' onClick={showOnMap}>Show on Map</Text> }
            <Group>
                {(myVote?.vote === 1) ?
                    <ActionIcon
                        variant="transparent"
                        size="md"
                        radius='xl'
                        onClick={() => {
                            myVote.vote = 0;
                            updateVote(myVote);
                        }}
                    >
                        <HandThumbsupFill height={24} width={24} />
                    </ActionIcon>
                    : <ActionIcon
                        variant="transparent"
                        size="md"
                        radius='xl'
                        onClick={() => {
                            myVote.vote = 1;
                            updateVote(myVote);
                        }}
                    >
                        <HandThumbsup height={24} width={24} />
                    </ActionIcon>}
                {(myVote?.vote === -1) ?
                    <ActionIcon
                        variant="transparent"
                        size="md"
                        radius='xl'
                        onClick={() => {
                            myVote.vote = 0;
                            updateVote(myVote);
                        }}
                    >
                        <HandThumbsdownFill height={24} width={24} />
                    </ActionIcon>
                    : <ActionIcon
                        variant="transparent"
                        size="md"
                        radius='xl'
                        onClick={() => {
                            myVote.vote = -1;
                            updateVote(myVote);
                        }}
                    >
                        <HandThumbsdown height={24} width={24} />
                    </ActionIcon>}
            </Group>
        </Group>
        <List px="md" pt='md'>{voteList}</List>
        { showCommentEditor &&
            <FocusTrap>
                {/*onSubmit save to firebase and clear value. Allow deleting comments by author. */}
                <form onSubmit={form.onSubmit((values) => {
                    saveConfirmationHaptic();
                    createComment(firebaseRefs, user, place, values.comment);
                    form.reset();
                    setShowCommentEditor(false);
                })}
                >
                    <div style={{ paddingLeft: "0.5rem", paddingRight: "0.5rem" }}>
                        <Textarea
                            onFocus={() => commentTextAreaRef.current?.scrollIntoView()}
                            ref={commentTextAreaRef}
                            placeholder="Comment"
                            size="md"
                            {...form.getInputProps('comment')}
                            data-autofocus
                        />
                        <Group justify='right' mt="md">
                            <Button variant='default'
                                    onClick={() => {
                                        form.reset();
                                        setShowCommentEditor(false);
                                    }}>
                                Cancel
                            </Button>
                            <Button type="submit">Add</Button>
                        </Group>
                    </div>
                </form>
            </FocusTrap>
        }
        <List px="md">{commentList}</List>
        {comments.length === 0 && <>
            <Text style={{ textAlign: 'center' }} size='md' pt='md'>Nothing&apos;s been mentioned!</Text>
            { !showCommentEditor &&
                <Text td="underline" style={{ textAlign: 'center' }} size='md'
                      onClick={() => setShowCommentEditor(!showCommentEditor)}>
                    Add a comment
                </Text>
            }
            </>}
        <RelatedPlaces place={place} />
        <Text px='md' style={{ textAlign: 'left', paddingTop: "0.5rem" }} size='sm' color='dimmed'>
            Created by {creator?.displayName ?? '<unknown>'} {place.created?.toRelative() ?? ''}
        </Text>
    </>;
}

export function ActivityDetails({ trip, placeDocId, titleAtTop, placeImages }:
    { trip: Trip, placeDocId: string, titleAtTop: boolean, placeImages: PlaceImage[] }): JSX.Element {
    const firebaseRefs = useFirebaseRefs();
    const placeRef = doc(firebaseRefs.firestore, 'trips', trip.docid!, 'activities', placeDocId)
        .withConverter(placeConverter);
    const { result: place } = useDoc(placeRef, 'ActivityDetails');

    if (!place) {
        return <TSLoader id='ActivityDetails' layout='fill-content-area' />;
    } else {
        return <ActivityDetailsInnards placeImages={placeImages} place={place} titleAtTop={titleAtTop} />;
    }
}

export function TripActivityScreen({ place }: { place: Place }) {
    const router = useRouter();
    const sheets = useContext(SheetsContext);
    const colorScheme = useColorScheme();
    const user = useContext(UserContext);
    const firebaseRefs = useFirebaseRefs();
    const [ mode, setMode ] = useState<'view' | 'edit'>('view');
    const [ sheetState, setSheetState ] = useState<SheetState>();
    const imageUploaderRef = useRef<ImageUploaderTrigger>(undefined as any as ImageUploaderTrigger);
    const trip = useTripById(place.tripdocid);
    const searchParams = useQueryParams();
    const modal = searchParams.first('modal');
    const closeModal = () => {
        searchParams.replace({}, [ 'modal' ]);
    };

    useEffect(() => {
        if (mode === 'view') {
            setSheetState(undefined);
        }
    }, [ mode ]);

    const placeImages = usePlaceImages({ docid: place.tripdocid ?? undefined }, place);

    return (
        <Tabs
            {...tabsProps(colorScheme)}
            onChange={newTab => {
                if (place.tripdocid) {
                    router.push(`/trip/${place.tripdocid}${newTab === undefined ? '' : `?tab=${newTab}`}`);
                } else {
                    router.push('/');
                }
            }}
        >
            { modal === 'travelers' && trip && <TravelersSheet trip={trip} onClose={closeModal} /> }
            <TSAppShell
                ariaLabel='Trip Activity'
                activeTrip={trip ?? undefined}
                header={{
                    title: place?.title ?? undefined,
                    rightSide: <TSRightSideMenu ariaLabel='More Options'>
                        <Menu.Dropdown>
                            <Menu.Item
                                leftSection={<IconEdit height={18} />}
                                onClick={() => setMode('edit')}>
                                Edit Activity
                            </Menu.Item>
                            <Menu.Item
                                leftSection={<PhotoOnRectangle height={18} />}
                                onClick={() => {
                                    sheets.showSheetWith(
                                        context => <RearrangeImages
                                            onClose={() => context.removeSheet()}
                                            placeImages={placeImages}
                                        />,
                                        { title: "Rearrange Photos", disableDrag: true }
                                    );
                                }}>
                                Manage Photos
                            </Menu.Item>
                            <Menu.Item
                                leftSection={<Trash height={18} />}
                                onClick={() => {
                                    showModalAsync(
                                        sheets,
                                        'Delete Activity?',
                                        [
                                            { title: 'Cancel', id: 'cancel' },
                                            { title: 'Delete', type: 'destructive', id: 'remove' },
                                        ]
                                    ).then(result => {
                                        if (result.type === 'choice' && result.choice.id === 'remove') {
                                            deletePlace(firebaseRefs, place);
                                            router.push(`/trip/${place.tripdocid}`);
                                        }
                                    });
                                }}>
                                Delete Activity
                            </Menu.Item>
                        </Menu.Dropdown>
                    </TSRightSideMenu>,
                }}
            >
                { mode === 'edit' && <TSSheet
                    isOpen
                    onClose={() => setMode('view')}
                    title={`Edit ${place.title}`}>
                    <AddEditPlace
                        onClose={() => setMode('view')}
                        place={place}
                        sheetState={sheetState ?? 'opening'}
                    />
                </TSSheet> }
                <div style={{ height: '100%' }} className='tscroll-appshell-main'>
                    <ActivityDetailsInnards placeImages={placeImages} place={place} titleAtTop={false} />
                </div>

                { place.tripdocid && <ImageUploader
                    uploaderRef={imageUploaderRef}
                    tripId={place.tripdocid}
                    placeDocId={place.docid ?? undefined}
                    user={user}
                    relatedImages={placeImages}
                /> }
                <TripAppShellFooter
                    floatingActionButtonLabel='Add Photo'
                    onClickFloatingActionButton={() => {
                        imageUploaderRef.current?.click();
                    }}
                />
            </TSAppShell>
        </Tabs>
    );
}
