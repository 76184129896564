import React, { createContext, ReactElement, useContext } from "react";
import { Text } from "@mantine/core";
import { SheetContentContext, SheetsData } from "./Sheets";

import placeClasses from "./SavedPlacesList/SavedPlacesList.module.css";

export type ModalChoiceOptions = { title: string, type?: 'normal' | 'destructive' };
const InternalModalContext = createContext<SheetContentContext | undefined>(undefined);
type TSModalChoiceProps = { onClick: () => unknown } & ModalChoiceOptions;
function TSModalChoice(props: TSModalChoiceProps) {
    const modalContext = useContext(InternalModalContext);

    const style: React.CSSProperties = {
        textAlign: 'center',
        paddingTop: '0.25rem',
        paddingBottom: '0.25rem',
    };
    if (props.type === 'destructive') {
        style.backgroundColor = 'red';
        style.color = 'white';
    }

    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    return <div
        className={placeClasses.item}
        style={style}
        onClick={() => {
            modalContext?.removeSheet();
            props.onClick();
        }}>
        {props.title}
    </div>;
}

// eslint-disable-next-line react/no-unused-prop-types
type TSModalProps = { onDismiss: () => unknown, children: ReactElement<TSModalChoiceProps>[], detail?: string };
function TSModal(props: TSModalProps) {
    return <div
        style={{ paddingLeft: '1rem', paddingRight: '1rem', height: '100%', display: 'flex', flexDirection: "column" }}>
        { props.detail && <Text style={{ paddingBottom: '1rem' }}>{props.detail}</Text> }
        {props.children}
    </div>;
}

type AsyncResponse<T> = { type: 'dismiss' } | { type: 'choice', choice: T };
export async function showModalAsync<T extends ModalChoiceOptions>(
    sheets: SheetsData, title: string, choices: T[], detail?: string) {
    return new Promise<AsyncResponse<T>>(resolve => {
        sheets.showSheetWith(
            context => (
                <TSModal
                    onDismiss={() => {
                        context.removeSheet();
                        resolve({ type: 'dismiss' });
                    }}
                    detail={detail}
                >
                    {choices.map((choice, index) =>
                        <TSModalChoice
                            {...choice}
                            key={index}
                            onClick={() => {
                                context.removeSheet();
                                resolve({ type: 'choice', choice });
                            }}
                        />)
                    }
                </TSModal>),
            {
                title,
                detent: 'content-height',
                onSheetClosed: () => resolve({ type: 'dismiss' }),
            }
        );
    });
}
