import { DateTime } from "luxon";

export type LoggerFactory = (key: string) => MessageSink;
export type MessageSink = {
    log: (message: string) => void
    warn: (message: string) => void
    error: (message: string) => void
};
export type LogEntry = { timestamp: DateTime, sessionId: DateTime, level: 'log' | 'warn' | 'error', channel: string, message: string };
export type LogListener = (logEntry: LogEntry) => unknown;

type InternalLogEntry = Omit<Omit<LogEntry, 'timestamp'>, 'sessionId'> & { timestamp: number, sessionId: number };

let loggerInitialized = false;
const logListeners: Set<LogListener> = new Set();
export const getLogger: LoggerFactory = bufferedConsoleLogger();

const sessionId = DateTime.now(); // constant for the entire JS context execution
const storeName = "log-entries";

deleteOldLogs();

function storeLogEntry(entry: LogEntry) {
    const internalEntry: InternalLogEntry = {
        ...entry,
        timestamp: entry.timestamp.toMillis(),
        sessionId: entry.sessionId.toMillis(),
    };
    try {
        const dbRequest = openDatabase();
        dbRequest?.addEventListener('success', () => {
            const tx = dbRequest.result.transaction(storeName, 'readwrite');
            const objectStore = tx.objectStore(storeName);
            const insertRequest = objectStore.add(internalEntry);
            insertRequest.addEventListener('error', error => console.warn("Error adding a log entry", error));
        });
    } catch (error) {
        console.warn("Trapped error storing log entry:", error);
    }
}

const dbName = "log-db";
function openDatabase() {
    if (!('indexedDB' in global)) {
        return undefined;
    }

    const dbRequest = indexedDB.open(dbName, 1);
    dbRequest.addEventListener('upgradeneeded', () => initializeDatabase(dbRequest));
    dbRequest.addEventListener('error', error => console.warn(`Error opening db '${dbName}'`, error));
    return dbRequest;
}

function initializeDatabase(request: IDBOpenDBRequest) {
    const db = request.result;
    const objectStore = db.createObjectStore(storeName, {
        keyPath: 'id',
        autoIncrement: true,
    });

    objectStore.createIndex("timestamp", "timestamp", { unique: false });
    objectStore.createIndex("sessionId", "sessionId", { unique: false });
}

function pushEntry(entry: LogEntry) {
    storeLogEntry(entry);
    logListeners.forEach(listener => listener(entry));
}

export function registerLogListener(listener: LogListener): () => void {
    logListeners.add(listener);
    return () => logListeners.delete(listener);
}

function bufferedConsoleLogger(): LoggerFactory {
    return (key: string) => ({
        log: (message: string) => {
            pushEntry({ timestamp: DateTime.now(), channel: key, level: 'log', message, sessionId });
            console.log(`[${DateTime.now().toISO()}] [${key}] ${message}`);
        },
        warn: (message: string) => {
            pushEntry({ timestamp: DateTime.now(), channel: key, level: 'warn', message, sessionId });
            console.warn(`[${DateTime.now().toISO()}] [${key}] ${message}`);
        },
        error: (message: string) => {
            pushEntry({ timestamp: DateTime.now(), channel: key, level: 'error', message, sessionId });
            console.error(`[${DateTime.now().toISO()}] [${key}] ${message}`);
        },
    });
}

/**
 * Exported as a mechanism for consumers to guarantee initialization, even though
 * an unused import is sufficient.
 */
export function initLogger() {
    if (!loggerInitialized) {
        global.fetch = buildFetchReplacement(global.fetch);
        loggerInitialized = true;
    }
}

initLogger();

let needsFirebaseAuthTimeoutHack = true;
export function setNeedsFirebaseAuthTimeoutHack(val: boolean) {
    needsFirebaseAuthTimeoutHack = val;
}

type FetchSignature = (input: RequestInfo | URL, init?: RequestInit) => Promise<Response>;
function buildFetchReplacement(originalFetch: FetchSignature): FetchSignature {
    return async (input, init) => {
        let url: string = '<null>';
        let method: string | undefined;
        if (typeof input === 'string') {
            url = input.toString();
        } else {
            if ('url' in input) {
                url = (input as Request).url;
            } else if (input) {
                url = input.toString();
            }

            if ('method' in input) {
                method = (input as Request).method;
            }
        }

        if (!method && init && init.method) {
            method = init.method;
        } else {
            method = 'GET';
        }

        const logCompletion = logHttpBegin(method, url);
        try {
            // Firebase Auth blocks initialization on requests to these APIs.
            // See https://github.com/firebase/firebase-js-sdk/issues/4133 for details.
            let timeoutId;
            if (needsFirebaseAuthTimeoutHack
                && (url.startsWith('https://identitytoolkit.googleapis.com') || url.startsWith('https://securetoken.googleapis.com'))
                && !init?.signal) {
                if (!init) {
                    init = {};
                }
                const controller = new AbortController();
                timeoutId = setTimeout(() => controller.abort(), 2000); // 2-second timeout
                init.signal = controller.signal;
            }

            const result = await originalFetch(input, init);
            if (timeoutId !== undefined) {
                // We only want to time out the request, not the response handling.
                clearTimeout(timeoutId);
            }
            logCompletion(`${result.status}`);
            return result;
        } catch (err) {
            logCompletion("failed");
            throw err;
        }
    };
}

let fetchIdGenerator = 0;
const logger = getLogger("http");
export function logHttpBegin(method: string, url: string): (status: string) => unknown {
    const fetchId = ++fetchIdGenerator;
    const startTime = Date.now();
    logger.log(`${method}:${fetchId} ${url}`);
    return status => logger.log(`${method}:${fetchId} <= ${status} in ${Date.now() - startTime} ms`);
}

export function getLogHead(): Promise<LogEntry[]> {
    const entries: LogEntry[] = [];
    return new Promise<LogEntry[]>((resolve, reject) => {
        const dbRequest = openDatabase();
        dbRequest?.addEventListener('success', () => {
            const db = dbRequest.result;
            const tx = db.transaction(storeName, 'readonly');
            const cursor = tx.objectStore(storeName).index('timestamp').openCursor(null, 'prev');
            cursor.addEventListener('success', () => {
                const record = cursor.result?.value as InternalLogEntry | undefined;
                if (record) {
                    entries.push({
                        timestamp: DateTime.fromMillis(record.timestamp),
                        sessionId: DateTime.fromMillis(record.sessionId),
                        level: record.level,
                        channel: record.channel,
                        message: record.message,
                    });
                    cursor.result?.continue();
                } else {
                    resolve(entries.reverse());
                }
            });
            cursor.addEventListener('error', reject);
        });
    });
}

function deleteOldLogs() {
    setTimeout(() => {
        const dbRequest = openDatabase();
        dbRequest?.addEventListener('success', () => {
            const tx = dbRequest.result.transaction(storeName, 'readwrite');
            const cursor = tx.objectStore(storeName).index('timestamp').openCursor(null, 'prev');

            let recordCount = 0;
            let deletedCount = 0;
            cursor.addEventListener('success', () => {
                const record = cursor.result?.value as InternalLogEntry | undefined;
                if (record) {
                    recordCount++;
                    if (recordCount > 10000) {
                        deletedCount++;
                        cursor.result?.delete();
                    }
                    cursor.result?.continue();
                } else {
                    console.log(`Deleted ${deletedCount} old log records`);
                }
            });
        });
    }, 0);
}

export function isHistoricalLogEntry(entry: LogEntry) {
    return entry.sessionId.toMillis() !== sessionId.toMillis();
}
