import { DateTime } from "luxon";

export type ExifSubset = {
    DateTimeOriginal?: string
    OffsetTimeOriginal?: string
    GPS?: ExifGps
};

export type ExifGps = {
    AltitudeRef?: number
    DestBearingRef?: string
    Longitude?: number
    ImgDirectionRef?: string
    DestBearing?: number
    Latitude?: number
    SpeedRef?: string
    LatitudeRef?: string
    HPositioningError?: number
    Altitude?: number
    ImgDirection?: number
    Speed?: number
    DateStamp?: string
    LongitudeRef?: string
    TimeStamp?: string
};

export function pruneExif(exif: any | undefined): ExifSubset | undefined {
    if (exif) {
        return {
            DateTimeOriginal: exif.DateTimeOriginal,
            OffsetTimeOriginal: exif.OffsetTimeOriginal,
            GPS: extractGps(exif),
        };
    } else {
        return undefined;
    }
}

export function extractGps(exif: any | undefined): ExifGps | undefined {
    if (exif?.GPS) {
        const gps = {
            AltitudeRef: exif.GPS?.AltitudeRef,
            DestBearingRef: exif.GPS?.DestBearingRef,
            Longitude: exif.GPS?.Longitude,
            ImgDirectionRef: exif.GPS?.ImgDirectionRef,
            DestBearing: exif.GPS?.DestBearing,
            Latitude: exif.GPS?.Latitude,
            SpeedRef: exif.GPS?.SpeedRef,
            LatitudeRef: exif.GPS?.LatitudeRef,
            HPositioningError: exif.GPS?.HPositioningError,
            Altitude: exif.GPS?.Altitude,
            ImgDirection: exif.GPS?.ImgDirection,
            Speed: exif.GPS?.Speed,
            DateStamp: exif.GPS?.DateStamp,
            LongitudeRef: exif.GPS?.LongitudeRef,
            TimeStamp: exif.GPS?.TimeStamp,
        };

        // Prune any 'undefined' fields since Firebase doesn't like undefineds.
        Object.keys(gps).forEach(key => {
            if ((gps as any)[key] === undefined) {
                delete (gps as any)[key];
            }
        });

        if (gps.Latitude !== null && gps.Latitude !== undefined
            && gps.Longitude !== null && gps.Longitude !== undefined) {
            // If there is no lat / lon data, just return null; there's not much we'll be able
            // to do with the data.
            return gps;
        } else {
            return undefined;
        }
    } else {
        return undefined;
    }
}

export function photoTimestampFromExif(exif: ExifSubset | undefined) {
    // Sample data:
    // "DateTimeOriginal": "2023:12:05 19:59:50"
    // "OffsetTimeOriginal": "+01:00"
    if (exif?.DateTimeOriginal && exif?.OffsetTimeOriginal) {
        return DateTime.fromFormat(`${exif.DateTimeOriginal}${exif.OffsetTimeOriginal}`, "yyyy:MM:dd HH:mm:ssZZ");
    } else if (exif?.GPS?.DateStamp && exif?.GPS?.TimeStamp) {
        return DateTime.fromFormat(`${exif.GPS?.DateStamp} ${exif.GPS?.TimeStamp}`, "yyyy:MM:dd HH:mm:ss", { zone: 'UTC' });
    } else if (exif?.DateTimeOriginal) {
        return DateTime.fromFormat(`${exif.DateTimeOriginal}`, "yyyy:MM:dd HH:mm:ss", { zone: 'UTC' });
    } else {
        return undefined;
    }
}

export function convertAndroidExif(exif: any): ExifGps {
    // Android EXIF contains inline GPS records instead of nested records, like this:
    //   GPSDateStamp: "2024:01:13"
    //   GPSLatitude: "37/1,25/1,2161/100"
    //   GPSLatitudeRef: "N"
    //   GPSLongitude: "122/1,5/1,595/100"
    //   GPSLongitudeRef: "W"
    //   GPSTimeStamp: "12:53:22"
    let gps: ExifGps | undefined = undefined;
    const copy = { ...exif };
    if (exif?.GPSDateStamp) {
        gps = gps ?? {}
        gps.DateStamp = exif.GPSDateStamp;
    }
    if (exif?.GPSTimeStamp) {
        gps = gps ?? {}
        gps.TimeStamp = exif.GPSTimeStamp;
    }
    if (exif?.GPSLatitude) {
        gps = gps ?? {}
        gps.Latitude = exif.GPSLatitude;
    }
    if (exif?.GPSLatitudeRef) {
        gps = gps ?? {}
        gps.LatitudeRef = exif.GPSLatitudeRef;
    }
    if (exif?.GPSLongitude) {
        gps = gps ?? {}
        gps.Longitude = exif.GPSLongitude;
    }
    if (exif?.GPSLongitudeRef) {
        gps = gps ?? {}
        gps.LongitudeRef = exif.GPSLongitudeRef;
    }
    if (gps) {
        copy.GPS = gps;
    }
    return copy;
}

