export type PublicUserData = {
    isLoggedIn: boolean
    status: 'logged-in' | 'logged-out' | 'unknown' | 'cached-data-available'
    username?: string | null
    firebaseUserId?: string | undefined
    displayName?: string | null
    devTools?: 'enabled' | null
    photoURL?: string | null
};

export type PrivateUserData = {
    countryTracking?: 'enabled' | 'disabled'
    searchProviders?: ('apple' | 'google' | 'mock')[]
    privateDataLoaded: boolean
};

export type UserData = PublicUserData & PrivateUserData;
export type LoggedInUserData = UserData & {
    firebaseUserId: string,
    isLoggedIn: true,
    status: 'logged-in',
};

export function getInitialsShortname(displayName: String | null) {
    let initials = '';
    let shortname = '';
    if (displayName) {
        initials = displayName?.match(/(^\S\S?|\s\S)?/g)!.map(v =>
            v.trim()).join("").match(/(^\S|\S$)?/g)!.join("").toLocaleUpperCase() ?? '';
        const firstName = displayName?.split(' ').slice(0, -1).join(' ') ?? '';
        const lastName = displayName?.split(' ').slice(-1).join(' ') ?? '';
        shortname = `${firstName} ${Array.from(lastName)[0]}`;
    }
    return {initials, shortname};
}
