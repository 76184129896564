import { PropsWithChildren, useEffect, useState } from "react";

export function Delayed(props: { delay: number } & PropsWithChildren) {
    const [ show, setShow ] = useState<boolean>(props.delay === 0); // we show immediately if no delay is needed

    useEffect(() => {
        if (props.delay !== 0) {
            const timeoutId = setTimeout(() => setShow(true), props.delay);
            return () => clearTimeout(timeoutId);
        } else {
            return () => { };
        }
    }, [ props.delay ]);

    if (show) {
        return <>{props.children}</>;
    } else {
        return null;
    }
}
