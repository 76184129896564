import { RefObject, useEffect, useState } from 'react';
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';

const fakeHeightListeners: Set<(height: number | undefined) => unknown> = new Set();
export function setFakeKeyboardHeight(height: number | undefined) {
    fakeHeightListeners.forEach(listener => listener(height));
}

export function useKeyboardScrollEffect(elementRef: RefObject<HTMLElement>) {
    const { keyboardStatus } = useKeyboardInfo();

    useEffect(() => {
        if (keyboardStatus) {
            elementRef.current?.scrollIntoView({ inline: 'start', behavior: 'smooth' });
        }
    }, [ elementRef, keyboardStatus ]);
}

export function useKeyboardInfo() {
    const [ keyboardInfo, setKeyboardInfo ] = useState<
        { keyboardInfo: KeyboardInfo | undefined, keyboardStatus: 'willShow' | 'visible' | 'hidden' }
    >({ keyboardStatus: 'hidden', keyboardInfo: undefined });

    useEffect(() => {
        const fakeHeightListener = (height: number | undefined) => {
            if ((height ?? 0) > 0) {
                setKeyboardInfo({ keyboardInfo: { keyboardHeight: height ?? 0 }, keyboardStatus: 'visible' });
            } else {
                setKeyboardInfo({ keyboardInfo: undefined, keyboardStatus: 'hidden' });
            }
        };
        fakeHeightListeners.add(fakeHeightListener);

        if (Capacitor.isNativePlatform()) {
            const showListener = Keyboard.addListener('keyboardWillShow', info => {
                setKeyboardInfo({ keyboardInfo: info, keyboardStatus: 'willShow' });
            });
            const didShowListener = Keyboard.addListener('keyboardDidShow', info => {
                setKeyboardInfo({ keyboardInfo: info, keyboardStatus: 'visible' });
            });
            const hideListener = Keyboard.addListener('keyboardWillHide', () => {
                setKeyboardInfo({ keyboardInfo: undefined, keyboardStatus: 'hidden' });
            });
            return () => {
                showListener.remove();
                didShowListener.remove();
                hideListener.remove();
                fakeHeightListeners.delete(fakeHeightListener);
            };
        } else {
            return () => {
                fakeHeightListeners.delete(fakeHeightListener);
            };
        }
    }, []);

    return keyboardInfo;
}
