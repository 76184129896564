import { onValue, ref } from "@firebase/database";
import { createContext, useEffect, useState } from "react";
import { useFirebaseRefs } from "./firebase";
import { getLogger } from "./logger";

const logger = getLogger("connectivity");
export type ConnectivityData = {
    connectivityStatus: 'online' | 'offline' | 'unknown',
    databaseStatus: 'present' | 'absent' | 'unknown',
};

export function useConnectivityDataContextState() {
    const firebaseRefs = useFirebaseRefs();
    const [ connectivityData, setConnectivityData ] = useState<ConnectivityData>({
        connectivityStatus: 'unknown',
        databaseStatus: 'unknown',
    });

    useEffect(() => {
        const connectedRef = ref(firebaseRefs.database, '.info/connected');

        return onValue(connectedRef, (snap) => {
            const isConnected = snap.val() as boolean;
            const newConnectivityData: ConnectivityData = {
                connectivityStatus: isConnected === true ? 'online'
                    : isConnected === false ? 'offline'
                        : 'unknown',
                databaseStatus: 'present',
            };
            setConnectivityData(newConnectivityData);
        });
    }, [ firebaseRefs.database ]);

    useEffect(() => {
        logger.log(`Detected a change in connectivity. New status: ${connectivityData.connectivityStatus}; db: ${connectivityData.databaseStatus}`);
    }, [ connectivityData.connectivityStatus, connectivityData.databaseStatus ]);

    return connectivityData;
}

export const ConnectivityContext = createContext<ConnectivityData>({
    connectivityStatus: 'unknown',
    databaseStatus: 'unknown',
});
