import { getLogger } from "./logger";

// eslint-disable-next-line global-require
const idbcache = require('idbcache').default as IDBCache;

export type IDBCache = {
    set(key: string, val: string, time: number): Promise<void>
    get(key: string): Promise<string | null>
    remove(key: string): void
    flush(): void
};

let context: Cache;
export function defaultCache() {
    initialize();
    return context;
}

type Cache = {
    memoize<TReturn>(fn: Fn<TReturn>, ...args: any[]): Promise<TReturn>;
    fetchFromCache<TReturn>(fn: Fn<TReturn>, ...args: any[]): Promise<TReturn | undefined>;
    keyForSignature<TReturn>(fn: Fn<TReturn>, ...args: any[]): string;
    revokeKey(key: string): void;
};

export function initialize() {
    if (!context) {
        context = newCache("localstorage-cache", 7 * 24 * 60); // 1-week cache by default
    }
}
initialize();

const logger = getLogger('durable-cache');

type Fn<TReturn> = (...args: any[]) => Promise<TReturn>;

export function newCache(prefix: string, ttlMinutes: number): Cache {
    return {
        async memoize<TReturn>(fn: Fn<TReturn>, ...args: any[]): Promise<TReturn> {
            if (!fn.name) {
                throw new Error("Cannot memoize unnamed function!");
            }
            const key = this.keyForSignature(fn, ...args);
            try {
                const cached = await idbcache.get(key);
                if (cached) {
                    return JSON.parse(cached) as TReturn;
                }
            } catch (err) {
                logger.warn(`Trapped exception while checking memoization cache. Will ignore. Error: ${JSON.stringify(err)}`);
            }

            const evaluated = await fn(...args);
            const stringified = JSON.stringify(evaluated);
            try {
                await idbcache.set(key, stringified, ttlMinutes);
            } catch (err) {
                logger.warn(`Trapped exception while writing to storage. Will ignore. Error: ${JSON.stringify(err)}`);
            }

            // we parse the cached value so that the behavior is the same regardless of whether it's a cache hit or miss
            return JSON.parse(stringified);
        },

        async fetchFromCache<TReturn>(fn: Fn<TReturn>, ...args: any[]): Promise<TReturn | undefined> {
            if (!fn.name) {
                throw new Error("Cannot look up unnamed function!");
            }
            const key = this.keyForSignature(fn, ...args);
            const cached = await idbcache.get(key);
            if (cached) {
                return JSON.parse(cached) as TReturn;
            } else {
                return undefined;
            }
        },

        keyForSignature<TReturn>(fn: Fn<TReturn>, ...args: any[]): string {
            return `${prefix}:${fn.name}:${JSON.stringify(args)}`;
        },

        revokeKey(key: string) {
            idbcache.remove(key);
        },
    };
}
