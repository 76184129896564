import { useRouter } from "next/navigation";
import { Burger, Drawer, NavLink } from "@mantine/core";
import {
    IconBookmarks,
    IconClipboardCopy,
    IconEdit, IconEPassport,
    IconGlobe,
    IconHelpCircle,
    IconHistory, IconKeyboardHide, IconKeyboardShow,
    IconList,
    IconLocation,
    IconMap,
    IconPlaneDeparture, IconScript,
    IconSettings,
    IconSubtask,
    IconTrash,
    IconUsers,
} from '@tabler/icons-react';
import { Helm, PhotoOnRectangle } from 'framework7-icons-plus/react';
import { notifications } from "@mantine/notifications";
import { useContext, useMemo, useState } from 'react';
import { Capacitor } from "@capacitor/core";
import { Trip } from "../lib/interfaces";
import { useQueryParams } from "../lib/urls";
import { BackgroundJobContext, UserContext } from '../lib/context';
import { showAddEditTrip } from "./AddEditTrip/AddEditTrip";
import { SheetsContext } from "./Sheets";
import { showDeleteTrip } from "./DeleteTrip/DeleteTrip";
import { setFakeKeyboardHeight } from '../lib/keyboard';
import { BackgroundJobs } from '../lib/background-jobs';
import { countryTrackingEnabled, triggerBackgroundRunner } from "../lib/visa-analysis";

export const OVERVIEW_TAB_NAME = 'Overview';
export const TRIPS_TAB_NAME = 'Trips';
export const SAVED_PLACES_TAB_NAME = 'Saved';
export const MAP_TAB_NAME = 'Map';

const ICON_SIZE = 18;

function fixCapacitorLinks(href: string) {
    const match = href.match(/^capacitor:\/\/localhost\/(.*)/);
    if (match) {
        return `https://app.travelscroll.com/${match[1]}`;
    } else {
        return href;
    }
}

// If the calling context provides isDrawerOpen / setDrawerOpen functions, we use them; otherwise, we use internal state
export function TravelScrollMenu(props: { activeTrip?: Trip | null, drawerState?: { isDrawerOpen: boolean, setDrawerOpen: (open: boolean) => unknown } }) {
    const [ isDrawerOpen, setDrawerOpen ] = useState(false);
    const effectiveDrawerState = props.drawerState ?? { isDrawerOpen, setDrawerOpen };

    return <>
        <TravelScrollDrawer activeTrip={props.activeTrip} isOpen={effectiveDrawerState.isDrawerOpen} setOpen={effectiveDrawerState.setDrawerOpen} />
        <TravelScrollBurger isOpen={effectiveDrawerState.isDrawerOpen} setOpen={effectiveDrawerState.setDrawerOpen} />
    </>;
}

export function TravelScrollBurger(props: { canOpenMenu?: boolean, isOpen: boolean, setOpen: (isOpen: boolean) => unknown }) {
    const { jobs } = useContext(BackgroundJobContext);

    const canOpenMenu = props.canOpenMenu ?? true;

    const hasActiveJobs = useMemo(
        () => jobs.find(j => j.status === 'pending') !== undefined,
        [ jobs ]);

    return <Burger
        size="sm"
        opened={props.isOpen}
        onClick={() => props.setOpen(!props.isOpen)}
        color='white'
        variant='filled'
        aria-label={canOpenMenu ? 'Main Menu' : undefined}
        style={{
            animation: hasActiveJobs ? "rotate-360 2s ease infinite" : undefined,
        }}
    />;
}

export function TravelScrollDrawer(props: {
    activeTrip?: Trip | null,
    isOpen: boolean,
    setOpen: (isOpen: boolean) => unknown
}): JSX.Element {
    const router = useRouter();
    const userData = useContext(UserContext);
    const backgroundJobs = useContext(BackgroundJobContext);

    // TODO set the 'active' attribute on the current nav link, perhaps by parsing the route

    const nav = async (url: string) => {
        await router.push(url);
        props.setOpen(false);
    };

    return <>
        <Drawer
            title={<div style={{ paddingTop: 'env(safe-area-inset-top)' }}>Travel Scroll</div>}
            withCloseButton={false}
            opened={props.isOpen}
            size='20rem'
            onClose={() => props.setOpen(false)}
        >
            { userData.status !== 'logged-out' && <>
                <NavLink label='Home' defaultOpened={!props.activeTrip}>
                    <NavLink leftSection={<IconList height={ICON_SIZE} />} label={OVERVIEW_TAB_NAME}
                             onClick={() => nav(`/?tab=${OVERVIEW_TAB_NAME}`)} />
                    <NavLink leftSection={<IconPlaneDeparture height={ICON_SIZE} />} label={TRIPS_TAB_NAME}
                             onClick={() => nav(`/?tab=${TRIPS_TAB_NAME}`)} />
                    <NavLink leftSection={<IconBookmarks height={ICON_SIZE} />} label={SAVED_PLACES_TAB_NAME}
                             onClick={() => nav(`/?tab=${SAVED_PLACES_TAB_NAME}`)} />
                    <NavLink leftSection={<IconMap height={ICON_SIZE} />} label={MAP_TAB_NAME}
                             onClick={() => nav(`/?tab=${MAP_TAB_NAME}`)} />
                    { userData.firebaseUserId && <NavLink leftSection={<IconGlobe height={ICON_SIZE} />} label='Globe'
                        onClick={() => nav(`/traveler/${userData.firebaseUserId}/history`)} />}
                </NavLink>
                { props.activeTrip && <TripLinks activeTrip={props.activeTrip} isOpen={props.isOpen} setOpen={props.setOpen} /> }
                <NavLink
                    leftSection={<IconClipboardCopy height={ICON_SIZE} />}
                    label='Copy Link'
                    description='Copy a link to the current page'
                    onClick={() => {
                        navigator.clipboard.writeText(fixCapacitorLinks(document.location.href));
                        notifications.show({
                            title: "Done",
                            message: "Copied link to clipboard!",
                            autoClose: true,
                        });
                        props.setOpen(false);
                    }}
                />
            </> }
            { userData.status === 'logged-out' &&
                <>
                    <NavLink label='Log In' href={`/enter?redirect=${document.location.href}`} />
                    <NavLink label='Sign Up' href={`/enter?redirect=${document.location.href}`} />
                </>
            }
            <NavLink label='Preferences' defaultOpened={false}>
                { userData.status !== 'logged-out' && <NavLink
                    leftSection={<IconSettings height={ICON_SIZE} />}
                    label='Settings'
                    onClick={() => nav('/settings')} /> }
                { countryTrackingEnabled(userData) && <NavLink
                    leftSection={<IconEPassport height={ICON_SIZE} />}
                    label='Visa Analysis'
                    onClick={() => nav('/visa-analysis')} /> }
                <NavLink
                    leftSection={<IconScript height={ICON_SIZE} />}
                    label='Logs'
                    onClick={() => nav('/logs')} />
            </NavLink>
            <BackgroundJobTree backgroundJobs={backgroundJobs} />
            { userData.devTools === 'enabled' && <NavLink label='Dev Tools' defaultOpened>
                <KeyboardToggle close={() => props.setOpen(false)} />
                { Capacitor.isNativePlatform() && <NavLink
                    leftSection={<IconLocation height={ICON_SIZE} />}
                    label='Trigger location runner'
                    onClick={() => {
                        triggerBackgroundRunner();
                        props.setOpen(false);
                    }}
                /> }
                <FakeBackgroundJobLink
                    duration={10}
                    failJob={false}
                    close={() => props.setOpen(false)}
                    backgroundJobs={backgroundJobs} />
                <FakeBackgroundJobLink
                    duration={20}
                    failJob={false}
                    close={() => props.setOpen(false)}
                    backgroundJobs={backgroundJobs} />
                <FakeBackgroundJobLink
                    duration={20}
                    failJob
                    close={() => props.setOpen(false)}
                    backgroundJobs={backgroundJobs} />
            </NavLink> }
        </Drawer>
    </>;
}

function TripLinks(props: {
    activeTrip: Trip
    isOpen: boolean
    setOpen: (newState: boolean) => unknown
}) {
    const searchParams = useQueryParams();
    const router = useRouter();
    const sheets = useContext(SheetsContext);

    const openSheetOnCurrentPage = (modal: string) => {
        props.setOpen(false);
        searchParams.replace({ modal }, []);
    };

    const openTab = (tab: string) => {
        router.push(`/trip/${props.activeTrip.docid}?tab=${tab}`);
        props.setOpen(false);
    };

    return <NavLink label={props.activeTrip.title} defaultOpened>
        <NavLink leftSection={<Helm height={ICON_SIZE} width={ICON_SIZE} />}
                 label='Plans'
                 onClick={() => openTab('Plans')} />
        <NavLink leftSection={<PhotoOnRectangle height={ICON_SIZE} width={ICON_SIZE} />}
                 label='Gallery'
                 onClick={() => openTab('Gallery')} />
        <NavLink leftSection={<IconMap height={ICON_SIZE} />}
                 label='Map'
                 onClick={() => openTab('Map')} />
        <NavLink leftSection={<IconUsers height={ICON_SIZE} />}
                 label='Travelers'
                 onClick={() => openSheetOnCurrentPage('travelers')} />
        <NavLink leftSection={<IconHistory height={ICON_SIZE} />}
                 label='History'
                 onClick={() => openTab('History')} />
        <NavLink label='Update Trip'>
            <NavLink leftSection={<IconEdit height={ICON_SIZE} />} label='Title & Date'
                     onClick={async () => {
                         await showAddEditTrip(sheets, props.activeTrip, "TitleDate");
                         props.setOpen(!props.isOpen);
                     }} />
            <NavLink leftSection={<IconLocation height={ICON_SIZE} />} label='Location'
                     onClick={async () => {
                         await showAddEditTrip(sheets, props.activeTrip, "Location");
                         props.setOpen(!props.isOpen);
                     }} />
            <NavLink leftSection={<IconTrash height={ICON_SIZE} />} label='Delete' aria-label='Delete Trip'
                     onClick={async () => {
                         await showDeleteTrip(sheets, props.activeTrip);
                         props.setOpen(!props.isOpen);
                     }} />
        </NavLink>
    </NavLink>;
}

function KeyboardToggle({ close }: { close: () => unknown }) {
    const divSelector = '#ts-fake-keyboard';
    const isFakeKeyboardVisible = !!document.body.querySelector(divSelector);

    const removeFakeKeyboard = () => {
        setFakeKeyboardHeight(undefined);
        const fakeKeyboardDiv = document.body.querySelector(divSelector) as HTMLDivElement;
        if (fakeKeyboardDiv) {
            fakeKeyboardDiv.ontransitionend = () => {
                fakeKeyboardDiv.parentElement?.removeChild(fakeKeyboardDiv);
            };
            fakeKeyboardDiv.style.height = '0';
        }
        close();
    };

    if (isFakeKeyboardVisible) {
        // we use 'IconKeyboardShow' here because of the way the graphic is laid out
        return <NavLink
            leftSection={<IconKeyboardShow size={ICON_SIZE} />}
            label='Hide Fake Keyboard'
            onClick={removeFakeKeyboard}
        />;
    } else {
        // we use 'IconKeyboardHide' here because of the way the graphic is laid out
        return <NavLink
            leftSection={<IconKeyboardHide size={ICON_SIZE} />}
            label='Show Fake Keyboard'
            onClick={() => {
                const fakeKeyboardHeight = 350;

                setFakeKeyboardHeight(fakeKeyboardHeight);

                const fakeKeyboardDiv = document.createElement('div');
                fakeKeyboardDiv.id = 'ts-fake-keyboard';
                fakeKeyboardDiv.style.position = 'absolute';
                fakeKeyboardDiv.style.height = '0';
                fakeKeyboardDiv.style.left = '0';
                fakeKeyboardDiv.style.right = '0';
                fakeKeyboardDiv.style.bottom = '0';
                fakeKeyboardDiv.style.backgroundColor = 'rgba(255,255,255,0.3)';
                fakeKeyboardDiv.style.borderTop = '1px solid black';
                fakeKeyboardDiv.style.zIndex = `${Number.MAX_SAFE_INTEGER}`;
                fakeKeyboardDiv.style.backdropFilter = 'blur(8px)';
                fakeKeyboardDiv.style.transition = 'all .3s ease-in';
                fakeKeyboardDiv.style.fontSize = '48px';
                fakeKeyboardDiv.style.textAlign = 'center';
                fakeKeyboardDiv.textContent = '⌨️ Fake keyboard ⌨️ Click to remove';
                fakeKeyboardDiv.onclick = removeFakeKeyboard;
                setTimeout(() => { fakeKeyboardDiv.style.height = `${fakeKeyboardHeight}px`; });
                document.body.appendChild(fakeKeyboardDiv);
                close();
            }}
        />;
    }
}

function BackgroundJobTree(props: { backgroundJobs: BackgroundJobs }) {
    if (props.backgroundJobs.jobs.length === 0) {
        return null;
    }

    return <NavLink label='Background Jobs' defaultOpened={false}>
        {props.backgroundJobs.jobs.map((job, index) => {
            const suffix = job.status === 'pending'
                ? `${Math.round(job.percentComplete * 100)}% complete`
                : job.status === 'success'
                    ? 'success!'
                    : 'failure!';
            return <NavLink
                key={`background-job-${index}`}
                label={`${job.name}: ${suffix}`}
                leftSection={<IconSubtask height={ICON_SIZE} />}
            />;
        })}
    </NavLink>;
}

function FakeBackgroundJobLink(props: { close: () => unknown, duration: number, failJob: boolean, backgroundJobs: BackgroundJobs }) {
    return <NavLink
        leftSection={<IconSubtask height={ICON_SIZE} />}
        label={`Start ${props.duration}-second job${props.failJob ? ' that fails' : ''}`}
        onClick={() => {
            const job = props.backgroundJobs.registerJob(`Fake ${props.duration}-second Job`);
            const interval = setInterval(() => {
                if (props.failJob && job.percentComplete > 0.5) {
                    job.failJob();
                    clearInterval(interval);
                } else if (job.percentComplete < 1) {
                    job.updatePercentComplete(job.percentComplete + (1 / (props.duration * 2))); // multiply by 2 because we update every half second
                } else {
                    clearInterval(interval);
                }
            }, 500);
            props.close();
        }}
    />;
}
