import Lightbox, { useController, useLightboxState } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { notifications } from "@mantine/notifications";
import { deleteDoc, doc } from "firebase/firestore";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { MdOutlineNoPhotography, MdOutlinePhoto } from "react-icons/md";
import { Button, Menu } from "@mantine/core";
import { useContext, useEffect, useMemo } from "react";
import { Trash } from "framework7-icons-plus/react";
import { Capacitor } from "@capacitor/core";
import { StatusBar } from "@capacitor/status-bar";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import { FirebaseRefs, useFirebaseRefs } from "../lib/firebase";
import { PlaceImage } from "../lib/interfaces";
import { showModalAsync } from "./TSModal";
import { SheetsContext, sheetsMountPointId } from "./Sheets";
import { updateTrip } from "../lib/tripFunctions";
import { useTripById } from "../lib/hooks";

export type ImageSpec = {
    url: string,
    url400h: string,
    docid: string,
    tripId: string,
    activityId: string
};
export function ImageLightbox(
    {
        images,
        isOpen,
        close,
        placeholderText,
        initialImageIndex,
    }: {
        images: (ImageSpec | { url: string })[]
        isOpen: boolean
        close: () => void
        placeholderText: (image: (ImageSpec | { url: string })) => string
        initialImageIndex: number
    }): JSX.Element {

    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            if (isOpen) {
                const hidePromise = StatusBar.hide();
                ScreenOrientation.unlock();
                return () => {
                    hidePromise.finally(() => StatusBar.show());
                };
            } else {
                ScreenOrientation.lock({ orientation: 'portrait' });
                return () => { };
            }
        } else if (isOpen) {
            //Unlock Web Screen Orientation here
            return () => { };
        } else {
            //Lock Web Screen Orientation to Portrait here
            return () => { };
        }
    }, [ isOpen ]);

    const imageSpecImages = useMemo(
        () => images.map(image => ({
            src: image.url,
        })),
        [ images ]);

    return <>
        <Lightbox
            styles={{
                root: { zIndex: 300 }, // Corresponds to the header / footer / sheet index
                toolbar: {
                    paddingTop: 'env(safe-area-inset-top)', // ensure we can get to the close control
                },
            }}
            portal={{ root: document.getElementById(sheetsMountPointId) }}
            index={initialImageIndex}
            close={close}
            plugins={[ Zoom ]}
            open={isOpen}
            carousel={{ finite: true }}
            render={{
                buttonZoom: () => null,
                slideFooter: () => <ImageLightBoxFooter images={images} />,
            }}
            toolbar={{ buttons: [ "close" ] }}
            slides={imageSpecImages}
        />
    </>;
}

// delete ImagePicture in firestore
export const deleteImage = (firebaseRefs: FirebaseRefs, oldImage: PlaceImage | { url: string }) => {
    if (oldImage && Object.prototype.hasOwnProperty.call(oldImage, 'tripId')) {
        const imageSpec = oldImage as ImageSpec;
        const fref = doc(firebaseRefs.firestore, 'trips', imageSpec.tripId!, 'pictures', imageSpec.docid!);
        deleteDoc(fref);

        notifications.show({
            title: "Photo Removed",
            message: "Your photo is removed",
            autoClose: true,
        });
    }
};

function ImageLightBoxFooter(
        {
            images,
        }: {
            images: (ImageSpec | { url: string })[]
        }): JSX.Element | null {
    const firebaseRefs = useFirebaseRefs();
    const sheets = useContext(SheetsContext);
    const { currentIndex } = useLightboxState();
    const currentImage = images[currentIndex] as ImageSpec;
    if (!currentImage || !Object.prototype.hasOwnProperty.call(currentImage, 'tripId')) {
        return null;
    }
    const trip = useTripById(currentImage.tripId);
    const { close } = useController();
    return <div className='safeAreaPaddingBottom' style={{
        color: "white",
        position: "absolute",
        bottom: 10,
        right: 10,
    }}>
        <Menu shadow="md" width={200}>
            <Menu.Target>
                <Button
                    size='md'
                    p='xs'
                    color='white'
                    style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
                    aria-label='Edit Image'
                >
                    Edit
                </Button>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item
                    aria-label='Delete Photo'
                    leftSection={<Trash height={18} />}
                    onClick={() => {
                        showModalAsync(
                            sheets,
                            'Remove Photo?',
                            [
                                { title: 'Cancel', id: 'cancel' },
                                { title: 'Remove', type: 'destructive', id: 'remove' },
                            ]
                        ).then(async result => {
                            if (result.type === 'choice' && result.choice.id === 'remove') {
                                close();
                                deleteImage(firebaseRefs, currentImage);
                                if (trip?.img === currentImage.url) {
                                    updateTrip(
                                        firebaseRefs,
                                        currentImage.tripId,
                                        {
                                            img: null,
                                            img400h: null,
                                        });
                                }
                                close();
                            }
                        });
                    }}>
                    Delete Photo
                </Menu.Item>
                {(trip?.img !== currentImage.url) && <Menu.Item
                    leftSection={<MdOutlinePhoto height={18} />}
                    onClick={async () => {
                        showModalAsync(
                            sheets,
                            'Make Main Trip Photo?',
                            [
                                { title: 'Cancel', id: 'cancel' },
                                { title: 'Yes', type: 'normal', id: 'yes' },
                            ]
                        ).then(async result => {
                            if (result.type === 'choice' && result.choice.id === 'yes') {
                                close();
                                updateTrip(
                                    firebaseRefs,
                                    currentImage.tripId,
                                    {
                                        img: currentImage.url,
                                        img400h: currentImage.url400h,
                                    });
                                close();
                            }
                        });
                    }}>
                    Make Main Trip Photo
                </Menu.Item>}
                {(trip?.img === currentImage.url) && <Menu.Item
                    leftSection={<MdOutlineNoPhotography height={18} />}
                    onClick={async () => {
                        showModalAsync(
                            sheets,
                            'Clear Main Trip Photo?',
                            [
                                { title: 'Cancel', id: 'cancel' },
                                { title: 'Clear', type: 'destructive', id: 'clear' },
                            ]
                        ).then(async result => {
                            if (result.type === 'choice' && result.choice.id === 'clear') {
                                close();
                                updateTrip(
                                    firebaseRefs,
                                    currentImage.tripId,
                                    {
                                        img: null,
                                        img400h: null,
                                    });
                                close();
                            }
                        });
                    }}>
                    Clear Main Trip Photo
                </Menu.Item>}
            </Menu.Dropdown>
        </Menu>
    </div>;
}
