import { useEffect, useState } from "react";
import { Loader } from "@mantine/core";
import { getLogger } from "../lib/logger";
import { Delayed } from "./Delayed";

const logger = getLogger('TSLoader');

export function TSLoader(props: { delay?: number, layout: 'fill-content-area' | 'inline-centered', id: string }) {
    const [ startTime ] = useState(() => new Date().getTime());

    useEffect(() => {
        logger.log(`Displaying loader '${props.id}'`);
        return () => logger.log(`Dismissing loader '${props.id}'. Visible for ${new Date().getTime() - startTime}ms`);
    }, [ props.id, startTime ]);

    return <Delayed delay={props.delay ?? 100}>
        <Loader
            size="xl"
            style={{
                height: props.layout === 'fill-content-area' ? 400 : undefined,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        />
    </Delayed>;
}
