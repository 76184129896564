import { TextInput } from '@mantine/core';
import React, { useContext, useEffect, useState } from 'react';
import { useForm, UseFormReturnType } from '@mantine/form';
import { UserContext } from '../../lib/context';
import { useFirebaseRefs } from "../../lib/firebase";
import {
    GooglePlacePhoto,
    PlaceRecord,
    SearchEngineResult,
    Trip,
    UpdateTripSectionType,
} from '../../lib/interfaces';
import { SearchChipsAndList } from "../SearchUI/SearchUI";
import { Position, positionFromLatLng } from "../../lib/position";
import { raceOrNotify } from "../../lib/promises";
import { SheetsData } from "../Sheets";
import { newSearchQuery } from "../../lib/search";
import { blurOnEnterKey } from "../../lib/eventHandlers";
import { TravelerEditor } from "../TripAppShell/TravelersSheet";
import { StepWizard } from "../StepWizard/StepWizard";
import { TSDate } from '../../lib/time';
import { insertTrip, updateTrip } from '../../lib/tripFunctions';
import { ClearTextAreaRightSection } from "./ClearTextAreaRightSection";
import { computeBias } from "../../lib/geolocation";
import { TSDatePicker } from "../TSDatePicker";

import stepWizardClasses from "../StepWizard/StepWizard.module.css";

/**
 * Shows a series of modal sheets collecting information to create or edit a trip
 * including travelers
 */
export async function showAddEditTrip(sheets: SheetsData, tripTemplate: Partial<Trip>, updateSection?: UpdateTripSectionType) {
    const updateSectionDisplay = updateSection === 'TitleDate' ? 'Title & Date' : 'Location';
    const title = (() => {
        if (tripTemplate.docid) {
            return `Update ${updateSectionDisplay}`;
        } else {
            return 'Add New Trip';
        }
    })();

    sheets.showSheetWith(
        context => <AddEditTrip
            onClose={() => context.removeSheet()}
            trip={tripTemplate}
            updateSection={updateSection} />,
        {
            title,
            disableDrag: true,
        }
    );
}

type AddEditTripForm = UseFormReturnType<{
    title: string;
    startDate: Date | undefined;
    location: string;
},
    (values: {
        title: string;
        startDate: Date | undefined;
        location: string;
    }) => {
        title: string;
        startDate: Date | undefined;
        location: string;
    }>;

function SectionZeroEditTrip(props: {
    form: AddEditTripForm,
    onClear: () => void,
    values: Partial<Trip>,
    replacer: () => void,
    currentUserId: string | undefined,
    onClick: (result: PlaceRecord | SearchEngineResult) => Promise<void> }) {
    return <div
        className={stepWizardClasses.stepSection}
        style={{ flex: '1', display: 'flex', flexDirection: 'column' }}
    >
        <hr style={{ width: "100%" }} />
        <div
            className={stepWizardClasses.progress}
            style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
            }}>
            <TextInput
                label='1. Location'
                autoFocus
                required
                style={{
                    display: "inline-block",
                    flex: 1,
                }}
                {...props.form.getInputProps("location")}
                onKeyUp={blurOnEnterKey}
                rightSection={<ClearTextAreaRightSection onClear={props.onClear}/>}
            />
        </div>
        <SearchChipsAndList
            searchQuery={newSearchQuery({
                    searchTerm: (props.form.values as any).location,
                    chipConfig: {
                        tripChips: false,
                        savedPlaces: false,
                        recents: false,
                        searchEngine: true,
                    },
                    emptyQueryBehavior: "match-none",
                    bias: computeBias([ positionFromLatLng(props.values) ]),
                    searchType: 'region',
                    sources: {
                        yourSaved: false,
                        otherSaved: false,
                        recentTrips: false,
                        searchEngine: true,
                    },
                },
                props.replacer)
            }
            places={[]}
            userTrips={[]}
            currentUserId={props.currentUserId ?? undefined}
            uiState="inline"
            detailMode="results"
            notifySearchHits={props.replacer}
            onClick={props.onClick}/>
    </div>;
}

function SectionOneEditTrip(props: {
    form: AddEditTripForm,
    onInput: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onClear: () => void }) {
    return <div className={stepWizardClasses.stepSection}>
        <hr style={{ width: "100%" }} />
        <div className={stepWizardClasses.progress}>
            1. Location: <span className={stepWizardClasses.fieldValue}>{props.form.values.location}</span>
        </div>
        <div
            className={stepWizardClasses.progress}
            style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <TextInput
                required
                autoFocus
                label="2. Title:"
                style={{ display: "inline-block", flex: 1 }}
                {...props.form.getInputProps("title")}
                onInput={props.onInput}
                enterKeyHint="done"
                onKeyUp={blurOnEnterKey}
                rightSection={<ClearTextAreaRightSection onClear={props.onClear} />}
            />
        </div>
        <div style={{ flex: 1 }}>
            <TSDatePicker
                label="2. Date:"
                placeholder="When do you start?"
                dates={[ TSDate.fromDate(props.form.values.startDate) ?? null, null ]}
                onChange={value => {
                    props.form.setFieldValue('startDate', value[0]?.toLocalJSDate());
                }}
            />
        </div>
    </div>;
}

export function AddEditTrip(
    {
        trip,
        onClose,
        updateSection,
    }: {
        trip: Partial<Trip>,
        onClose: () => unknown,
        updateSection?: UpdateTripSectionType,
    }) {
    const firebaseRefs = useFirebaseRefs();
    const { firebaseUserId: currentUserId } = useContext(UserContext);
    const user = useContext(UserContext);

    const [ currentStep, setCurrentStep ] = useState(0);

    // Leave all these values undefined unless the user makes a change. Calls to setDoc() on trip edit
    // should be minimal, so that concurrent editors conflict minimally.
    const [ position, setPosition ] = useState<Position | undefined>();
    const [ googlePlaceId, setGooglePlaceId ] = useState<string | undefined>();
    const [ googleLocality, setGoogleLocality ] = useState<string>();
    const [ googlePhotos, setGooglePhotos ] = useState<GooglePlacePhoto[]>();

    // Track if the traveler IDs have been modified. Array management in firebase via setDoc()
    // is not idempotent, so if two people are modifying a trip at the same time, data can
    // be lost if we assign a value to the traveler ID field in both updates. Tracking this
    // data lets us limit this concurrency to only be an issue if two people concurrently
    // edit the travelers list, which is presumably less frequent.
    const [ travelerIds, setTravelerIds ] = useState<string[]>();

    const form = useForm({
        initialValues: {
            title: trip.title ?? '',
            startDate: trip.startdate?.toLocalJSDate(),
            location: trip.location ?? '',
        },
        validate: (values) => {
            if ((updateSection === undefined && currentStep === 0) || updateSection === 'Location') {
                return {
                    location: !values.location || values.location.trim().length < 1
                        ? 'A location is required'
                        : null,
                };
            } else if ((updateSection === undefined && currentStep === 1) || updateSection === 'TitleDate') {
                return {
                    title: !values.title || values.title.trim().length < 1 ? 'A title is required' : null,
                    startDate: !values.startDate ? 'A start date is required' : null,
                };
            } else {
                return {};
            }
        },
    });

    useEffect(() => {
        if (updateSection === 'TitleDate') {
            setCurrentStep(1);
        }
    }, [ updateSection ]);

    const upsertTrip = async () => {
        const tripDocId = trip.docid;
        const partialTrip = buildTripRecordFromInternalState();

        if (tripDocId) {
            await updateTrip(firebaseRefs, tripDocId, partialTrip);
        } else {
            await insertTrip(firebaseRefs, user, partialTrip);
        }
    };

    function buildTripRecordFromInternalState() {
        const partialTrip: Partial<Trip> = {
            startdate: TSDate.fromDate(form.values.startDate),
            location: form.values.location,
            title: form.values.title,
            locality: googleLocality,
        };

        if (position) {
            partialTrip.lat = position.lat;
            partialTrip.lng = position.lng;
        }

        if (googlePlaceId) {
            partialTrip.placeid = googlePlaceId;
        }

        if (googleLocality) {
            partialTrip.locality = googleLocality;
        }

        if (googlePhotos && googlePhotos.length > 0) {
            // These are included for old clients only; new clients look up the photo
            // from the Google place ID
            partialTrip.img = googlePhotos[0].url;
            partialTrip.img400h = googlePhotos[0].url400h;
        }

        if (travelerIds) {
            partialTrip.users = [ ...travelerIds ];
        }

        return partialTrip;
    }

    const steps = updateSection === undefined ? [ "Location", "Details", "Travelers" ] : (updateSection === "Location") ? [ "Location" ] : [ "Title & Date" ];
    const nextStep = () =>
        setCurrentStep((current) => {
            if (form.validate().hasErrors) {
                return current;
            }
            if (updateSection === undefined) {
                return current < steps.length ? Math.min(current + 1, steps.length - 1) : current;
            } else {
                return current;
            }
        });

    return <StepWizard
        updateSection={updateSection}
        steps={steps}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        nextStep={nextStep}
        onDone={() => {
            if (form.validate().hasErrors) {
                return setCurrentStep((current) => current);
            } else {
                const title = form.values.title ?? form.values.location;
                const detail = title || '';
                raceOrNotify(
                    upsertTrip(),
                    500,
                    `Saved ${detail}`,
                    `Failed to save ${detail}!`
                );
                form.reset();
                return onClose();
            }
        }}
    >
        {currentStep === 0 && <>
            <SectionZeroEditTrip
                form={form}
                onClear={() => form.setFieldValue('location', '')}
                values={trip}
                replacer={() => {}}
                currentUserId={currentUserId}
                onClick={async (result) : Promise<void> => {
                    if (result.type === 'place') {
                        if (result.place.location) {
                            form.setFieldValue('location', result.place.location);
                            if (!form.values.title || form.values.title === '') {
                                // only set the 'title' field value if we haven't done so already, either
                                // via out-of-order components or via pre-existing values (e.g., an edit)
                                form.setFieldValue('title', result.place.location);
                            }
                        }
                        setPosition(positionFromLatLng(result.place));
                        setGooglePlaceId(result.place.placeid ?? undefined);
                    } else {
                        if (result.title) {
                            form.setFieldValue('location', result.title);
                            if (!form.values.title || form.values.title === '') {
                                // only set the 'title' field value if we haven't done so already, either
                                // via out-of-order components or via pre-existing values (e.g., an edit)
                                form.setFieldValue('title', result.title);
                            }
                        }
                        const [ resultPosition, locality, photos ] = await Promise.all([
                            await result.position(),
                            await result.locality(),
                            await result.photos(),
                        ]);
                        setPosition(resultPosition);
                        setGoogleLocality(locality);
                        setGooglePhotos(photos);
                        if (result.type === 'google-search') {
                            setGooglePlaceId(result.value.place_id);
                        }
                    }

                    if (updateSection === undefined) {
                        nextStep();
                    } else {
                        if (form.validate().hasErrors) {
                            setCurrentStep((current) => current);
                        } else {
                            const title = form.values.title ?? form.values.location;
                            const detail = title || '';
                            await raceOrNotify(
                                upsertTrip(),
                                500,
                                `Saved ${detail}`,
                                `Failed to save ${detail}!`
                            );
                            form.reset();
                            onClose();
                        }
                    }
            }}/>
        </>}

        { currentStep === 1 && <SectionOneEditTrip
            form={form}
            onInput={e => form.setFieldValue('title', e.currentTarget.value)}
            onClear={() => form.setFieldValue('title', '')}/> }

        { currentStep === 2 && <div
                className={`${stepWizardClasses.stepSection} keyboard-height-padded`}
                style={{ overflowY: 'auto' }}
            >
            <hr style={{ width: '100%' }} />
            <TravelerEditor
                canEditRoster
                userIds={travelerIds ?? trip.users ?? [ currentUserId! ]}
                addUser={toAdd => {
                    // We always include the current userid here to avoid a state management bug. Since
                    // we use the null-ness of 'travelerIds' to decide whether or not to add the 'users'
                    // field to the partial trip during save, we need to ensure that newly-created trips
                    // have the self-user in the list whenever the new trip also has additional users.
                    // We have safeguards in tripFunctions:insertTrip() to guarantee that we add the
                    // self-user when inserting a trip that otherwise has no users, but if we create a
                    // new trip with additional members, we need the current user to be part of the list.
                    // Otherwise, the creator ends up excluded from their own trip.
                    setTravelerIds([ ...new Set([ currentUserId!, ...(travelerIds ?? []), toAdd.uid ]) ]);
                }}
                deleteUser={toDelete => {
                    setTravelerIds((travelerIds ?? []).filter(t => t !== toDelete.uid));
                }}
            />
        </div> }
    </StepWizard>;
}
