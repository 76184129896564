import { Group } from "@mantine/core";
import { ChevronRight } from "framework7-icons-plus/react";
import { useColorScheme } from "@mantine/hooks";
import { Place, PlaceClassType, PlaceType, SavedType } from '../lib/interfaces';
import { ActivitySVG, SavedSVG } from "./PlaceSVGs";

export function PlaceDetailIcons(props: { place: Place }) {
    const colorScheme = useColorScheme();

    const savedSvg = props.place.placeclass === PlaceClassType.Saved
        ? <SavedSVG
            mode={colorScheme}
            type={props.place.categories && props.place.categories.length > 0 ? props.place.categories[0] : 'Favorite'}
            size={24}
        />
        : null;
    const typeSvg = <ActivityIcon activity={props.place} fallback={false} />;

    return <Group wrap='nowrap' gap='xs' style={{ marginRight: 'auto' }}>
        {savedSvg}
        {typeSvg}
        <ChevronRight height={18} width={18} />
    </Group>;
}

export function ActivityIcon(props: { activity: Place, fallback: boolean }) {
    const modifier: SavedType | undefined = props.activity.categories?.includes('Favorite')
        ? 'Favorite'
        : props.activity.categories?.includes('Want to go')
            ? 'Want to go'
            : undefined;

    return <PlaceTypeIcon
        type={props.activity.activityType ?? props.activity.placetype ?? PlaceType.Activity}
        fallback={props.fallback}
        modifier={modifier}
    />;
}

export function PlaceTypeIcon(props: {
    type: PlaceType,
    fallback: boolean,
    size?: number,
    strokeColor?: string,
    modifier?: SavedType
}) {
    const colorScheme = useColorScheme();

    if (!props.fallback && props.type === PlaceType.Activity) {
        return null;
    } else {
        return <ActivitySVG
            type={props.type}
            context='markup'
            size={props.size ?? 24}
            strokeColor={props.strokeColor}
            mode={colorScheme}
            modifier={props.modifier}
            styleTarget='inline'
        />;
    }
}
