import React, {
    CSSProperties,
    PropsWithChildren,
    useEffect,
    useRef,
    useState,
} from "react";
import { ImageCache } from "@travelscroll/capacitor-image-cache";
import { getLogger } from "../lib/logger";

const logger = getLogger("TSImageCache");

// TODO perhaps put this into a context
const cache = new ImageCache();

// TODO add placeholder support during load

function stylesForImage(props: { style?: CSSProperties }, cacheUrl: string | undefined) {
    const style: CSSProperties = props.style ? { ...props.style } : {};
    if (!cacheUrl) {
        style.border = '1px inset rgba(0,0,0,.2)';
    }
    return style;
}

const whiteSvg = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1"><path d="M0,0h1v1H0z" fill="#FFF"/></svg>';
const whiteSvgDataUrl = `data:image/svg+xml;base64,${btoa(whiteSvg)}`;
export function TSCachedImg(props: PropsWithChildren & {
    style: CSSProperties,
    onFetchFailure?: () => unknown,
    src: string | null | undefined,
    lazyLoading: boolean,
    alt: string,
    onClick?: () => unknown,
}) {
    const cacheUrl = useCacheUrl(props.src, props.onFetchFailure);
    const style = stylesForImage({}, cacheUrl);

    const imgRef = useRef<HTMLImageElement>(null as unknown as HTMLImageElement);

    // TODO consider if we can replace with next.Image, including error-handling
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    return <div style={{ position: 'relative' }} onClick={props.onClick}>
        <img
            ref={imgRef}
            loading={props.lazyLoading ? 'lazy' : undefined}
            alt={props.alt}
            style={{
                minWidth: '100%',
                width: '100%',
                objectFit: 'cover',
                ...props.style,
                border: (style.border as string) ?? undefined,
            }}
            src={cacheUrl ?? whiteSvgDataUrl}
            onError={() => {
                if (imgRef.current) {
                    // TODO should we use a placeholder here?
                    imgRef.current.src = whiteSvgDataUrl;
                }
                props.onFetchFailure && props.onFetchFailure();
            }}
        />
        {props.children}
    </div>;
}

export function useCacheUrl(url: string | undefined | null, onFetchFailure: (() => unknown) | undefined) {
    const [ cacheUrl, setCacheUrl ] = useState<string | undefined>();

    useEffect(() => {
        if (url) {
            cache.get({ src: url })
                .then(response => setCacheUrl(response.value))
                .catch(err => {
                    if (err !== 'Error: cancelled') {
                        logger.warn(`Trapped an error while fetching a URL from cache. Error: '${err}'; url: '${url}'`);
                    }
                    onFetchFailure && onFetchFailure();
                });
        } else {
            setCacheUrl(undefined);
        }
    }, [ url ]);

    return cacheUrl;
}
