import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ActionIcon, Chip, Slider, Text, TextInput, useMantineTheme } from "@mantine/core";
import { GlobeMethods, GlobeProps } from "react-globe.gl";
import { IconCircleArrowUp, IconPlayerPauseFilled, IconPlayerPlayFilled } from "@tabler/icons-react";
import { useRouter } from "next/navigation";
import * as THREE from 'three';
import { RequiresAuthnAndData } from "../../../components/RequiresAuthnAndData";
import { useToday } from "../../../components/TripActivityScreen/dates";
import { HomeContext } from "../../../lib/context";
import { Position, positionFromLatLng } from "../../../lib/position";
import { Trip } from "../../../lib/interfaces";
import { TSDate } from "../../../lib/time";
import { ClearTextAreaRightSection } from "../../../components/AddEditTrip/ClearTextAreaRightSection";
import { isSearchMatch } from "../../../lib/searchHooks";
import { useQueryParams } from "../../../lib/urls";
import { TravelScrollMenu } from "../../../components/TravelScrollMenu";

'use client';

export default function TravelerHistory() {
    const [ Globe, setGlobe ] = useState<React.FunctionComponent<GlobeProps> | undefined>();

    useEffect(() => {
        import('react-globe.gl').then(g => setGlobe(g.default));
    }, []);

    return <RequiresAuthnAndData isLoading={!Globe} childProvider={() => <AnnotatedGlobe Globe={Globe!} />} />;
}

TravelerHistory.debugId = 'TravelerHistory';

type Path = [
    { lat: number, lng: number, trip: Trip, alpha: number },
    { lat: number, lng: number, trip: Trip, alpha: number }
];

function createPaths(trips: (Trip & Position)[], today: TSDate) {
    const sorted = trips
        .sort((a, b) => a.startdate
            ? b.startdate
                ? a.startdate.compareTo(b.startdate)
                : -1
            : 1);

    if (sorted.length === 0) {
        return [];
    }

    const oldest = sorted.find(t => t.startdate)?.startdate;
    const newest = sorted[sorted.length - 1].startdate;
    const spanInDays = newest && oldest ? newest.compareTo(oldest) : 0; // if either is null, the other is too

    const paths: Path[] = [];
    for (let i = 1; i < sorted.length; i++) {
        const age = sorted[i].startdate ? today.compareTo(sorted[i].startdate!) : 0;
        const alpha = age < 0
            ? 1
            : spanInDays === 0 ? 1 : (spanInDays - age) / spanInDays;
        paths.push([
            { lat: sorted[i - 1].lat, lng: sorted[i - 1].lng, trip: sorted[i - 1], alpha },
            { lat: sorted[i].lat, lng: sorted[i].lng, trip: sorted[i], alpha },
        ]);
    }
    return paths;
}

type TimeFilter = 'all' | 'last-6' | 'last-12';
function dateRangeForFilter(filter: TimeFilter, today: TSDate): [TSDate, TSDate] | undefined {
    switch (filter) {
        case "last-12":
            return [ today.minus({ days: 365 }), today ];
        case "last-6":
            return [ today.minus({ days: 183 }), today ];
        default:
            return undefined;
    }
}

function TripHeader(props: { trip?: Trip & Position }) {
    const router = useRouter();

    const title = props.trip?.title;
    const location = props.trip?.location !== title ? props.trip?.location : undefined;
    const date = props.trip?.startdate;
    const locationAndDate = location && date
        ? `${location} — ${date.toString()}`
        : date?.toString() ?? undefined;

    if (!props.trip) {
        return null;
    }

    return <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <div style={{ paddingTop: '0.25rem' }}><TravelScrollMenu /></div>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Text
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
                size='lg'
                fw='bold'
            >
                {title}
            </Text>
            <Text
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
                size='md'
            >
                {locationAndDate}
            </Text>
        </div>
        <div style={{ paddingTop: '1rem' }}>
            <ActionIcon size={18} onClick={() => router.push(`/trip/${props.trip?.docid}`)}>
                <IconCircleArrowUp />
            </ActionIcon>
        </div>
    </div>;
}

class GreatCircle extends THREE.Curve<THREE.Vector3> {
    private startPoint: THREE.Vector3;
    private endPoint: THREE.Vector3;
    private radius: number;

    constructor(startPoint: THREE.Vector3, endPoint: THREE.Vector3) {
        super();
        this.startPoint = startPoint;
        this.endPoint = endPoint;
        this.radius = this.startPoint.length();
    }

    public getPoint(t: number): THREE.Vector3 {
        return this.startPoint.clone()
            .multiplyScalar(t)
            .add(this.endPoint.clone().multiplyScalar(1 - t))
            .normalize()
            .multiplyScalar(this.radius);
    }
}

function greatCircleForPath(path: Path, globe: GlobeMethods) {
    const photoCoords = globe.getCoords(path[0].lat, path[0].lng, 0.001);
    const endPoint = globe.getCoords(path[1].lat, path[1].lng, 0.001);
    const startVector = new THREE.Vector3(photoCoords.x, photoCoords.y, photoCoords.z);
    const endVector = new THREE.Vector3(endPoint.x, endPoint.y, endPoint.z);
    return new GreatCircle(startVector, endVector);
}

function AnnotatedGlobe({ Globe }: { Globe: React.FunctionComponent<GlobeProps & { ref?: React.MutableRefObject<GlobeMethods | undefined> }> }) {
    const theme = useMantineTheme();
    const { userTrips } = useContext(HomeContext);
    const queryParams = useQueryParams();
    const today = useToday();

    const timeFilter = queryParams.first('timeFilter') as TimeFilter ?? 'all';
    const searchFilter = queryParams.first('searchFilter') ?? '';

    const filtered = useMemo(
        () => (userTrips ?? [])
            .filter(t => {
                const position = positionFromLatLng(t);
                if (!position) {
                    return false;
                }

                const dateRange = dateRangeForFilter(timeFilter, today);
                if (dateRange
                    && (!t.startdate
                        || t.startdate.isBefore(dateRange[0])
                        || t.startdate.isAfter(dateRange[1]))) {
                    return false;
                }

                if (searchFilter !== '') {
                    return isSearchMatch(searchFilter, [ t.title, t.location, t.address ]);
                } else {
                    return true;
                }
            }) as (Trip & Position)[],
        [ userTrips, timeFilter, searchFilter, today ]
    );

    const averageLatitude = useMemo(
        () => {
            const latitudes = filtered.map(position => position.lat);
            if (latitudes.length > 0) {
                const sum = latitudes.reduce((total, current) => total + current, 0);
                return sum / latitudes.length;
            } else {
                return 0;
            }
        },
        [ filtered ]
    );

    const tripPoints = useMemo(() => filtered
        .map((trip, index) => ({
            lat: trip.lat,
            lng: trip.lng,
            size: 0.001,
            color: 'yellow',
            trip,
        })), [ filtered ]);
    const paths = useMemo(
        () => createPaths(filtered, today),
        [ filtered, today ]
    );

    const [ hasPerformedZoom, setHasPerformedZoom ] = useState(false);

    const [ animating, setAnimating ] = useState(true);
    const [ currentRenderId, setCurrentRenderId ] = useState(0);
    const [ selectedTripId, setSelectedTripId ] = useState<string | undefined>(undefined);

    const globeContainerDiv = useRef<HTMLDivElement>(null as unknown as HTMLDivElement);
    const isInteractingRef = useRef(false);
    const globeRef = useRef<GlobeMethods | undefined>();

    useEffect(() => {
        if (animating) {
            let timeout: any;
            (function nextPath() {
                setCurrentRenderId(prev => prev + 1);
                timeout = setTimeout(() => requestAnimationFrame(nextPath), 5000);
            }());
            return () => { timeout && clearTimeout(timeout); };
        } else {
            return () => undefined;
        }
    }, [ animating ]);

    const currentTripIndex = animating
        ? filtered.length > 0
            ? currentRenderId % filtered.length
            : 0
        : Math.max(0, filtered.findIndex(trip => trip.docid === selectedTripId));

    const tripToRender = filtered[currentTripIndex];
    const pathToRender = currentTripIndex === 0 || !animating
        ? undefined
        : paths.find((path, index) => path[1].trip === tripToRender);
    const globeSegments = 5;
    const globeCanvasHeight: number | undefined = globeContainerDiv.current?.clientHeight ?? undefined;
    const footerHeight = globeContainerDiv.current ? globeContainerDiv.current.clientHeight / globeSegments + 2 : undefined;

    const repositionAnimationDuration = 2000;

    if (tripToRender) {
        // If the user has zoomed into the globe, we preserve the latitude and zoom that was set; otherwise,
        // we move to the average latitude and a zoom level that empirically fits well.
        const coords = globeRef.current?.pointOfView();
        globeRef.current?.pointOfView(
            {
                lat: coords && hasPerformedZoom && !Number.isNaN(coords.lat) ? coords.lat : averageLatitude,
                lng: tripToRender.lng,
                altitude: coords && hasPerformedZoom && !Number.isNaN(coords.altitude) ? coords.altitude : 2.5,
            },
            repositionAnimationDuration);
    }

    return <div
        className='dynamic-viewport-height safeAreaPaddingTop safeAreaPaddingBottom'
        style={{
            display: 'grid',
            gridTemplateRows: `1fr ${globeSegments}fr 1fr`,
            gridAutoRows: 'true',
        }}
    >
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 'auto',
            marginBottom: 'auto',
            paddingLeft: '1rem',
            paddingRight: '1rem',
        }}>
            <TripHeader trip={tripToRender} />
            <div
                style={{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                { !animating && <ActionIcon>
                    <IconPlayerPlayFilled size={15} onClick={() => {
                        setAnimating(true);
                        setCurrentRenderId(currentTripIndex); // reset the render ID so that we start from the current trip
                    }} />
                </ActionIcon> }
                { animating && <ActionIcon>
                    <IconPlayerPauseFilled size={15} onClick={() => {
                        setAnimating(false);
                        setSelectedTripId(tripToRender.docid ?? undefined); // snapshot the current trip id so that we maintain our position
                    }} />
                </ActionIcon> }
                <div style={{ flex: '1' }}>
                    <Slider
                        style={{ width: '100%' }}
                        styles={{
                            track: {
                                marginLeft: 0,
                                marginRight: 0,
                            },
                        }}
                        min={0}
                        max={filtered.length - 1}
                        step={1}
                        value={currentTripIndex}
                        onChange={index => {
                            setAnimating(false);
                            setSelectedTripId(filtered[index].docid ?? undefined);
                        }}
                    />
                </div>
            </div>
        </div>
        <div ref={globeContainerDiv}>
            { globeCanvasHeight !== undefined &&
                <Globe
                    ref={globeRef}
                    onGlobeReady={() => {
                        const canvas = globeContainerDiv.current.querySelector('canvas');
                        // TODO how to de-register?
                        canvas?.addEventListener('touchstart', () => { isInteractingRef.current = true; });
                        canvas?.addEventListener('touchend', () => { isInteractingRef.current = false; });
                        canvas?.addEventListener('scroll', () => { isInteractingRef.current = true; });
                        canvas?.addEventListener('scrollend', () => { isInteractingRef.current = false; });
                        canvas?.addEventListener('wheel', () => { isInteractingRef.current = true; });
                        canvas?.addEventListener('wheelend', () => { isInteractingRef.current = false; });
                    }}
                    globeImageUrl="/images/earth-day.jpg"
                    pointsData={tripPoints}
                    pointAltitude="size"
                    pointColor="color"
                    onPointClick={point => {
                        const { trip } = point as any;
                        setAnimating(false);
                        setSelectedTripId(trip.docid ?? undefined);
                    }}
                    height={globeCanvasHeight}
                    onZoom={(pov) => {
                        if (isInteractingRef.current) {
                            setHasPerformedZoom(true);
                        }
                    }}
                    customLayerData={pathToRender ? [ { path: pathToRender } ] : []}
                    customThreeObject={d => buildCustomThreeObject(d, globeRef.current!, repositionAnimationDuration)}
                />
            }
        </div>
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '1rem',
                paddingBottom: 'calc(1rem + env(safe-area-inset-bottom))',
                paddingLeft: '1rem',
                paddingRight: '1rem',
                marginTop: 'auto',
                marginBottom: 'auto',
                gap: '0.5rem',
                backgroundColor: theme.colors.dark[8],
                position: "absolute",
                height: `calc(var(--keyboard-height, 0) + ${footerHeight}px)`,
                bottom: 0,
                width: '100%',
        }}>
            <div
                style={{ display: 'flex', gap: '0.25rem' }}
            >
                <Chip.Group
                    multiple={false}
                    value={timeFilter}
                    onChange={value => {
                        if (value && value.length > 0) {
                            queryParams.replace({ timeFilter: value }, []);
                        } else {
                            queryParams.replace({ }, [ 'timeFilter' ]);
                        }
                    }}
                >
                    <Chip value='all'>All</Chip>
                    <Chip value='last-12'>Last 12 Months</Chip>
                    <Chip value='last-6'>Last 6 Months</Chip>
                </Chip.Group>
            </div>
            <TextInput
                value={searchFilter}
                onChange={event => queryParams.replace({ searchFilter: event.currentTarget.value }, [])}
                placeholder='Filter...'
                rightSection={<ClearTextAreaRightSection onClear={() => queryParams.replace({}, [ 'searchFilter' ])} />}
            />
        </div>
    </div>;
}

function buildCustomThreeObject(record: Object, globe: GlobeMethods, repositionAnimationDuration: number) {
    const { path } = record as { path: Path };
    const curve = greatCircleForPath(path, globe);

    const group = new THREE.Group();
    const length = curve.getLength();

    // We divide, floor and multiply to guarantee an even number, which becomes odd via 'getSpacedPoints()'
    const segments = Math.floor(Math.floor(length) / 2) * 2;

    const points = curve.getSpacedPoints(segments);
    points.reverse();

    const material = new THREE.MeshPhysicalMaterial({ color: 'orange', thickness: 100 });
    let i = 0;
    let start: DOMHighResTimeStamp;
    const next = (time: DOMHighResTimeStamp) => {
        if (!start) {
            start = time;
        }
        const percent = (time - start) / repositionAnimationDuration;
        for (; i < (points.length * percent) - 1 && i < points.length - 1; i += 2) {
            // TODO we could perhaps make the curves directly from angular math instead of going back and forth to points
            const segmentCurve = new GreatCircle(
                new THREE.Vector3(points[i].x, points[i].y, points[i].z),
                new THREE.Vector3(points[i + 1].x, points[i + 1].y, points[i + 1].z));
            const tubeGeometry = new THREE.TubeGeometry(
                segmentCurve,
                1,
                1,
                8,
                false);
            group.add(new THREE.Mesh(tubeGeometry, material));
        }

        if (percent < 1) {
            requestAnimationFrame(next);
        }
    };
    requestAnimationFrame(next);
    return group;
}
