import { Affix, Indicator, Menu, Text } from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import React, { useContext, useEffect, useState } from 'react';
import { doc, writeBatch } from 'firebase/firestore';
import { notifications } from "@mantine/notifications";
import { IconX } from '@tabler/icons-react';
import { Camera, CloudUpload, Trash } from 'framework7-icons-plus/react';
import { byImageActivityOrder, PlaceImage } from '../../lib/interfaces';
import { useFirebaseRefs } from '../../lib/firebase';
import { showModalAsync } from '../TSModal';
import { SheetsContext } from '../Sheets';
import { deleteImage } from '../ImageLightbox';
import { TSCachedImg } from "../TSImageCache";

import classes from "./RearrangeImages.module.css";

export function RearrangeImages({ placeImages, onClose }:
    { placeImages: PlaceImage[], onClose: () => unknown }): JSX.Element {
    const firebaseRefs = useFirebaseRefs();
    const [ state, handlers ] = useListState<PlaceImage>([]);
    const [ openEdit, setOpenEdit ] = useState(false);
    const [ showEditButton, setShowEditButton ] = useState(true);
    const sheets = useContext(SheetsContext);

    useEffect(() => {
        placeImages.sort(byImageActivityOrder);
        handlers.setState(placeImages);
    }, []);

    const items = openEdit
        ? state.map((item, index) => (
            <Draggable index={index} draggableId={item.docid!} isDragDisabled>
                {(provided, snapshot) => (
                    <Indicator
                        onClick={async () => {
                            setShowEditButton(false);
                            const response = await showModalAsync(
                                sheets,
                                'Remove Photo?',
                                [
                                    { title: 'Cancel' },
                                    { title: 'Remove', id: 'remove', type: 'destructive' },
                                ]
                            );
                            if (response.type === 'choice' && response.choice.id === 'remove') {
                                deleteImage(firebaseRefs, item);
                                handlers.remove(index);
                                setShowEditButton(true);
                            }
                        }}
                        m='md'
                        label={<IconX size={18} />}
                        inline
                        color="red"
                        position="top-start"
                        size={30}
                        withBorder
                    >
                        <div
                            className={`${classes.item} ${snapshot.isDragging ? classes.itemDragging : ''}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <TSCachedImg
                                style={{ height: '80px', width: '80px' }}
                                src={item.url400h ?? item.url}
                                alt='Trip Image'
                                lazyLoading={false}
                            />
                        </div>
                    </Indicator>
                )}
            </Draggable>
        ))
        : state.map((item, index) => (
            <Draggable index={index} draggableId={item.docid!}>
                {(provided, snapshot) => (
                    <div
                        className={`${classes.item} ${snapshot.isDragging ? classes.itemDragging : ''}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <TSCachedImg
                            key={`${index}`}
                            style={{ height: '80px', width: '80px' }}
                            src={item.url400h ?? item.url}
                            alt='Trip Image'
                            lazyLoading={false}
                        />
                    </div>
                )}
            </Draggable>
        ));

    function writeReorder(result: PlaceImage[]) {
        const batch = writeBatch(firebaseRefs.firestore);
        //firebase batchwrite result
        result.forEach((img, i) => {
            const placeDoc = doc(firebaseRefs.firestore, 'trips', img.tripId!, 'pictures', img.docid!);
            batch.set(placeDoc, { activityOrder: i }, { merge: true });
        });
        batch.commit().catch((e: any) => notifications.show({ message: `Your photos did not reorder - ${e.message}` }));
    }

    function sortByTaken() {
        sortBy((a, b) => (a.phototaken?.toMillis() ?? 0) - (b.phototaken?.toMillis() ?? 0));
    }

    function sortByUploaded() {
        sortBy((a, b) => (a.docCreated?.toMillis() ?? 0) - (b.docCreated?.toMillis() ?? 0));
    }

    function sortBy(comparator: (a: PlaceImage, b: PlaceImage) => number) {
        const copy = [ ...state ];
        copy.sort(comparator);

        let arraysAreEqual = true;
        for (let i = 0; i < copy.length; i++) {
            if (copy[i] !== state[i]) {
                arraysAreEqual = false;
                break;
            }
        }

        if (!arraysAreEqual) {
            writeReorder(copy);
            handlers.setState(copy);
        }
    }

    function reorderWithin(startIndex: number, endIndex: number) {
        const result = Array.from(state);
        const [ removed ] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        writeReorder(result);
    }

    return (<>
        <DragDropContext
            onDragEnd={({ destination, source }) => {
                handlers.reorder({ from: source.index, to: destination?.index || 0 });
                if (destination) { reorderWithin(source.index, destination.index); }
            }}
        >
            <Droppable droppableId="dnd-list" direction="horizontal">
                {(provided) => (
                    <div {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'start',
                            flexWrap: 'wrap',
                        }}>
                        {items}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
        {showEditButton && <Affix position={{
            bottom: 20,
            right: 20,
            }}
            zIndex={10000000}
            >
            <Menu shadow="md" width={200}>
                <Menu.Target>
                    <Text size='md' p='md' color='dimmed'>
                        Edit
                    </Text>
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Item
                        leftSection={<Camera height={18} />}
                        onClick={async () => {
                            sortByTaken();
                            setOpenEdit(false);
                        }}
                    >
                        Sort by date taken
                    </Menu.Item>
                    <Menu.Item
                        leftSection={<CloudUpload height={18} />}
                        onClick={async () => {
                            sortByUploaded();
                            setOpenEdit(false);
                        }}
                    >
                        Sort by date uploaded
                    </Menu.Item>
                    <Menu.Item
                        leftSection={<Trash height={18} />}
                        onClick={async () => setOpenEdit(!openEdit)}
                    >
                        Delete Photos
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </Affix>}
    </>);
}
